import './CardField.css';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Field } from '../Field';

export class CardField extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {

  }


  render() {
    const { fieldConfig, nextProp, indexNext, funtionHandleSetProps, handleErrorInComponent, parentRef, index, idCurrentCategory } = this.props;
    return (
      <>
        <Card className='letters card-question'>
          <Card.Body className="card-body-question">
            <Card.Title className='text-center'>
              {fieldConfig.title}
            </Card.Title>
            <Card.Text className='text-left title-card'>
              {fieldConfig.body}
            </Card.Text>
            <Row className='justify-content-center'>
              <Col md='12'>
                <Field
                  key={fieldConfig.id}
                  fieldConfig={fieldConfig}
                  funtionHandleSetProps={funtionHandleSetProps}
                  handleErrorInComponent={handleErrorInComponent}
                  parentRef={parentRef}
                  index={index}
                  nextProp={nextProp}
                  indexNext={indexNext}
                  idCurrentCategory={idCurrentCategory}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

      </>
    );
  }
}
export default CardField;
