import React from "react";
import { LoadingPage } from "../../pages/LoadingPage";
import { Card } from "react-bootstrap";
import { SelectedRiderSavingService } from "../../Services/SelectedRiderSavingService";
import './SelectedRiderSaving.css';
import { PaymentDetails } from "../SelectedRiderList/PaymentDetails";
import { ButtonTSV } from '../ButtonTSV';
import { InsuranceAplicationServices } from "../../Services/InsuranceApplicationServices";
import { TokenServices } from "../../Services/TokenServices";
import { JSONExchangeTSV } from "../../Services/JSONExchangeTSV";
import { SelectedSavingProjection } from "./SelectedSavingProjection";
import { SelectedProductListServices } from "../../Services/SelectedProductListServices";
import { ParameterService } from "../../Services/ParameterService";
import { SelectedRiderListService } from "../../Services/SelectedRiderListService";
import { ModalInfo } from '../ModalInfo';

const { REACT_APP_PAYMENT_TYPE, REACT_APP_PAYMENT_MODE, REACT_APP_MINIMUM_INITIAL_DEPOSIT,
    REACT_APP_DISCLAIMER_PARAM_ID, REACT_APP_MAX_SUM_IRA, REACT_APP_MAX_SUM_ANUALIDAD, REACT_APP_IDTSVPRODUCT_IRA,
    REACT_APP_IDTSVPRODUCT_ANUALIDAD, REACT_APP_IDPARAMETER_MONTHLY, REACT_APP_INITIAL_DEPOSIT_DESCRIPTION,
    REACT_APP_ADITIONAL_DEPOSIT_DESCRIPTION,REACT_APP_URL_TSV_REQUEST } = process.env;

export class SelectedRiderSaving extends React.Component {
    constructor(props) {
        super(props);
        this.selectedRiderListService = new SelectedRiderListService();
        this.selectedRiderSavingService = new SelectedRiderSavingService();
        this.insuranceApplicationServices = new InsuranceAplicationServices();
        this.jsonExchangeTSV = new JSONExchangeTSV();
        this.selectedProductlistServices = new SelectedProductListServices();
        this.parameterServices = new ParameterService();
        this.tokenService = new TokenServices();
        this.state = {
            send: false,
            urlRedirect: `${REACT_APP_URL_TSV_REQUEST}`,
            loading: false, value: '', valueEntry: '', errorMessageInitial: '', validInitial: true,
            errorMessageInitialEntry: '', validEntry: true, refresh: false, refreshType: false, projections: false,
            isUpdateValue: true, isUpdateEntry: true, errorMessage: '', disclaimer: '',
            errorMessageSum: '',
            modalOptions: { show: false, title: '', description: '', differentIcon: true },
        };
        this.riderListFunction = this.riderListFunction.bind(this);
    }

    /**
     * Function to return to the previous screen.
     */
    riderListFunction() {
        if (this.props.handleClickBackRider) {
            this.props.handleClickBackRider();
        }
    }

    /**
      * This method upload the payment method information
      */
    componentDidUpdate(prevProps, prevState) {
    }

    /**
      * This method upload the payment method information
      */
    async componentDidMount() {
        let response = this.selectedRiderSavingService.GetCurrentSavingAmount();

        if (response) {
            this.setState({ value: response.initial_deposit, valueEntry: response.additional_deposit });
        } else {
            this.selectedRiderSavingService.SetSavingAmount(this.state.value, this.state.valueEntry);
        }
        let disclaimers = await this.parameterServices.GetAllParameterById(
            { 'idParameterValue': Number(REACT_APP_DISCLAIMER_PARAM_ID) });
        if (disclaimers.data.length > 0) {
            let selectedProduct = this.jsonExchangeTSV.getCurrentSelectedProduct();
            let currentDisclaimer = disclaimers.data.filter(disclaimer => Number(disclaimer.name)
                == Number(selectedProduct.id_product));
            if (currentDisclaimer[0]) {
                this.setState({ disclaimer: currentDisclaimer[0].value });
            }

        }

    }

    /**
      * Function to update the status of the payment method.
      */
    refreshData = () => {
        let refresh = !this.state.refresh;
        this.setState({ refresh: refresh });
    }

    /**
      * Function to update the status of the payment frequency.
      */
    refreshTypeData = () => {
        let refreshType = !this.state.refreshType;
        let refresh = !this.state.refresh;
        this.setState({ refreshType: refreshType, refresh: refresh });
    }

    /**
      * Function that changes the payment type that has been selected.
      * @param {Event} event change value of selection field
      */
    handleChangeInitial(event) {
        let response = this.selectedRiderSavingService
            .HandleInputChange(event.target.value, Number(REACT_APP_MINIMUM_INITIAL_DEPOSIT));
        let { value, message, valid } = response;
        this.setState({ value: value, errorMessageInitial: message, validInitial: valid, isUpdateValue: false });

        if (response.valid) {
            this.selectedRiderSavingService.SetSavingAmount(response.value, this.state.valueEntry);
            this.validateAmountSum(value, this.state.valueEntry);
        }
    }

    /**
      * Function that changes the payment type that has been selected.
      * @param {Event} event change value of selection field
      */
    handleChangeEntry(event) {
        let response = this.selectedRiderSavingService.HandleInputChange(event.target.value, null);
        let { value, message, valid } = response;
        this.setState({ valueEntry: value, errorMessageEntry: message, validEntry: valid, isUpdateEntry: false });

        if (valid) {
            this.selectedRiderSavingService.SetSavingAmount(this.state.value, value);
            this.validateAmountSum(this.state.value, value);
        }


    }

    validateAmountSum(ValInitial, ValEntry) {
        let payment = this.selectedRiderListService.GetPayment();
        if (Number(payment.modeIntervals) === Number(REACT_APP_IDPARAMETER_MONTHLY)) {

            let idProduct = this.props.idTSVProduct;
            let errorMessage = '';
            let max = 0;

            let initialValue = ValInitial !== '' && ValInitial ? ValInitial.replaceAll(',', '') * 1 : 0;
            let aditionalValue = ValEntry !== '' && ValEntry ? ValEntry.replaceAll(',', '') * 12 : 0;
            let total = initialValue + aditionalValue;


            let totalFormat = this.selectedRiderSavingService.HandleInputChange(total.toString(), Number(total));

            if (idProduct === REACT_APP_IDTSVPRODUCT_IRA) {
                max = Number(REACT_APP_MAX_SUM_IRA);
                errorMessage = 'Para el producto de IRA el monto anual del depósito inicial junto al depósito adicional no puede sobrepasar un total de $5.000. Actualmente está solicitando $';
            }

            if (idProduct == REACT_APP_IDTSVPRODUCT_ANUALIDAD) {
                max = Number(REACT_APP_MAX_SUM_ANUALIDAD);
                errorMessage = 'Para el producto de Anualidad el monto anual del depósito inicial junto al depósito adicional no puede sobrepasar un total de $500.000. Actualmente está solicitando $';
            }

            if (Number(total) > max) {
                this.setState({
                    validInitial: false,
                    validEntry: false,
                    errorMessageSum: `${errorMessage}${totalFormat.value}`
                })
            } else {
                this.setState({
                    validInitial: true,
                    validEntry: true,
                    errorMessageSum: ''
                })
            }
        }

    }


    /**
      * Process the call to pass to the next component
      */
    handleClickNext = async () => {
        this.setState({ loading: true });
        if (this.isValid()) {
            if (this.state.isUpdateValue && this.state.isUpdateEntry) {
                this.props.handleClickNext();
                let applicationSent = await this.selectedRiderSavingService.SendApplicationSale();
                if (applicationSent) {
                    let currentCategory = this.jsonExchangeTSV.getCurrentSerie();
                    if (applicationSent.success) {
                        let JSONprocessFileCreate = await this.selectedRiderSavingService.CreateJSONProcessFile();
                        if (!JSONprocessFileCreate) {
                            this.props.handleErrorInComponent(true);
                        } else {
                            const token = this.tokenService.DecryptToken();
                            let insuranceApplication = {
                                'idTSVApplication': applicationSent.data,
                                'name': 'N/A',
                                'firstSurname': 'N/A',
                                'email': 'N/A',
                                'phone': 'N/A',
                                'comments': 'N/A',
                                'idCategory': currentCategory.id_series ? Number(currentCategory.id_series) : null,
                                'idJSONProcess': JSONprocessFileCreate.idJSONProcessFile ? JSONprocessFileCreate.idJSONProcessFile : null
                            };
                            let applicationSaved = await this.insuranceApplicationServices.AddInsuranceApp
                                (insuranceApplication, token);

                            if (!applicationSaved || !applicationSaved.statusResponse) {
                                this.props.handleErrorInComponent(true);

                            } else {
                                let selectedProduct = this.jsonExchangeTSV.getCurrentSelectedProduct();
                                this.jsonExchangeTSV.setInsuredPersons(this.selectedProductlistServices.
                                    ProcessInsuredPersonsApproved(
                                        selectedProduct.insured_persons_approved));
                                window.open(`${REACT_APP_URL_TSV_REQUEST}${insuranceApplication.idTSVApplication}`, '_blank');
                                this.setState({ loading: false, send: true, urlRedirect: `${REACT_APP_URL_TSV_REQUEST}${insuranceApplication.idTSVApplication}` });                          
                            }
                        }
                    } else {
                        this.props.handleErrorInComponent(true);
                    }
                } else {
                    this.props.handleErrorInComponent(true);
                }
            } else {
                let errorMessage = "*Para completar la solicitud debe primero VER PROYECCIONES";
                this.setState({ errorMessage: errorMessage });
            }
        }
    }

    /**
    * Renders a modal with de information of each answer option.
    */
    showModal(title, description) {
        let modalOptions = { show: true, title: title, description: description, differentIcon: true };
        this.setState({ modalOptions: modalOptions });
    }

    /**
     * Close the modal with de information of each answer option.
     */
    closeModal = () => {
        let modalOptions = { show: false };
        this.setState({
            modalOptions: modalOptions
        });
    };



    /**
      * Function that validates that the entered values are correct
      */
    isValid() {
        if (!this.state.validEntry || !this.state.validInitial) {
            return false
        }

        let { value, valueEntry } = this.state;
        let responseValue = this.selectedRiderSavingService.HandleInputChange(value, Number(REACT_APP_MINIMUM_INITIAL_DEPOSIT));
        this.setState({ value: responseValue.value, errorMessageInitial: responseValue.message, validInitial: responseValue.valid });

        let responseEntry = this.selectedRiderSavingService.HandleInputChange(valueEntry, null);
        this.setState({ valueEntry: responseEntry.value, errorMessageEntry: responseEntry.message, validEntry: responseEntry.valid });

        if (responseValue.valid && responseEntry.valid) {
            return true;
        }
        return false;
    }

    /**
     * Show and update the projections table
     */
    showProjections = () => {
        if (this.isValid()) {
            this.setState({ projections: true, isUpdateEntry: true, isUpdateValue: true, errorMessage: '' });
        }
    }

    /**
     * Hide the projection table 
     */
    hideProjections = () => {
        this.setState({ projections: false });
    }

    render() {
        const { loading, disclaimer, modalOptions, send, urlRedirect } = this.state;

        if (loading) {
            return (
                <div className='page'> <LoadingPage /> </div>
            );
        }

        return (
            <div className="container shadow-sm letters rider">

                <div >
                    <p className="title-wel-sub-rider mt-4">
                    Ingresa la información para ver tu proyección de ahorro:
                    </p>
                    <div className='main-rider'>
                        <Card className='riders-list'>
                            <Card.Body>
                                <div className="row justify-content-md-center">
                                    <div className="col-sm-12 col-md-5">
                                        <PaymentDetails
                                            text={'Método de Pago'}
                                            idPayment={REACT_APP_PAYMENT_TYPE}
                                            handleErrorInComponent={this.props.handleErrorInComponent}
                                            refreshData={this.refreshData} />
                                    </div>
                                    <div className=" col-sm-12 col-md-5">
                                        <PaymentDetails
                                            text={'Frecuencia de Pago'}
                                            idPayment={REACT_APP_PAYMENT_MODE}
                                            handleErrorInComponent={this.props.handleErrorInComponent}
                                            refreshTypeData={this.refreshTypeData} />
                                    </div>
                                </div>
                                <div className="row justify-content-md-center">
                                    <div className="col-sm-12 col-md-5">
                                        <Card.Text className='text-left title-card'>
                                        {'Depósito Inicial'}
                                        </Card.Text>
                                        <input
                                            type="text"
                                            placeholder="Depósito Inicial"
                                            onChange={(event) => this.handleChangeInitial(event)}
                                            value={this.state.value}
                                            className={this.state.validInitial ? ('form-control custom-input input-rider') : ('form-control is-invalid is-invalid-border custom-input')}
                                            required={true}
                                        />
                                        <a style={{ display: 'block' }}>
                                            <i className={'modal-icon-rider-save'} tabIndex="0" aria-label="Más información" role="button"
                                                onClick={() => this.showModal('Depósito Inicial', REACT_APP_INITIAL_DEPOSIT_DESCRIPTION)}
                                                style={this.state.validInitial ? { display: 'block' } : { display: 'none' }}>
                                                <svg width="20" height="40" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="9" cy="16" r="9" transform="rotate(-90 9 16)" fill="#052675" />
                                                    <path d="M8.02148 18.4478V17.9058C8.02148 17.437 8.12158 17.0293 8.32178 16.6826C8.52197 16.3359 8.88818 15.9673 9.42041 15.5767C9.93311 15.2104 10.27 14.9126 10.4312 14.6831C10.5972 14.4536 10.6802 14.1973 10.6802 13.9141C10.6802 13.5967 10.563 13.355 10.3286 13.189C10.0942 13.0229 9.76709 12.9399 9.34717 12.9399C8.61475 12.9399 7.77979 13.1792 6.84229 13.6577L6.04395 12.0537C7.13281 11.4434 8.2876 11.1382 9.5083 11.1382C10.5142 11.1382 11.3125 11.3799 11.9033 11.8633C12.499 12.3467 12.7969 12.9912 12.7969 13.7969C12.7969 14.334 12.6748 14.7979 12.4307 15.1885C12.1865 15.5791 11.7227 16.0186 11.0391 16.5068C10.5703 16.8535 10.2725 17.1172 10.1455 17.2979C10.0234 17.4785 9.9624 17.7153 9.9624 18.0083V18.4478H8.02148ZM7.78711 20.9526C7.78711 20.5425 7.89697 20.2324 8.1167 20.0225C8.33643 19.8125 8.65625 19.7075 9.07617 19.7075C9.48145 19.7075 9.79395 19.8149 10.0137 20.0298C10.2383 20.2446 10.3506 20.5522 10.3506 20.9526C10.3506 21.3384 10.2383 21.6436 10.0137 21.8682C9.78906 22.0879 9.47656 22.1978 9.07617 22.1978C8.66602 22.1978 8.34863 22.0903 8.12402 21.8755C7.89941 21.6558 7.78711 21.3481 7.78711 20.9526Z" fill="white" />
                                                </svg>

                                            </i>
                                        </a>

                                        {!this.state.validInitial ?
                                            <i className="select-i-rider">
                                                <svg width='25' height='25' viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <ellipse cx="18.5" cy="19.3965" rx="18.5" ry="18.4428" fill="#F2274C" />
                                                    <path d="M17 27.8814C17 27.4259 17.1675 27.0299 17.5026 26.6932C17.8377 26.3566 18.2319 26.1883 18.6852 26.1883C19.1385 26.1883 19.5327 26.3566 19.8678 26.6932C20.2029 27.0299 20.3704 27.4259 20.3704 27.8814C20.3704 28.3368 20.2029 28.7229 19.8678 29.0398C19.5327 29.3368 19.1385 29.4853 18.6852 29.4853C18.2319 29.4853 17.8377 29.3368 17.5026 29.0398C17.1675 28.7229 17 28.3368 17 27.8814ZM18.3896 24.1388C18.3107 23.2081 18.222 22.2279 18.1235 21.1982C18.0446 20.1487 17.9559 19.119 17.8574 18.1091C17.7785 17.0794 17.6997 16.0992 17.6209 15.1685C17.5617 14.2378 17.5026 13.4061 17.4435 12.6734C17.3843 11.921 17.3351 11.3071 17.2957 10.8319C17.2562 10.3368 17.2365 10.0398 17.2365 9.94077C17.2365 9.40611 17.3843 9.02987 17.68 8.81205C17.9756 8.59423 18.3107 8.48532 18.6852 8.48532C19.0597 8.48532 19.3948 8.59423 19.6904 8.81205C19.9861 9.02987 20.1339 9.40611 20.1339 9.94077C20.1339 10.0992 20.0945 10.6635 20.0156 11.6338C19.9368 12.6041 19.8382 13.7824 19.72 15.1685C19.6214 16.5546 19.5032 18.0497 19.3652 19.6536C19.2272 21.2378 19.0991 22.7328 18.9809 24.1388H18.3896Z" fill="white" />
                                                </svg>
                                            </i> : null}
                                        <div className="text-left">
                                            {!this.state.validInitial &&
                                                <span className='error text-left'> {this.state.errorMessageInitial} </span>
                                            }
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-5">
                                        <Card.Text className='text-left title-card'>
                                        {'Depósito Adicional'}
                                        </Card.Text>
                                        <input
                                            type="text"
                                            placeholder="Depósito Adicional"
                                            onChange={(event) => this.handleChangeEntry(event)}
                                            value={this.state.valueEntry}
                                            required={true}
                                            className={this.state.validEntry ? ('form-control custom-input') : ('form-control is-invalid is-invalid-border custom-input')}
                                        />
                                        <a style={{ display: 'block' }}>
                                            <i className={'modal-icon-rider-save'} tabIndex="0" aria-label="Más información" role="button"
                                                onClick={() => this.showModal('Depósito Adicional', REACT_APP_ADITIONAL_DEPOSIT_DESCRIPTION)}
                                                style={this.state.validEntry ? { display: 'block' } : { display: 'none' }}>
                                                <svg width="20" height="40" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="9" cy="16" r="9" transform="rotate(-90 9 16)" fill="#052675" />
                                                    <path d="M8.02148 18.4478V17.9058C8.02148 17.437 8.12158 17.0293 8.32178 16.6826C8.52197 16.3359 8.88818 15.9673 9.42041 15.5767C9.93311 15.2104 10.27 14.9126 10.4312 14.6831C10.5972 14.4536 10.6802 14.1973 10.6802 13.9141C10.6802 13.5967 10.563 13.355 10.3286 13.189C10.0942 13.0229 9.76709 12.9399 9.34717 12.9399C8.61475 12.9399 7.77979 13.1792 6.84229 13.6577L6.04395 12.0537C7.13281 11.4434 8.2876 11.1382 9.5083 11.1382C10.5142 11.1382 11.3125 11.3799 11.9033 11.8633C12.499 12.3467 12.7969 12.9912 12.7969 13.7969C12.7969 14.334 12.6748 14.7979 12.4307 15.1885C12.1865 15.5791 11.7227 16.0186 11.0391 16.5068C10.5703 16.8535 10.2725 17.1172 10.1455 17.2979C10.0234 17.4785 9.9624 17.7153 9.9624 18.0083V18.4478H8.02148ZM7.78711 20.9526C7.78711 20.5425 7.89697 20.2324 8.1167 20.0225C8.33643 19.8125 8.65625 19.7075 9.07617 19.7075C9.48145 19.7075 9.79395 19.8149 10.0137 20.0298C10.2383 20.2446 10.3506 20.5522 10.3506 20.9526C10.3506 21.3384 10.2383 21.6436 10.0137 21.8682C9.78906 22.0879 9.47656 22.1978 9.07617 22.1978C8.66602 22.1978 8.34863 22.0903 8.12402 21.8755C7.89941 21.6558 7.78711 21.3481 7.78711 20.9526Z" fill="white" />
                                                </svg>
                                            </i>
                                        </a>
                                        {!this.state.validEntry ?
                                            <i className="select-i-rider">
                                                <svg width='25' height='25' viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <ellipse cx="18.5" cy="19.3965" rx="18.5" ry="18.4428" fill="#F2274C" />
                                                    <path d="M17 27.8814C17 27.4259 17.1675 27.0299 17.5026 26.6932C17.8377 26.3566 18.2319 26.1883 18.6852 26.1883C19.1385 26.1883 19.5327 26.3566 19.8678 26.6932C20.2029 27.0299 20.3704 27.4259 20.3704 27.8814C20.3704 28.3368 20.2029 28.7229 19.8678 29.0398C19.5327 29.3368 19.1385 29.4853 18.6852 29.4853C18.2319 29.4853 17.8377 29.3368 17.5026 29.0398C17.1675 28.7229 17 28.3368 17 27.8814ZM18.3896 24.1388C18.3107 23.2081 18.222 22.2279 18.1235 21.1982C18.0446 20.1487 17.9559 19.119 17.8574 18.1091C17.7785 17.0794 17.6997 16.0992 17.6209 15.1685C17.5617 14.2378 17.5026 13.4061 17.4435 12.6734C17.3843 11.921 17.3351 11.3071 17.2957 10.8319C17.2562 10.3368 17.2365 10.0398 17.2365 9.94077C17.2365 9.40611 17.3843 9.02987 17.68 8.81205C17.9756 8.59423 18.3107 8.48532 18.6852 8.48532C19.0597 8.48532 19.3948 8.59423 19.6904 8.81205C19.9861 9.02987 20.1339 9.40611 20.1339 9.94077C20.1339 10.0992 20.0945 10.6635 20.0156 11.6338C19.9368 12.6041 19.8382 13.7824 19.72 15.1685C19.6214 16.5546 19.5032 18.0497 19.3652 19.6536C19.2272 21.2378 19.0991 22.7328 18.9809 24.1388H18.3896Z" fill="white" />
                                                </svg>
                                            </i> : null}
                                        <div className="text-left">
                                            {!this.state.validEntry &&
                                                <span className='error text-left'> {this.state.errorMessageEntry} </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {modalOptions.show ?
                                    <ModalInfo modalOptions={modalOptions} closeModal={this.closeModal} /> :
                                    null}
                                <div className="text-left">
                                    {this.state.errorMessage != '' &&
                                        <span className='error text-left'> {this.state.errorMessage} </span>
                                    }
                                </div>

                                <div className="text-left">
                                    {this.state.errorMessageSum != '' &&
                                        <span className='error text-left'> {this.state.errorMessageSum} </span>
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                        <div className="bottons--container-rider">
                    <div className="button-back-rider mt-3 mb-3" onClick={() => this.riderListFunction()}>
                        <svg className="svg-back" width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.9727 22.4905L1.47221 11.9476L10.9727 1.40466" stroke="#052675" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <div className="button-next-rider mt-3 mb-3">
                        <ButtonTSV
                            text={this.state.projections ? 'Cerrar' : 'QUIERO VER LAS PROYECCIONES'}
                            colorbBtn='btn-white' customClass='white'
                            function={this.state.projections ? this.hideProjections : this.showProjections} />
                    </div>
                    <div className="button-next-rider mt-3 mb-3" disabled={send} >
                    
                               <button onClick={this.handleClickNext} className="btn btn-landing stepbtn  btn-primary22 btn-buy">
                                    <span className='home'><span>{'COMPRAR ESTA SOLUCIÓN'}</span></span>
                                    <div className="icon iconCard">     <svg width={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"} height={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"}
                                        className='image-svg-landing'
                                        xmlns="http://www.w3.org/2000/svg"
                                        fillRule="evenodd" clipRule="evenodd">
                                        <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
                                    </svg></div>
                                </button>
                    </div>
                    {send ?
                            <div className="redirect mb-3">
                                <a href={urlRedirect} target="_blank" rel="TSV">Si el botón Comprar esta Solución no le redirige presione AQUI</a>
                            </div>
                            : null}
                </div>
                        {this.state.projections ?
                            <SelectedSavingProjection
                                value={this.state.value}
                                valueEntry={this.state.valueEntry}
                                idTSVProduct={this.props.idTSVProduct}
                                disclaimer={disclaimer}
                                idTSVCategory={this.props.idTSVCategory} /> : null
                        }

                    </div>
                </div>
              
            </div>
        )
    }
}
export default SelectedRiderSaving;