import { JSONExchangeTSV } from "./JSONExchangeTSV";

const maxLengthField = process.env.REACT_APP_MAX_LENGTH;

export class LongAnswerCardServices {

    constructor() {
        this.jsonExchangeTSV = new JSONExchangeTSV();

    }

    /**
     * Gets the previous question with his answer from local storage, answered by the user.
     * @param  {Number} idCurrentCategory The numerical id of the current category.
     * @param  {Object} fieldConfig The question of the current component.
     * @return {Object} The question with his answer object if exist, if not then returns false.
    */
    GetPreviousAnswerFromLocalStorage = (idCurrentCategory, fieldConfig) => {
        let response = {
            formValid: null,
            step: null,
            next: null,
            value: null,
            isValid: null,
            field: null,
            errorMessage: null
        };
        if (this.IsCurrentCategory(idCurrentCategory)) {
            if (this.GetLastAnswerSaved(fieldConfig) != null) {
                fieldConfig.value = this.GetLastAnswerSaved(fieldConfig);
                let step = fieldConfig.options[0].step;

                response.errorMessage = '';
                response.valid = true;
                response.value = fieldConfig.value;
                response.step = step;
                response.field = fieldConfig;
                return response;
            }
            return false;
        }
        return false;
    }

    /**
     * Handles a change on a input a valid if this change is correct.
     * @param  {Object} event The event when the input change.
     * @param {field}   field The question object used in the component.
     * @return {Object} The response object to set props and state in the component.
    */
    HandleInputChange = (event, field) => {
        field.value = event.target.value;
        const response = this.CheckValidity(field);
        return response;
    }

    /**
     * Validates if the value of the input is correct.
     * @param {field}   field The question object used in the component.
     * @return {Object} The response object to set props and state in the component.
     */
    CheckValidity = (field) => {
        let rules = field;
        let value = field.value;
        let isValid = true;
        let errorMessage = "";
        let response = {
            errorMessage: null,
            isValid: null,
            value: null,
            validInput: null,
            fieldConfig: null
        }
        switch (true) {

            case (rules.required && value.trim() === ''):
                isValid = false;
                errorMessage = '*Campo es requerido';
                break;
            case (maxLengthField && value.length >  Number(maxLengthField)):

                value = field.value.slice(0,  Number(maxLengthField));

                isValid = false;
                errorMessage = `*Este campo solo admite ${maxLengthField} caracteres`;
                break;
            case (rules.minLength && value.length <= rules.minLength):
                isValid = false;
                errorMessage = `*Este campo requiere ${rules.minLength} caracteres como mínimo`;
                break;
            case (rules.pattern && !rules.pattern.test(value)):
                isValid = false;
                errorMessage = '*Este campo no cumple con el formato requerido';
                break;

        }
        field.valid = isValid;

        response.errorMessage = errorMessage;
        response.isValid = isValid;
        response.validInput = isValid;
        response.value = value;
        response.fieldConfig = field;

        return response;

    }

    /**
     * Validates if the input is correct to go to the next question and save it in the local storage.
     * @param {Boolean}   valid A boolean value to handle if the question status is correct to go forward.
     * @param  {Object} fieldConfig The question of the current component.
     * @return {Object} The response object to set props and state in the component.
     */
    NextStep = (valid, fieldConfig) => {
        let step = 0;
        let response = {
            formValid: null,
            step: null,
            next: null,
            field: null,
            isValid: null,
            value: null
        };

        let answerOption = { "id": 0 };
        if (fieldConfig.options) {
            if (fieldConfig.options[0]) {
                answerOption.id = fieldConfig.options[0].id;
            }
        }
        step = fieldConfig.options[0].step;
        let productQuestion = {
            "id_question": `${fieldConfig.id}`,
            "question_type": `${fieldConfig.type}`,
            "question_text": `${fieldConfig.body}`,
            "id_answer": `${answerOption.id}`,
            "value_answer": `${fieldConfig.value}`,
            "id_tsv_field": `${fieldConfig.idTSVField}`,
            "question_number": fieldConfig.questionNumber
        }
        if (!this.jsonExchangeTSV.existQuestionOfProductQuestions(productQuestion)) {
            this.jsonExchangeTSV.addQuestionToProductQuestion(productQuestion);
        } else {
            let lastQuestion = this.jsonExchangeTSV.getQuestionOfProductQuestionsById(productQuestion);
            this.jsonExchangeTSV.removeQuestionOfProductQuestions(lastQuestion);
            this.jsonExchangeTSV.addQuestionToProductQuestion(productQuestion);
        }
        response.formValid = true;
        response.valid = valid;
        response.step = step;
        response.next = true;
        response.field = fieldConfig
        response.isValid = true;

        return response;
    }

    /**
     * Validates if the category chosen by the user is the same of the local storage.
     * @param {Number}   isCurrentCategory A numerical id of the category
     * @return {Boolean} True if it is the current category.
     */
    IsCurrentCategory = (idCurrentCategory) => {
        let currentCategory = this.jsonExchangeTSV.getCurrentSerie();
        if (parseInt(currentCategory.id_series) === idCurrentCategory) {
            return true;
        }
        return false;

    }

    /**
     * Gets the last answer saved for a question in the local storage.
     * @param {Object}   fieldConfig The question of the current component.
     * @return {Boolean} True if it is the current category.
    */
    GetLastAnswerSaved = (fieldConfig) => {
        let question = { id_question: fieldConfig.id };
        let lastAnswer = {};
        if (fieldConfig.idMainQuestion === '') {
            lastAnswer = this.jsonExchangeTSV.getQuestionOfProductQuestionsById(question);
        } else {
            lastAnswer = this.jsonExchangeTSV.getSubQuestionOfProductQuestionsById(question);
        }
        if (!lastAnswer) {
            return null;
        }
        if (lastAnswer.value_answer) {
            return lastAnswer.value_answer;
        } else if (lastAnswer.answer_list) {
            let answerValue = null;
            lastAnswer.answer_list.forEach(answer => {
                if (answer.value_answer === fieldConfig.value) {
                    answerValue = answer.value_answer;
                }
            });
            return answerValue;
        } else {
            return null;
        }

    }

}