import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
ReactDOM.render(<BrowserRouter>
  <Switch>
    <Route path="/compra" component={App} exact />
    <Route path="/" component={App} exact />
    <Route path="/compra/terminos" component={App} exact />
    <Route path="/compra/privacidad" component={App} exact />
    <Route path="/compra/ahorro" component={App} exact />
    <Route path="/compra/vida" component={App} exact />
    <Route path="/compra/salud" component={App} exact />
    <Route path="/compra/riders" component={App} exact />

  </Switch>
</BrowserRouter>,
  document.getElementById('root'));