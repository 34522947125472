import React from 'react';
import { Link } from 'react-router-dom';


export class Privacy extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){
  }

  render(){
    return (
       <div className="page-content">
        <div className="container">
        <p>    <Link to={location => ({ ...location, pathname: "/compra" })} className="go-home">&laquo; Regresar al Inicio</Link></p>

            <header className="page-header">
                <h1 className="page-title">Aviso de Privacidad de Sitios Web</h1>
            </header>

            <main className="page-main">
                <p><em>Última modificación: 15 de junio de 2021</em></p>
                <br/>
                <h4>Introducción</h4>
                <p>Triple-S Management Corporation, Triple-S Salud, Inc., Triple-S Advantage, Inc., Triple-S Propiedad,
                    Inc. y Triple-S Vida, Inc. (en conjunto <strong>«Triple-S»</strong>, la <strong>«Compañía»</strong>
                    o <strong>«Nosotros»</strong>) respetamos su privacidad y estamos comprometidos a salvaguardar la
                    información personal que recibimos de usted cuando interactúa con cualquiera de las aplicaciones
                    móviles, sitios web y servicios en línea de la Compañía.</p>
                <p>En algunos casos, la información que recopilamos sobre usted está sujeta a los requisitos de la Ley
                    de Transferencia y Responsabilidad de Seguro Médico (HIPAA, por sus siglas en inglés) de 1996 y/o la
                    Ley Gramm-Leach-Bliley (GLBA, por sus siglas en inglés), según enmendadas. Si usted es afiliado de
                    Triple-S y se registra para utilizar los servicios en línea del portal de afiliados, esa información
                    está sujeta a la HIPAA y/o a la GLBA. En esas circunstancias, aplicarán el <a
                        href="https://salud.grupotriples.com/aviso-de-privacidad-de-sitios-web/" target="_blank"
                        rel="noopener noreferrer">Aviso de Prácticas de Privacidad de HIPAA de Triple-S Salud</a>, Inc.
                    y <a href="https://advantage.grupotriples.com/aviso-de-practicas-de-privacidad/" target="_blank"
                        rel="noopener noreferrer">Triple-S Advantage, Inc.</a> y el <a
                        href="https://vida.grupotriples.com/wp-content/uploads/Privacy-Notice-Spa-TSV-2021.pdf"
                        target="_blank" rel="noopener noreferrer">Aviso de Prácticas de Privacidad de la GLBA de
                        Triple-S Vida, Inc.</a> y <a
                        href="https://propiedad.grupotriples.com/politica-de-confidencialidad/" target="_blank"
                        rel="noopener noreferrer">Triple-S Propiedad, Inc.</a> (en conjunto, «Aviso de Prácticas de
                    Privacidad»).</p>
                <p>Este Aviso de Privacidad de Sitios Web describe los tipos de información que podemos recopilar de
                    usted o que puede proporcionar cuando visita nuestros sitios web [<a
                        href="https://management.grupotriples.com" target="_blank"
                        rel="noopener noreferrer">management.grupotriples.com;</a> <a
                        href="https://salud.grupotriples.com" target="_blank"
                        rel="noopener noreferrer">salud.grupotriples.com</a>; <a
                        href="https://advantage.grupotriples.com" target="_blank"
                        rel="noopener noreferrer">advantage.grupotriples.com</a>; <a href="https://www.sssvital.com"
                        target="_blank" rel="noopener noreferrer">sssvital.com</a>; <a
                        href="https://propiedad.grupotriples.com" target="_blank"
                        rel="noopener noreferrer">propiedad.grupotriples.com</a>; <a
                        href="https://vida.grupotriples.com">vida.grupotriples.com</a>], (nuestros «Sitios Web») y
                    nuestras prácticas para recopilar, usar, mantener, proteger y divulgar esa información. Si tiene
                    preguntas sobre qué política se aplica en una situación determinada, comuníquese con el Oficial de
                    Cumplimiento de Triple-S Vida a <a href="mailto:Elizabeth.Rodriguez@sssvida.com" target="_blank"
                        rel="noopener noreferrer">Elizabeth.Rodriguez@sssvida.com </a> o llame al <a
                        href="tel:787758-4888">(787) 758-4888</a>.</p>
                <br/>

                <p><strong>Esta política se aplica a la información que recopilamos:</strong></p>
                <ul>
                    <li className="first">en este Sitio Web,</li>
                    <li>en los mensajes electrónicos entre usted y este Sitio Web,</li>
                    <li className="last">cuando interactúa con nuestros anunciantes y aplicaciones en sitios web y servicios
                        de terceros, si esas aplicaciones y anuncios incluyen enlaces a esta política.</li>
                </ul>
                <br/>

                <p><strong>No se aplica a la información recopilada:</strong></p>
                <ul>
                    <li className="first">a través de nosotros sin conexión, o</li>
                    <li className="last">mediante un tercero a través de cualquier aplicación o contenido, por ejemplo, una
                        publicidad, que tenga un enlace o sea accesible desde los Sitios Web.</li>
                </ul>
                <p>Lea este aviso de privacidad detenidamente para comprender nuestras políticas y prácticas con
                    respecto a su información y cómo la administraremos. Al acceder o utilizar nuestros Sitios Web, o al
                    hacer clic para aceptar los términos de uso de nuestro Aviso de Privacidad de Sitios Web y el <a
                        href="https://vida.grupotriples.com/wp-content/uploads/Privacy-Notice-Spa-TSV-2021.pdf" target="_blank"
                        rel="noopener noreferrer">Aviso de Prácticas de Privacidad</a> cuando esta opción esté
                    disponible, usted acepta y se adhiere a sus términos. Este Aviso de Privacidad de Sitios Web o el
                    Aviso de Prácticas de Privacidad de la Compañía pueden cambiar de tiempo en tiempo (consulte
                    «Enmiendas y actualizaciones»). Si continúa usando los Sitios Web de Triple-S o vuelve a aceptar el
                    Aviso de Privacidad de Sitios Web y el Aviso de Prácticas de Privacidad después de la publicación de
                    los términos revisados, se considera que los cambios fueron aceptados. Por lo tanto, consulte el
                    Aviso de Privacidad de Sitios Web y/o el Aviso de Prácticas de Privacidad periódicamente para
                    consultar las actualizaciones.</p>

                <br/>
                <h4>Niños menores de 13 años</h4>
                <p>Nuestros Sitios Web no están destinados a niños menores de trece (13) años. Ninguna persona menor de
                    trece (13) años puede proporcionar información personal en los Sitios Web de Triple-S. No
                    recopilamos deliberadamente información personal de niños menores de trece (13) años. Si es menor de
                    trece (13) años, no utilice ni proporcione información personal en nuestros Sitios Web o mediante
                    cualquiera de sus funciones, no se registre, no utilice ninguna de las funciones interactivas o de
                    comentarios públicos de nuestros Sitios Web, ni nos proporcione información sobre usted, incluido su
                    nombre, dirección, número de teléfono, dirección de correo electrónico o solicitudes de cubierta. Si
                    descubrimos que hemos recopilado o recibido información personal de un niño menor de trece (13) años
                    sin el consentimiento de los padres, procederemos a eliminar dicha información. Si cree que
                    podríamos tener información sobre un niño menor de trece (13) años, comuníquese con el Oficial de
                    Cumplimiento de Triple-S Vida a <a href="mailto:Elizabeth.Rodriguez@sssvida.com" target="_blank"
                        rel="noopener noreferrer">Elizabeth.Rodriguez@sssvida.com</a> o llame al <a
                        href="tel:787758-4888">(787) 758-4888</a>.</p>
                <h4>Información que recopilamos sobre usted y cómo la recopilamos</h4>
                <p>Recopilamos diferentes tipos de información sobre los usuarios de nuestros Sitios Web, incluida la
                    información:</p>
                <ul>
                    <li className="first">mediante la cual se le puede identificar personalmente como nombre, apellido,
                        dirección de correo electrónico, número de teléfono, fecha de nacimiento [o cualquier otro
                        identificador que sirva para contactarlo en línea o fuera de línea] («información personal»);
                        y/o</li>
                    <li className="last">sobre su conexión de Internet, el equipo que utiliza para acceder a nuestros Sitios
                        Web y los detalles de uso.</li>
                </ul>

                <br/>
                <p>Recopilamos esta información:</p>
                <ul>
                    <li className="first">directamente de usted cuando nos la proporcione.</li>
                    <li className="last">automáticamente mientras navega por los sitios. La información recopilada
                        automáticamente puede incluir detalles de uso, direcciones IP, servidor doméstico,
                        identificación de sesión, navegador, sistemas operativos e información recopilada a través de
                        cookies, web beacons y otras tecnologías de seguimiento.</li>
                </ul>

                <br/>
                <p><em><strong>Información que usted nos proporciona</strong></em></p>
                <p>La información que recopilamos en nuestros Sitios Web o a través de ellos puede incluir:</p>
                <ul>
                    <li className="first">información que proporciona al llenar formularios en nuestro Sitio Web. Esto
                        incluye la información proporcionada al momento de registrarse para usar nuestro Sitio Web,
                        suscribirse a nuestro servicio, publicar material o solicitar más servicios.</li>
                    <li>registros y copias de sus direcciones de correo electrónico si se comunica con nosotros.</li>
                    <li>sus respuestas a encuestas o solicitudes de comentarios que podríamos pedirle que complete con
                        fines de investigación.</li>
                    <li>detalles de las transacciones que realice a través de nuestro Sitio Web y del cumplimiento de
                        sus órdenes. Es posible que deba proporcionar información financiera antes de realizar una orden
                        o un pago en cualquiera de sus pólizas de seguro de Triple-S a través de nuestro Sitio Web.</li>
                    <li className="last">sus consultas de búsqueda en el Sitio Web.</li>
                </ul>
                <p>La única información personal que la Compañía obtiene sobre los usuarios individuales de sus Sitios
                    Web es la que los usuarios proporcionan voluntariamente. En caso de que la Compañía pueda necesitar
                    información personal adicional para proporcionar a los usuarios contenido personalizado o para
                    informarles sobre nuevas funciones o servicios, se les solicitará explícitamente esa información.
                </p>

                <br/>
                <p><strong><em>Descargo de responsabilidad de redes sociales</em></strong></p>
                <p>También puede proporcionar información para que se publique o muestre (de aquí en adelante,
                    «publicada») en las páginas de redes sociales de Triple-S mediante los enlaces a las cuentas de
                    redes sociales de la Compañía publicados en cada uno de sus Sitios Web («Contribuciones de
                    Usuarios»). Sus Contribuciones de Usuarios se publican y transmiten a otros bajo su propio riesgo.
                    Aunque podemos limitar el acceso público a dichas páginas, puede establecer ciertas configuraciones
                    de privacidad para tal información iniciando sesión en el perfil de su cuenta; tenga en cuenta que
                    ninguna medida de seguridad es perfecta o impenetrable. Además, no podemos controlar las acciones de
                    otros usuarios del Sitio Web con los que usted pueda decidir compartir sus Contribuciones de
                    Usuarios. Por lo tanto, no podemos garantizar que sus Contribuciones de Usuarios no sean vistas por
                    personas no autorizadas. Por último, todos los usuarios de aplicaciones de redes sociales deben
                    cumplir con sus términos de servicio. Como tal, Triple-S no garantiza la exactitud, veracidad o
                    integridad de la información contenida en dichas Contribuciones de Usuarios y no se responsabiliza
                    por las Contribuciones de Usuarios publicadas en las cuentas de redes sociales de la Compañía.</p>

                <br/>
                <p><strong><em>Información que obtenemos a través de tecnologías de recopilación automática de
                            datos</em></strong></p>
                <p>Mientras navega e interactúa en nuestros Sitios Web, podemos utilizar tecnologías de recopilación
                    automática de datos para obtener cierta información sobre su equipo, las acciones de navegación y
                    los patrones, que incluyen:</p>
                <ul>
                    <li className="first">detalles de sus visitas a nuestros Sitios Web, incluidos los datos de tráfico y
                        otros datos de comunicación y los recursos a los que accede y utiliza en el Sitio Web.</li>
                    <li className="last">información sobre su computadora y la conexión a Internet, incluida su dirección
                        IP, el sistema operativo y el tipo de navegador.</li>
                </ul>
                <p>Triple-S no utiliza estas tecnologías para recopilar información sobre sus actividades en línea a lo
                    largo del tiempo y en sitios web de terceros u otros servicios en línea (seguimiento de
                    comportamiento).</p>
                <p>La información que recopilamos automáticamente son datos estadísticos y no incluyen información
                    personal, pero podríamos mantenerlos o asociarlos con la información personal que recopilamos de
                    otras formas o que recibimos de terceros. Nos ayuda a optimizar nuestro Sitio Web y a ofrecer un
                    servicio mejor y más personalizado, al permitirnos:</p>
                <ul>
                    <li className="first">calcular el tamaño de nuestra audiencia y los patrones de uso.</li>
                    <li>almacenar información sobre sus preferencias, lo que nos permite personalizar nuestro Sitio Web
                        de acuerdo con sus intereses individuales.</li>
                    <li>acelerar sus búsquedas.</li>
                    <li className="last">reconocerlo cuando regrese a nuestro Sitio Web.</li>
                </ul>
                <p>Las tecnologías que utilizamos para la recopilación automática de esta información pueden incluir:
                </p>
                <ul>
                    <li className="first"><strong>Cookies (o cookies del navegador).</strong> Las «cookies» son pequeños
                        archivos de texto que un sitio web transfiere al disco duro o navegador del usuario del sitio
                        web para añadir funcionalidad o para hacer un seguimiento del uso del sitio web. Nuestros Sitios
                        Web utilizan cookies durante su sesión en línea para ofrecer contenido específico según sus
                        intereses. Nuestras cookies permiten a Triple-S vincular el uso de los Sitios Web con la
                        información que ha proporcionado de forma específica y consciente a través de nuestros Sitios
                        Web. Usted puede negarse a aceptar las cookies del navegador al activar las configuraciones
                        apropiadas en su navegador. Sin embargo, si selecciona esta configuración, es posible que no
                        pueda acceder a algunas partes de nuestros Sitios Web. A menos de que haya ajustado las
                        configuraciones de su navegador para que rechace las cookies, nuestro sistema usará cookies
                        cuando su navegador acceda a nuestros Sitios Web.</li>
                    <li><strong>Cookies Flash.</strong> Ciertas funciones de nuestros Sitios Web pueden usar objetos
                        almacenados localmente (o cookies Flash) para recopilar y almacenar información sobre sus
                        preferencias y datos de navegación hacia, desde y en el interior de nuestro Sitio Web. Las
                        cookies Flash no se administran con las mismas configuraciones del navegador que se utilizan
                        para las cookies del navegador. Para obtener información sobre la administración de sus
                        configuraciones de privacidad y seguridad para las cookies Flash, consulte «Opciones sobre cómo
                        usamos y divulgamos su información».</li>
                    <li className="last"><strong>Web Beacons.</strong> Las páginas de nuestros Sitios Web y nuestros correos
                        electrónicos pueden contener archivos electrónicos pequeños conocidos como web beacons (también
                        conocidos como GIFS transparentes, etiquetas de píxel y GIFS de un solo píxel) que le permiten a
                        la Compañía, por ejemplo, contar los usuarios que han visitado esas páginas o que han abierto un
                        correo electrónico para otras estadísticas relacionadas con el sitio web (por ejemplo, registrar
                        la popularidad de ciertos contenidos del sitio web y verificar la integridad del sistema y del
                        servidor).</li>
                </ul>

                <br/>
                <h4>Cómo utilizamos su información</h4>
                <p>Utilizamos la información que recopilamos sobre usted o que usted nos proporciona, incluida cualquier
                    información personal:</p>
                <ul>
                    <li className="first">para presentarle nuestros Sitios Web y sus contenidos.</li>
                    <li>para proporcionarle información, productos o servicios que nos solicite o haya autorizado.</li>
                    <li>para cumplir con cualquier otro propósito para el que usted la proporciona.</li>
                    <li>ID de usuario y contraseñas para proporcionarle acceso a sus cuentas con la Compañía.</li>
                    <li>para proporcionarle avisos sobre su cuenta/suscripción, incluidos los avisos de vencimiento y
                        renovación.</li>
                    <li>para cumplir con nuestras obligaciones y hacer valer nuestros derechos derivados de cualquier
                        contrato que hayamos celebrado con usted, incluida la facturación y el cobro.</li>
                    <li>para notificarle sobre cambios en nuestros Sitios Web o en cualquier producto o servicio que
                        ofrecemos o proporcionamos a través de ellos.</li>
                    <li>para permitirle participar en funciones interactivas en nuestros Sitios Web.</li>
                    <li>para fines de investigación interna sobre las preferencias de nuestros usuarios y/o para mejorar
                        nuestros productos, servicios y diseños de los sitios.</li>
                    <li>para plantear y dar respuesta a cuestiones y consultas de todo tipo.</li>
                    <li>de cualquier otro modo que podamos describir cuando proporcione la información.</li>
                    <li className="last">para cualquier otro fin, con su consentimiento.</li>
                </ul>
                <p>Los usuarios también pueden solicitar la cancelación de la suscripción o la exclusión voluntaria de
                    los correos electrónicos de marketing o publicidad de Triple-S mediante la opción «cancelar
                    suscripción». Para más detalles, consulte «Opciones sobre cómo usamos y divulgamos su información».
                </p>
                <p>Cualquier información médica protegida o información financiera no pública enviada por los afiliados
                    a través del portal de servicios al afiliado en línea de la Compañía se manejará de acuerdo con el
                    Aviso de Prácticas de Privacidad de HIPAA y el <a
                        href="https://vida.grupotriples.com/wp-content/uploads/Privacy-Notice-Spa-TSV-2021.pdf"
                        target="_blank" rel="noopener noreferrer">Aviso de Prácticas de Privacidad de la GLBA</a>, según
                    corresponda. Como resultado, los derechos de exclusión voluntaria y/o de acceso de los usuarios
                    pueden estar limitados.</p>

                <br/>
                <p><em><strong>Enlaces externos</strong></em></p>
                <p>A veces, nuestros Sitios Web pueden contener enlaces a otros Sitios Web de Internet. Les pedimos a
                    todos nuestros socios, colaboradores y terceros que implementen políticas y prácticas que respeten
                    la privacidad de nuestros usuarios. Sin embargo, Triple-S no es responsable de las prácticas de
                    privacidad ni del contenido de dichos sitios web. Este Aviso de Privacidad de Sitios Web solo
                    incluye el uso y la divulgación de la información que recopilamos de usted. En la medida en que
                    divulgue su información personal a otras partes, ya sea que los enlaces estén o no en los Sitios Web
                    de la Compañía, se pueden aplicar diferentes reglas para el uso o la divulgación de la información
                    personal que usted revele. Debido a que la Compañía no controla los avisos de privacidad de
                    terceros, usted está sujeto a las prácticas y costumbres de privacidad de dicho tercero. Le
                    recomendamos que haga preguntas antes de revelar su información personal a otros.</p>
                <h4>Divulgación de su información</h4>
                <p>Podemos divulgar información agregada sobre nuestros usuarios e información que no identifica a
                    ningún individuo sin restricciones.</p>
                <p>Podemos divulgar la información personal que recopilamos o que usted proporciona como se describe en
                    esta política de privacidad:</p>
                <ul>
                    <li className="first">a nuestras compañías subsidiarias y afiliadas.</li>
                    <li>a contratistas, proveedores de servicios y otros terceros de los que nos valemos para apoyar
                        nuestro negocio y que están obligados por contrato a mantener la confidencialidad de la
                        información personal y a utilizarla solo para los fines para los que se la revelamos.</li>
                    <li>a un comprador u otro sucesor en caso de fusión, desinversión, reestructuración, reorganización,
                        disolución u otra venta o transferencia de algunos o todos los activos de Triple-S, ya sea como
                        empresa en funcionamiento o como parte de una quiebra, liquidación o procedimiento similar, en
                        el que la información personal que posee Triple-S de los usuarios de nuestro Sitio Web se
                        encuentre entre los activos transferidos.</li>
                    <li>a terceros para que le proporcionen sus productos o servicios si usted ha dado su consentimiento
                        para estas divulgaciones. Por contrato, exigimos que tales terceros mantengan la
                        confidencialidad de la información personal y la utilicen solo para los fines para los que se la
                        divulgamos. Para más detalles, consulte «Opciones sobre cómo usamos y divulgamos su
                        información».</li>
                    <li>para cumplir el propósito para el que usted la proporciona.</li>
                    <li>para cualquier otro propósito que divulguemos cuando nos proporciona la información.</li>
                    <li className="last">con su consentimiento.</li>
                </ul>
                <p>CON EXCEPCIÓN DE LO EXPUESTO EN ESTE AVISO DE PRIVACIDAD DE SITIOS WEB, TRIPLE-S NO VENDE, PRESTA,
                    ALQUILA NI DIVULGA DE NINGÚN MODO LA INFORMACIÓN PERSONAL A TERCEROS.</p>

                <br/>
                <p><strong><em>Divulgaciones exigidas por ley</em></strong></p>
                <p>También podemos divulgar su información personal:</p>
                <ul>
                    <li className="first">para cumplir con cualquier orden judicial, ley o proceso legal, incluso para
                        responder a cualquier solicitud gubernamental o regulatoria.</li>
                    <li>para hacer cumplir o aplicar nuestros términos de uso y otros acuerdos, incluso para fines de
                        facturación y cobro.</li>
                    <li className="last">si creemos que la divulgación es necesaria o apropiada para proteger los derechos,
                        la propiedad o la seguridad de Triple-S, nuestros clientes u otros. Esto incluye el intercambio
                        de información con otras compañías y organizaciones a efectos de protección contra el fraude.
                    </li>
                </ul>

                <br/>
                <h4>Opciones sobre cómo usamos y divulgamos su información</h4>
                <p>Nos esforzamos por ofrecerle opciones con respecto a la información personal que nos proporciona.
                    Hemos creado mecanismos para proporcionarle el siguiente control sobre su información:</p>
                <ul>
                    <li className="first last"><strong>Tecnologías de seguimiento y publicidad.</strong> Puede configurar su
                        navegador para que rechace todas las cookies o algunas de ellas, o para que le avise cuando se
                        envíen cookies. Para saber cómo puede manejar la configuración de cookies Flash, visite la
                        página de configuración de Flash Player en el sitio web de Adobe. Si deshabilita o rechaza las
                        cookies, tenga en cuenta que es posible que no pueda acceder a algunas partes de este sitio o
                        que no funcionen correctamente.</li>
                </ul>

                <br/>
                <h4>Acceso y corrección de su información</h4>
                <p>Puede revisar y cambiar su información personal entrando en el Sitio Web correspondiente y visitando
                    la página del perfil de su cuenta. No podemos borrar su información personal, a menos que también se
                    borre su cuenta de usuario. Podemos rechazar una solicitud de cambio de información si consideramos
                    que el cambio violaría cualquier ley o requerimiento legal o haría que la información fuera
                    incorrecta.</p>
                <p>Si borra sus Contribuciones de Usuarios de las páginas de redes sociales de la Compañía, las copias
                    de sus Contribuciones de Usuarios pueden permanecer visibles en páginas almacenadas en caché y
                    archivadas o pueden haber sido copiadas o almacenadas por otros usuarios del Sitio Web. El acceso y
                    el uso adecuados de la información proporcionada a través de los Sitios Web se rigen por nuestros
                    términos de uso.</p>
                <h4>Solicitantes de empleo</h4>
                <p>Como parte de cualquier proceso de contratación, Triple-S recopila y procesa datos personales
                    relacionados con los solicitantes de empleo. Triple-S se compromete a ser transparente en relación
                    con la forma en que la Compañía recopila y utiliza esos datos y su dedicación para salvaguardar esa
                    información como se describe en el <a
                        href="https://management.grupotriples.com/politica-de-privacidad-de-reclutamiento-de-triple-s/"
                        target="_blank" rel="noopener noreferrer">Aviso de Privacidad para Solicitantes de Empleo</a>.
                </p>

                <br/>
                <h4>Seguridad de los datos</h4>
                <p>Hemos implementado medidas diseñadas para proteger su información personal contra pérdidas
                    accidentales y contra el acceso, el uso, la alteración y la divulgación no autorizados. Toda la
                    información que nos proporciona se almacena en nuestros servidores seguros protegidos por
                    cortafuegos. Todas las transacciones de pago se cifrarán mediante tecnología SSL o de conformidad
                    con las normas de la industria para evitar la divulgación o exposición no autorizadas de la
                    información de identificación personal.</p>
                <p>La seguridad de su información también depende de usted. Cuando le proporcionemos (o cuando usted
                    haya elegido) una contraseña para acceder a determinadas partes de nuestros Sitios Web, usted es
                    responsable de mantener la confidencialidad de esta contraseña. Le pedimos que no comparta su
                    contraseña con nadie.</p>
                <p>Triple-S no realiza llamadas ni envía solicitudes por correo electrónico solicitando información
                    sobre cuentas personales o tarjetas de crédito. Si recibe una llamada o un mensaje de correo
                    electrónico que parece ser de Triple-S y le piden que proporcione su ID de correo electrónico,
                    contraseña, número de Seguro Social u otra información personal, no responda ni conteste.</p>

                <br/>
                <h4>Enmiendas y actualizaciones</h4>
                <p>Nuestra práctica habitual es publicar cualquier cambio que hagamos en nuestro Aviso de Privacidad de
                    Sitios Web en esta página con un aviso de que se ha actualizado en cada una de las páginas de inicio
                    de los Sitios Web. Si realizamos cambios sustanciales en la forma en que tratamos la información
                    personal de nuestros usuarios, se lo notificaremos por correo electrónico a la dirección de correo
                    electrónico principal especificada en su cuenta, a través de un aviso en las páginas de inicio de
                    los Sitios Web y/o solicitaremos que vuelva a aceptar los términos de nuestro Aviso de Privacidad de
                    Sitios Web y del Aviso de Prácticas de Privacidad. Usted es responsable de asegurarse de que
                    tengamos una dirección de correo electrónico activa, válida y actualizada, y de visitar
                    periódicamente nuestros Sitios Web y este aviso de privacidad para verificar si hay cambios.</p>
                <p>Este Aviso de Privacidad de Sitios Web no pretende crear (ni crea) ningún derecho contractual u otros
                    derechos legales en nombre de las partes.</p>

                <br/>
                <h4>Información de contacto</h4>
                <p>Para hacer preguntas o comentarios sobre este aviso de privacidad y nuestras prácticas de privacidad,
                    comuníquese con el Oficial de Cumplimiento de Triple-S Vida a <a
                        href="mailto:Elizabeth.Rodriguez@sssvida.com" target="_blank"
                        rel="noopener noreferrer">Elizabeth.Rodriguez@sssvida.com</a> o llame al <a
                        href="tel:787758-4888">(787) 758-4888</a>.</p>
            </main>

          
            <p><Link to={location => ({ ...location, pathname: "/compra" })}> Regresar al Inicio</Link> </p>
        </div>
    </div>
    );
  }

}