import { JSONExchangeTSV } from "./JSONExchangeTSV";
import { WebServicesTSV } from "./WebServicesTSV";

const date_calculation = process.env.REACT_APP_DATE_CALCULATION;
const date = process.env.REACT_APP_DATE;
const savingCategory = process.env.REACT_APP_SAVING_CATEGORY;
export class DateCardServices {

    constructor() {
        this.jsonExchangeTSV = new JSONExchangeTSV();
        this.webServicesTSV = new WebServicesTSV();

    }

    /**
     * Gets the previous question with his answer from local storage, answered by the user.
     * @param  {Number} idCurrentCategory The numerical id of the current category.
     * @param  {Object} fieldConfig The question of the current component.
     * @return {Object} The question with his answer object if exist, if not then returns false.
    */
    GetPreviousAnswerFromLocalStorage = (idCurrentCategory, fieldConfig) => {
        let response = {
            formValid: null,
            step: null,
            next: null,
            value: null,
            isValid: null,
            field: null,
            errorMessage: null
        };
        if (this.IsCurrentCategory(idCurrentCategory)) {
            if (this.GetLastAnswerSaved(fieldConfig) != null) {
                fieldConfig.value = this.GetLastAnswerSaved(fieldConfig);
                let step = fieldConfig.options[0].step;

                response.errorMessage = '';
                response.valid = true;
                response.value = fieldConfig.value;
                response.step = step;
                response.field = fieldConfig;

                return response;
            }
            return false;
        }
        return false;
    }

    /**
     * Handles a change on a input a valid if this change is correct.
     * @param  {Object} event The event when the input change.
     * @param {field}   field The question object used in the component.
     * @return {Object} The response object to set props and state in the component.
    */
    HandleInputChange = (event, field) => {
        field.value = event.target.value === undefined ? '' : event.target.value;
        const response = this.CheckValidity(field);
        return response;
    }

    /**
     * 
     * @param {*} date day of birth inserted by the user
     * @returns difference of days between the date entered by the user and the current date
     */
    numerOfDays(date) {
        const parts = date.split("-");
        const day = parts[2];
        const month = Number(parts[1]) - 1;
        const year = parts[0];
        const datefromUserTimeStamp = (new Date(year, month, day)).getTime();
        const now = new Date();
        const nowTimeStamp = now.getTime();;
        const microSecondsDiff = Math.abs(datefromUserTimeStamp - nowTimeStamp);
        // Number of milliseconds per day = 24 hrs/day * 60 minutes/hour * 60 seconds/minute * 1000 ms/second
        const daysDiff = Math.round(microSecondsDiff / (1000 * 60 * 60 * 24));
        return daysDiff;
    }
    /**
     * 
     * @param {*} date day of birth inserted by the user
     * @returns date in format dd/mm/yyyy
     */
    formatDate(date) {
        const parts = date.split("-");
        const day = parts[2];
        const month = parts[1];
        const year = parts[0];
        return `${day}/${month}/${year}`;
    }
    /**
     * 
     * @param {*} date day of birth inserted by the user
     * @returns determines if the date entered by the user is greater than the current one
     */
    dateGreaterThanCurrent(date) {
        const parts = date.split("-");
        const day = Number(parts[2]);
        const month = Number(parts[1])-1;
        const year = Number(parts[0]);
        const currentDate = new Date();
        const datefromUserTimeStamp = (new Date(year, month, day)).getTime();
        const dateFromAPIString = new Date(currentDate);
        let isGreater = datefromUserTimeStamp >  dateFromAPIString.getTime();
        return isGreater;
    }

    /**
     * Validates if the value of the input is correct.
     * @param {field}   field The question object used in the component.
     * @return {Object} The response object to set props and state in the component.
    */
    CheckValidity = (field) => {
        let response = {
            errorMessage: null,
            valid: null,
            isValid: null,
            value: null
        }
        let rules = field;
        const value = field.value;
        let isValid = true;
        let errorMessage = "";
        let daysDiff = this.numerOfDays(value);
        const parts = value.split("-");
        const year = Number(parts[0]);
        if (rules.required && value.trim() === '') {
            isValid = false;
            errorMessage = '*Debe seleccionar una fecha';
        } else if (!rules.required && value.trim() === '') {
            isValid = true;
        }  else if (this.dateGreaterThanCurrent(value)) {
            isValid = false;
            errorMessage = `*Debe ingresar una fecha menor a la fecha actual`;
        }else if (daysDiff > 44195) { // 44195 equals 120 years
            isValid = false;
            errorMessage = `*Debe ingresar una fecha valida mayor a ${this.formatDate(value)}`;
        }else if(year.toString().length < 3){
            isValid = false;
            errorMessage = `*Debe ingresar un año valido`;
        }

        field.valid = isValid;
        response.isValid = isValid;
        response.errorMessage = errorMessage;
        response.value = value;

        return response;
    }

    /**
     * Validates if the input is correct to go to the next question and save it in the local storage.
     * @param {Boolean}   valid A boolean value to handle if the question status is correct to go forward.
     * @param {fieldConfig}   fieldConfig The question object used in the component.
     * @return {Object} The response object to set props and state in the component.
    */
    NextStep = async (valid, fieldConfig) => {
        let step = 0;
        let response = {
            valid: null,
            formValid: null,
            step: null,
            next: null,
            field: null,
            isValid: null
        }
        if (!valid) {
            response.isValid = false;
            return response;
        }
        if (fieldConfig.value === '') {
            response.valid = valid;
            response.next = true;
            response.field = fieldConfig
            response.isValid = true;
            let step = fieldConfig.questionNumber + 1;
            if (step === fieldConfig.totalFields) {
                step = 0;
                response.step = step;
            } else {
                response.step = step;
            }

            return response;
        }
        let answerOption = { "id": 0 };
        let productQuestion = null;
        if (fieldConfig.type === date) {
            if (fieldConfig.options) {
                if (fieldConfig.options[0]) {
                    answerOption.id = fieldConfig.options[0].id;
                    step = fieldConfig.options[0].step;
                }
            }
            productQuestion = {
                "id_question": `${fieldConfig.id}`,
                "question_type": `${fieldConfig.type}`,
                "question_text": `${fieldConfig.body}`,
                "id_answer": `${answerOption.id}`,
                "value_answer": `${fieldConfig.value}`,
                "id_tsv_field": `${fieldConfig.idTSVField}`,
                "question_number": fieldConfig.questionNumber
            };
            if (!this.jsonExchangeTSV.existQuestionOfProductQuestions(productQuestion)) {
                this.jsonExchangeTSV.addQuestionToProductQuestion(productQuestion);
            } else {
                let lastQuestion = this.jsonExchangeTSV.getQuestionOfProductQuestionsById(productQuestion);
                this.jsonExchangeTSV.removeQuestionOfProductQuestions(lastQuestion);
                this.jsonExchangeTSV.addQuestionToProductQuestion(productQuestion);
            }
            response.valid = valid;
            response.next = true;
            response.field = fieldConfig
            response.isValid = true;
            response.step = step;

            return response;
        } else if (fieldConfig.type === date_calculation) {
            let valueAnswer = { 'id': 0, 'text': '' };
            let serviceResponseDate = await this.GetTSVDate(fieldConfig.value);
            if (!serviceResponseDate.errorInComponent) {
                answerOption = this.GetCorrectDateRange(serviceResponseDate.responseDate, fieldConfig.options);
                step = answerOption.step;
                valueAnswer.text = `${fieldConfig.value}`;
                valueAnswer.id = `${answerOption.id}`;
                productQuestion = {
                    "id_question": `${fieldConfig.id}`,
                    "question_type": `${fieldConfig.type}`,
                    "question_text": `${fieldConfig.body}`,
                    "id_answer": `${valueAnswer.id}`,
                    "value_answer": `${valueAnswer.text}`,
                    "id_tsv_field": `${fieldConfig.idTSVField}`,
                    "question_number": fieldConfig.questionNumber

                };
                if (!this.jsonExchangeTSV.existQuestionOfProductQuestions(productQuestion)) {
                    this.jsonExchangeTSV.addQuestionToProductQuestion(productQuestion);
                } else {
                    let lastQuestion = this.jsonExchangeTSV.getQuestionOfProductQuestionsById(productQuestion);
                    this.jsonExchangeTSV.removeQuestionOfProductQuestions(lastQuestion);
                    this.jsonExchangeTSV.addQuestionToProductQuestion(productQuestion);
                }
                if (Number(this.jsonExchangeTSV.getCurrentSerie().id_series) === Number(savingCategory)) {
                    let insuredPersons = this.jsonExchangeTSV.getCurrentInsuredPersons();
                    insuredPersons[0] = {
                        ...insuredPersons[0],
                        'date_Of_birth': new Date(valueAnswer.text).toJSON(),
                        'age': serviceResponseDate.responseDate
                    };
                    this.jsonExchangeTSV.setInsuredPersons(insuredPersons);
                }
                response.valid = valid;
                response.next = true;
                response.field = fieldConfig
                response.isValid = true;
                response.step = step;

                return response;
            } else {
                let response = { errorInComponent: serviceResponseDate.errorInComponent, loading: serviceResponseDate.loading };
                return response;
            }

        }

        return null;
    }

    /**
     * Validates if the category chosen by the user is the same of the local storage.
     * @param {Number}   isCurrentCategory A numerical id of the category
     * @return {Boolean} True if it is the current category.
    */
    IsCurrentCategory = (isCurrentCategory) => {
        let currentCategory = this.jsonExchangeTSV.getCurrentSerie();
        if (parseInt(currentCategory.id_series) === isCurrentCategory) {
            return true;
        }
        return false;
    }

    /**
     * Gets the last answer saved for a question in the local storage.
     * @param {Object}   fieldConfig The question of the current component.
     * @return {Boolean} True if it is the current category.
    */
    GetLastAnswerSaved = (fieldConfig) => {
        let question = { id_question: fieldConfig.id };
        let lastAnswer = {};
        if (fieldConfig.idMainQuestion === '') {
            lastAnswer = this.jsonExchangeTSV.getQuestionOfProductQuestionsById(question);
        } else {
            lastAnswer = this.jsonExchangeTSV.getSubQuestionOfProductQuestionsById(question);
        }
        if (!lastAnswer) {
            return null;
        }
        if (lastAnswer.value_answer) {
            return lastAnswer.value_answer;
        } else if (lastAnswer.answer_list) {
            let answerValue = null;
            lastAnswer.answer_list.forEach(answer => {
                if (answer.value_answer === fieldConfig.value) {
                    answerValue = answer.value_answer;
                }
            });
            return answerValue;
        } else {
            return null;
        }
    }

    /**
     * Gets the valid date according to TSV Date service.
     * @param {Date}   date  The date picked by the user.
     * @return {Promise} The promise with de correct date from TSV.
    */
    async GetTSVDate(date) {
        let loading = true;
        let errorInComponent = false;
        let actualDate = new Date();
        let actualDateJson = JSON.stringify(actualDate);
        let dateOfBirth = new Date(date);
        let dateOfBirthJson = JSON.stringify(dateOfBirth);
        let person = { dateOfBirth: JSON.parse(dateOfBirthJson), effectiveDate: JSON.parse(actualDateJson) };
        let responseService = await this.webServicesTSV.PeopleAge(person);
        let responseDate = null;
        if (responseService.statusResponse) {
            loading = false;
            let ageCalculationStrategy = responseService.data.data.ageCalculationStrategy;
            if (ageCalculationStrategy === 'ActualAge') {
                responseDate = responseService.data.data.lastBirthday;
            } else if (ageCalculationStrategy === 'NearestAge') {
                responseDate = responseService.data.data.nearestBirthday;
            }
        } else {
            loading = false;
            errorInComponent = true;
        }
        let response = { errorInComponent: errorInComponent, loading: loading, responseDate: responseDate };
        return response;
    }

    /**
     * Gets the answer option according to the tsv date from the web service
     * @param {Number}   age  The age of the user.
     * @param {Array}    options The answer options array.
     * @return {Object} The answer option object.
    */
    GetCorrectDateRange(age, options) {
        let answerOption = { 'id': 0 };
        options.forEach(option => {
            if (Number(age) >= Number(option.option) && Number(age) <= Number(option.option2)) {
                answerOption = option;
            }
        });
        return answerOption;
    }


    /**
     * Gets the actual date in YY-MM-DD format. 
     * @return {String} The actual date in YY-MM-DD format.
     */
    GetCurrentDateInFormat = () => {
        let date = new Date(),
            month = '' + (date.getMonth() + 1),
            day = '' + date.getDate(),
            year = date.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
}