import { TokenServices } from "./TokenServices";

const urlBase = process.env.REACT_APP_URL_API_TSV;

export class JSONProcessFileServices {

    constructor() {
        this.tokenService = new TokenServices();
    }

    /**
    *  Adds a new JSONN process File in the API.
    *  @param  {Object} jsonProcess The json object.
    *  @return  {Promise} The response from the API.
    */
    AddJSONProcessFile = async (jsonProcess) => {
        const result = { statusResponse: false, data: null, error: null }
        const token = this.tokenService.DecryptToken();
        await fetch(`${urlBase}JSONProcess/Add`, {
            method: 'POST',
            body: JSON.stringify(jsonProcess),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(async response => {
            if (response.ok) { result.statusResponse = true; }
            await response.json().then(resultapi => result.data = resultapi);
        })
            .catch(error => console.log(error));
        return result;
    }
}