import React from "react";
import './RiderCard.css';
import { FieldRider } from '../FieldRider';
import { RiderCardService } from '../../Services/RiderCardService';
import { JSONRider } from '../../Services/JSONRider';
import { SelectedRiderListService } from '../../Services/SelectedRiderListService';
import { ModalInfo } from '../ModalInfo';

const { REACT_APP_IDTSVCATEGORY_LIFE, REACT_APP_BASIC_PLAN } = process.env;

export class RiderCard extends React.Component {

    constructor(props) {
        super(props);
        this.riderCardService = new RiderCardService();
        this.jsonRider = new JSONRider();
        this.selectedRiderListService = new SelectedRiderListService();
        this.state = {
            selectedCheckboxes: [],
            isActive: this.props.categoryName === REACT_APP_BASIC_PLAN ? true : this.props.rider.isActive,
            isOptional: this.props.categoryName === REACT_APP_BASIC_PLAN ? false : this.props.rider.isOptional,
            riderDetails: this.props.riderDetails, premium: null,
            isBasicPlan: this.props.categoryName === REACT_APP_BASIC_PLAN ? true : false,
            description: this.props.rider.helpTip != "",
            modalOptions: { show: false, title: '', description: '' }
        };
    }

    /**
      * Function that obtains the amount of the premium of the selected supplement.
      */
    getPremium() {
        let { riderDetails } = this.state;
        if (riderDetails.table != undefined) {
            riderDetails.table.map((rider, index) => {
                let categoryName = this.props.categoryName.includes(rider[1]);
                if (index > 0 && categoryName) {
                    this.setState({ premium: rider[3] != undefined ? rider[3] : null });
                }
            });
        }
    }
    /**
     * Check if the rider is stored in local storage if it is, 
     * the component is rendered with that rider
     */
    componentDidMount() {
        let selectedCheckboxes = this.state.selectedCheckboxes;
        let { categoryName } = this.props;
        let riderCategory = this.jsonRider.getCurrentRiders();
        this.getPremium();
        riderCategory.forEach(rider => {
            if (rider.isActive) {
                selectedCheckboxes.push(rider.categoryName);
            }
        });
        const sameValue = riderCategory.filter(rider => rider.categoryName === categoryName);
        if (sameValue[0]) {
            if (sameValue[0].isActive) {
                if(sameValue[0].faceAmountText == null){
                    this.activateSupplement();  
                }
                this.setState({ isActive: true });

            } else {
                this.setState({ isActive: false });
            }
        } else {
            if (this.state.isActive) {
                selectedCheckboxes.push(categoryName);
                this.activateSupplement();
            }
        }
        this.setState({ selectedCheckboxes: selectedCheckboxes });
    }

    /**
      * Handle select active supplement
      */
    activateSupplement = () => {
        let { categoryName, type, rider } = this.props;
        let requestRider = {
            categoryName: categoryName, type: type, value: rider.faceAmount,
            selectedInsuredCategory: rider.selectedInsuredCategory.planCodePrefix
        };
        this.selectedRiderListService.SaveRiderCategories(requestRider);
        
    //this.props.loadRiderCategory();
    }

    /**
      * Handles the change in an entry and updates the value of the selected rider
      * @param {Event} event change value of check field
      */
    changeCategoryName = (event) => {
        let request = {
            event: event,
            selectedCheckboxes: this.state.selectedCheckboxes,
            isActive: this.state.isActive,
            type: this.props.type,
            planCodePrefix: this.props.rider.selectedInsuredCategory.planCodePrefix
        };
        let response = this.riderCardService.HandleInputQuantityChange(request);
        this.setState({
            selectedCheckboxes: response.selectedCheckboxes,
            isActive: response.isActive
        });

        if (response.rider != null) {
            this.props.loadRiderCategory();
        }
    }

    /**
      * Renders a modal with de information of each answer option.
      */
    showModal(title, description) {
        let modalOptions = { show: true, title: title, description: description };
        this.setState({ modalOptions: modalOptions });
    }

    /**
      * Close the modal with de information of each answer option.
      */
    closeModal = () => {
        let modalOptions = { show: false };
        this.setState({
            modalOptions: modalOptions
        });
    };
    render() {
        const { idTSVCategory, categoryName } = this.props;
        const { modalOptions } = this.state;
        return (
            <>
                <div className={idTSVCategory === REACT_APP_IDTSVCATEGORY_LIFE ? 'card-rider' : 'card-rider-health'}>
                    <div className="rider-container-inputs">
                        <div className="container-check">
                            <div className="rider-check">
                                <input type="checkbox" className="input-check-rider"
                                    disabled={!this.state.isOptional}
                                    checked={this.state.selectedCheckboxes.includes(categoryName)}
                                    onChange={this.changeCategoryName} value={categoryName} />
                                <div className="categories-label">
                                    <label className="categories" key={1}>
                                        {categoryName}
                                    </label>
                                </div>
                               
                                <a style={{ display: !this.state.description ? 'none' : 'block' }}><i className='modal-icon-rider' tabIndex="0" aria-label="Más información" role="button"
                                    onClick={() => this.showModal(categoryName, this.props.rider.helpTip)}>
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12.5" cy="12.5" r="12.5" fill="#FDC740" onClick={() => this.showModal('', "Hola desde solictud de cubiertas ........")} />
                                        <path d="M11.4863 18.2508C11.4863 17.9421 11.5995 17.6737 11.8259 17.4455C12.0523 17.2174 12.3187 17.1033 12.625 17.1033C12.9313 17.1033 13.1976 17.2174 13.424 17.4455C13.6504 17.6737 13.7636 17.9421 13.7636 18.2508C13.7636 18.5595 13.6504 18.8212 13.424 19.036C13.1976 19.2373 12.9313 19.3379 12.625 19.3379C12.3187 19.3379 12.0523 19.2373 11.8259 19.036C11.5995 18.8212 11.4863 18.5595 11.4863 18.2508ZM12.4252 15.7142C12.3719 15.0834 12.312 14.4191 12.2454 13.7212C12.1922 13.0098 12.1322 12.3119 12.0656 11.6275C12.0124 10.9296 11.9591 10.2652 11.9058 9.63441C11.8659 9.00361 11.8259 8.43992 11.786 7.94334C11.746 7.43333 11.7127 7.01727 11.6861 6.69516C11.6595 6.35963 11.6461 6.15832 11.6461 6.09121C11.6461 5.72884 11.746 5.47383 11.9458 5.3262C12.1455 5.17857 12.3719 5.10475 12.625 5.10475C12.878 5.10475 13.1044 5.17857 13.3042 5.3262C13.5039 5.47383 13.6038 5.72884 13.6038 6.09121C13.6038 6.19858 13.5772 6.58108 13.5239 7.23872C13.4707 7.89636 13.4041 8.69492 13.3242 9.63441C13.2576 10.5739 13.1777 11.5872 13.0844 12.6743C12.9912 13.748 12.9047 14.7613 12.8247 15.7142H12.4252Z" fill="#052675" />
                                    </svg>
                                </i></a>
                            
                            </div>
                        </div>
                        <div className="container-field">
                            <div className={'rider-field-health'}>
                                {this.state.isActive &&
                                    (
                                        <FieldRider
                                            key={JSON.stringify(categoryName)}
                                            rider={this.props.rider}
                                            show={this.props.show}
                                            amount={this.props.amount}
                                            idTSVCategory={idTSVCategory}
                                            id={this.props.id}
                                            loadRiderCategory={this.props.loadRiderCategory}
                                            type={this.props.type}
                                            handleValidRange={this.props.handleValidRange}
                                            premium={this.state.premium}
                                            premiumAmount={this.props.premiumAmount}
                                            isBasicPlan={this.state.isBasicPlan}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {modalOptions.show ?
                    <ModalInfo modalOptions={modalOptions} closeModal={this.closeModal} /> :
                    null}
            </>
        )
    }
}
export default RiderCard;