import React from 'react';
import './SelectedRider';
import { Col, Row } from "react-bootstrap";
import { SelectedRiderListService } from '../../Services/SelectedRiderListService';
import './SelectedRider.css';
import { JSONRider } from '../../Services/JSONRider';

export class SelectedRiderMultiple extends React.Component {

    constructor(props) {
        super(props);
        this.selectedRiderListService = new SelectedRiderListService();
        this.jsonRider = new JSONRider();
        this.state = {
            valueOptions: 0,
            selectedInsuredCategory: this.props.selectedInsuredCategory
        };
    }

    /**
      * Method that loads selected options and updates which section to render
      */
    componentDidMount() {
        let { categoryName, type, options, selectedInsuredCategory } = this.props;
        let riderCategory = this.jsonRider.getCurrentRiders();
        let { faceAmountText, insuredCategory } = '';
        let value = options[0].display != undefined ? options[0].value : null;

        const sameValue = riderCategory.filter(rider => rider.categoryName === categoryName);

        if (sameValue[0]) {
            let prefix = sameValue[0].selectedInsuredCategoryPlanCodePrefix;
            insuredCategory = prefix === selectedInsuredCategory ? prefix : selectedInsuredCategory;

            faceAmountText = value !== sameValue[0].faceAmountText ? sameValue[0].faceAmountText : value;
            this.setState({ valueOptions: faceAmountText, selectedInsuredCategory: insuredCategory });

        } else {
            this.initialization(value, selectedInsuredCategory);
        }
    }

    /**
      * Function that updates the value of the selected rider and adds it to the list of selected categories.
      * @param {Int} value quantity selected in selection field
      */
    initialization = (value, selectedInsuredCategory, isChange) => {
        let { categoryName } = this.props;
        let type = "multiple";
        let request = { categoryName: categoryName, type: type, value: value, selectedInsuredCategory: selectedInsuredCategory };
        let rider = this.selectedRiderListService.SaveRiderCategories(request);
        if (isChange) {
            this.props.loadRiderCategory();
        }
    }

    /**
      * Function that updates the value of the selected rider and adds it to the list of selected categories.
      * @param {Event} event change value of selection field
      */
    handleInputChangeInsured = (event) => {
        let value = event.target.value
        this.setState({ selectedInsuredCategory: value });
        this.initialization(this.state.valueOptions, value, true);
    }

    /**
      * Function that updates the value of the selected rider and adds it to the list of selected categories.
      * @param {Event} event change value of selection field
      */
    handleInputChangeOptions = (event) => {
        let value = event.target.value
        this.setState({ valueOptions: value });
        this.initialization(value, this.state.selectedInsuredCategory, true);
    }

    render() {
        return (
            <Row>
                <Col md='5'>
                    <select className="form-control input custom-input"
                        onChange={(event) => this.handleInputChangeInsured(event)}
                        value={this.state.selectedInsuredCategory}>

                        {this.props.optionsInsured.map((option) => {
                            return (
                                <option key={option.planCodePrefix} value={option.planCodePrefix}> {option.name} </option>);
                        })
                        }
                    </select>
                </Col>
                <Col md='5'>
                    <select className="form-control input custom-input"
                        onChange={(event) => this.handleInputChangeOptions(event)}
                        value={this.state.valueOptions}>

                        {this.props.options.map((option) => {
                            if (option.display != undefined) { // selected faceAmountOptions 
                                return (
                                    <option key={option.value} value={option.value}> {option.display} </option>
                                );
                            }
                        })
                        }
                    </select>
                </Col>
                <Col md='2'>
                    <div className="premium">
                        <p className="title-premiun amount-text">SubTotal: {this.props.premium}</p>
                        <p className="value-premiun amount-text">{this.props.premium} </p>
                    </div>
                </Col>
            </Row>
        )
    }
}