import { JSONExchangeTSV } from "./JSONExchangeTSV";

export class MultipleChoiseCardServices {

    constructor() {
        this.jsonExchangeTSV = new JSONExchangeTSV();

    }

    /**
     * Gets the previous question with his answer from local storage, answered by the user.
     * @param  {Number} idCurrentCategory The numerical id of the current category.
     * @param  {Object} fieldConfig The question of the current component.
     * @param  {Array} options the options of the select element..
     * @param  {Array} selectedCheckboxes The selected elements by the user.
     * @return {Object} The question with his answer object if exist, if not then returns false.
    */
    GetPreviousAnswerFromLocalStorage = (idCurrentCategory, fieldConfig, options, selectedCheckboxes) => {
        fieldConfig.value = [];
        if (this.IsCurrentCategory(idCurrentCategory)) {
            if (this.GetLastAnswerSaved(fieldConfig) != null) {
                fieldConfig.value = this.GetLastAnswerSaved(fieldConfig);
                options.forEach(option => {
                    fieldConfig.value.forEach(answerList => {
                        answerList.forEach(value => {
                            if (parseInt(value.id_answer) === option.id) {
                                selectedCheckboxes.push(value.id_answer);
                            }
                        });
                    });

                });
                fieldConfig.value = [...selectedCheckboxes];
                let step = fieldConfig.options[0].step;

                const response = {
                    selectedCheckboxes: selectedCheckboxes,
                    errorMessage: '',
                    valid: true,
                    value: fieldConfig.value,
                    fieldConfig: fieldConfig,
                    step: step
                };

                return response;
            }
            return false;
        }
        return false;
    }

    /**
     * Handles a change on a input a valid if this change is correct.
     * @param  {Object} event The event when the input change.
     * @param {field}   field The question object used in the component.
     * @param  {Array} selectedCheckboxes The selected elements by the user.
     * @return {Object} The response object to set props and state in the component.
    */
    HandleInputChange = (event, fieldConfig, selectedCheckboxes) => {
        const response = this.CheckValidity(event, fieldConfig, selectedCheckboxes);
        return response;
    }

    /**
     * Validates if the value of the input is correct.
     * @param {fieldConfig}   fieldConfig The question object used in the component.
     * @param  {Array} selectedCheckboxes The selected elements by the user.
     * @param  {Object} selectedOption The selected option by the user.
     * @return {Object} The response object to set props and state in the component.
     */
    CheckValidity = (selectedOption, fieldConfig, selectedCheckboxes) => {
        let response = fieldConfig.idMainQuestion === '' ? this.CheckBoxValidity(selectedOption, selectedCheckboxes, fieldConfig) :
        this.SelectValidity(selectedOption, fieldConfig);

        return response;
    }

    /**
     * Validates if the value of the option checked is correct in multiple choise in matrix
     * @param {fieldConfig}   fieldConfig The question object used in the component.
     * @param  {Object} selectedOption The selected option by the user.
     * @return {Object} The response object to set props and state in the component.
     */
    SelectValidity(selectedOption, fieldConfig) {
        let rules = fieldConfig;
        let value = selectedOption;
        let isValid = false;
        let errorMessage = "";
        if (rules.required && value.length === 0) {
            isValid = false;
            value = "";
            errorMessage = '*Debe seleccionar al menos una de las opciones';
        }
        else if (!rules.required && value.length === 0) {
            isValid = true;
        } else {
            isValid = true;
        }
        fieldConfig.valid = isValid;
        fieldConfig.value = value;
        let response = {
            errorMessage: errorMessage,
            value: value,
            isValid: isValid
        };
        return response;

    }
    /**
     * Validates if the value of the option checked is correct in multiple choise without matrix
     * @param {fieldConfig}   fieldConfig The question object used in the component.
     * @param  {Array} selectedCheckboxes The selected elements by the user.
     * @param  {Object} selectedOption The selected option by the user.
     * @return {Object} The response object to set props and state in the component.
     */
    CheckBoxValidity(selectedOption, selectedCheckboxes, fieldConfig) {
        let rules = fieldConfig;
        let value = selectedOption;
        let isValid = false;
        let errorMessage = "";
        const responseValueCheck = this.MultipleChoiceValueChecked(value, fieldConfig, selectedCheckboxes);
        selectedCheckboxes = responseValueCheck.selectedCheckboxes;
    
        
        if (rules.required && selectedCheckboxes.length === 0) {
            isValid = false;
            value = "";
            errorMessage = '*Debe seleccionar al menos una de las opciones';
        } else if (!rules.required && selectedCheckboxes.length === 0) {
            isValid = true;
        }
        else {
            isValid = true;
        }
        fieldConfig.valid = isValid;
        let response = {
            errorMessage: errorMessage,
            value: value,
            isValid: isValid,
            selectedCheckboxes: responseValueCheck.selectedCheckboxes
        };
        return response;
    }

    /**
     * Validates if the value of the input is valid in the list of selected options.
     * @param {fieldConfig}   fieldConfig The question object used in the component.
     * @param  {Array} selectedCheckboxes The selected elements by the user.
     * @param  {Object} value The selected option by the user.
     * @return {Object} The response object to set props and state in the component.
     */
    MultipleChoiceValueChecked = (value, fieldConfig, selectedCheckboxes) => {
        let objectOption = fieldConfig.options.filter(selected => selected.id === Number(value))[0];
        let notSure = fieldConfig.options.filter(selected => selected.option === 'No estoy seguro');
        if (Array.isArray(value)) {
            let selectedOptions = [];
            value.forEach(option => {
                selectedOptions.push({ "id": option.value });
            });
            selectedCheckboxes = selectedOptions;
            fieldConfig.value = selectedOptions;
        } else {
            if (selectedCheckboxes.length === 0) {
                selectedCheckboxes.push(value);
                fieldConfig.value.push(value);

            } else {
                const sameValue = selectedCheckboxes.filter(selected => selected === value);
                if (sameValue[0]) {
                    let indexOf = selectedCheckboxes.indexOf(sameValue[0]);
                    selectedCheckboxes.splice(indexOf, 1);
                    fieldConfig.value.splice(indexOf, 1);
                    objectOption = null;
                    notSure = null;
                } else {
                    selectedCheckboxes.push(value);
                    fieldConfig.value.push(value);
                }
            }
            selectedCheckboxes = selectedCheckboxes;
        }

        let response = {
            fieldConfig: fieldConfig,
            selectedCheckboxes: selectedCheckboxes
        };
        if(objectOption !== null && objectOption.option === 'No estoy seguro'){
            selectedCheckboxes = []; 
            selectedCheckboxes.push(objectOption.id.toString());
            fieldConfig.value = selectedCheckboxes;
            response = {
                fieldConfig: fieldConfig,
                selectedCheckboxes: selectedCheckboxes
            };
         }else if(notSure !== undefined  && notSure !== null  && notSure.length > 0){
             
            const notSureValue = selectedCheckboxes.filter(selected => selected === notSure[0].id.toString());
            if (notSureValue[0]) {
                let indexOf = selectedCheckboxes.indexOf(notSureValue[0]);
                selectedCheckboxes.splice(indexOf, 1);
                fieldConfig.value.splice(indexOf, 1);
                response = {
                    fieldConfig: fieldConfig,
                    selectedCheckboxes: selectedCheckboxes
                };
            } 
         }

        return response;
    }

    /**
     * Validates if the input is correct to go to the next question and save it in the local storage.
     * @param {Boolean}   valid A boolean value to handle if the question status is correct to go forward.
     * @param  {Object} fieldConfig The question of the current component.
     * @return {Object} The response object to set props and state in the component.
     */
    NextStep = (valid, fieldConfig) => {
        let step = 0;
        let response = {
            valid: null,
            step: null,
            fieldConfig: null,
            isValid: null,
            next: null
        };
        if (!valid) {
            response.isValid = false;
            return response;
        } else if (fieldConfig.value === '' || fieldConfig.value.length === 0 ) {
            response.valid = valid;
            let step = fieldConfig.questionNumber + 1;
            if (step === fieldConfig.totalFields) {
                step = 0;
                response.step = step;
            } else {
                response.step = step;
            }
            response.next = true;
            response.fieldConfig = fieldConfig
            response.isValid = true;

            return response;
        }
        else {
            let answerValue = [];
            fieldConfig.value.forEach(value => {
                answerValue.push(fieldConfig.options.filter(option => option.id === parseInt(value))[0]);
            });
            step = answerValue[0].step;
            let answerListJSON = [];
            let value_answer_list = [];
            let insuredPersons = this.jsonExchangeTSV.getCurrentInsuredPersons();
            answerValue.forEach(answerValue => {

                value_answer_list.push({
                    'id_answer': `${answerValue.id}`, 'value_answer': `${answerValue.text}`,
                    'idInsuredPerson': insuredPersons[0] ? insuredPersons[0].idInsuredPerson : null
                });
            });
            answerListJSON.push(value_answer_list);

            let productQuestion = {
                "id_question": `${fieldConfig.id}`,
                "question_type": `${fieldConfig.type}`,
                "question_text": `${fieldConfig.body}`,
                "id_tsv_field": `${fieldConfig.idTSVField}`,
                "question_number": fieldConfig.questionNumber,
                "answer_list": answerListJSON
            };
            if (!this.jsonExchangeTSV.existQuestionOfProductQuestions(productQuestion)) {
                this.jsonExchangeTSV.addQuestionToProductQuestion(productQuestion);
            } else {
                let lastQuestion = this.jsonExchangeTSV.getQuestionOfProductQuestionsById(productQuestion);
                this.jsonExchangeTSV.removeQuestionOfProductQuestions(lastQuestion);
                this.jsonExchangeTSV.addQuestionToProductQuestion(productQuestion);
            }
            response.valid = valid;
            response.step = step;
            response.next = true;
            response.fieldConfig = fieldConfig;
            response.isValid = true;

            return response;
        }
    }

    /**
     * Validates if the category chosen by the user is the same of the local storage.
     * @param {Number}   isCurrentCategory A numerical id of the category
     * @return {Boolean} True if it is the current category.
     */
    IsCurrentCategory = (idCurrentCategory) => {
        let actualCateory = this.jsonExchangeTSV.getCurrentSerie();
        if (parseInt(actualCateory.id_series) === idCurrentCategory) {
            return true;
        }
        return false;
    }

    /**
     * Gets the last answer saved for a question in the local storage.
     * @param {Object}   fieldConfig The question of the current component.
     * @return {Boolean} True if it is the current category.
     */
    GetLastAnswerSaved = (fieldConfig) => {
        let question = { id_question: fieldConfig.id };
        let lastAnswer = this.jsonExchangeTSV.getQuestionOfProductQuestionsById(question);
        if (lastAnswer.answer_list) {
            return lastAnswer.answer_list;
        }
        return null;
    }
}