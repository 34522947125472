import './Header.css';
import React from 'react';
import { Container, Navbar, } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className='top'>
        <div className="top-slim-menu text-center"></div>
        <Navbar >
          <Container>
            <Navbar.Brand >
              <Link   to={location => ({ ...location, pathname: "/compra" })}>
              <img src="/compra/images/newLogo.png" className="nav-logo" />
              <div className="spacer"></div>
              </Link>
            </Navbar.Brand>
          </Container>
        </Navbar>
      </div>
    );
  }

}