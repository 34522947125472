import React from "react";
import './SliderCard.css';
import { Col, Row } from "react-bootstrap";
import { SelectedRiderListService } from '../../Services/SelectedRiderListService';
import { JSONRider } from '../../Services/JSONRider';
import { SliderCardService } from '../../Services/SliderCardService';
import { JSONExchangeTSV } from "../../Services/JSONExchangeTSV";

const { REACT_APP_BASIC_PLAN, REACT_APP_LABORATORY_TEST, REACT_APP_WILLING_QUESTION,
    REACT_APP_NO, REACT_APP_MAX_TEST } = process.env;

export class SliderCard extends React.Component {

    constructor(props) {
        super(props);
        this.selectedRiderListService = new SelectedRiderListService();
        this.jsonRider = new JSONRider();
        this.sliderCardService = new SliderCardService();
        this.jsonExchangeTSV = new JSONExchangeTSV();
        this.state = {
            valueAmount: this.props.sliderType.faceAmount,
            showField: false,
            selectedInsuredCategory: this.props.selectedInsuredCategory,
            refresh: false,
            id: this.props.id, changeField: true,
            validRange: true, errorMessageRange: '',
            isLaboratoryTest: false
        };
        this.nextRef = React.createRef();
        this.currentValueRef = React.createRef();
    }

    /**
     * Method that loads the selected riders and updates which section to render
     */
    componentDidMount() {
        let { categoryName, type } = this.props;
        let { selectedInsuredCategory } = this.state;
        let riderCategory = this.jsonRider.getCurrentRiders();
        let valueAmount = this.props.sliderType.faceAmount;
        let { minFaceAmountText } = this.props.sliderType
        let minVal = this.sliderCardService.getAmountText(minFaceAmountText);
        let changeField = this.state.changeField;
        let isLaboratoryTest = false;

        let valueLaboratory = this.jsonExchangeTSV.
            getQuestionOfRiskQuestionsByIdTSV(REACT_APP_LABORATORY_TEST, REACT_APP_WILLING_QUESTION);
        if (valueLaboratory != null) {
            if (valueLaboratory.value_answer == REACT_APP_NO) {
                isLaboratoryTest = valueLaboratory.value_answer == REACT_APP_NO ? true : false;
            }
        }

        const sameValue = riderCategory.filter(rider => rider.categoryName === categoryName);
        if (sameValue[0]) {
            let value = valueAmount != sameValue[0].faceAmountText ?
                valueAmount : sameValue[0].faceAmountText;
            changeField = sameValue[0].changeField;
            let valueTemp = value;
            if (changeField && categoryName == REACT_APP_BASIC_PLAN) {
                value = this.sliderCardService.getInsuranceAmount(value, categoryName,
                    type, selectedInsuredCategory, minVal, false, isLaboratoryTest);
                if (valueTemp != value) {
                    this.loadDataRider(value, true);
                }
            }
            value = this.getNumberFormat(value);
            this.setState({ valueAmount: value, refresh: true, changeField: false });
            changeField = false;
            if (this.props.type == 'sliderMultiple' && sameValue[0].faceAmountText == null) {
                this.loadDataRider(value, true);
            } else {
                if (changeField && categoryName == REACT_APP_BASIC_PLAN) {
                    this.loadDataRider(value);
                }
            }
        } else {
            let amount = this.props.sliderType.faceAmount;
            if (changeField && categoryName == REACT_APP_BASIC_PLAN) {
                amount = this.sliderCardService.getInsuranceAmount(amount, categoryName,
                    type, selectedInsuredCategory, minVal, false, isLaboratoryTest);

                if (this.props.sliderType.faceAmount != amount) {
                    this.loadDataRider(amount, true);
                }
            }
            amount = this.getNumberFormat(amount);
            this.setState({ valueAmount: amount, refresh: false, changeField: false });
            this.loadDataRider(amount);
        }
        this.setState({
            showField: this.props.sliderType.maxFaceAmountSlider > 1 ? true : false,
            isLaboratoryTest: isLaboratoryTest
        });
    }

    /**
      * Function generates quantity format.
      * @param {number} value value to convert
      */
    getNumberFormat(value) {
        value = Number(value).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        value = value.toString().substring(0, value.indexOf('.'));
        return value;
    }

    /**
      * Function that obtains the amount of the selected riders 
      * @returns {number} selected rider value 
      */
    getFaceAmountText() {
        let amount = this.props.sliderType.faceAmountText;
        let faceAmount = this.props.sliderType.faceAmount;
        if (amount != null) {
            if (Number(faceAmount) > 1) {
                return `$${amount}`;
            }
            return '';
        }
    }


    componentDidUpdate(prevProps, prevState) {
    }

    /**
      * Function that updates the value of the selected rider and adds it to the list of selected categories.
      * @param {Int} faceAmount selected amount from slider
      */
    loadDataRider = (faceAmount, isChange) => {
        let { categoryName, type } = this.props;
        let { selectedInsuredCategory, changeField } = this.state;
        let isSelected = type === 'sliderMultiple' ? 'sliderSelected' : '';
        faceAmount = faceAmount.toString().replaceAll(",", "");

        let request = {
            categoryName: categoryName, type: type, value: faceAmount, selectedInsuredCategory: selectedInsuredCategory, isSelected: isSelected,
            changeField: changeField
        };

        this.selectedRiderListService.SaveRiderCategories(request);
        if (isChange) {
            this.props.loadRiderCategory();
        }
    }

    /**
      * Function that updates the value of the selected rider and adds it to the list of selected categories.
      * @param {Event} event change value of slider field
      */
    handleInputChange = (event) => {
        let faceAmount = event.target.value;
        this.setState({
            valueAmount: faceAmount
        });
    }
    /**
      * Function that adds it to the list of selected categories.
      */
    handleClickSlider = () => {
        let faceAmount = this.state.valueAmount;
        let isLaboratoryTest = this.state.isLaboratoryTest;
        let { minFaceAmountText, maxFaceAmountText } = this.props.sliderType;
        let minVal = this.sliderCardService.getAmountText(minFaceAmountText);
        let maxVal = isLaboratoryTest != true ? this.sliderCardService.getAmountText(maxFaceAmountText) :
            REACT_APP_MAX_TEST;
        const response = this.sliderCardService.HandleInputChange(faceAmount, minVal, maxVal);
        let { validRange, errorMessageRange } = response;
        this.setState({ validRange: validRange, errorMessageRange: errorMessageRange });
        this.props.handleValidRange(validRange);

        if (validRange) {
            this.loadDataRider(faceAmount, true);
        }
    }
    /**
      * @param {number} value, value that will receive currency format
      * @returns the value in currency format
      */
    getAmountForSlider(value) {
        value = Math.trunc(value);
        return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }



    /**
      * Function that controls the event of the insurance amount field. 
      * @param {Event} event text input event
      */
    handleChangeBonus = (event) => {
        let { minFaceAmountText, maxFaceAmountText } = this.props.sliderType;
        let isLaboratoryTest = this.state.isLaboratoryTest;

        let maxVal = isLaboratoryTest != true ? this.sliderCardService.getAmountText(maxFaceAmountText) :
            this.sliderCardService.getAmountText(REACT_APP_MAX_TEST);

        let minVal = this.sliderCardService.getAmountText(minFaceAmountText);

        const response = this.sliderCardService.HandleInputChange(event.target.value, minVal, maxVal);
        let { validRange, errorMessageRange, valueRange } = response;
        this.setState({
            validRange: validRange, errorMessageRange: errorMessageRange, valueAmount: valueRange
        });
        this.props.handleValidRange(validRange);
    }

    /**
      * Function that sends the amount of the selected insurance to the service.
      */
    handleBlurBonus = () => {
        if (this.state.validRange) {
            let faceAmount = this.state.valueAmount;
            this.loadDataRider(faceAmount, true);
        }
    }

    render() {
        return (
            <>
                {this.state.showField &&
                    <Row>
                        <Col md='10'>
                            <div className="input-div-text">
                                <input
                                    type="text"
                                    onChange={(event) => this.handleChangeBonus(event)}
                                    onBlur={this.handleBlurBonus}
                                    value={this.state.valueAmount}
                                    required={true}
                                    placeholder={'Monto del Seguro'}
                                    className={this.state.validRange ? ('form-control custom-input') : ('form-control is-invalid is-invalid-border custom-input')}
                                />
                            </div>
                            {!this.state.validRange ?
                                <i className="input-i-rider">
                                    <svg width='25' height='25' viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <ellipse cx="18.5" cy="19.3965" rx="18.5" ry="18.4428" fill="#F2274C" />
                                        <path d="M17 27.8814C17 27.4259 17.1675 27.0299 17.5026 26.6932C17.8377 26.3566 18.2319 26.1883 18.6852 26.1883C19.1385 26.1883 19.5327 26.3566 19.8678 26.6932C20.2029 27.0299 20.3704 27.4259 20.3704 27.8814C20.3704 28.3368 20.2029 28.7229 19.8678 29.0398C19.5327 29.3368 19.1385 29.4853 18.6852 29.4853C18.2319 29.4853 17.8377 29.3368 17.5026 29.0398C17.1675 28.7229 17 28.3368 17 27.8814ZM18.3896 24.1388C18.3107 23.2081 18.222 22.2279 18.1235 21.1982C18.0446 20.1487 17.9559 19.119 17.8574 18.1091C17.7785 17.0794 17.6997 16.0992 17.6209 15.1685C17.5617 14.2378 17.5026 13.4061 17.4435 12.6734C17.3843 11.921 17.3351 11.3071 17.2957 10.8319C17.2562 10.3368 17.2365 10.0398 17.2365 9.94077C17.2365 9.40611 17.3843 9.02987 17.68 8.81205C17.9756 8.59423 18.3107 8.48532 18.6852 8.48532C19.0597 8.48532 19.3948 8.59423 19.6904 8.81205C19.9861 9.02987 20.1339 9.40611 20.1339 9.94077C20.1339 10.0992 20.0945 10.6635 20.0156 11.6338C19.9368 12.6041 19.8382 13.7824 19.72 15.1685C19.6214 16.5546 19.5032 18.0497 19.3652 19.6536C19.2272 21.2378 19.0991 22.7328 18.9809 24.1388H18.3896Z" fill="white" />
                                    </svg>
                                </i> : null}
                            {!this.state.validRange ?
                                <div className="text-left message-error">
                                    <span className='error text-left'> {this.state.errorMessageRange} </span>
                                </div>
                                : null
                            }
                            {this.props.sliderType.validationMessageIsVisible &&
                                <div className="text-left">
                                    <span className='error'>{this.props.sliderType.validationMessage}</span>
                                </div>
                            }
                        </Col>
                        <Col md='2'>
                            <div className="premium">
                                <p className="title-premiun amount-text">SubTotal: {this.props.premium}</p>
                                <p className="value-premiun amount-text">{this.props.premium} </p>
                            </div>
                        </Col>
                    </Row>
                }
                {!this.state.showField &&
                    (
                        <Row>
                            <Col md='10'>
                                <div className="slider-text">
                                    <label className="amount-text-slider"> {this.getFaceAmountText()}
                                    </label>
                                </div>
                            </Col>
                            <Col md='2'>
                                <div className="premium">
                                    <p className="title-premiun amount-text">SubTotal: {this.props.premium}</p>
                                    <p className="value-premiun amount-text">{this.props.premium} </p>
                                </div>
                            </Col>
                        </Row>
                    )
                }
            </>
        )
    }
}
export default SliderCard;