import { JSONExchangeTSV } from "./JSONExchangeTSV";

const urlBaseTSV = process.env.REACT_APP_URL_API_TSV_PORTAL;
const urlBaseTSVSALES = process.env.REACT_APP_URL_API_TSV_PORTAL_SALES;
const { REACT_APP_CANCER_PRODUCTS_INFO, REACT_APP_SSN_QUESTION, 
    REACT_APP_CLIENTID_TSV, REACT_APP_CLIENTSECRET_TSV } = process.env
export class WebServicesTSV {

    constructor() {
        this.jsonExchangeTSV = new JSONExchangeTSV();
    }

    // Weight / height ratio classification service with respect to defined tables (BMI).
    // In the insurer parameter always send TSV.
    SurchargePercentage = async (person) => {
        let myHeaders = new Headers();
        myHeaders.append("Accept", "*/*");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("clientId", REACT_APP_CLIENTID_TSV);
        myHeaders.append("clientSecret", REACT_APP_CLIENTSECRET_TSV);
        const result = { statusResponse: false, data: null, error: null };
        await fetch(`${urlBaseTSV}Underwriting/surchargePercentage?Feet=${person.Feet}&Inch=${person.Inch}&Weight=${person.Weight}&Insurer=tsv`, {
            method: 'GET',
            headers: myHeaders,
        }).then(async response => {
            if (response.ok) {
                result.statusResponse = true;
                await response.json().then(resultapi => result.data = resultapi);
            }
            else if (response.status === 404) {
                result.statusResponse = 404;
            }
        }).catch(error => console.log(error));
        return result;
    }

    // Service for determining the user's age based on the date of birth and
    // Service for determining the user's age based on the date of birth and product ..
    // - Respond with 2 ages: closest and the completed years.
    // - Optionally receive the product, calculate the date regarding it and indicate which one should be used. This applies to risk questions.
    PeopleAge = async (person) => {
        const result = { statusResponse: false, data: null, error: null };
        let myHeaders = new Headers();
        myHeaders.append("Accept", "*/*");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("clientId", REACT_APP_CLIENTID_TSV);
        myHeaders.append("clientSecret", REACT_APP_CLIENTSECRET_TSV);

        let body = JSON.stringify(person);
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: body,
        };
        await fetch(`${urlBaseTSV}People/age`, requestOptions)
            .then(async response => {
                if (response.ok) { result.statusResponse = true; }
                await response.json().then(resultapi => result.data = resultapi);
            })
            .catch(error => console.log('error', error));
        return result;
    }
    // Service that determines if the user is a TSV customer and what products they have (cancer policies).
    // Use the last 4 digits of the social security number or the full number.
    // returns a list of active products that the client has associated with. If you are not a TSV customer, it returns empty.
    PersonProductInfo = async (personInfo) => {
        const result = { statusResponse: false, data: null, error: null };
        let myHeaders = new Headers();
        myHeaders.append("Accept", "*/*");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("clientId", REACT_APP_CLIENTID_TSV);
        myHeaders.append("clientSecret", REACT_APP_CLIENTSECRET_TSV);

        let body = JSON.stringify(personInfo);
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: body
        };
        await fetch(`${urlBaseTSV}People/insured/productinfo`, requestOptions)
            .then(async response => {
                if (response.ok || response.status === 404) { result.statusResponse = true; }
                await response.json().then(resultapi => result.data = resultapi);
            })
            .catch(error =>
                console.log('error', error)
            );
        return result;
    }

    // Service that sends the json from the local storage  to TSV to create  a new insurance application.
    // Use the json from the local storage converted to  string.
    // Return true if the appplication is correct.
    ApplicationSales = async (application) => {
        const result = { statusResponse: false, data: null, error: null };
        let myHeaders = new Headers();
        myHeaders.append("Accept", "*/*");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("clientId", REACT_APP_CLIENTID_TSV);
        myHeaders.append("clientSecret", REACT_APP_CLIENTSECRET_TSV);

        let body = JSON.stringify(application);
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: body
        };
        await fetch(`${urlBaseTSV}ApplicationSales/create`, requestOptions)
            .then(async response => {
                if (response.ok || response.status === 404) { result.statusResponse = true; }
                await response.json().then(resultapi => result.data = resultapi);
            })
            .catch(error =>
                console.log('error', error)
            );
        return result;
    }

    // Service that gets the policy totals of a product that the  customer has with TSV.
    // Use the the customer and product information.
    // The main insured person to request products.
    // Return the aggregate total of policies.
    PolicyTotals = async (idTSVProduct, mainInsuredPerson) => {
        const result = { statusResponse: false, data: null, error: null };

        //process and create the request object needed by the service
        let productsInfo = JSON.parse(REACT_APP_CANCER_PRODUCTS_INFO);
        let productRequest = {
            'ssn': null,
            'dob': null,
            'sex': null,
            'productType': null,
            'firstdiag': null,
            'coverageType': null,
            'status': 'A'
        }
        // get the ssn from the question of the local storage
        let questionSSN = this.jsonExchangeTSV.getCurrentProductQuestions();
        questionSSN = questionSSN.filter(quesiton => quesiton.question_text.includes(REACT_APP_SSN_QUESTION));

        productRequest.dob = mainInsuredPerson.dateOfBirth;
        productRequest.sex = mainInsuredPerson.gender === 'Masculino' ? 'M'
            : mainInsuredPerson.gender === 'Femenino' ? 'F' : null;
        productRequest.ssn = questionSSN[0] ? questionSSN[0].value_answer ?
            questionSSN[0].value_answer : null : null;


        for (const product of productsInfo) {

            if (product.id_tsv === idTSVProduct) {
                productRequest.coverageType = product.coverageType;
                productRequest.productType = product.productType;
                productRequest.firstdiag = product.firstDiag;
                break;
            }
        }

        let myHeaders = new Headers();
        myHeaders.append("Accept", "*/*");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("clientId", REACT_APP_CLIENTID_TSV);
        myHeaders.append("clientSecret", REACT_APP_CLIENTSECRET_TSV);
        let body = JSON.stringify(productRequest);
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: body
        };
        await fetch(`${urlBaseTSV}Policy/Totals`, requestOptions)
            .then(async response => {
                if (response.ok || response.status === 404) { result.statusResponse = true; }
                await response.json().then(resultapi => result.data = resultapi);
            })
            .catch(error =>
                console.log('error', error)
            );
        return result;
    }


    /**
     * Service to save the user/customer contact information 
     * @param {*} contactInfo, user contact information that will be send 
     * @returns 200code/true if user contact information was save  
     */
    SalesForce = async (contactInfo) => {
        const result = { statusResponse: false, data: null, error: null };
        let myHeaders = new Headers();
        myHeaders.append("Accept", "*/*");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("clientId", REACT_APP_CLIENTID_TSV);
        myHeaders.append("clientSecret", REACT_APP_CLIENTSECRET_TSV);

        let body = JSON.stringify(contactInfo);
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: body
        };
        await fetch(`${urlBaseTSVSALES}send`, requestOptions)
            .then(async response => {
                if (response.ok) { result.statusResponse = true; };
            })
            .catch(error =>
                console.log('error', error)
            );
        return result;
    }

    /** 
      * Service that verifies if TSV services are above.
      * @returnsReturns the service information 
      */
    OverallLoadStatus = async () => {
        const result = { statusResponse: false, data: null, error: null };
        let myHeaders = new Headers();
        myHeaders.append("Accept", "*/*");
        myHeaders.append("clientId", REACT_APP_CLIENTID_TSV);
        myHeaders.append("clientSecret", REACT_APP_CLIENTSECRET_TSV);
        let requestOptions = { method: 'GET', headers: myHeaders };

        await fetch(`${urlBaseTSV}Configuration/DataWarehouseOverallLoadStatus`, requestOptions)
            .then(async response => {
                if (response.ok) {
                    result.statusResponse = true;
                    await response.json().then(resultapi => result.data = resultapi);
                };
            }).catch(error => console.log('error', error));
        return result;
    }

    /** 
      * Service that verifies if TSV services policy are above.
      * @returns Returns the service information 
      */
    PolicyLoadStatus = async () => {
        const result = { statusResponse: false, data: null, error: null };
        let myHeaders = new Headers();
        myHeaders.append("Accept", "*/*");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("clientId", REACT_APP_CLIENTID_TSV);
        myHeaders.append("clientSecret", REACT_APP_CLIENTSECRET_TSV);
        let requestOptions = { method: 'GET', headers: myHeaders };
        await fetch(`${urlBaseTSV}Configuration/DataWarehousePolicyLoadStatus`, requestOptions)
            .then(async response => {
                if (response.ok) {
                    result.statusResponse = true;
                    await response.json().then(resultapi => result.data = resultapi);
                };
            }).catch(error =>
                console.log('error', error));
        return result;
    }
}
