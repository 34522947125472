import React from "react";
import { LoadingPage } from "../../pages/LoadingPage";
import { Card } from "react-bootstrap";
import { SelectedRiderListService } from "../../Services/SelectedRiderListService";
import { ParameterService } from "../../Services/ParameterService";
import './SelectedRiderList.css';

const { REACT_APP_PAYMENT_MODE } = process.env;

export class PaymentDetails extends React.Component {
    constructor(props) {
        super(props);
        this.selectedRiderListService = new SelectedRiderListService();
        this.parameterService = new ParameterService();
        this.state = {
            loading: true,
            value: 0,
            paymentsTypes: [],
            refresh: false,
            payment: { paymentMod: null, modeIntervals: null, paymentType: null, methodKey: null }
        };
    }

    /**
      * Function that initializes the payment method.
      * @param {String} text Description of the payment method
      * @param {String} value Payment method value
      */
    initializePayment = async (text, value) => {
        let paymentType = this.state.payment;

        if (this.props.idPayment === REACT_APP_PAYMENT_MODE) {
            paymentType.paymentMod = text;
            paymentType.modeIntervals = value;
        } else {
            paymentType.paymentType = text;
            paymentType.methodKey = value;
        }
        await this.selectedRiderListService.SavePaymentLocalstorage(paymentType, this.props.idPayment);
        let response = this.selectedRiderListService.GetPaymentOfProductPayment(paymentType);
        this.setState({ payment: response, loading: false });

        if (this.props.refreshTypeData) {
            this.props.refreshTypeData();
        } else {
            this.props.refreshData();
        }
    }

    /**
      * This method is used to load the payment type.
      */
    loadDataPayment = () => {
        const idParameterType = { "idParameterValue": Number(this.props.idPayment) };
        this.parameterService.GetAllParameterById(idParameterType).then(result => {
            let refresh = !this.state.refresh;
            if (result.statusResponse) {
                this.setState({ paymentsTypes: result.data, loading: false, refresh: refresh });
            }
        }).catch(error => {
            if (error.error != null) {
                console.log("Ha ocurrido un error al cargar los métodos de pago", error);
                this.props.handleErrorInComponent(true);
            }
            this.setState({ loading: false });
        });
    }

    /**
      * This method upload the payment method information
      */
    componentDidMount() {
        this.loadDataPayment();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.refresh !== this.state.refresh) {
            this.getDataLocalStorage();
        }
    }

    /**
      * Function that obtains the payment data saved in the localstorage 
      */
    getDataLocalStorage = () => {
        let { paymentsTypes } = this.state;
        let response = this.selectedRiderListService.GetPayment();
        if (response.modeIntervals != undefined) {
            let valuePayment = this.props.idPayment === REACT_APP_PAYMENT_MODE ? response.modeIntervals : response.methodKey;
            this.setState({ value: valuePayment, loading: false });

            if (this.props.refreshTypeData) {
                this.props.refreshTypeData();
            } else {
                this.props.refreshData();
            }
        } else {
            let data = paymentsTypes != null && paymentsTypes.length > 0 ? paymentsTypes : null;
            this.initializePayment(data != null ? data[0].name : null, data != null ? data[0].value : null);

        }
    }

    /**
      * Function that changes the payment typr that has been selected.
      * @param {Event} event change value of selection field
      */
    changePaymentType(event) {
        let text = event.target.options[event.target.selectedIndex].text;
        let value = event.target.value;
        this.initializePayment(text, value);
        this.setState({ value: value });
        if (this.props.handledRider) {
            this.props.handledRider();
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div className='page'> <LoadingPage /> </div>
            );
        } else {
            return (<>
                <Card.Text className='text-left title-card'>
                    {this.props.text}
                </Card.Text>
                <div className='select-payment'>
                    <select
                        className="form-control input custom-input"
                        onChange={(event) => this.changePaymentType(event)}
                        value={this.state.value} >
                        <option value="" disabled selected>{this.props.text }</option>  
                        {this.state.paymentsTypes.map((paymentType, key) =>
                            <option key={key} value={paymentType.value}> {paymentType.name}  </option>
                        )}
                    </select>
                </div>
            </>)
        }
    }
}
export default PaymentDetails;