import React from "react";
import { CategoryServices } from "../../Services/CategoryServices";
import { JSONExchangeTSV } from "../../Services/JSONExchangeTSV";
import { SelectedProductListServices } from "../../Services/SelectedProductListServices";
import { TokenServices } from "../../Services/TokenServices";
import { ButtonTSV } from "../ButtonTSV";
import './ProductCard.css';
import { SelectedRiderListService } from "../../Services/SelectedRiderListService";
import { ModalProductDescription } from './ModalProductDescription';

const saving = process.env.REACT_APP_IDTSVCATEGORY_SAVING;

const showScore = process.env.REACT_APP_SHOW_SCORE;

export class ProductCard extends React.Component {

    constructor(props) {
        super(props);
        this.jsonExchangeTSV = new JSONExchangeTSV();
        this.state = { showModal: false, showModalDescription: false, insuredPersonsApproved: [], insuredPersonsDenied: [] };
        this.selectedProductlistServices = new SelectedProductListServices();
        this.categoryServices = new CategoryServices();
        this.tokenService = new TokenServices();
        this.selectedRiderListService = new SelectedRiderListService();
        this.state = {
            score: false
        };

    }

    async componentDidMount() {
        await this.showSocore();

    }
    async showSocore() {
        let score = await this.selectedProductlistServices.IsVisibleScore(showScore);
        this.setState({ score: score });
    }
    /**
     * 
     * @param {*} dateOfBirth string with the date of birth of the insured
     * @returns date format dd / mm / yyy
     */
    handleDateFormat(dateOfBirth) {
        let userEnteredDateISO = '';
        if (dateOfBirth != null) {
            const parts = dateOfBirth.split("-");
            const day = parts[2].split('T')[0]
            userEnteredDateISO = `${day}/${parts[1]}/${parts[0]}`;
        }
        return userEnteredDateISO;
    }
    handleProductCard = async () => {
        const { product } = this.props;
        let selectedProduct = {
            id_product: `${product.idProduct}`, name: product.name,
            id_tsv_product: product.idTSVProduct ? product.idTSVProduct : null,
            insured_persons_approved: product.insuredPersonsApproved,
            insured_persons_denied: product.insuredPersonsDenied
        };
        let previousProduct = this.jsonExchangeTSV.getCurrentSelectedProduct();
        if (Number(previousProduct.id_product) !== Number(selectedProduct.id_product)) {
            this.jsonExchangeTSV.setRisks({ risk_questions: [], insured_risk: [] });
        }
        this.jsonExchangeTSV.setSelectedProduct(selectedProduct);
        this.props.handleProductNameInfo(selectedProduct.name);
        let idCategory = { idCategory: Number(this.jsonExchangeTSV.getCurrentSerie().id_series) };
        const token = this.tokenService.DecryptToken();
        const response = await this.categoryServices.ExistsRiskInCategory(token, idCategory);
        if (response.statusResponse) {
            if (response.data) {
                this.selectedRiderListService.InitializeJSONRider(product.idTSVProduct ? product.idTSVProduct : null);
                this.props.handleRiskQuestions(this.props.idProduct, product.idTSVProduct, product.idTSVCategory);
            } else {
                this.selectedRiderListService.InitializeJSONRider(product.idTSVProduct ? product.idTSVProduct : null);
                this.props.goRiders(product.idTSVProduct, product.idTSVCategory);
            }
        } else {
            this.selectedRiderListService.InitializeJSONRider(product.idTSVProduct ? product.idTSVProduct : null);
            this.props.goRiders(product.idTSVProduct, product.idTSVCategory);

        }
        let helpSticky = document.getElementById("div-help-sticky");
        let helpChat = document.getElementById("div-help-chat");
        helpSticky.style.display = "none";
        helpChat.style.display = "block";


    }

    handleInsuredModalClick = () => {
        const { product } = this.props;
        this.setState({
            insuredPersonsApproved: product.insuredPersonsApproved,
            insuredPersonsDenied: product.insuredPersonsDenied, showModal: true
        });
    }
    handleInsuredModalDescriptionClick = () => {
        const { product } = this.props;
        this.setState({
            insuredPersonsApproved: product.insuredPersonsApproved,
            insuredPersonsDenied: product.insuredPersonsDenied, showModal: false, showModalDescription: true
        });
    }


    /**
     * Close the modal with de information of each answer option.
     */
    closeModal = () => {
        this.setState({ showModal: false });
    }

    closeModalDescription = () => {
        this.setState({ showModalDescription: false });
    }
    cutDescription(description) {
        return description.length > 295 ? description.substring(0, 295) : description;
    }
    cutPoints(point) {
        point = point += '';
        return point.substring(0, 3);
    }
    conditionalRender = () => {
        let page = '';
        const { showModal, showModalDescription, score } = this.state;
        switch (true) {
            case showModal:
                page = this.renderModal();
                break;
            case showModalDescription:
                page = <ModalProductDescription
                    title={this.props.title}
                    description={this.props.description}
                    average={this.props.average}
                    score={score}
                    closeModalDescription={this.closeModalDescription}
                    handleProductCard={this.handleProductCard} />;
                break;
            default:
                return <></>;
        }
        return <>{page}</>;
    }

    renderModal = () => {
        const { insuredPersonsApproved, insuredPersonsDenied } = this.state;
        return (
            <div className="div-modal-product" >
                <div className="modal-size">
                    <div className="modal-dialog modal-dialog-product modal-lg modal-border-product">

                        <div className="modal-content modal-content-product">
                            <div className="header-modal-product">
                                <h5 className="modal-title-product">  {this.props.title}</h5>
                                <button className="close trash-align" data-dismiss="modal"
                                    onClick={this.closeModal} aria-label="Close">
                                    <span className='close-modal' aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h6 className="modal-sub-text">Personas cubiertas por la póliza</h6>
                                <table className="table table-product" >
                                    <thead>
                                        <th style={{ width: "35%" }}>
                                            <p className="colum-text">Nombre</p>
                                            <hr className="line-name" />
                                        </th>
                                        <th>
                                            <p className="colum-text">Parentesco</p>
                                            <hr className="line-name" />
                                        </th>
                                        <th >
                                            <p className="colum-text">Fecha de Nacimiento</p>
                                            <hr className="line-name" />
                                        </th>
                                        <th>
                                            <p className="colum-text">G&eacute;nero</p>
                                            <hr className="line-name" />
                                        </th>
                                    </thead>
                                    <tbody>
                                        {insuredPersonsApproved.map((insuredApproved) => (
                                            <tr key={insuredApproved.idInsured}>
                                                <td data-label="Nombre">{insuredApproved.name} {insuredApproved.lastName}</td>
                                                <td data-label="Parentesco">{insuredApproved.relationship}</td>
                                                <td data-label="Fecha de Nacimiento">{this.handleDateFormat(insuredApproved.dateOfBirth)}</td>
                                                <td data-label="Género">{insuredApproved.gender}</td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </table>
                                {
                                    insuredPersonsDenied.length > 0 ?
                                        <>
                                            <h6 className="modal-sub-text">*Personas no cubiertas por la póliza</h6>
                                            <table className="table table-product" >
                                                <thead>
                                                    <th style={{ width: "35%" }}>
                                                        <p className="colum-text">Nombre</p>
                                                        <hr className="line-name" />
                                                    </th>
                                                    <th style={{ width: "20%" }}>
                                                        <p className="colum-text">Parentesco</p>
                                                        <hr className="line-name" />
                                                    </th>
                                                    <th>
                                                        <p className="colum-text">Fecha de Nacimiento</p>
                                                        <hr className="line-name" />
                                                    </th>
                                                    <th>
                                                        <p className="colum-text">G&eacute;nero</p>
                                                        <hr className="line-name" />
                                                    </th>
                                                </thead>
                                                <tbody>
                                                    {insuredPersonsDenied.map((insuredDenied) => (
                                                        <tr key={insuredPersonsDenied.idInsured}>
                                                            <td data-label="Nombre">{insuredDenied.name} {insuredDenied.lastName}</td>
                                                            <td data-label="Parentesco">{insuredDenied.relationship}</td>
                                                            <td data-label="Fecha de Nacimiento">{this.handleDateFormat(insuredDenied.dateOfBirth)}</td>
                                                            <td data-label="Género">{insuredDenied.gender}</td>
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            </table>
                                            <h5 className="modal-sub-text-note">*Esta(s) persona(s) pueden ser elegibles para una póliza individual o para otro producto.</h5>
                                        </>
                                        :
                                        null//<h5 className="modal-sub-text">Todas las personas son elegibles para la póliza.</h5>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="card card-product">
                    <div className="product-card">
                        <div className="card-title-div-products">
                            <p className="card-title-products">
                                <span className="product-title">{this.props.title}
                                    {this.state.score ? <span className="dot">
                                        <p className="card-subtitle">
                                            {this.cutPoints(this.props.average)}
                                            <p className="score">score</p>
                                        </p>
                                    </span>
                                        : null}
                                </span>
                            </p>
                        </div>
                        <div className="">
                            <div className=''>
                                <p className="card-text-div-products">
                                    <span className="product-description">
                                        <p className="cut-description">{this.cutDescription(this.props.description)}
                                            {this.props.description.length > 295 ?
                                                <svg className="moreInfo" width="19" height="22" viewBox="0 0 19 22" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="9.3" cy="11.8152" r="9.3" transform="rotate(-90 9.3 11.8152)" fill="#052675" onClick={this.handleInsuredModalDescriptionClick} />
                                                    <path d="M10.3264 11.2754H14.3498V12.623H10.3264V16.7832H8.96899V12.623H4.96508V11.2754H8.96899V7.0957H10.3264V11.2754Z" fill="white" />
                                                </svg> : null
                                            }  </p>
                                    </span>
                                </p>
                            </div>
                            <div className='card-footer text-center'>

                                <button onClick={this.handleProductCard} className="btn btn-landing cartbtn  btn-primary22 btn-buy">
                                    <span className='home'><span>{'quiero esta opción'}</span></span>
                                    <div className="icon iconCard">     <svg width={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"} height={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"}
                                        className='image-svg-landing'
                                        xmlns="http://www.w3.org/2000/svg"
                                        fillRule="evenodd" clipRule="evenodd">
                                        <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
                                    </svg></div>
                                </button>
                            </div>
                            {this.props.idTSVCategory !== saving ?
                                <div className='card-footer text-center'>
                                    <button className="btn btn-landing  btn-primary btn-next" onClick={this.handleInsuredModalClick}>
                                        <span><span>{'Personas Cubiertas'}</span></span>
                                        <div className="icon">
                                            <svg width={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"} height={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"}
                                                className='image-svg-landing'
                                                xmlns="http://www.w3.org/2000/svg"
                                                fillRule="evenodd" clipRule="evenodd">
                                                <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
                                            </svg>
                                        </div>
                                    </button>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                    {
                        this.conditionalRender()
                    }
                </div>

            </>
        )
    }



}
export default ProductCard;
