import './UniqueSelectionCard.css';
import React from 'react';
import { UniqueSelectionCardServices } from '../../Services/UniqueSelectionCardServices';

export class UniqueSelectionCard extends React.Component {

  constructor(props) {
    super(props);
    this.uniqueSelectionCardServices = new UniqueSelectionCardServices();
    this.state = {
      value: '-1',
      valid: false,
      validInput: true,
      errorMessage: null
    };
  }

  /**
   * Verifies that the nextProp property has changed, when its value is true it takes the identifier of the current component and calls nextStep to verify that the question was answered correctly.
   */
  componentDidUpdate(prevProps) {
    if (prevProps.nextProp !== this.props.nextProp) {
      if (this.props.nextProp && this.props.indexNext === this.props.index) {
        this.nextStep();
      } else if (this.props.nextProp && this.props.fieldConfig.idMainQuestion !== '') {
        if (this.props.indexNext.includes(this.props.index)) {
          this.nextStep();
        }
      }
    }
  }

  /**
   * Checks if the answer of the question is stored in the local storage if so the component is rendered with that answer 
   */
  componentDidMount() {
    const { fieldConfig, idCurrentCategory } = this.props;
    const response =
      this.uniqueSelectionCardServices.GetPreviousAnswerFromLocalStorage(idCurrentCategory, fieldConfig);
    if (response) {
      this.setState({
        errorMessage: response.errorMessage, valid: response.valid, value: response.value
      });
      this.props.funtionHandleSetProps(true, response.step, false, response.field);
    }
  }

  /**
   * Validates if the input is correct to go to the next question and save it in the local storage.
   */
  nextStep = () => {
    const { valid } = this.state;
    const { fieldConfig } = this.props;
    let response = null;
    if(fieldConfig.value === '' && !fieldConfig.required){
      response= this.uniqueSelectionCardServices.NextStep(true, fieldConfig);
    }else{
      response= this.uniqueSelectionCardServices.NextStep(valid, fieldConfig);
    }
      
    if (!response.isValid) {
      this.setState({ errorMessage: '*Debe seleccionar una opción', validInput: response.isValid, });
      this.props.funtionHandleSetProps(valid, 0, true, fieldConfig);
    } else {
      this.props.funtionHandleSetProps(response.valid, response.step, response.next, response.field);
    }
  }

  /**
   * Handles a change on a input a valid if this change is correct.
   */
  handleInputChange = (event) => {
    const { fieldConfig } = this.props;
    const response = this.uniqueSelectionCardServices.HandleInputChange(event, fieldConfig);
    this.setState({
      errorMessage: response.errorMessage, valid: response.isValid, validInput: response.isValid,
      value: response.value
    });
    this.props.funtionHandleSetProps(response.isValid, false, 0);
  }

  render() {
    const { options, required, fieldConfig } = this.props;
    const { valid, validInput, errorMessage } = this.state;
    let isInputMatix = fieldConfig.idMainQuestion !== '';
    return (
      <>
      <div className={validInput && !isInputMatix ? ('border-div-input') : validInput && isInputMatix ? (''):
             !validInput && !isInputMatix ?     ('is-invalid-border'): ''}>
      <div className={!isInputMatix ? ('text-left inpt-select') : ('text-left control-matrix')}>
        <select className={ `form-control custom-input ${(validInput && isInputMatix) ? (' height-input-matrix custom-select-matrix') :
         (validInput && !isInputMatix)? (' height-input custom-select'):
         (!validInput && !isInputMatix) ? ('is-invalid-input height-input custom-alert '): 
         (!validInput && isInputMatix) ?'height-input-matrix  is-invalid-input custom-alert-matrix' : ''}`}
          required={required}
          onChange={(event) => this.handleInputChange(event)}
          value={fieldConfig.value || '-1'}>
          <option key={'-1'} value={'-1'}>
            {'Selecciona'}
          </option>
          {options.map(option => (
            <option key={option.id} value={option.id}>
              {option.text}
            </option>
          ))}
        </select>
      </div>
      </div>
      <div className="text-left">
            {!validInput && <span className='error'>{errorMessage}</span>}
      </div>
      </>
    );
  }

}