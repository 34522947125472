import { TokenServices } from "./TokenServices";

const REACT_APP_URL_API_TSV = process.env.REACT_APP_URL_API_TSV;

export class ParameterService {
    constructor() {
        this.tokenServices = new TokenServices();
        this.token = this.tokenServices.DecryptToken();
    }

    /** 
     * This method is for loading parameters values by id parameter
     * @param { Object } idParameter - The id of the parameters values to consult 
     * @return { Promise } promise with list of object with parameters values by id parameter
     */
    GetAllParameterById = (idParameter) => {
        let myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${this.token}`);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(idParameter)
        };

        return new Promise(function (resolve, reject) {
            const result = { statusResponse: false, data: null, error: null }
            fetch(`${REACT_APP_URL_API_TSV}ParameterValue/GetAllByParameterId`, requestOptions)
                .then(response => {
                    if (response.ok) { result.statusResponse = true; }
                    response.json().then(resultapi => {
                        result.data = resultapi.list != null ? resultapi.list : [];
                        resolve(result);
                    })
                }).catch(error => {
                    result.error = error; reject(result);
                })
        });
    }
}