import './Welcome.css';
import React from 'react';
import { CategoryList } from '../../components/CategoryCard/CategoryList';
import { LocalStorageTokenServices } from '../../Services/LocalStorageTokenServices';
import { TokenServices } from '../../Services/TokenServices';
import { CategoryServices } from '../../Services/CategoryServices';

export class Welcome extends React.Component {
  constructor(props) {
    super(props);
    this.categoryServices = new CategoryServices();
    this.localStorageTokenServices = new LocalStorageTokenServices();
    this.tokenService = new TokenServices();
    this.state = {
      data: []
    }
  };

  fetchData = async () => {
    const token = this.tokenService.DecryptToken();
    const response = await this.categoryServices.GetCategories(token);
    if (response.statusResponse)
      this.setState({
        data: response.data
      })
  }

  async getDataCategories() {
    await this.localStorageTokenServices.RefreshDateExpirationToken();
    await this.fetchData();
  }
  
  componentDidMount() {
    this.getDataCategories()
  }

  render() {
    return (
      <div className='page'>
        <CategoryList categories={this.state.data}
          buttonText='Me Interesa '
          categoryFunction={this.props.categoryFunction} />
      </div>
    );
  }
}