import './YesNoQuestionCard.css';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { YesNoQuestionCardServices } from '../../Services/YesNoQuestionCardServices';
import { ModalInfo } from '../ModalInfo';

export class YesNoQuestionCard extends React.Component {

  constructor(props) {
    super(props);
    this.yesNoQuestionServices = new YesNoQuestionCardServices();
    this.state = {
      valueChecked: null,
      optionNo: {},
      optionYes: {},
      errorMessage: null,
      valid: false,
      validInput: true,
      value: '-1',
      stepBack: 0,
      modalOptions: { show: false, title: '', description: '' },
    };
  }

  /**
   * Verifies that the nextProp property has changed, when its value is true it takes the identifier of the current component and calls nextStep to verify that the question was answered correctly.
   */
  componentDidUpdate(prevProps) {
    if (prevProps.nextProp !== this.props.nextProp) {
      if (this.props.nextProp && this.props.indexNext === this.props.index) {
        this.nextStep();
      } else if (this.props.nextProp && this.props.fieldConfig.idMainQuestion !== '') {
        if (this.props.indexNext.includes(this.props.index)) {
          this.nextStep();
        }
      }
    }
  }

  /**
   * Checks if the answer of the question is stored in the local storage if so the component is rendered with that answer 
   */
  componentDidMount() {
    const { fieldConfig, idCurrentCategory } = this.props;
    fieldConfig.idMainQuestion === '' ? this.setValueAnswerd(fieldConfig, idCurrentCategory) :
      this.setState({
        valid: fieldConfig.valid, value: fieldConfig.value, valueChecked: fieldConfig.value === 'Si' ? true : fieldConfig.value === 'No' ? false : null
      });

  }

  /**
   * Take the answer of the question saved in the localstorage according to the component ID
   * @param {*} fieldConfig object with component attributes
   * @param {*} idCurrentCategory idCurrentCategory id of the current category
   */

  setValueAnswerd(fieldConfig, idCurrentCategory) {
    const response =
      this.yesNoQuestionServices.GetPreviousAnswerFromLocalStorage(idCurrentCategory, fieldConfig);
    if (response) {
      this.setState({
        errorMessage: response.errorMessage, valid: response.valid, validInput: response.valid,
        value: response.value, valueChecked: response.valueChecked
      });
      this.props.funtionHandleSetProps(true, response.step, false, response.field);
    }
  }
  /**
   * Validates if the input is correct to go to the next question and save it in the local storage.
   */
  nextStep = () => {
    const { valid } = this.state;
    const { fieldConfig } = this.props;
    let response = null;
    if (fieldConfig.value === '' && !fieldConfig.required) {
      response = this.yesNoQuestionServices.NextStep(true, fieldConfig);
    } else {
      response = this.yesNoQuestionServices.NextStep(valid, fieldConfig);
    }
    if (!response.isValid) {
      this.setState({ errorMessage: '*Debe seleccionar una opción', validInput: response.isValid });
      this.props.funtionHandleSetProps(response.isValid, 0, true, fieldConfig);
    } else {
      this.props.funtionHandleSetProps(response.isValid, response.step, response.next, response.field);
    }
  }

  /**
   * Handles a change on a input a valid if this change is correct.
   */
  handleInputChange = (event, field) => {
    const response = this.yesNoQuestionServices.HandleInputChange(event, field);
    this.setState({
      errorMessage: response.errorMessage, valid: response.isValid, validInput: response.isValid,
      value: response.value, valueChecked: response.valueChecked
    });
    this.props.funtionHandleSetProps(response.isValid, false, 0);
  }
  /**
 * Renders a modal with de information of each answer option.
 */
  showModal(title, description) {
    let modalOptions = { show: true, title: title, description: description };
    this.setState({ modalOptions: modalOptions });
  }

  /**
   * Close the modal with de information of each answer option.
   */
  closeModal = () => {
    let modalOptions = { show: false };
    this.setState({
      modalOptions: modalOptions
    });
  };

  render() {
    const { optionNo, optionYes, valueChecked, valid, validInput, errorMessage, modalOptions } = this.state;
    const { fieldConfig, options } = this.props;
    let isInputMatix = fieldConfig.idMainQuestion !== '';
    let descriptionYes = fieldConfig.options[0].description ? fieldConfig.options[0].description : false;
    let descriptionNo = fieldConfig.options[1].description ? fieldConfig.options[1].description : false;
    return (
      isInputMatix ?
        <>
          <div className={validInput ? ('border-div-yn-matrix') :
            ('yn-is-invalid-border')}>

            <div className="yesno-matrix">
              <input type="radio" value='Si'
                checked={true === valueChecked}
                onChange={(event) => this.handleInputChange(event, this.props.fieldConfig)}
                name={optionYes.id}
                id={optionYes.id} />
              <label>Sí</label>
            </div>
                <a style={{ display: !descriptionYes ? 'none' : 'block' }}><i className={isInputMatix ? 'modal-matrix' : 'modal-icon-yn'} tabIndex="0" aria-label="Más información" role="button"
                  onClick={() => this.showModal('SI', descriptionYes)}>
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12.5" cy="12.5" r="12.5" fill="#FDC740" onClick={() => this.showModal('', descriptionYes)} />
                    <path d="M11.4863 18.2508C11.4863 17.9421 11.5995 17.6737 11.8259 17.4455C12.0523 17.2174 12.3187 17.1033 12.625 17.1033C12.9313 17.1033 13.1976 17.2174 13.424 17.4455C13.6504 17.6737 13.7636 17.9421 13.7636 18.2508C13.7636 18.5595 13.6504 18.8212 13.424 19.036C13.1976 19.2373 12.9313 19.3379 12.625 19.3379C12.3187 19.3379 12.0523 19.2373 11.8259 19.036C11.5995 18.8212 11.4863 18.5595 11.4863 18.2508ZM12.4252 15.7142C12.3719 15.0834 12.312 14.4191 12.2454 13.7212C12.1922 13.0098 12.1322 12.3119 12.0656 11.6275C12.0124 10.9296 11.9591 10.2652 11.9058 9.63441C11.8659 9.00361 11.8259 8.43992 11.786 7.94334C11.746 7.43333 11.7127 7.01727 11.6861 6.69516C11.6595 6.35963 11.6461 6.15832 11.6461 6.09121C11.6461 5.72884 11.746 5.47383 11.9458 5.3262C12.1455 5.17857 12.3719 5.10475 12.625 5.10475C12.878 5.10475 13.1044 5.17857 13.3042 5.3262C13.5039 5.47383 13.6038 5.72884 13.6038 6.09121C13.6038 6.19858 13.5772 6.58108 13.5239 7.23872C13.4707 7.89636 13.4041 8.69492 13.3242 9.63441C13.2576 10.5739 13.1777 11.5872 13.0844 12.6743C12.9912 13.748 12.9047 14.7613 12.8247 15.7142H12.4252Z" fill="#052675" />
                  </svg>
                </i></a>
            <div className="yesno-matrix">
              <input type="radio" value='No'
                checked={false === valueChecked}
                onChange={(event) => this.handleInputChange(event, this.props.fieldConfig)}
                name={optionNo.id}
                id={optionNo.id}
              />
              <label>No</label>
            </div>
         <a style={{ display: !descriptionNo ? 'none' : 'block' }}><i className={isInputMatix ? 'modal-matrix' : 'modal-icon-yn'} tabIndex="0" aria-label="Más información" role="button"
              onClick={() => this.showModal('NO', descriptionNo)}
              style={{ display: descriptionNo === '' || descriptionNo === null || descriptionNo === undefined ? 'none' : 'block' }}>
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12.5" cy="12.5" r="12.5" fill="#FDC740" onClick={() => this.showModal('', descriptionNo)} />
                <path d="M11.4863 18.2508C11.4863 17.9421 11.5995 17.6737 11.8259 17.4455C12.0523 17.2174 12.3187 17.1033 12.625 17.1033C12.9313 17.1033 13.1976 17.2174 13.424 17.4455C13.6504 17.6737 13.7636 17.9421 13.7636 18.2508C13.7636 18.5595 13.6504 18.8212 13.424 19.036C13.1976 19.2373 12.9313 19.3379 12.625 19.3379C12.3187 19.3379 12.0523 19.2373 11.8259 19.036C11.5995 18.8212 11.4863 18.5595 11.4863 18.2508ZM12.4252 15.7142C12.3719 15.0834 12.312 14.4191 12.2454 13.7212C12.1922 13.0098 12.1322 12.3119 12.0656 11.6275C12.0124 10.9296 11.9591 10.2652 11.9058 9.63441C11.8659 9.00361 11.8259 8.43992 11.786 7.94334C11.746 7.43333 11.7127 7.01727 11.6861 6.69516C11.6595 6.35963 11.6461 6.15832 11.6461 6.09121C11.6461 5.72884 11.746 5.47383 11.9458 5.3262C12.1455 5.17857 12.3719 5.10475 12.625 5.10475C12.878 5.10475 13.1044 5.17857 13.3042 5.3262C13.5039 5.47383 13.6038 5.72884 13.6038 6.09121C13.6038 6.19858 13.5772 6.58108 13.5239 7.23872C13.4707 7.89636 13.4041 8.69492 13.3242 9.63441C13.2576 10.5739 13.1777 11.5872 13.0844 12.6743C12.9912 13.748 12.9047 14.7613 12.8247 15.7142H12.4252Z" fill="#052675" />
              </svg>
            </i></a>
            {modalOptions.show ?
              <ModalInfo modalOptions={modalOptions} closeModal={this.closeModal} /> :
              null}
          </div>
          <div className="text-left">
            {!validInput && <span className='error'>{errorMessage}</span>}
          </div>

        </>
        :
        <>
          <div className={validInput ? ('border-div-yn') :
            ('is-invalid-border')}>
            <Card.Body>
              <Row>
                <Col>
                  <div className="yes-not text-left p-3 bg-white rounded checkbox-container mb-4">
                    <input className="ml-3" type="radio" value='Si'
                      checked={true === valueChecked}
                      onChange={(event) => this.handleInputChange(event, this.props.fieldConfig)}
                      name={optionYes.id}
                      id={optionYes.id} />
                    <label className="ml-3 radio-label" >Sí</label>
                  </div>
                  <a style={{ display: !descriptionYes ? 'none' : 'block' }}><i className={isInputMatix ? 'modal-matrix' : 'modal-icon-yn'} tabIndex="0" aria-label="Más información" role="button"
                    onClick={() => this.showModal('SI', descriptionYes)}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12.5" cy="12.5" r="12.5" fill="#FDC740" onClick={() => this.showModal('', descriptionYes)} />
                      <path d="M11.4863 18.2508C11.4863 17.9421 11.5995 17.6737 11.8259 17.4455C12.0523 17.2174 12.3187 17.1033 12.625 17.1033C12.9313 17.1033 13.1976 17.2174 13.424 17.4455C13.6504 17.6737 13.7636 17.9421 13.7636 18.2508C13.7636 18.5595 13.6504 18.8212 13.424 19.036C13.1976 19.2373 12.9313 19.3379 12.625 19.3379C12.3187 19.3379 12.0523 19.2373 11.8259 19.036C11.5995 18.8212 11.4863 18.5595 11.4863 18.2508ZM12.4252 15.7142C12.3719 15.0834 12.312 14.4191 12.2454 13.7212C12.1922 13.0098 12.1322 12.3119 12.0656 11.6275C12.0124 10.9296 11.9591 10.2652 11.9058 9.63441C11.8659 9.00361 11.8259 8.43992 11.786 7.94334C11.746 7.43333 11.7127 7.01727 11.6861 6.69516C11.6595 6.35963 11.6461 6.15832 11.6461 6.09121C11.6461 5.72884 11.746 5.47383 11.9458 5.3262C12.1455 5.17857 12.3719 5.10475 12.625 5.10475C12.878 5.10475 13.1044 5.17857 13.3042 5.3262C13.5039 5.47383 13.6038 5.72884 13.6038 6.09121C13.6038 6.19858 13.5772 6.58108 13.5239 7.23872C13.4707 7.89636 13.4041 8.69492 13.3242 9.63441C13.2576 10.5739 13.1777 11.5872 13.0844 12.6743C12.9912 13.748 12.9047 14.7613 12.8247 15.7142H12.4252Z" fill="#052675" />
                    </svg>
                  </i></a>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="yes-not text-left p-3 bg-white rounded checkbox-container">
                    <input className="ml-3" type="radio" value='No'
                      checked={false === valueChecked}
                      onChange={(event) => this.handleInputChange(event, this.props.fieldConfig)}
                      name={optionNo.id}
                      id={optionNo.id}
                    />
                    <label className="ml-3 radio-label" >No</label>
                  </div>
                  <a style={{ display: !descriptionNo ? 'none' : 'block' }}><i className={isInputMatix ? 'modal-matrix' : 'modal-icon-yn'} tabIndex="0" aria-label="Más información" role="button"
                    onClick={() => this.showModal('NO', descriptionNo)}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12.5" cy="12.5" r="12.5" fill="#FDC740" onClick={() => this.showModal('', descriptionNo)} />
                      <path d="M11.4863 18.2508C11.4863 17.9421 11.5995 17.6737 11.8259 17.4455C12.0523 17.2174 12.3187 17.1033 12.625 17.1033C12.9313 17.1033 13.1976 17.2174 13.424 17.4455C13.6504 17.6737 13.7636 17.9421 13.7636 18.2508C13.7636 18.5595 13.6504 18.8212 13.424 19.036C13.1976 19.2373 12.9313 19.3379 12.625 19.3379C12.3187 19.3379 12.0523 19.2373 11.8259 19.036C11.5995 18.8212 11.4863 18.5595 11.4863 18.2508ZM12.4252 15.7142C12.3719 15.0834 12.312 14.4191 12.2454 13.7212C12.1922 13.0098 12.1322 12.3119 12.0656 11.6275C12.0124 10.9296 11.9591 10.2652 11.9058 9.63441C11.8659 9.00361 11.8259 8.43992 11.786 7.94334C11.746 7.43333 11.7127 7.01727 11.6861 6.69516C11.6595 6.35963 11.6461 6.15832 11.6461 6.09121C11.6461 5.72884 11.746 5.47383 11.9458 5.3262C12.1455 5.17857 12.3719 5.10475 12.625 5.10475C12.878 5.10475 13.1044 5.17857 13.3042 5.3262C13.5039 5.47383 13.6038 5.72884 13.6038 6.09121C13.6038 6.19858 13.5772 6.58108 13.5239 7.23872C13.4707 7.89636 13.4041 8.69492 13.3242 9.63441C13.2576 10.5739 13.1777 11.5872 13.0844 12.6743C12.9912 13.748 12.9047 14.7613 12.8247 15.7142H12.4252Z" fill="#052675" />
                    </svg>
                  </i></a>
                </Col>
              </Row>
            </Card.Body>
            {modalOptions.show ?
              <ModalInfo modalOptions={modalOptions} closeModal={this.closeModal} /> :
              null}

          </div>
          <div className="text-left">
            {!validInput && <span className='error'>{errorMessage}</span>}
          </div>

        </>
    );
  }
}