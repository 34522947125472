import React from 'react';
import CategoryCard from './CategoryCard';
const life = process.env.REACT_APP_ID_LIFE;
const saving = process.env.REACT_APP_SAVING_CATEGORY;
const health = process.env.REACT_APP_HEALTH_CATEGORY;
export class CategoryList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {

  }
  hiddenHelpSticky(){
    let helpSticky = document.getElementById("div-help-sticky");
    let helpChat = document.getElementById("div-help-chat");

    if(helpSticky !== null){
      helpSticky.style.display = "none";
      helpChat.style.display = "block";
    }
  }
  render() {  
     this.hiddenHelpSticky();
    return (

              <>
                {this.props.categories.map((category) => {
                  return (
                    <CategoryCard
                      key={category.idCategory}
                      title={category.name}
                      description={category.description}
                      img={category.image}
                      buttonText={this.props.buttonText}
                      categoryFunction={this.props.categoryFunction}
                      idCategory={category.idCategory}
                      path={category.idCategory === Number(saving)? '/compra/ahorro': category.idCategory === Number(life)? '/compra/vida': '/compra/salud'}
                    />
                  )
                  return null;
                }
                )
                }
              </>
    )
  }
}