import { JSONExchangeTSV } from './JSONExchangeTSV';
import { JSONProcessFileServices } from './JSONProcessFileServices';
import { JSONRider } from './JSONRider';
import { WebServicesTSV } from './WebServicesTSV';

const { REACT_APP_IDTSVCATEGORY_LIFE, REACT_APP_IDTSVCATEGORY_HEALTH, REACT_APP_PAYMENT_MODE,
    REACT_APP_COUPLE, REACT_APP_CHILD, REACT_APP_COUP, REACT_APP_DAD } = process.env;

export class SelectedRiderListService {

    constructor() {
        this.jsonExchangeTSV = new JSONExchangeTSV();
        this.jsonRider = new JSONRider();
        this.webServicesTSV = new WebServicesTSV();
        this.JSONProcessFileServices = new JSONProcessFileServices();

    }

    /**
      * Save payment method information to local storage
      * @param {Object}  payment payment method to be saved in the localstorage
      * @param {Number} type type of payment method or payment frequency
      */
    async SavePaymentLocalstorage(payment, type) {
        let localPayment = this.jsonExchangeTSV.getPaymentOfProductPayment();
        let paymentProduct = {
            "policy_payment_mode": payment.paymentMod,
            "payment_type": payment.paymentType,
            "payment_method_key": payment.methodKey,
            "policy_payment_mode_intervals": payment.modeIntervals
        };

        if (localPayment) {
            if (type === REACT_APP_PAYMENT_MODE) {
                paymentProduct.payment_type = localPayment.payment_type;
                paymentProduct.payment_method_key = localPayment.payment_method_key;

            } else {
                paymentProduct.policy_payment_mode = localPayment.policy_payment_mode;
                paymentProduct.policy_payment_mode_intervals = localPayment.policy_payment_mode_intervals
            }
        }
        this.jsonExchangeTSV.setPaymentOfProductPayment(paymentProduct);
    }


    /**
     * Get the payment method saved in localstorage, if not found, save it
     * @param {Object} payment payment method to be saved to local storage
     * @return {Object}   payment method found in local storage
     */
    GetPaymentOfProductPayment(payment) {
        let localPayment = this.jsonExchangeTSV.getPaymentOfProductPayment();
        if (localPayment.policy_payment_mode == undefined) {
            localPayment = payment;
        }
        return localPayment;
    }

    /**
     * Get the payment method saved in localstorage, if not found, save it
     * @return {Object}   payment method found in local storage
     */
    GetPayment() {
        let paymentLocalStorage = this.jsonExchangeTSV.getPaymentOfProductPayment();
        let payment = {};
        if (paymentLocalStorage.payment_method_key != undefined) {
            payment.methodKey = paymentLocalStorage.payment_method_key;
            payment.modeIntervals = paymentLocalStorage.policy_payment_mode_intervals;
        }
        return payment;
    }
    /**
  * Modify a rider of the array of riders in the local storage.
  * @param  {Object} rider The rider object.
  */
    modifyRiderBasicPlan(rider) {
        this.jsonRider.modifyRiderBasicPlan(rider);
    }

    /**
     * Add or remove the selected rider from the list depending on the action
     * @param {String} riderCategory name of the selected rider
     * @return {Array} New list of selected riders
     */
    GetRiderCategories(riderCategory) {
        let selectCategory = this.jsonRider.getCurrentRiders();
        if (selectCategory.length === 0) {
            this.jsonRider.addRiderToRiders(riderCategory);
        } else {
            const sameValue = selectCategory.filter(rider => rider.categoryName === riderCategory.categoryName);
            if (sameValue[0]) {
                if (!riderCategory.isActive) {
                    sameValue[0].isActive = false;
                    this.jsonRider.modifyRiderOfRiders(sameValue[0]);
                } else {
                    sameValue[0].isActive = true;
                    sameValue[0].selectedInsuredCategoryPlanCodePrefix = riderCategory.selectedInsuredCategoryPlanCodePrefix === sameValue[0].selectedInsuredCategoryPlanCodePrefix ?
                        sameValue[0].selectedInsuredCategoryPlanCodePrefix : riderCategory.selectedInsuredCategoryPlanCodePrefix;

                    sameValue[0].faceAmountText = sameValue[0].faceAmountText === riderCategory.faceAmountText ? sameValue[0].faceAmountText : riderCategory.faceAmountText;
                    sameValue[0].selectedSpecDescription = riderCategory.selectedSpecDescription === sameValue[0].selectedSpecDescription ?
                        sameValue[0].selectedSpecDescription : riderCategory.selectedSpecDescription;

                    sameValue[0].changeField = riderCategory.changeField === sameValue[0].changeField ? sameValue[0].changeField : riderCategory.changeField;
                    this.jsonRider.modifyRiderOfRiders(sameValue[0]);
                }
            } else {
                this.jsonRider.addRiderToRiders(riderCategory);
            }
        }
        return this.jsonRider.getCurrentRiders();
    }

    /**
      * Save information of riders and endorsements to local storage
      * @param {Object} riders rider data to save
      * @param {Object} endorsements endorsements data to save
      */
    SaveDetailsAndApplicationData(details, applicationData) {
        this.jsonExchangeTSV.setApplicationDataOfProductPayment(applicationData);
        this.jsonExchangeTSV.setDetailsOfProductPayment(details);
    }

    /**
      * Get the riders found in the localstorage
      * @return {Array} List of riders saved in the localstorage
      */
    GetRiderSelectLocalStorage() {
        return this.jsonRider.getCurrentRiders();
    }
      /**
      * SET the riders found in the localstorage
      * @return {Array} SET List of riders saved in the localstorage
      */
      async SetRiders(riders) {
        return await this.jsonRider.setRiders(riders);
    }

    /**
      * Validates if the product chosen by the user is the same of the local storage.
      * @param {Number}  idTSVProduct A numerical id of the product
      * @return {Boolean} True if it is the current product.
      */
    IsCurrentProduct = (idTSVProduct) => {
        let currentProduct = this.jsonRider.getCurrentSelectedProduct();
        if (currentProduct.id_tsv_product === idTSVProduct) {
            return true;
        }
        return false;
    }

    /**
      * Initialize the rider JSON that is stored in the localstorage
      */
    InitializeJSONRider(idTSVProduct) {
        let product = { 'id_tsv_product': `${idTSVProduct}`, isNew: false };
        if (this.jsonRider.isJSONinitialized()) {
            if (!this.IsCurrentProduct(idTSVProduct)) {
                this.jsonRider.initializeJSON();
                product.isNew = true;
                this.jsonRider.setSelectedProduct(product);
            } else {
                product.isNew = false;
                this.jsonRider.setSelectedProduct(product);
            }
        } else {
            this.jsonRider.initializeJSON();
            product.isNew = true;
            this.jsonRider.setSelectedProduct(product);
        }
    }

    /**
      * Check if the category exists in the list of selected rider
      * @param {String} categoryName  Name of the category to search in the list of riders
      * @param {Array} riderCategories Selected riders list
      * @return {Object} rider information 
      */
    GetCategory(categoryName, riderCategories) {
        const sameValue = riderCategories.filter(rider => rider.categoryName === categoryName);
        if (sameValue[0]) {
            return sameValue[0];
        }
        return null;
    }

    /**
      * Create the structure of the riders to send to the api
      * @param {Object} request Selected rider data
      * @return {Object} Structure of the riders to send to the TSV api
      */
    SaveRiderCategories(request) {
        let { categoryName, type, value, selectedInsuredCategory, isSelected, changeField } = request;
        let riderCategories = this.jsonRider.getCurrentRiders();

        let riderCategory =
        {
            "categoryName": categoryName,
            "isActive": true,
            "selectedInsuredCategoryPlanCodePrefix": selectedInsuredCategory,
            "faceAmountText": null,
            "selectedSpecDescription": null,
            "performSolveTypeName": null,
            "changeField": changeField
        };
        let isMultilple = isSelected != undefined ? isSelected : '';

        if (type != null) {

            if (type === 'insuredCategories' || type === 'multiple') {
                riderCategory.selectedInsuredCategoryPlanCodePrefix = type === 'insuredCategories' ? value :
                    type === 'multiple' ? selectedInsuredCategory : null;
            }

            if (isMultilple !== 'select') {
                riderCategory.faceAmountText = (type === 'faceAmountOptions' || type === 'slider' ||
                    type === 'multiple' || isMultilple === 'sliderSelected') ? value : null;
            }

            if (isMultilple !== 'sliderSelected') {
                riderCategory.selectedSpecDescription = type === 'availableSpecs' || isMultilple === 'select' ? value : null;
            }
            riderCategory.performSolveTypeName = null;

            if (this.GetCategory(categoryName, riderCategories)) {
                let rider = this.GetCategory(categoryName, riderCategories);
                riderCategory.selectedInsuredCategoryPlanCodePrefix = rider.selectedInsuredCategoryPlanCodePrefix === riderCategory.selectedInsuredCategoryPlanCodePrefix ?
                    rider.selectedInsuredCategoryPlanCodePrefix : riderCategory.selectedInsuredCategoryPlanCodePrefix;

                if (isMultilple !== 'select') {
                    riderCategory.faceAmountText = riderCategory.faceAmountText === rider.faceAmountText ?
                        rider.faceAmountText : riderCategory.faceAmountText;
                } else {
                    riderCategory.faceAmountText = rider.faceAmountText;
                }
                if (isMultilple !== 'sliderSelected') {
                    riderCategory.selectedSpecDescription = rider.selectedSpecDescription === riderCategory.selectedSpecDescription ?
                        rider.selectedSpecDescription : riderCategory.selectedSpecDescription;
                } else {
                    riderCategory.selectedSpecDescription = rider.selectedSpecDescription;
                }
            }
        }
        riderCategory.isActive = true;
        this.GetRiderCategories(riderCategory);
        return riderCategory;
    }
    getEdad(dateString) {
        let hoy = new Date()
        let fechaNacimiento = new Date(dateString)
        let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
        let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
        if (
            diferenciaMeses < 0 ||
            (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
        ) {
            edad--
        }
        return edad
    }
    /**
     * This method create an array using the information filled in the main matrix 
     * @returns an array with the information of each person that will be insuranced
     */
    GetFamilyMember(idSerieTSV, idTSVProduct) {
        let insured = this.jsonExchangeTSV.getCurrentSelectedProduct();
        let members = insured.insured_persons_approved != undefined ? insured.insured_persons_approved : [];
        let structure = {};
        structure.additionalInsured = [];
        let insuredRisk = this.jsonExchangeTSV.getCurrentRisk().insured_risk;

        members.map((member, index) => {
            let gender = member.gender !== null ? member.gender.charAt(0) : null;
            let risk = "N";

            if (insuredRisk != undefined) {
                if (insuredRisk.length > 0) {
                    risk = insuredRisk[index] != undefined ? insuredRisk[index].id_tsv_risk : "N";
                }
            }

            let basicInsured = {
                IsEnabled: true, IsSelected: true, GenderShortForm: gender,
                RiskKey: risk,
                DateOfBirth: member.dateOfBirth,
                age: this.getEdad(member.dateOfBirth)
            };

            if (member.primaryInsured) {
                if (idSerieTSV === REACT_APP_IDTSVCATEGORY_LIFE) {
                    basicInsured.rating = {
                        ratedTable: member.ratedTable != null ? member.ratedTable : 0,
                        ratedYears: 0,
                        flatExtraYears: 0,
                        flatExtra: 0
                    };
                }
                structure.Primary = basicInsured;
            } else {
                if (idSerieTSV === REACT_APP_IDTSVCATEGORY_LIFE) {
                    basicInsured.rating = {
                        ratedTable: member.ratedTable != null ? member.ratedTable : 0,
                        ratedYears: 0,
                        flatExtraYears: 0,
                        flatExtra: 0
                    };
                    structure.additionalInsured.push(basicInsured);
                }
                if (idSerieTSV === REACT_APP_IDTSVCATEGORY_HEALTH) {
                    let relation = member.relationship;

                    if (idTSVProduct != null) {

                        if (idTSVProduct.includes(REACT_APP_COUP)) {
                            structure.spouse = basicInsured;
                        } else {
                            if (idTSVProduct.includes(REACT_APP_DAD)) {
                                structure.child = basicInsured;
                            } else {
                                if (relation.includes(REACT_APP_CHILD)) {
                                    structure.child = basicInsured;
                                } else {
                                    if (relation.includes(REACT_APP_COUPLE)) {
                                        structure.spouse = basicInsured;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        });
        return structure;
    }


    /**
      * Check if the selected product is the same as the one in the localstorage
      * @return {Boolean} True if it is the product.
      */
    IsNewProduct() {
        let response = this.jsonRider.getCurrentSelectedProduct();
        return response.isNew != undefined ? response.isNew : true;
    }

    /**
      * Sends a new application of insurance to TSV server.
      * @return {Promise} The response of TSV server.
      */
    SendApplicationSale = async () => {
        let JSONLocalStorage = this.jsonExchangeTSV.getJSONTSV();
        let request = { data: JSONLocalStorage };
        let responseService =
            await this.webServicesTSV.ApplicationSales(request);
        if (responseService.statusResponse) {
            return responseService.data;
        } else {
            return false;
        }
    }
    /**
     * Creates a new JSV process file in the API.
     * @return {Promise} The response of the API.
     */
    CreateJSONProcessFile = async () => {
        let JSONLocalStorage = this.jsonExchangeTSV.getJSONTSV();
        let request = { file: JSONLocalStorage };
        let responseService =
            await this.JSONProcessFileServices.AddJSONProcessFile(request);
        if (responseService.statusResponse) {
            return responseService.data;
        } else {
            return false;
        }
    }

    /**
      * Add or remove the selected rider from the list depending on the action
      * @param {String} categoryName category name of the selected rider
      */
    UpdateRiderFaceAmount(categoryName, faceAmount) {
        let selectCategory = this.jsonRider.getCurrentRiders();
        if (selectCategory.length > 0) {
            const sameValue = selectCategory.filter(rider => rider.categoryName === categoryName);
            if (sameValue[0]) {
                sameValue[0].faceAmountText = faceAmount;
                this.jsonRider.modifyRiderOfRiders(sameValue[0]);
            }
        }
    }

    /**
      * Update product information on local storage.
      * @param {Object} product product information
      */
    UpdateSelectedProduct(product) {
        let productJson = this.jsonExchangeTSV.getCurrentSelectedProduct();

        let selectedProduct = {
            id_product: `${product.idProduct}`, name: product.name,
            id_tsv_product: product.idTSVProduct ? product.idTSVProduct : null,
            insured_persons_approved: productJson.insured_persons_approved,
            insured_persons_denied: productJson.insured_persons_denied
        };
        this.jsonExchangeTSV.setSelectedProduct(selectedProduct);
        let productRider = { 'id_tsv_product': `${product.idTSVProduct}`, isNew: true };
        this.jsonRider.setSelectedProduct(productRider);
    }

    /**
      * This method obtains the information of the main insured. 
      * @returns an object with the information of primary insured
     */
    GetPrimaryInsured() {
        let insured = this.jsonExchangeTSV.getCurrentSelectedProduct();
        let members = insured.insured_persons_approved != undefined ? insured.insured_persons_approved : [];
        let primaryInsured = {};
        members.map((member) => {
            if (member.primaryInsured) {
                primaryInsured = member;
            }
        });
        return primaryInsured;
    }

}