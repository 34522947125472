import React, { Component } from 'react';
import { Procedure } from '../../Services/Procedure';
import './StepperForm.css';
import { HeaderStepper } from '../../components/HeaderStepper';
import Stepper from '../../../bootstrap/bs-stepper';
import { LoadingPage } from '../../pages/LoadingPage';
import { JSONExchangeTSV } from '../../Services/JSONExchangeTSV';
import { QuestionServices } from '../../Services/QuestionServices';
import { CardType } from '../CardType';
import { SelectedRiderList } from '../../components/SelectedRiderList';
import { SelectedRiderListS4 } from '../../components/SelectedRiderS4';

import { SelectedRiderSaving } from "../../components/SelectedRiderSaving";
import { ButtonTSV } from '../ButtonTSV';
import { WebServicesTSV } from '../../Services/WebServicesTSV'
import { Link } from 'react-router-dom';

const matrix = process.env.REACT_APP_MATRIX;
const mainMatrix = process.env.REACT_APP_MAIN_MATRIX;
const { REACT_APP_IDTSVCATEGORY_SAVING, REACT_APP_ID_LIFE, REACT_APP_ID_TEST,
    REACT_APP_SELECT_PRODUCT, REACT_APP_IDTSVCATEGORY_HEALTH,REACT_APP_IDTSVCATEGORY_LIFE, REACT_APP_IDTSVCATEGORY_UL } = process.env;

export class StepperForm extends Component {

    constructor(props) {
        super(props);
        this.questionServices = new QuestionServices();
        this.procedure = new Procedure();
        this.jsonExchangeTSV = new JSONExchangeTSV();
        this.webServicesTSV = new WebServicesTSV();
        this.state = {
            stepper: null,
            step: 1,
            totalQuestions: 0,
            fields: [{}],
            fieldsRisk: [{}],
            fieldsProduct: [{}],
            stepField: 1,
            stepBack: 1,
            formValid: false,
            error: false,
            errorInFields: false,
            questionBodyWithError: '',
            errorMessage: '',
            loading: true,
            nextProp: false,
            selectProduct: false,
            refreshStepper: false,
            indexNext: 0,
            productQuestionNumber: 0,
            mainMatrix: [{}],
            isRider: false,
            currentProductName: '',
            selectProductTSV: { idTSVProduct: 0, idTSVCategory: 0, productTermList: [] }
        }
        this.handleSetProps = this.handleSetProps.bind(this);
        this.handleErrorInComponent = this.handleErrorInComponent.bind(this);
        this.handleProductNameInfo = this.handleProductNameInfo.bind(this);
        this.goRiders = this.goRiders.bind(this);

    }
    /**
    * get the questions for the stepper
    */
    componentDidMount() {
        this.getFetchServices();
    }

    /**
     * Changes the error state in case a TSV service is down
     */
    fetchServices = async () => {
        let responseOverall = await this.webServicesTSV.OverallLoadStatus();
        let responsePolicy = await this.webServicesTSV.PolicyLoadStatus();

        if (responseOverall.statusResponse && responsePolicy.statusResponse) {
            if (!responseOverall.data.data || !responsePolicy.data.data) {
                this.setState({ error: true, loading: false });
            } else {
                this.setState({ loading: false });
                this.getFields();
            }
        }
    }

    /**
      * Changes the error state in case a TSV service is down
      */
    async getFetchServices() {
        this.setState({ loading: true });
        await this.fetchServices();
    }

    /**
     * if refreshStepper is true you must restart the stepper with the set of risk questions
     * @param {*} prevProps previous value of properties
     * @param {*} prevState previous value of state
     */
    componentDidUpdate(prevProps, prevState) {
        if (prevState.refreshStepper !== this.state.refreshStepper) {
            let stepper = new Stepper(document.querySelector('#stepper1'));
            let selectProduct = this.state.step === this.state.productQuestionNumber;
            this.setState({ stepper: stepper, selectProduct: selectProduct }, function next() {
                let step = this.state.step === 0 ? 1 : this.state.step;
                this.setNext(step);
            });
        }
    }
    /**
     * Get the questions according to the category id and create the stepper
     */
    getFields() {
        this.questionServices.GetDataFields({ "idCategory": this.props.idCategory }).then(result => {
            if (result.statusResponse) {
                let errorInFields = false;
                let fields = result.fields;
                let questionBodyWithError = '';
                fields.map((field, index) => {
                    if (field.options) {
                        if (field.options.length === 0) {
                            errorInFields = true;
                            questionBodyWithError = field.body;
                        }
                    }
                    field.ref = React.createRef();
                    field.value = '';
                    field.index = index;
                    field.idCategory = this.props.idCategory;
                    field.totalFields = fields.length;
                });
                this.setState({ questionBodyWithError: questionBodyWithError, errorInFields: errorInFields, fields: fields, totalQuestions: fields.length, fieldsProduct: fields, loading: false, error: false });
                if (!errorInFields) {
                    let stepper = new Stepper(document.querySelector('#stepper1'));
                    this.setState({ stepper: stepper });
                }
            } else {
                this.setState({ loading: false, error: true });
            }
        });

    }
    /**
    * Get the risk questions according to the product id 
    */
    handleGetFieldsRisk = async (idProduct, idTSVProduct, idTSVCategory, productTermList) => {
        if (Number(this.props.idCategory) === Number(REACT_APP_ID_LIFE) || Number(this.props.idCategory) === Number(REACT_APP_ID_TEST)) {
            this.questionServices.GetRiskDataFields(idProduct, this.props.idCategory).then(result => {
                if (result.statusResponse) {
                    let fieldsRiskProduct = result.fields;
                    fieldsRiskProduct.map((field, index) => {
                        field.ref = React.createRef();
                        field.value = ''
                        field.index = index
                        field.back = index
                        field.idCategory = this.props.idCategory
                    });
                    let totalQuestions = fieldsRiskProduct.length;
                    this.setState({
                        fields: fieldsRiskProduct, fieldsRisk: fieldsRiskProduct, step: 0, indexNext: 0, refreshStepper: true,
                        totalQuestions: totalQuestions, selectProductTSV: { idTSVProduct: idTSVProduct, idTSVCategory: idTSVCategory, productTermList: productTermList }, nextProp: !this.state.nextProp
                    });
                } else {
                    this.setState({ loading: false, error: true });
                }

            });
        } else {
            this.setState({ isRider: true, selectProduct: false, selectProductTSV: { idTSVProduct: idTSVProduct, idTSVCategory: idTSVCategory } });
        }

    }
    /**
     * set the error variable according to the result of the WS tsv
     * @param {*} error boolean with value true or false
     */
    handleErrorInComponent(error, errorMessage) {
        if (errorMessage) {
            this.setState({ error: error, errorMessage: errorMessage });
        } else {
            this.setState({ error: error });

        }
    }

    /**
     * If next and formValid are true, determine if the question requires hidden processing, and update the current question with the value of the previous question for the back pass
     * @param {*} formValid Determines if the question is answered, if the question has an answer value formValid is true
     * @param {*} stepField Value of the next question
     * @param {*} next Boolean that determines whether to pass or the next question
     * @param {*} field Current question object
     */
    async handleSetProps(formValid, stepField, next, field) {
        this.setState({
            formValid: formValid, next: next, stepField: stepField
        })
        let back = 0;
        let totalQuestions = this.state.totalQuestions;
        let updatedFields = [...this.state.fields];
        let fieldsProduct = [...this.state.fieldsProduct];
        let { idTSVCategory, idTSVProduct } = this.state.selectProductTSV;
        this.state.formValid = formValid;
        if (next) {
            if (formValid) {
                if (stepField !== 0 && stepField !== undefined) {
                    if (stepField <= totalQuestions) {
                        let updatedField = this.getNextField(stepField);
                        let selectProduct = false;
                        if (updatedField[0].hidden) {
                            let resultApi = await this.procedure.callWS(updatedField[0], field, fieldsProduct);
                            if (resultApi.statusResponse && resultApi.data !== null) {
                                if (updatedField[0].idProduct == '') {
                                    selectProduct = resultApi.data === 0;
                                    stepField = resultApi.data !== 0 ? resultApi.data : this.getStepSelectProduct(field.questionNumber);
                                } else {
                                    stepField = resultApi.data;
                                    if (resultApi.data === 0) {
                                        selectProduct = false;
                                        stepField = totalQuestions;
                                        this.goRiders(idTSVProduct, idTSVCategory);
                                    }
                                }
                                updatedField = this.getNextField(stepField);
                            } else {
                                this.setState({ error: true });
                            }
                        }
                        back = field.questionNumber;
                        if (updatedField[0].back !== undefined) {
                            updatedField[0].back = back;
                            if (field.type === mainMatrix) {
                                let childMatriz = updatedFields.filter(question => question.type === matrix);
                                childMatriz.forEach(element => {
                                    element.ref.current.state.renderBase = false;
                                });
                            }
                            updatedFields.splice(updatedField[0].index, 1, updatedField[0]);
                            this.setState({ fields: updatedFields, selectProduct: selectProduct });
                            this.compareSecuence(back, stepField, field);
                        }
                    }
                } else {
                    if (field.idProduct !== '') {
                        this.goRiders(idTSVProduct, idTSVCategory);
                    } else {
                        stepField = this.getStepSelectProduct(field.questionNumber);
                    }
                }
            }
            this.setNext(stepField);

        }
    }
    /**
     * Get the object of the following question
     * @param {*} stepField next question number
     * @returns object of the following question
     */
    getNextField(stepField) {
        let updatedFields = [...this.state.fields];
        let nextField = updatedFields.filter(nextF => nextF.questionNumber === stepField);
        let updatedField = { ...nextField };
        return updatedField;
    }
    /**
     * Get the step where the product selection is located
     * @param {*} questionNumber number of the previous question to return from product selection
     * @returns the step where the product selection is located
     */
    getStepSelectProduct(questionNumber) {
        let updatedFields = [...this.state.fields];
        let nextField = updatedFields.filter(nextF => nextF.type === 'Seleccion Producto');
        let updatedField = { ...nextField };
        updatedField[0].back = questionNumber;
        let stepField = updatedField[0].questionNumber;
        updatedFields.splice(updatedField[0].index, 1, updatedField[0]);
        this.setState({ fields: updatedFields, selectProduct: true, productQuestionNumber: stepField });
        return stepField;
    }
    /**
     * Determine if the questions are in sequence (1,2,3,4 ...)
     * If not, delete the questions previously saved in the local storage that do not follow the sequence
     * @param {*} back Number of the previous question
     * @param {*} next Next question number
     * @param {*} field The current field
     */
    compareSecuence(back, next, field) {
        let backSecuence = Number(back + 1);
        let nextNumber = Number(next);
        if (backSecuence !== nextNumber) {
            let fields = [...this.state.fields];
            for (let index = backSecuence; index < nextNumber - 1; index++) {
                let field = fields.filter(field => field.questionNumber === index);
                let nextfield = fields.filter(field => field.questionNumber === backSecuence);
                if (field[0] && !nextfield[0].hidden) {
                    let product_questions = this.jsonExchangeTSV.getCurrentProductQuestions();
                    let removeLocalStorageQuestion = product_questions.filter(question => question.id_question === field[0].id);
                    if (removeLocalStorageQuestion.length > 0) {
                        this.jsonExchangeTSV.removeQuestionOfProductQuestions(removeLocalStorageQuestion[0]);
                    }
                }

            }
        }
        // only in no matrix questions
        let fieldsQuestions = [...this.state.fields];
        if (Array.isArray(field.options) && Number(field.idQuestionClass) === Number(REACT_APP_SELECT_PRODUCT)) {
            if (this.checkDifferentSteps(field)) {
                let product_questions = this.jsonExchangeTSV.getCurrentProductQuestions();
                let questionsToRemove = product_questions.filter
                    (question => question.question_number > field.questionNumber);
                let nextQuestionNumber = field.questionNumber + 1;
                let nextfield = fieldsQuestions.filter(field => field.questionNumber === nextQuestionNumber);
                let isNextFieldHidden = nextfield[0] ? nextfield[0].hidden : null;
                if (isNextFieldHidden) {
                    questionsToRemove = questionsToRemove.filter(question => question.question_number !== nextfield[0].questionNumber)
                }
                if (questionsToRemove.length > 0) {
                    for (const questionToRemove of questionsToRemove) {
                        this.jsonExchangeTSV.removeQuestionOfProductQuestions(questionToRemove);
                    }
                }
            }
        }

    }

    /**
     * Checks if a question has different possible steps.
     * @param {Object} field the question.
     * @returns {Boolean} true if the question has different possible steps.
     */
    checkDifferentSteps = (field) => {
        let counter = 0;
        for (const option of field.options) {
            if (counter < field.options.length && option.step != field.options[counter + 1] && option.step !== 0) {
                return true;
            }
            counter++;
        }
        return false;
    }
    /**
     * Returns to the previous question
     * @param {*} stepBack Previous step
     */
    handleClickBack = (stepBack) => {
        if (stepBack === 0) {
            this.backToSelectProduct();
        } else {
            this.setState({ step: stepBack, nextProp: false });
            this.state.stepper.to(stepBack);
        }
    }
    /**
     * reload the stepper with the product selection question 
     */
    backToSelectProduct() {
        let { fieldsProduct, productQuestionNumber } = this.state;
        let totalQuestions = fieldsProduct.length;
        this.setState({
            fields: fieldsProduct, totalQuestions: totalQuestions, step: productQuestionNumber,
            refreshStepper: !this.state.refreshStepper, nextProp: !this.state.nextProp
        });
    }

    /**
     * Go to the next question
     * Only if the fields of the current question have been filled
     * @param {*} stepField Next question number
     */
    setNext = (stepField) => {
        const { formValid } = this.state;
        if (formValid) {
            this.setState({ stepField: stepField, step: stepField });
            this.state.stepper.to(stepField);
        } else {
            this.setState({ next: true });
        }
        this.setState({ nextProp: false });
    }

    onSubmit(e) {
        e.preventDefault()
    }

    /**
     * Process the call to pass to the next question, change the nextProp to true and the indexNext with the index of the component
     * @param {*} field Current question object
     */
    handleClickNext = (field) => {
        if (field != undefined) {
            const childelement = field.ref.current;
            this.setState({ nextProp: true, indexNext: childelement.props.index, selectProduct: false });
            if (this.state.step === this.state.totalQuestions && !this.state.selectProduct) {
                let { idTSVCategory, idTSVProduct } = this.state.selectProductTSV;
                this.goRiders(idTSVProduct, idTSVCategory);
            }
            try {
                window.scrollTo(0, 0);
            }
            catch{
                console.log('error scroll top');
            }
        }
    }
    /**
     * Updates the selected product information in the header of the page 
     * @param {String} name The current product name
     */
    handleProductNameInfo = (name) => {
        this.setState({ currentProductName: `: ${name}` });
    }
    /**
     * set isRider to true to display the riders screen
     */
    goRiders = (idTSVProduct, idTSVCategory) => {
        let productTermList = this.state.selectProductTSV.productTermList;
        this.setState({
            isRider: true,
            selectProduct: false,
            selectProductTSV: { idTSVCategory: idTSVCategory, idTSVProduct: idTSVProduct, productTermList: productTermList }
        });
    }
    /**
     * function for step back riders, should be returned to stepper with product selection questions
     */
    handleClickBackRider = () => {
        let { fieldsProduct, productQuestionNumber } = this.state;
        this.setState({
            fields: fieldsProduct, totalQuestions: fieldsProduct.length, nextProp: true, isRider: false, selectProduct: true,
            step: productQuestionNumber, refreshStepper: !this.state.refreshStepper, selectProductTSV: { idTSVCategory: 0, idTSVProduct: 0 }
        });
    }
    render() {
        const controls = this.state.fields;
        let { totalQuestions, step, currentProductName } = this.state;
        const percentageClass = `${((step * 100) / totalQuestions)}%`;
        const { idCategory } = this.props;
        const { nextProp, indexNext, selectProduct, fields } = this.state;
        const currentCategory = this.jsonExchangeTSV.getCurrentSerie();
        if (this.state.loading) {
            return (
                <div className='page'>
                    <LoadingPage />
                </div>
            );
        }
        else if (this.state.error && this.state.errorMessage === '') {
            return (
                <>
                    <p className="title-wel">
                        En este momento no podemos continuar con la solicitud. Por favor intente más tarde.
                    </p>
                    <div className='container pt-5'>
                        <div className='row'>
                            <div className='btn-home-container'>
                                <div className='btn-home'>
                                    <span className='btn-image-home'>
                                        <svg className="svg-back" width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.9727 22.4905L1.47221 11.9476L10.9727 1.40466" stroke="#052675" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                    <Link to={location => ({ ...location, pathname: "/compra" })}>
                                        <button className='btn btn-sm btn-text-home'>
                                            <div className="text-rigth text-button"> Ir a inicio</div>
                                        </button >
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        else if (this.state.error && this.state.errorMessage !== '') {
            return (
                <>
                    <p className="title-wel">
                        {this.state.errorMessage}
                    </p>
                    <div className='container pt-5'>
                        <div className='row'>
                            <div className='btn-home-container' >
                                <div className='btn-home'>
                                    <span className='btn-image-home'>
                                        <svg className="svg-back" width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.9727 22.4905L1.47221 11.9476L10.9727 1.40466" stroke="#052675" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                    <Link to={location => ({ ...location, pathname: "/compra" })}>
                                        <button className='btn btn-sm btn-text-home' >
                                            <div className="text-rigth text-button"> Ir a inicio</div>
                                        </button >
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            );
        }
        else if (this.state.errorInFields) {
            return (
                <>
                    <p className="title-wel">
                        La pregunta: {this.state.questionBodyWithError}, no tiene opciones de respuesta. Por favor contacte al administrador.
                    </p>
                    <div className='container pt-5'>
                        <div className='row'>
                            <div className='btn-home-container' >
                                <div className='btn-home'>
                                    <span className='btn-image-home'>
                                        <svg className="svg-back" width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.9727 22.4905L1.47221 11.9476L10.9727 1.40466" stroke="#052675" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                    <Link to={location => ({ ...location, pathname: "/compra" })}>
                                        <button className='btn btn-sm btn-text-home'>
                                            <div className="text-rigth text-button"> Ir a inicio</div>
                                        </button >
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </>);
        }
        else if (this.state.isRider) {
            return (
                <>
                 <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{ width: percentageClass }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div className='information-text'>
                        <p>{currentCategory.name}{currentProductName} </p>
                    </div>
                    <div className="riderList">
                        {this.state.selectProductTSV.idTSVCategory === REACT_APP_IDTSVCATEGORY_SAVING ?
                            <SelectedRiderSaving
                                idTSVProduct={this.state.selectProductTSV.idTSVProduct}
                                idTSVCategory={this.state.selectProductTSV.idTSVCategory}
                                handleErrorInComponent={this.handleErrorInComponent}
                                handleClickBackRider={this.handleClickBackRider}
                                handleClickNext={this.handleClickNext} />
                        
                        : this.state.selectProductTSV.idTSVCategory  !==  REACT_APP_IDTSVCATEGORY_UL ?
                            <SelectedRiderList
                                idTSVProduct={this.state.selectProductTSV.idTSVProduct}
                                idTSVCategory={this.state.selectProductTSV.idTSVCategory}
                                productTermList={this.state.selectProductTSV.productTermList}
                                handleErrorInComponent={this.handleErrorInComponent}
                                handleClickBackRider={this.handleClickBackRider}
                                handleClickNext={this.handleClickNext}
                                handleProductNameInfo={this.handleProductNameInfo}
                            />
                        
                        : this.state.selectProductTSV.idTSVCategory === REACT_APP_IDTSVCATEGORY_UL ?
                            <SelectedRiderListS4 idTSVProduct={this.state.selectProductTSV.idTSVProduct}
                                idTSVCategory={this.state.selectProductTSV.idTSVCategory}
                                handleErrorInComponent={this.handleErrorInComponent}
                                handleClickBackRider={this.handleClickBackRider}
                                handleClickNext={this.handleClickNext}
                                handleProductNameInfo={this.handleProductNameInfo} />
                        : null
                        }
                    </div>
                </>
            )
        } else {
            return (
                <div>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{ width: percentageClass }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div id="stepper1" className="bs-stepper">
                        <form onSubmit={this.onSubmit}>
                            <div className="bs-stepper-header overflow-auto container-custom">
                                <HeaderStepper fields={this.state.fields} className="display-header" />
                            </div>
                            <div className='information-text'>
                                <p>{currentCategory.name}{currentProductName}</p>
                            </div>
                            {
                                controls.map((field, index) => {
                                    return (
                                        <CardType key={index} field={field} index={index}
                                            handleErrorInComponent={this.handleErrorInComponent}
                                            handleSetProps={this.handleSetProps}
                                            goRiders={this.goRiders}
                                            step={step} fields={fields}
                                            idCategory={idCategory} nextProp={nextProp}
                                            indexNext={indexNext} selectProduct={selectProduct}
                                            totalQuestions={totalQuestions}
                                            handleGetFieldsRisk={this.handleGetFieldsRisk}
                                            handleClickBack={this.handleClickBack}
                                            handleClickNext={this.handleClickNext}
                                            handleProductNameInfo={this.handleProductNameInfo}
                                        />
                                    );

                                })
                            }
                        </form>
                    </div>
                </div>
            );
        }
    }
}