import './LandingPage.css';

import React from 'react';
import { Link } from 'react-router-dom';
import { CategoryList } from '../../components/CategoryCard/CategoryList';
import { LocalStorageTokenServices } from '../../Services/LocalStorageTokenServices';
import { TokenServices } from '../../Services/TokenServices';
import { CategoryServices } from '../../Services/CategoryServices';
import Slider from "react-slick";
export class LandingPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: 0, animation: 'entrade-left',
      slideIndex: 0,
      updateCount: 0,
      data: []
    };
    this.categoryServices = new CategoryServices();
    this.localStorageTokenServices = new LocalStorageTokenServices();
    this.tokenService = new TokenServices();
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  fetchData = async () => {
    const token = this.tokenService.DecryptToken();
    const response = await this.categoryServices.GetCategories(token);
    if (response.statusResponse)
      this.setState({
        data: response.data
      })
  }

  async getDataCategories() {
    await this.localStorageTokenServices.RefreshDateExpirationToken();
    await this.fetchData();
  }

  componentDidMount() {
    this.getDataCategories()
  }

  NextArrow(props) {
    return (
      <div className='faqs-carousel' onClick={this.next}>
        <button className="slick-next slick-arrow" aria-label="Next">Next</button>
      </div>
    );
  }

  PrevArrow(props) {
    return (
      <div className='faqs-carousel' onClick={this.previous}>
        <button className="slick-prev slick-arrow" aria-label="Previous" >Previous</button>
      </div>
    );
  }
  nextAnimation(step) {
    this.slider2.slickGoTo(step);
    this.setState({
      step: step
    });
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    const settings2 = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,

    };
    return (
      <>
        <section className="site-hero">
          <div className="container">
            <div className="hero-content">
              <h1 className="hero-title">Protegemos tu vida y a quienes son tu vida</h1>
              <p>Encuentra los seguros para planificar tu futuro y cuidar a quienes más quieres.</p>

            </div>
            <figure className="mobile-img">
              <img src="/compra/images/img-hero-mob.png" alt="Personas que mas quieres" />
            </figure>
          </div>
        </section>
        <section className="sec-intro">
          <div className="container">
            <h2 className="sec-title">
              <div>Adquiere soluciones <span>para<br />proteger lo que más valoras</span></div>
            </h2>
            <ul className="intro-carousel ul-stick">
              <CategoryList categories={this.state.data}
                buttonText='Compra'
                categoryFunction={this.props.categoryFunction} />
            </ul>
          </div>
        </section>

        <section className="sec-options">
          <div className="container">
            <h2 className="sec-title"><span>¿Por qué somos la </span>mejor opción<span> para ti?</span></h2>

            <div className="flex-row-container">
              <div className="flex-row-item"><div className="box">
              <div className='box-content'>

                <div className="icon icon-1"></div>
                <h3 className="title">Protege la estabilidad financiera de tu familia</h3>
                <div className="desc">
                  <p>Descubre opciones para cuidar a los tuyos a corto plazo, con seguros de vida flexibles y según tu realidad.</p>
                </div>
                </div>

              </div></div>
              <div className="flex-row-item">    <div className="box">
                <div className='box-content'>
                <div className="icon icon-2"></div>
                <h3 className="title">Prepárate en caso de cáncer o una enfermedad temida</h3>
                <div className="desc">
                  <p>Obtén una póliza que te apoyará en lo que te recuperas con beneficios de salud y dinero para gastos diarios.</p>
                </div>
                </div>
               
              </div></div>
              <div className="flex-row-item">    <div className="box">
              <div className='box-content'>
                <div className="icon icon-3"></div>
                <h3 className="title">Complementa tus ingresos durante el retiro</h3>
                <div className="desc">
                  <p>Conoce los seguros de vida que te permiten ahorrar para tener más recuros durante tu jubilación</p>
                </div>
                </div>

              </div></div>
            </div>

          </div>
        </section>

        <section className="sec-statistics">
          <div className="container">
            <ul className="statistics-list">
              <li>
                <div className="icon icon-1"></div>
                <h3>500,000+</h3>
                <p>Clientes satisfechos</p>
              </li>
              <li>
                <div className="icon icon-2"></div>
                <h3>700,000+</h3>
                <p>Pólizas activas</p>
              </li>
              <li>
                <div className="icon icon-3"></div>
                <h3>$77 millones</h3>
                <p>Reclamaciones pagadas en 2021</p>
              </li>
              <li>
                <div className="icon icon-4"></div>
                <h3>50+</h3>
                <p>Años protegiendo vidas</p>
              </li>
              <li>
                <div className="icon icon-5"></div>
                <h3>&lt;10 días </h3>
                <p>Tiempo promedio en pagar una reclamación</p>
              </li>
              <li>
                <div className="icon icon-6"></div>
                <h3>26</h3>
                <p>Oficinas de servicio alrededor de la isla</p>
              </li>
            </ul>
          </div>
        </section>
        <section className="sec-get-insurance">
          <div className="container">
            <div className="sec-content">
              <h2 className="sec-title">Adquiere tu seguro en 5 pasos</h2>
              <div className='bar-btn'>
                <span className={this.state.step == 0 ? "st-btn-active" : "st-btn-inactive"} onClick={() => this.nextAnimation(0)}></span>
                <span className={this.state.step == 1 ? "st-btn-active" : "st-btn-inactive"} onClick={() => this.nextAnimation(1)}></span>
                <span className={this.state.step == 2 ? "st-btn-active" : "st-btn-inactive"} onClick={() => this.nextAnimation(2)}></span>
                <span className={this.state.step == 3 ? "st-btn-active" : "st-btn-inactive"} onClick={() => this.nextAnimation(3)}></span>
                <span className={this.state.step == 4 ? "st-btn-active" : "st-btn-inactive"} onClick={() => this.nextAnimation(4)}></span>
              </div>
              <Slider ref={b => (this.slider2 = b)} {...settings2}>
                <div>
                  <ul className="steps-carousel">
                    <li>
                      <div className='step-content'>
                        <h3 className="title">Paso 1</h3>
                        <h4 className="subtitle">Tipo de Protección</h4>
                        <div className="desc">
                          <p>Selecciona el tipo de protección que deseas.</p>
                        </div>
                        <button className="btn btn-landing  btn-primary btn-next" onClick={() => this.nextAnimation(1)}>
                          <span><span>Siguiente</span></span>
                          <div className="icon">
                            <svg width={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"} height={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"}
                              className='image-svg-landing'
                              xmlns="http://www.w3.org/2000/svg"
                              fillRule="evenodd" clipRule="evenodd">
                              <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
                            </svg>
                          </div>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="steps-carousel">
                    <li>
                      <div className='step-content'>
                        <h3 className="title">Paso 2</h3>
                        <h4 className="subtitle">¿Para quién es la protección?</h4>
                        <div className="desc">
                          <p>Responde las preguntas básicas de quien deseas asegurar y condición actual de salud.
                            Esto nos ayudará a identificar las opciones de protección que más te convienen, al
                            mejor precio posible.</p>
                        </div>
                        <button className="btn btn btn-landing   btn-primary btn-next" onClick={() => this.nextAnimation(2)}>
                          <span><span>Siguiente</span></span>
                          <div className="icon">     <svg width={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"} height={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"}
                            className='image-svg-landing'
                            xmlns="http://www.w3.org/2000/svg"
                            fillRule="evenodd" clipRule="evenodd">
                            <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
                          </svg></div>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="steps-carousel">
                    <li>
                      <div className='step-content'>
                        <h3 className="title">Paso 3</h3>
                        <h4 className="subtitle">Escoge el producto y beneficios adicionales</h4>
                        <div className="desc">
                          <p>Escoge la opción que más se adapte a tus necesidades y posibilidades. Dependiendo del
                            producto seleccionado, podrás añadir beneficios adicionales para la más completa
                            protección disponible. De ser necesario, y dependiendo del producto a escoger,
                            deberás completar información médica para proveerte la mejor tarifa y beneficios
                            posibles.</p>
                        </div>
                        <button className="btn btn-landing   btn-primary btn-next" onClick={() => this.nextAnimation(3)}>
                          <span><span>Siguiente</span></span>
                          <div className="icon">     <svg width={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"} height={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"}
                            className='image-svg-landing'
                            xmlns="http://www.w3.org/2000/svg"
                            fillRule="evenodd" clipRule="evenodd">
                            <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
                          </svg></div>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="steps-carousel">
                    <li>
                      <div className='step-content'>
                        <h3 className="title">Paso 4</h3>
                        <h4 className="subtitle">Completa la solicitud de seguro</h4>
                        <div className="desc">
                          <p>Completar información personal y en caso de algunos productos, información e
                            historial médico necesario para evaluar tu solicitud. (Para esta parte, recomendamos
                            completar en una PC, o al menos una tableta con pantalla grande).</p>
                        </div>
                        <button className="btn btn-landing   btn-primary btn-next" onClick={() => this.nextAnimation(4)}>
                          <span><span>Siguiente</span></span>
                          <div className="icon">     <svg width={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"} height={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"}
                            className='image-svg-landing'
                            xmlns="http://www.w3.org/2000/svg"
                            fillRule="evenodd" clipRule="evenodd">
                            <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
                          </svg></div>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="steps-carousel">
                    <li>
                      <div className='step-content'>
                        <h3 className="title">Paso 5</h3>
                        <h4 className="subtitle">Firma electrónicamente la solicitud</h4>
                        <div className="desc">
                          <p>Aceptar las condiciones requeridas y firmar electrónicamente la solicitud que te
                            enviaremos por correo electrónico.</p>
                          <br />
                          <p>Algunos productos tendrán cubierta inmediata, otros requieren de una evaluación de
                            parte de nuestro personal, la cual podría tomar de 3 a 7 días.</p>
                        </div>
                        <button className="btn btn-landing btn-primary3 btn-first" tabindex="0" onClick={() => this.nextAnimation(0)}>
                          <strong><u>
                            <p className='back-home'>Volver al
                              inicio</p>
                          </u></strong>
                        </button>
                        <Link className='a-line' to={location => ({ ...location, pathname: "/compra" })}>
                          <button className="btn btn-landing  btn-primary22 btn-buy">
                            <span><span>Comprar</span></span>
                            <div className="icon">     <svg width={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"} height={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"}
                              className='image-svg-landing'
                              xmlns="http://www.w3.org/2000/svg"
                              fillRule="evenodd" clipRule="evenodd">
                              <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
                            </svg></div>
                          </button>
                        </Link>

                      </div>
                    </li>
                  </ul>
                </div>
              </Slider>

            </div>
            <figure className="sec-figure">
              <img src="/compra/img/img-adquiere-tu-seguro.png" alt="Adquirir Seguro" />
            </figure>
          </div>
        </section>

        <section className="sec-faqs">
          <div className="container">
            <h2 className="sec-title">¿Tienes preguntas?</h2>
            <h3 className="sec-subtitle">¡Tenemos respuestas!</h3>
            <div className='faqs-s' >
              <div className='backtBtn-stick' id='nt-stick'>
                {this.PrevArrow()}
              </div>

              <div className='slider-fqs'>
                <Slider ref={c => (this.slider = c)} {...settings}>
                  <div>
                    <ul className="faqs-carousel">
                      <li>
                        <div className="box">
                          <h3>Pregunta 1</h3>
                          <h4>No soy tecnológico. ¿Podré completar el proceso? ¿Alguien me puede ayudar?</h4>
                          <p>Con solo responder preguntas sencillas y claras, podemos identificar tus necesidades y
                            posibilidades de asegurarte.</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul className="faqs-carousel">
                      <li>
                        <div className="box">
                          <h3>Pregunta 2</h3>
                          <h4>No estoy muy bien de salud en este momento, ¿podré asegurarme?</h4>
                          <p>Nuestro proceso de compra identificará las mejores opciones que se adapten a tus
                            necesidades. Tenemos cubiertas que no requieren pruebas médicas.</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul className="faqs-carousel">
                      <li>
                        <div className="box">
                          <h3>Pregunta 3</h3>
                          <h4>Fui rechazado anteriormente por otra compañía... ¿qué opciones tengo?</h4>
                          <p>Nuestro seguro de vida te permite asegurarte aún cuando hayas sido rechazado
                            anteriormente por otra aseguradora.</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul className="faqs-carousel">
                      <li>
                        <div className="box">
                          <h3>Pregunta 4</h3>
                          <h4>¿Me van a hacer preguntas sobre mi salud? ¿Es seguro?</h4>
                          <p>Dependiendo del tipo de producto, podríamos hacer preguntas sobre tu salud y hábitos de
                            conducta de manera que podamos establecer el producto que más te convenga con las
                            mejores tarifas y cubiertas adicionales posibles. Esta información es totalmente
                            confidencial y la mantenemos con altos niveles de seguridad, según lo requerido por la
                            industria.</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul className="faqs-carousel">
                      <li>
                        <div className="box">
                          <h3>Pregunta 5</h3>
                          <h4>¿Debo proveer información financiera como cuentas de banco o tarjeta de crédito? ¿Es
                            seguro?</h4>
                          <p>Para comodidad y conveniencia de nuestros asegurados, nuestras cubiertas son cobradas
                            mensualmente directamente de cuentas bancarias o tarjetas de crédito. Esta información
                            es mantenida en nuestros sistemas de manera segura y confidencial, cumpliendo a
                            cabalidad con las normas de la industria.</p>
                        </div>
                      </li></ul>
                  </div>
                  <div>
                    <ul className="faqs-carousel">
                      <li>
                        <div className="box">
                          <h3>Pregunta 6</h3>
                          <h4>¿Qué seguros puedo cotizar y comprar en la página?</h4>
                          <p>De una manera simple y rápida, podrás cotizar y comprar seguros de vida entera y de
                            término, pólizas funerales, seguros contra el cáncer, enfermedades temidas y accidentes
                            así como abrir una IRA o anualidad para prepararte para el futuro.</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Slider>

              </div>
              <div className='nextBtn-stick'>
                {this.NextArrow()}
              </div>
            </div>
          </div>
        </section>
        <div id="cookie-law-info-bar" className='cookie-law-info-bar' data-nosnippet="true">
          <span>
            <p>Este sitio web utiliza cookies para mejorar su experiencia. Para conocer más sobre información que
              recopilamos, cómo la utilizamos, y sus opciones, haga referencia al <a
                href="/aviso-de-privacidad-de-sitios-web/" className="ignore-external-alert"> <strong>Aviso de
                  Privacidad de Sitios Web</strong></a><strong>.</strong>
              <br />
              Al oprimir el botón Aceptar, estará aceptando los términos y condiciones.
            </p>
            <br />
            <a role="button" tabindex="0" data-cli_action="accept" id="cookie_action_close_header"
              className="medium cli-plugin-button cli-plugin-main-button cookie_action_close_header cli_action_button"
            >ACEPTAR</a>
          </span>
        </div>
      </>
    );
  }
}