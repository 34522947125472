import './CategoryCard.css';
import React from 'react';
import { ButtonTSV } from '../ButtonTSV';
import { JSONExchangeTSV } from '../../Services/JSONExchangeTSV';
import { JSONRider } from '../../Services/JSONRider';
import { Link } from 'react-router-dom';

const SAVING_CATEGORY = process.env.REACT_APP_SAVING_CATEGORY;
const REACT_APP_DEFAULT_IMAGE = process.env.REACT_APP_DEFAULT_IMAGE;


export class CategoryCard extends React.Component {

  constructor(props) {
    super(props);
    this.jsonExchangeTSV = new JSONExchangeTSV();
    this.jsonRider = new JSONRider();
    this.state = { imageUrl: '', des: '', options: '' };
    this.categoryCardFunction = this.categoryCardFunction.bind(this);
  }

  componentDidMount() {
    let des = this.props.description;
    let options = 'Opciones';
    this.setState({
      imageUrl: this.props.img, des: des.substring(0, des.lastIndexOf(options)) == '' ? des : des.substring(0, des.lastIndexOf(options)),
      options: des.lastIndexOf(options) == -1 ? '' : des.substring(des.lastIndexOf(options), des.length)
    })



  }

  categoryCardFunction() {
    if (this.props.categoryFunction) {
      this.props.categoryFunction(this.props.idCategory);
      let serie = {
        'id_series': `${this.props.idCategory}`,
        'name': `${this.props.title}`
      };
      if (this.jsonExchangeTSV.isJSONinitialized()) {
        if (this.jsonExchangeTSV.getCurrentSerie().id_series != serie.id_series) {
          this.jsonExchangeTSV.initializeJSON();
          this.jsonExchangeTSV.addPersonToInsuredPersons({ id_insured: 1, primary_insured: true });
          this.jsonExchangeTSV.setSerie(serie);
          this.jsonRider.initializeJSON();
        }
      } else {
        this.jsonExchangeTSV.initializeJSON();
        this.jsonExchangeTSV.addPersonToInsuredPersons({ id_insured: 1, primary_insured: true });
        this.jsonExchangeTSV.setSerie(serie);
      }
    }
  }
  onError() {
    this.setState({
      imageUrl: REACT_APP_DEFAULT_IMAGE
    })
  }
  render() {
    return (
      <li className='li-stick'>
        <div className="box">
          <div className={`icon ${this.state.imageUrl}`}></div>
          <h3 className={this.props.title.length > 16 ? 'title last-of-type' : 'title'}>{this.props.title}</h3>
          <div className="desc">
            <p> {this.state.des}</p>
            {this.state.options.length > 0 ? <p class="opt">{this.state.options}</p> : null }
         
          </div>
          <div class="card-footer-lp">
              
              <Link className='a-line' to={location => ({ ...location, pathname: this.props.path })}>
                          <button onClick={this.categoryCardFunction} className="btn btn-landing cartbtn  btn-primary22 btn-buy">
                            <span className='home'><span>{this.props.buttonText}</span></span>
                            <div className="icon iconCard">     <svg width={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"} height={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"}
                              className='image-svg-landing'
                              xmlns="http://www.w3.org/2000/svg"
                              fillRule="evenodd" clipRule="evenodd">
                              <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
                            </svg></div>
                          </button>
                  
                        </Link>

            </div>
        </div>

      </li>
    );
  }
}
export default CategoryCard;