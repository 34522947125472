import './MatrixCard.css';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Field } from '../Field';
import { MatrixCardServices } from '../../Services/MatrixCardServices';
import { LoadingPage } from '../../pages/LoadingPage';
import { ButtonTSV } from '../ButtonTSV';

export class MatrixCard extends React.Component {
  constructor(props) {
    super(props);
    this.matrixCardServices = new MatrixCardServices();
    this.state = {
      childQuestions: [],
      mainchildQuestions: [],
      valid: true,
      renderBase: false,
      title: '',
      oldChildQuestions: [],
      loading: false,
      indexNext: [],
      answers: [],
      nextProp: false,
      hideButton: false,
      mainMatriz: true,
      mainMatrizLength: 0
    };
    this.childElement = React.createRef();
  }

  /**
   * Verifies that the nextProp property has changed, when its value is true it takes the identifier of the current component and calls nextStep to verify that the question was answered correctly.
   */
  async componentDidUpdate(prevProps) {
    if (prevProps.nextProp !== this.props.nextProp) {
      if (this.props.nextProp && this.props.indexNext === this.props.index) {
        await this.nextStep();
      } else {
        if (!this.props.fieldConfig.mainMatriz && this.props.currentMatriz === this.props.index && !this.state.renderBase) {
          await this.checkMainMatriz();
          await this.setValuesUI();
        }
      }
    }
  }
  /**
   * Creates and render all the sub questions of the main question from the local storage.
   */
  componentDidMount() {
    const { questions, idCurrentCategory, fieldConfig } = this.props;
    const result = this.matrixCardServices.LoadAnswersLocalStorage(questions, idCurrentCategory, fieldConfig);
    result.isCurrentCategory ? result.questions != null ? this.loadAswers(result.questions) : this.initialProps() : this.setRefs();
  }
  /**
   * 
   * @param {*} questions object with questions with the answers saved in the localstorage
   */
  loadAswers(questions) {
    questions.forEach(field => {
      field.children.forEach(child => {
        child.ref = React.createRef();
      });
    });
    this.setState(prevState => ({ childQuestions: prevState.childQuestions.concat(questions), oldChildQuestions: prevState.childQuestions.concat(questions) }));
  }
  /**
   * Initial the properties of questions and old questions
   */
  initialProps() {
    this.setState({ childQuestions: this.props.questions, oldChildQuestions: this.props.question });
    this.setRefs();
  }
  /**
   * Creates a a react reference to each sub question according to their component: yes/no , long answer and others.
   */
  setRefs() {
    const childQuestions = this.props.questions;
    childQuestions.map((field) => {
      field.children.map((child) => {
        child.ref = React.createRef();
        child.value = '';
        child.valid = child.required ? false : true;
      })
    })
    this.setState({ childQuestions: childQuestions });
  }
  /**
   * Validates if the input is correct to go to the next question and save it in the local storage.
   */
  nextStep = async () => {
    //this.setState({ loading: true });
    let { childQuestions } = this.state;
    let newIdexNext = [];
    let isValid = true;
    let step = 0;
    const { fieldConfig } = this.props;
    for (const field of childQuestions) {
      const children = field.children;
      for (const child of children) {
        const childelement = child.ref.current;
        if (!child.valid) {
          isValid = false;
          if (childelement !== null) {
            newIdexNext.push(childelement.props.index);
          }
        }
      }
    }
    this.setState({ nextProp: true, indexNext: newIdexNext, valid: isValid });
    if (isValid) {
      const response = await this.matrixCardServices.SaveMatrixQuestionInLocalStorage(fieldConfig, childQuestions);
      if (!response) {
        // setTimeout(() => this.setState({ loading: false }), 2000);
        this.props.handleErrorInComponent(response.errorInComponent);
      } else {
        this.setState({ oldChildQuestions: childQuestions });
      }

    }
    step = fieldConfig.options.step;
    // this.setState({ loading: false });
    this.props.funtionHandleSetProps(isValid, step, true, fieldConfig);
  }

  setOldChild(childQuestions) {
    let childQuestionsCopy = childQuestions;
    let oldChildQuestions = childQuestionsCopy;
    childQuestionsCopy.forEach((question, index) => {
      let childre = question.children.filter(child => child.base === false);
      oldChildQuestions[index].children = childre;

    });
    this.setState({ oldChildQuestions: oldChildQuestions });
  }
  /**
   * verifies that the main matrix has already been answered and takes the values ​​of the answers
   */
  async checkMainMatriz() {
    if (!this.props.fieldConfig.mainMatriz) {
      if (this.props.fieldConfig.idProduct !== '') {
        let mainchildQuestions = await this.matrixCardServices.getApprovedPersonJSONMatrix();
        this.setState({ mainchildQuestions: mainchildQuestions, mainMatriz: false })
      } else {
        let mainchildQuestions = await this.matrixCardServices.getJSONQuestionsMatrix();
        this.setState({ mainchildQuestions: mainchildQuestions, mainMatriz: false })
      }
    }
  }
  /**
   * takes the value of the responses from the main matrix and assigns them to the fields of the following matrices as the data corresponds
   */
  async setValuesUI() {
    const { mainchildQuestions, oldChildQuestions } = this.state;
    this.state.childQuestions = [];
    let quesitonsBase = mainchildQuestions[0].filter(question => question.question_base === true);
    for (let index = 0; index < quesitonsBase[0].answer_list.length; index++) {
      let answers = await this.getAnswerBase(quesitonsBase, index);
      let allChildrens = oldChildQuestions !== undefined ? oldChildQuestions[index] !== undefined ? oldChildQuestions[index].children : null : null;
      let childrens = allChildrens !== null ? allChildrens.filter(children => children.base === false) : null;
      await this.addBaseQuestion(quesitonsBase, answers, childrens);
    }
  }
  /**
   * returns the answers of a base question
   * @param {*} quesitonsBase object with the base questions of the main matrix
   * @param {*} index index indicating the question to which you should search for the answers
   * @returns 
   */
  async getAnswerBase(quesitonsBase, index) {
    let answers = [];
    quesitonsBase.forEach(baseQuestion => {
      answers.push(baseQuestion.answer_list[index].value_answer);
    });
    return answers;
  }
  /**
   * Adds a new insured person and his data to the questions and answers array.
   */
  addClick() {
    const { questions } = this.props;
    const newChildQuestions = { ...questions[0] };
    let children = [];
    newChildQuestions.children.map((child) => {
      const newchild = { ...child };
      newchild.ref = React.createRef();
      newchild.value = '';
      newchild.valid = false;
      children.push(newchild);
    });
    newChildQuestions.children = children;
    this.setState(prevState => ({ childQuestions: prevState.childQuestions.concat(newChildQuestions), nextProp: false, valid: true }));
  }
  /**
   * renders the base questions of the main matrix with the number of insured inserted
   * @param {*} questionsBase object with the base questions of the main matrix
   * @param {*} answers array with the answers of the base questions of the main matrix
   */
  async addBaseQuestion(questionsBase, answers, oldChildren) {
    const { questions } = this.props;
    const newChildQuestions = { ...questions[0] };
    let resultQuestions = await this.matrixCardServices.AddBaseQuestions(newChildQuestions, questionsBase, answers, oldChildren)
    resultQuestions.children.map((child) => {
      child.ref = React.createRef();
    })
    this.setState(prevState => ({ childQuestions: prevState.childQuestions.concat(resultQuestions), nextProp: false, renderBase: true }));
  }
  /**
   * Removes a new insured person and his data to the questions and answers array.
   */
  removeClick(i) {
    let childQuestions = [...this.state.childQuestions];
    childQuestions.splice(i, 1);
    this.setState({ childQuestions });
  }
  render() {
    const { fieldConfig, funtionHandleSetProps, idCurrentCategory } = this.props;
    const { loading, childQuestions, indexNext } = this.state;
    let { nextProp } = this.state;
    if (loading) {
      return (
        <div className='page'>
          <LoadingPage />
        </div>
      );
    } else {
      return (
        <Card className='letters'>
          <Card.Body className="">
            <Card.Title className='text-center '>
              {fieldConfig.title}
            </Card.Title>
            <Card.Text className='text-left  title-card'>
              {fieldConfig.body}
            </Card.Text>
            {childQuestions.map((fields, indexA) =>
              <div key={indexA}>
                <div className="border-cards align-items-center" style={{ width: '100%' }}>
                  <Card className='card-body-content'>
                    {this.props.fieldConfig.mainMatriz ?
                      <>
                        {indexA === 0 ? <div>
                          <div className="title-card-insurance">
                            Asegurado Principal
                          </div>
                        </div> : <div>
                          <div className="title-card-insurance">
                            Asegurado Adicional
                          </div>
                        </div>}
                      </>
                      : null
                    }
                    <Card.Body>
                      {indexA > 0 && this.props.fieldConfig.mainMatriz ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor" className="bi bi-x trash-align" viewBox="0 0 16 16" onClick={this.removeClick.bind(this, indexA)}>
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg> : null
                      }
                      <Row className=''>
                        {fields.children.map((field, index) =>
                          <div key={index}>
                            <Col sm='12' className="matrix-label">
                              <div className="form-group">
                                <label>{field.body}</label>
                                <Field
                                  key={field.id}
                                  fieldConfig={field}
                                  funtionHandleSetProps={funtionHandleSetProps}
                                  parentRef={field.ref}
                                  mainMatrizLength={fields.children.length}
                                  disabledInput={field.disabled}
                                  index={`m-${field.id}-${index}-${indexA}`}
                                  nextProp={nextProp}
                                  indexNext={indexNext}
                                  idCurrentCategory={idCurrentCategory}
                                  value={field.value} />
                              </div>
                            </Col>
                          </div>
                        )}
                      </Row>
                    </Card.Body>
                  </Card>

                </div>
              </div>
            )}
          </Card.Body>
          <div className="div-matrix-button">
            <div className='matrix-button' hidden={!this.props.fieldConfig.mainMatriz}>
            

              <button onClick={this.addClick.bind(this)} className="btn btn-landing stepbtn  btn-primary22 btn-buy">
                <span className='home'><span>{'Asegurado Adicional'}</span></span>
                <div className="icon iconCard">     <svg  width="28" height="28"
                  className='image-svg-insurance-plus bi bi-plus'
                  fillRule="evenodd" clipRule="evenodd" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg></div>
              </button>
            </div>
          </div>
        </Card>
      );
    }
  }
}
export default MatrixCard;
