import { JSONExchangeTSV } from "./JSONExchangeTSV";
import { NumericalRangeCardServices } from "./NumericalRangeCardServices";
import { WebServicesTSV } from "./WebServicesTSV";
import { SelectedProductListServices } from "./SelectedProductListServices";


const longAnswer = process.env.REACT_APP_LONG_ANSWER;
const date = process.env.REACT_APP_DATE;
const date_calculation = process.env.REACT_APP_DATE_CALCULATION;
const yes_no = process.env.REACT_APP_YES_NO;
const multiple_choise = process.env.REACT_APP_MULTICHOICE;
const numerical_range = process.env.REACT_APP_RANGE;
const uniqueSelection = process.env.REACT_APP_UNIQUE_SELECTION;
const genderQuestion = process.env.REACT_APP_GENDER_QUESTION;
const selectProductQuestion = process.env.REACT_APP_SELECT_PRODUCT;
const selected_risk = process.env.REACT_APP_SELECT_RISK;
const list_product_age = process.env.REACT_APP_LIST_AGE_PRODUCT;
const nameQuestion = process.env.REACT_APP_NAME_QUESTION;
const lastNameQuestion = process.env.REACT_APP_LAST_NAME_QUESTION;
export class MatrixCardServices {

    constructor() {
        this.jsonExchangeTSV = new JSONExchangeTSV();
        this.webServicesTSV = new WebServicesTSV();
        this.numericalRangeCardServices = new NumericalRangeCardServices();
        this.selectedProductlistServices = new SelectedProductListServices();


    }

    /**
     * Save the matrix questions in the local storage.
     * @param {Object}   maxtrixQuestion The main question object.
     * @param {Array}   subQuestions The array of sub questions.
     */
    SaveMatrixQuestionInLocalStorage = async (maxtrixQuestion, subQuestions, isBaseQuestionRisk) => {
        let mainQuestion = {
            "id_question": `${maxtrixQuestion.id}`,
            "question_type": `${maxtrixQuestion.type}`,
            "question_text": `${maxtrixQuestion.body}`,
            "id_tsv_field": `${maxtrixQuestion.idTSVField}`,
            "main_matrix": maxtrixQuestion.mainMatriz,
            "question_number": maxtrixQuestion.questionNumber,
            "question_list": []
        };
        if (maxtrixQuestion.idQuestionClass === selected_risk) {
            mainQuestion.base_question_risk = isBaseQuestionRisk !== undefined ? isBaseQuestionRisk ? true : false : false;
        }
        let subQuestionsArray = [];
        //first create all the sub questions
        subQuestions[0].children.forEach(question => {
            let subQuestion = {
                "id_question": `${question.id}`,
                "question_type": `${question.type}`,
                "question_text": `${question.body}`,
                "id_tsv_field": `${question.idTSVField}`,
                "question_base": question.base,
                "answer_list": []
            };
            subQuestionsArray.push(subQuestion);
        });
        let insuredPersons = [];
        if (maxtrixQuestion.mainMatriz) {
            subQuestions.forEach((question, index) => {
                let insuredPerson = {};
                if (index == 0) {
                    insuredPerson = { 'id_insured': index + 1, 'primary_insured': true }
                } else {
                    insuredPerson = { 'id_insured': index + 1, 'primary_insured': false }
                }
                insuredPersons.push(insuredPerson);
            });
            this.jsonExchangeTSV.setInsuredPersons(insuredPersons);
        } else {
            insuredPersons = this.jsonExchangeTSV.getCurrentInsuredPersons();
        }
        //then asign answers values for each question, for each secondary insured person
        let insuredCounter = 1;
        for (const questionArray of subQuestions) {
            let counter = 0;
            let children = questionArray.children;
            for (const question of children) {
                if (subQuestionsArray[counter].id_question === question.id) {
                    if (question.options.length > 0) {
                        if (question.type === date) {
                            let valueAnswer = { 'id': 0, 'text': '' };
                            if (question.options) {
                                if (question.options[0]) {
                                    valueAnswer.text = question.value;
                                }
                            }
                            let insuredPerson = insuredPersons.filter(insuredPerson => insuredPerson.id_insured === insuredCounter);
                            subQuestionsArray[counter].answer_list.push({
                                "id_answer": `${valueAnswer.id}`,
                                "value_answer": `${valueAnswer.text}`,
                                "id_insured": insuredPerson[0] ? insuredPerson[0].id_insured : null
                            })
                        } else if (question.type === date_calculation) {
                            if (question.value !== '') {
                                let serviceResponseDate = await this.GetTSVDate(question.value);
                                if (!serviceResponseDate.errorInComponent) {
                                    let answerOption = this.GetCorrectDateRange(serviceResponseDate.responseDate, question.options);
                                    let valueAnswer = { 'id': 0, 'text': '' };
                                    valueAnswer.text = `${question.value}`;
                                    valueAnswer.id = `${answerOption.id}`;
                                    let insuredPerson = insuredPersons.filter(insuredPerson => insuredPerson.id_insured === insuredCounter);
                                    if (insuredPerson[0]) {
                                        insuredPerson[0] = {
                                            ...insuredPerson[0],
                                            'date_Of_birth': new Date(valueAnswer.text).toJSON(),
                                            'age': serviceResponseDate.responseDate
                                        };
                                    }
                                    this.jsonExchangeTSV.modifyPersonfInsuredPersons(insuredPerson[0]);
                                    insuredPersons = [...this.jsonExchangeTSV.getCurrentInsuredPersons()];
                                    insuredPerson = insuredPersons.filter(insuredPerson => insuredPerson.id_insured === insuredCounter);
                                    subQuestionsArray[counter].answer_list.push({
                                        "id_answer":
                                            `${valueAnswer.id}`, "value_answer": `${valueAnswer.text}`,
                                        "id_insured": insuredPerson[0] ? insuredPerson[0].id_insured : null
                                    });
                                } else {
                                    return false;
                                }
                            }
                        }
                        else if (question.type == longAnswer) {
                            let valueAnswer = { 'id': 0, 'text': '' };
                            if (question.options) {
                                if (question.options[0]) {
                                    valueAnswer.id = question.options[0].id
                                    valueAnswer.text = question.value;
                                }
                            }
                            let insuredPerson = insuredPersons.filter(insuredPerson => insuredPerson.id_insured === insuredCounter);
                            if (question.body === nameQuestion) {
                                if (insuredPerson[0]) {
                                    insuredPerson[0] = {
                                        ...insuredPerson[0],
                                        'name': valueAnswer.text
                                    };
                                }
                            }
                            if (question.body === lastNameQuestion) {
                                if (insuredPerson[0]) {
                                    insuredPerson[0] = {
                                        ...insuredPerson[0],
                                        'last_name': valueAnswer.text
                                    };
                                }
                            }
                            this.jsonExchangeTSV.modifyPersonfInsuredPersons(insuredPerson[0]);
                            insuredPersons = [...this.jsonExchangeTSV.getCurrentInsuredPersons()];
                            subQuestionsArray[counter].answer_list.push({
                                "id_answer": `${valueAnswer.id}`,
                                "value_answer": `${valueAnswer.text}`,
                                "id_insured": insuredPerson[0] ? insuredPerson[0].id_insured : null
                            });
                        } else if (question.type === yes_no) {
                            if (question.value !== '-1' && question.value !== '') {
                                let valueAnswer = question.options.filter(option => option.text.toLowerCase() === question.value.toLowerCase());
                                let insuredPerson = insuredPersons.filter(insuredPerson =>
                                    insuredPerson.id_insured === insuredCounter);
                                subQuestionsArray[counter].answer_list.push(
                                    {
                                        "id_answer": `${valueAnswer[0].id}`,
                                        "value_answer": `${valueAnswer[0].text}`,
                                        "id_insured": insuredPerson[0] ? insuredPerson[0].id_insured : null
                                    })
                            }

                        } else if (question.type === multiple_choise) {
                            let answerOptions = [];
                            for (const value of question.value) {
                                for (const option of question.options) {
                                    if (Number(value.value) === option.id) {
                                        answerOptions.push({ ...option });
                                    }
                                }
                            }
                            let insuredPerson = insuredPersons.filter(insuredPerson =>
                                insuredPerson.id_insured === insuredCounter);

                            let answerListArray = [];
                            for (const answerOption of answerOptions) {
                                answerListArray.push(
                                    {
                                        "id_answer": `${answerOption.id}`,
                                        "value_answer": `${answerOption.text}`,
                                        "id_insured": insuredPerson[0] ? insuredPerson[0].id_insured : null
                                    });
                            }
                            subQuestionsArray[counter].answer_list.push(answerListArray);

                        } else if (question.type === numerical_range) {
                            if (question.value !== '-1' && question.value !== '') {
                                let answerOption = {};
                                answerOption = this.numericalRangeCardServices
                                    .GetCorrectAnswerOption(question.value, question.options, question.age, question.risk);
                                let insuredPerson = insuredPersons.filter(insuredPerson =>
                                    insuredPerson.id_insured === insuredCounter);
                                subQuestionsArray[counter].answer_list.push(
                                    {
                                        "id_answer": `${answerOption.id}`,
                                        "value_answer": `${question.value}`,
                                        "id_insured": insuredPerson[0] ? insuredPerson[0].id_insured : null
                                    });
                            }
                        }
                        else {
                            if (question.value !== '') {
                                let insuredPerson = insuredPersons.filter(insuredPerson =>
                                    insuredPerson.id_insured === insuredCounter);
                                let valueAnswer = question.options.filter(option => option.id === Number(question.value));
                                if (insuredPerson[0] && question.type === uniqueSelection && question.body !== genderQuestion) {
                                    insuredPerson[0] = { ...insuredPerson[0], 'relationship': valueAnswer[0].text };
                                    this.jsonExchangeTSV.modifyPersonfInsuredPersons(insuredPerson[0]);
                                    insuredPersons = [...this.jsonExchangeTSV.getCurrentInsuredPersons()];
                                    insuredPerson = insuredPersons.filter(insuredPerson => insuredPerson.id_insured === insuredCounter);
                                } else if (insuredPerson[0] && question.type === uniqueSelection && question.body === genderQuestion) {
                                    insuredPerson[0] = { ...insuredPerson[0], 'gender': valueAnswer[0].text };
                                    this.jsonExchangeTSV.modifyPersonfInsuredPersons(insuredPerson[0]);
                                    insuredPersons = [...this.jsonExchangeTSV.getCurrentInsuredPersons()];
                                    insuredPerson = insuredPersons.filter(insuredPerson => insuredPerson.id_insured === insuredCounter);
                                }
                                subQuestionsArray[counter].answer_list.push(
                                    {
                                        "id_answer": `${valueAnswer[0].id}`,
                                        "value_answer": `${valueAnswer[0].text}`,
                                        "id_insured": insuredPerson[0] ? insuredPerson[0].id_insured : null
                                    });
                            }
                        }
                    } else {
                        subQuestionsArray[counter].answer_list.push({ "value_answer": `${question.value}` })

                    }
                }
                counter++;
            }
            insuredCounter++;

        }
        mainQuestion.question_list = subQuestionsArray;

        if (Number(maxtrixQuestion.idQuestionClass) == selectProductQuestion) {
            if (!this.jsonExchangeTSV.existQuestionOfProductQuestions(mainQuestion)) {
                this.jsonExchangeTSV.addQuestionToProductQuestion(mainQuestion);
            } else {
                let lastQuestion = this.jsonExchangeTSV.getQuestionOfProductQuestionsById(mainQuestion);
                this.jsonExchangeTSV.removeQuestionOfProductQuestions(lastQuestion);
                this.jsonExchangeTSV.addQuestionToProductQuestion(mainQuestion);
            }
        } else {
            if (!this.jsonExchangeTSV.existQuestionOfRiskQuestions(mainQuestion)) {
                this.jsonExchangeTSV.addRiskQuestionToRiskQuestions(mainQuestion);
            } else {
                let lastQuestion = this.jsonExchangeTSV.getQuestionOfRiskQuestionsById(mainQuestion);
                this.jsonExchangeTSV.removeQuestionOfRiskQuestions(lastQuestion);
                this.jsonExchangeTSV.addRiskQuestionToRiskQuestions(mainQuestion);
            }
        }

        return true;
    }

    /**
     * Validates if the category chosen by the user is the same of the local storage.
     * @param {Number}   isCurrentCategory A numerical id of the category
     * @return {Boolean} True if it is the current category.
    */
    IsCurrentCategory = (idCurrentCategory) => {
        let actualCateory = this.jsonExchangeTSV.getCurrentSerie();
        if (Number(actualCateory.id_series) === idCurrentCategory) {
            return true;
        }
        return false;
    }
    /**
     * Gets the last answer saved for a question in the local storage.
     * @param {Object}   fieldConfig The question of the current component.
     * @return {Array} True if it is the current category.
    */
    GetLastAnswerSaved = (fieldConfig) => {
        let question = { id_question: fieldConfig.id };
        if (Number(fieldConfig.idQuestionClass) == selectProductQuestion) {
            let lastAnswer = this.jsonExchangeTSV.getQuestionOfProductQuestionsById(question);
            if (lastAnswer.question_list) {
                return lastAnswer.question_list;
            }
        } else {
            let lastAnswer = this.jsonExchangeTSV.getQuestionOfRiskQuestionsById(question);
            if (lastAnswer.question_list) {
                return lastAnswer.question_list;
            }
        }

        return null;
    }

    /**
   * Gets the valid date according to TSV Date service.
   * @param {Date}   date  The date picked by the user.
   * @return {Promise} The promise with de correct date from TSV.
  */
    async GetTSVDate(date) {
        let loading = true;
        let errorInComponent = false;
        let actualDate = new Date();
        let actualDateJson = JSON.stringify(actualDate);
        let dateOfBirth = new Date(date);
        let dateOfBirthJson = JSON.stringify(dateOfBirth);
        let person = { dateOfBirth: JSON.parse(dateOfBirthJson), effectiveDate: JSON.parse(actualDateJson) };
        let responseService = await this.webServicesTSV.PeopleAge(person);
        let responseDate = null;
        if (responseService.statusResponse) {
            loading = false;
            let ageCalculationStrategy = responseService.data.data.ageCalculationStrategy;
            if (ageCalculationStrategy === 'ActualAge') {
                responseDate = responseService.data.data.lastBirthday;
            } else if (ageCalculationStrategy === 'NearestAge') {
                responseDate = responseService.data.data.nearestBirthday;
            }
        } else {
            loading = false;
            errorInComponent = true;
        }
        let response = { errorInComponent: errorInComponent, loading: loading, responseDate: responseDate };
        return response;
    }

    /**
     * Gets the answer option according to the tsv date from the web service
     * @param {Number}   age  The age of the user.
     * @param {Array}    options The answer options array.
     * @return {Object} The answer option object.
    */
    GetCorrectDateRange(age, options) {
        let answerOption = { 'id': 0 };
        options.forEach(option => {
            if (Number(age) >= Number(option.option) && Number(age) <= Number(option.option2)) {
                answerOption = option;
            }
        });
        return answerOption;
    }
    /**
    *  takes the values ​​of the answers of main Matriz
    */
    async getJSONQuestionsMatrix() {
        let _questions = this.jsonExchangeTSV.getCurrentProductQuestions();
        let result = [];
        if (_questions !== null && _questions !== []) {
            _questions.forEach(element => {
                if (element.main_matrix) {
                    result.push(element.question_list);
                }
            });
        }
        return result;
    }
    /**
    * takes the values of the approved insured from the main matrix
    */
    async getApprovedPersonJSONMatrix() {
        let _questionsMainMatrix = await this.getJSONQuestionsMatrix();
        let _selectedProduct = this.jsonExchangeTSV.getCurrentSelectedProduct();
        const list = JSON.parse(list_product_age);
        let id_product_age = list.filter(product => product.id_product === _selectedProduct.id_product)[0].id_product_tsv;
        let result = [];
        if (_questionsMainMatrix !== null && _questionsMainMatrix !== []
            && _selectedProduct !== null && _selectedProduct !== []) {
            let insuredPersons = _selectedProduct.insured_persons_approved;
            _questionsMainMatrix[0].filter(question => {
                let answer_list = question.answer_list;
                let new_answer_list = [];
                insuredPersons.forEach(insured => {
                    let new_answer = answer_list.filter(answer => answer.id_insured === insured.idInsured);
                    if (new_answer !== null) {
                        new_answer[0].age = insured.age;
                        new_answer[0].id_product_age = id_product_age;
                        new_answer[0].dateOfBirth = insured.dateOfBirth;
                        new_answer[0].idInsured = insured.idInsured;

                        new_answer_list.push(new_answer[0]);
                    }
                    question.answer_list = new_answer_list;
                });
            });
            result = _questionsMainMatrix;
        }
        return result;
    }
    /**
     * recalculate the age according to the selected product and set the values in the local storage
     * @param {*} dateInsured insured date of birth
     * @param {*} idProductAge product id for tsv date calculation service
     * @param {*} idInsured identifier of the insured in the matrix
     * @returns the age corresponding to the tsv service response
     */
    async getAgeProduct(dateInsured, idProductAge, idInsured) {
        let date = dateInsured;
        let age = null;
        let id_product_age = idProductAge;
        let actualDate = new Date();
        let actualDateJson = JSON.stringify(actualDate);
        let dateOfBirth = new Date(date);
        let dateOfBirthJson = JSON.stringify(dateOfBirth);
        let person = { dateOfBirth: JSON.parse(dateOfBirthJson), effectiveDate: JSON.parse(actualDateJson), productID: Number(id_product_age) };
        let responseService = await this.webServicesTSV.PeopleAge(person);
        let responseDate = null;
        if (responseService.statusResponse) {
            let ageCalculationStrategy = responseService.data.data.ageCalculationStrategy;
            if (ageCalculationStrategy === 'ActualAge') {
                responseDate = responseService.data.data.lastBirthday;
            } else if (ageCalculationStrategy === 'NearestAge') {
                responseDate = responseService.data.data.nearestBirthday;
            }
            age = responseDate;
        }
        let _selectedProduct = this.jsonExchangeTSV.getCurrentSelectedProduct();
        let insuredPersonsApproved = this.selectedProductlistServices.ProcessInsuredPersonsApproved(_selectedProduct.insured_persons_approved);
        insuredPersonsApproved.filter(person => person.id_insured === idInsured)[0].age = age;
        this.jsonExchangeTSV.modifyPersonfInsuredPersons(insuredPersonsApproved.filter(person => person.id_insured === idInsured)[0]);
        _selectedProduct.insured_persons_approved = this.selectedProductlistServices.ToInsuredPersonsTSVFormat(insuredPersonsApproved);
        this.jsonExchangeTSV.setSelectedProduct(_selectedProduct);
        return age;
    }
    /**
    * Sets all the sub questions from the local storage by the type of card in the matrix.
    */
    SetAnswersByType(lastAnswers, questions, newChildQuestions) {
        let childQuestions = questions[0];
        lastAnswers.forEach(question => {
            childQuestions.children.forEach(child => {
                if (child.id === question.id_question) {
                    let counter = 0;
                    if (question.answer_list.length > 0) {
                        question.answer_list.forEach(answer => {
                            switch (child.type) {
                                case uniqueSelection:
                                    let answerObject = child.options.filter(option => option.text === answer.value_answer);
                                    let uniqueChildCopy = { ...child };
                                    if (answerObject[0]) {
                                        let value = `${answerObject[0].id}`;
                                        uniqueChildCopy.value = value;
                                        uniqueChildCopy.valid = true;
                                    }
                                    newChildQuestions[counter].children.push(uniqueChildCopy);
                                    break;
                                case multiple_choise:
                                    let childCopyMulti = { ...child };
                                    let valueAnswers = answer;
                                    let multiValue = [];
                                    valueAnswers.forEach(answer => {
                                        let option = { value: `${answer.id_answer}`, label: `${answer.value_answer}` };
                                        multiValue.push(option);
                                    });
                                    childCopyMulti.value = multiValue;
                                    childCopyMulti.valid = true;
                                    newChildQuestions[counter].children.push(childCopyMulti);
                                    break;
                                default:
                                    let value = answer.value_answer;
                                    let childCopyOtherTypes = { ...child };
                                    childCopyOtherTypes.value = value;
                                    childCopyOtherTypes.valid = true;
                                    newChildQuestions[counter].children.push(childCopyOtherTypes);
                                    break;
                            }
                            counter++;
                        })
                    } else {
                        for (let index = 0; index < newChildQuestions.length; index++) {
                            let value = '';
                            let childCopyOtherTypes = { ...child };
                            childCopyOtherTypes.value = value;
                            childCopyOtherTypes.valid = true;
                            newChildQuestions[index].children.push(childCopyOtherTypes);

                        }

                    }

                }
            })
        })
        return newChildQuestions;
    }
    /**
    * Creates and render all the sub questions of the main question from the local storage.
    */
    LoadAnswersLocalStorage(questions, idCurrentCategory, fieldConfig) {
        const result = { isCurrentCategory: false, questions: null, error: null }
        result.isCurrentCategory = this.IsCurrentCategory(idCurrentCategory);
        if (result.isCurrentCategory) {
            let lastAnswerSaved = this.GetLastAnswerSaved(fieldConfig);
            if (lastAnswerSaved != null) {
                let emptyAnswers = lastAnswerSaved.filter(answer => answer.answer_list.length === 0);
                if (lastAnswerSaved.length === emptyAnswers.length) {
                    result.questions = null;
                    return result;
                } else {
                    //lastAnswerSaved = lastAnswerSaved.filter(answer => answer.answer_list.length > 0);

                }
                let newChildQuestions = [];
                let answertList = lastAnswerSaved.filter(answer => answer.answer_list.length > 0)[0] ?
                    lastAnswerSaved.filter(answer => answer.answer_list.length > 0)[0] : null;
                newChildQuestions = [answertList.answer_list.length];
                let counter = 0;
                answertList.answer_list.forEach(numberOfAnswers => {
                    newChildQuestions[counter] = { children: [] }
                    counter++;
                });
                let questionsAnswers = this.SetAnswersByType(lastAnswerSaved, questions, newChildQuestions);
                result.questions = questionsAnswers;
            }
        }
        return result;
    }
    /**
    * renders the base questions of the main matrix with the number of insured inserted
    * @param {*} questionsBase object with the base questions of the main matrix
    * @param {*} answers array with the answers of the base questions of the main matrix
    */
    AddBaseQuestions(newChildQuestions, questionsBase, answers, oldChildren) {
        let children = [];
        questionsBase.forEach((question) => {
            const newchild = { ...newChildQuestions.children[0] };
            newchild.body = question.question_text;
            newchild.type = question.question_type;
            newchild.id = question.id_question;
            newchild.idTSVField = question.id_tsv_field;
            newchild.base = question.question_base;
            newchild.options = [{ id: questionsBase[0].answer_list[0].id_answer, option: questionsBase[0].answer_list[0].text }];
            newchild.valid = true;
            newchild.disabled = true;
            children.push(newchild);
        });
        let newChildren = [];
        children.map((childTemp, index) => {
            const newValue = { ...childTemp };
            let answer = answers[index];
            newValue.value = answer;
            newChildren.push(newValue);
        });
        if (oldChildren === null) {
            oldChildren = [];
            newChildQuestions.children.forEach(child => {
                const oldChild = { ...child };
                oldChildren.push(oldChild);
            });
        }
        newChildQuestions.children = oldChildren;
        newChildren = newChildren.concat(newChildQuestions.children);
        newChildQuestions.children = newChildren;
        return newChildQuestions;
    }

}