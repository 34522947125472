import React from "react";
import './CardType.css';
import SelectedProductsList from '../SelectedProductsList/SelectedProductsList';
import { Card } from 'react-bootstrap';
import { CardField } from '../../components/CardField';
import { MatrixCard } from '../MatrixCard';
import { ButtonTSV } from '../ButtonTSV';
import { Link } from "react-router-dom";


const matrix = process.env.REACT_APP_MATRIX;
const mainMatrix = process.env.REACT_APP_MAIN_MATRIX;
const selectProductClass = process.env.REACT_APP_SELECT_PRODUCT;

export class CardType extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
    }
    render() {
        const { field, index, nextProp, totalQuestions, indexNext, handleErrorInComponent,
            handleSetProps, selectProduct, step, fields, idCategory, handleClickBack,
            handleClickNext, handleGetFieldsRisk } = this.props;

        let divType = null;
        let helpSticky = document.getElementById("div-help-sticky");
        let helpChat = document.getElementById("div-help-chat");
        helpChat.style.display = "block";
        switch (field.type) {
            case matrix:
            case mainMatrix:
                helpSticky.style.display = "none";
                divType = <MatrixCard
                    key={field.id}
                    index={index}
                    fieldConfig={field}
                    questions={field.questions}
                    handleErrorInComponent={handleErrorInComponent}
                    funtionHandleSetProps={handleSetProps}
                    ref={field.ref}
                    nextProp={nextProp}
                    indexNext={indexNext}
                    currentMatriz={Number(step - 1)}
                    allQuest={fields}
                    idCurrentCategory={idCategory}
                />
                break;
            case 'Seleccion Producto':
                divType = <SelectedProductsList
                    key={field.id}
                    index={index}
                    fieldConfig={field}
                    nextProp={nextProp}
                    indexNext={indexNext}
                    goRiders={this.props.goRiders}
                    selectProduct={selectProduct}
                    handleErrorInComponent={handleErrorInComponent}
                    funtionHandleSetProps={handleSetProps}
                    parentRef={field.ref}
                    handleGetFieldsRisk={handleGetFieldsRisk}
                    handleProductNameInfo={this.props.handleProductNameInfo}
                    buttonText='Seleccione'
                />
                break;
            default:
                helpSticky.style.display = "none";
                divType = <CardField
                    key={field.id}
                    index={index}
                    fieldConfig={field}
                    funtionHandleSetProps={handleSetProps}
                    handleErrorInComponent={handleErrorInComponent}
                    parentRef={field.ref}
                    nextProp={nextProp}
                    indexNext={indexNext}
                    idCurrentCategory={idCategory}
                />
                break;
        }
        return (
            <div className="bs-stepper-content" key={index}>
                <div id={`question-${index}`} className={field.type === matrix || field.type === mainMatrix ? 'content border-content-matrix' : 'content border-content'}>
                    {
                        divType
                    }
                    <div className="row bottons-cards-container" >
                        <div className="col-sm-12">
                            <div className="bottons-cards">
                                {step < totalQuestions ?
                                    <div className="button-next">
                                        <button onClick={() => handleClickNext(field)} className="btn btn-landing stepbtn  btn-primary22 btn-buy">
                                            <span className='home'><span>{'Continuar'}</span></span>
                                            <div className="icon iconCard">     <svg width={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"} height={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"}
                                                className='image-svg-landing'
                                                xmlns="http://www.w3.org/2000/svg"
                                                fillRule="evenodd" clipRule="evenodd">
                                                <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
                                            </svg></div>
                                        </button>
                                    </div>
                                    : field.idProduct !== '' && step === totalQuestions && !selectProduct ?
                                        <svg onClick={() => handleClickNext(field)} xmlns="http://www.w3.org/2000/svg" width="55" height="55" className="ml-5 mt-3 svg-next bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                        </svg>
                                        : null
                                }
                                {index >= 1 || field.idProduct !== '' ?
                                    <div className={selectProduct ? "product-button-back" : "button-back"} onClick={() => handleClickBack(selectProduct ? 1 : field.back)}>
                                        <svg className="svg-back" width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.9727 22.4905L1.47221 11.9476L10.9727 1.40466" stroke="#052675" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    : null
                                }
                                {index == 0 && Number(field.idQuestionClass) === Number(selectProductClass) ?
                                    <Link to={location => ({ ...location, pathname: "/compra" })}>
                                        <div className={"button-back"} >
                                            <svg className="svg-back" width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.9727 22.4905L1.47221 11.9476L10.9727 1.40466" stroke="#052675" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </Link>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default CardType;
