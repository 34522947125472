import { SelectedRiderListService } from './SelectedRiderListService';
import { JSONRider } from './JSONRider';

export class RiderCardService {

    constructor() {
        this.selectedRiderListService = new SelectedRiderListService();
        this.jsonRider = new JSONRider();
    }

    /**
     * Add the selected riders to the list of already selected riders.
     * @param  {Object} request selected riders to add to the list
     * @return {Object} The response object to set props and state in the component.
    */
    HandleInputQuantityChange = (request) => {
        let response = {
            selectedCheckboxes: [],
            isActive: false,
            rider: {}
        }
        let value = request.event.target.value === undefined ? '' : request.event.target.value;
        let selectCategory = request.selectedCheckboxes;
        let isActiveCategory = request.isActive;

        if (selectCategory.length === 0) {
            selectCategory.push(value);
            isActiveCategory = true;
        } else {
            const sameValue = selectCategory.filter(selected => selected === value);
            if (sameValue[0]) {
                let indexOf = selectCategory.indexOf(sameValue[0]);
                selectCategory.splice(indexOf, 1);
                isActiveCategory = false;
            } else {
                selectCategory.push(value);
                isActiveCategory = true;
            }
        }
        let riderCategory = this.jsonRider.getCurrentRiders();

        let rider = null;
        if (request.type === null) {
            let requestRider = {
                categoryName: value, type: request.type, value: null,
                selectedInsuredCategory: request.planCodePrefix
            };
            rider = this.selectedRiderListService.SaveRiderCategories(requestRider);
        } else {
            rider = this.selectedRiderListService.GetCategory(value, riderCategory);
            if (rider != null) {
                if (!isActiveCategory) {
                    rider.isActive = false;
                } else {
                    rider.isActive = true;
                }
                this.selectedRiderListService.GetRiderCategories(rider);
            }
        }

        if (rider == null) {
            let requestRider = {
                categoryName: value, type: request.type, value: null,
                selectedInsuredCategory: request.planCodePrefix
            };
            rider = this.selectedRiderListService.SaveRiderCategories(requestRider);
        }

        response.selectedCheckboxes = selectCategory;
        response.isActive = isActiveCategory;
        response.rider = rider;

        return response;
    }
}
