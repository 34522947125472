import React from 'react';
import { WebServicesTSV } from './WebServicesTSV';
import { JSONExchangeTSV } from './JSONExchangeTSV';
import { ProcedureServices } from './ProcedureServices';
import { MatrixCardServices } from './MatrixCardServices';

const productinfo = process.env.REACT_APP_PRODUCTINFO;
const list_productinfo = process.env.REACT_APP_LIST_PRODUCTINFO;
const age_amount = process.env.REACT_APP_AGE_AMOUNT;
const age = process.env.REACT_APP_AGE;
const product_type = process.env.REACT_APP_PRODUCT_TYPE;
const surcharge_percentage = process.env.REACT_APP_SURCHARGE_PERCENTAGE;
const matrix = process.env.REACT_APP_MATRIX;
const amount_sum = process.env.REACT_APP_AMOUNT_SUM;
const multichocice = process.env.REACT_APP_MULTICHOICE;
const load_data = process.env.REACT_APP_LOAD_DATA;


export class Procedure {
  constructor() {
    this.procedureService = new ProcedureServices();
    this.jsonExchangeTSV = new JSONExchangeTSV();
    this.webServiceTSV = new WebServicesTSV();
    this.matrixCardServices = new MatrixCardServices();
  }
  /**
   * calls the WS sw TSV according to the one assigned to each question
   * @param {*} fieldConfig  object with the question attributes
   * @returns WS response object
   */
  async callWS(fieldConfig, currentField, fieldsProduct) {
    let response = { statusResponse: true, data: null, error: null }
    let body = null;
    if (fieldConfig.idProduct !== undefined && fieldConfig.idProduct !== '') {
      body = {
        idQuestion: Number(fieldConfig.id),
        idProduct: Number(fieldConfig.idProduct),
        idCategory: Number(fieldConfig.idCategory)

      }

    } else {
      body = {
        idQuestion: Number(fieldConfig.id),
        idCategory: Number(fieldConfig.idCategory),
      }
    }
    response = await this.procedureService.GetOperators(body);
    if (response.statusResponse && response.data.listProcedures.length > 0) {
      let listProcedures = response.data.listProcedures;
      let procedure = listProcedures[0].idExecuteProcedure;
      let operators = listProcedures[0].operators;
      let result = null;
      switch (procedure) {
        case (Number(productinfo)):
          result = await this.getPoliciesCancer(operators, fieldConfig);
          return result;
        case (Number(age)):
          result = await this.getAge(operators, fieldConfig);
          return result;
        case (Number(product_type)):
          result = await this.getProductType(operators, fieldConfig);
          return result;
        case (Number(surcharge_percentage)):
          result = await this.getSurchargePercentage(operators, fieldConfig, currentField);
          return result;
        case (Number(list_productinfo)):
          result = await this.getPolicies(operators, fieldConfig);
          return result;
        case (Number(age_amount)):
          result = await this.calculateAgeAmount(operators, fieldConfig, fieldsProduct);
          return result;
        case (Number(amount_sum)):
          result = await this.calculateAmountSum(operators, fieldConfig);
          return result;
        case (Number(load_data)):
          result = await this.loadData(operators, fieldConfig, currentField, fieldsProduct);
          return result;
        default:
          return response;
      }
    }
    response.data = null;
    return response;
  }
  /**
   * creates the body for the TSV according to the operators that correspond to the question
   * @param {*} operators array with the identifiers of the questions to process
   * @returns object with request body
   */
  createBody(operators, question_list) {
    let product_questions = question_list !== undefined ? question_list: this.jsonExchangeTSV.getCurrentProductQuestions();
    try {
      let body = {};
      if(question_list !== undefined){
        let product_questions =  this.jsonExchangeTSV.getCurrentProductQuestions();;
        operators.forEach(element => {
          let question = product_questions.filter(question => question.id_question === element.idQuestion);
          if(question.length > 0){
            let value_answer = question[0].value_answer;
            body[element.operator] = value_answer;
          }
        });
        product_questions = question_list;
        operators.forEach(element => {
          let question = product_questions.filter(question => question.id_question === element.idQuestion);
          if(question.length > 0){
            let value_answer = question[0].answer_list[0].value_answer;
            value_answer = element.operator === 'dob' ? new Date(value_answer).toJSON() : value_answer;
            value_answer = value_answer === "Femenino" ? "F" : value_answer === "Masculino" ? "M" : value_answer;
            body[element.operator] = value_answer;
          }
        });
      }else{
        operators.forEach(element => {
          let question = product_questions.filter(question => question.id_question === element.idQuestion);
          if(question.length > 0){
            let value_answer = question[0].value_answer;
            value_answer = element.operator === 'dob' ? new Date(value_answer).toJSON() : value_answer;
            value_answer = value_answer === "Femenino" ? "F" : value_answer === "Masculino" ? "M" : value_answer;
            body[element.operator] = value_answer;
          }
        });
      }
      return body;
    } catch {
      return null;
    }
  }
  /**
   * get the responses from the fields with the assigned operators
   * @param {*} operators array with the identifiers of the fields in which to look for the response value
   * @param {*} currentField current array to search for field identifiers
   * @returns array with the response value for each of the fields
   */
  async getAnswersMatrix(operators, currentField) {
    currentField.id_question = currentField.id;
    let questions = null;
    if (currentField.idProduct !== undefined && currentField.idProduct !== '') {
      questions = this.jsonExchangeTSV.getQuestionOfRiskQuestionsById(currentField);
    } else {
      questions = this.jsonExchangeTSV.getQuestionOfProductQuestionsById(currentField);
    }
    let answersOperators = [];
    operators.forEach(element => {
      let question_list = questions.question_list;
      let question = question_list.filter(q => q.id_question === element.idQuestion);
      if (question.length > 0) {
        let answerOperator = {};
        answerOperator.element = element.operator;
        answerOperator.answers = question;
        answersOperators.push(answerOperator)
      }
    });
    return answersOperators;
  }
  /**
   * Call the WS of TSV that calculates the age according to the date of birth
   * @param {*} operators array with the identifiers of the questions to process
   * @returns WS response object
   */
  async getAge(operators, fieldConfig) {
    let response = { statusResponse: true, data: null, error: null }
    let body = this.createBody(operators);
    let responseDate = null;
    if (body !== null) {
      let response = await this.webServiceTSV.PeopleAge(body);
      if (response.statusResponse) {
        let ageCalculationStrategy = response.data.data.ageCalculationStrategy;
        if (ageCalculationStrategy === 'ActualAge') {
          responseDate = response.data.data.lastBirthday;
        } else if (ageCalculationStrategy === 'NearestAge') {
          responseDate = response.data.data.nearestBirthday;
        }
      }
    }
    response.data = responseDate;
    return this.processAnswer(fieldConfig, response);
  }
  /**
   * Obtains the policies of the insured 
   * @param {*} operators array with the identifiers of the questions to process
   * @returns WS response object
   */
  async getPoliciesCancer(operators, fieldConfig) {
    let response = { statusResponse: true, data: null, error: null }
    let mainMatrix = this.jsonExchangeTSV.getMainMatrix();
    let question_list = mainMatrix.question_list;
    let body = this.createBody(operators, question_list);
    fieldConfig.value = [];
    const none = fieldConfig.options.filter(selected => selected.option === 'Ninguno');
    const ch = fieldConfig.options.filter(selected => selected.option === 'CH');

    let answerValue = '';
    if (body !== null) {
      response = await this.webServiceTSV.PersonProductInfo(body);
      if (response.statusResponse && response.data.data !== null) {
        let products = response.data.data;
        products.productInfo.forEach(product => {
          let fdc = false;
          let fdcValue = '';
          product.benefitRiders.forEach(element => {
            if (element.benefitFirstDiagnostic === 'FDC' && product.productType == 'C' && product.coverageType == 'H' )  {
              fdc = true;
              fdcValue = 'FDC';
            } else if (element.benefitFirstDiagnostic === 'FDH' && product.productType == 'V' && product.coverageType == 'H' ) {
              fdc = true;
              fdcValue = 'FDH';
            }
          });
          let first_diagnostic = fdc ? fdcValue : '';
          let idProduct = `${product.productType}${product.coverageType}${first_diagnostic}`;
          fieldConfig.options.forEach(option => {
            answerValue = option.text == idProduct ? option.id.toString() : '';
              if(idProduct.includes('CH') && product.firstDiagnostic === ''){
                if (ch !== undefined && ch !== null) {
                fieldConfig.value.push(ch[0].id.toString());
              }
            }
            if (answerValue !== '') {
              fieldConfig.value.push(answerValue);
            }
          });

        });
      }
    }
    fieldConfig.value = [...new Set(fieldConfig.value)];
    if (fieldConfig.value.length === 0) {
      if (none !== undefined && none !== null) {
        fieldConfig.value.push(none[0].id.toString());
      }
    }
    response.data = fieldConfig.value;
    return this.processAnswer(fieldConfig, response);
  }
  /**
 * Obtains the policies of the insured and adds the value of FDC with the new policy requested
 * @param {*} operators array with the identifiers of the questions to process
 * @returns WS response object
 */
  async calculateAmountSum(operators, fieldConfig) {
    let response = { statusResponse: true, data: null, error: null }
    let sum = 0;
    operators[0].id_question = operators[0].idQuestion;
    operators[1].id_question = operators[1].idQuestion;
    let idAmount1 = operators[0];
    let idAmount2 = operators[1];
    let amoutPolice = await this.jsonExchangeTSV.getQuestionOfProductQuestionsById(idAmount1);
    let amountSolicitude = await this.jsonExchangeTSV.getQuestionOfProductQuestionsById(idAmount2);
    if (amoutPolice !== null && amountSolicitude !== null) {
      let amount1 = amoutPolice.value_answer.replace(",", "");
      let amount2 = amountSolicitude.value_answer.replace(",", "");
      sum += Number(amount1) + Number(amount2);
    }
    response.data = sum > 50000 ? 'Si' : 'No';
    return this.processAnswer(fieldConfig, response);
  }
  /**
   * Obtains the policies of the insured 
   * @param {*} operators array with the identifiers of the questions to process
   * @returns WS response object
   */
  async getPolicies(operators, fieldConfig) {
    let response = { statusResponse: true, data: null, error: null }
    let body = this.createBody(operators);
    let answerValue = '';
    fieldConfig.value = [];
    const zl = fieldConfig.options.filter(selected => selected.option === 'ZL');
    const none = fieldConfig.options.filter(selected => selected.option === 'Ninguno');

    if (body !== null) {
      response = await this.webServiceTSV.PersonProductInfo(body);
      if (response.statusResponse && response.data.data !== null) {
        let products = response.data.data;
        products.productInfo.forEach(product => {
          if (product.coverageType === 'L') {
            fieldConfig.options.forEach(option => {
              let compareProduct = `${product.productType}L`;
              answerValue = option.text == compareProduct ? option.id.toString() : '';
              if (product.productId === '98ART') {
                if (zl !== undefined && zl !== null) {
                  answerValue = zl[0].id.toString();
                }
              }
              if (answerValue !== '') {
                fieldConfig.value.push(answerValue);
              }
            });
          }
        });
      }
    }
    fieldConfig.value = [...new Set(fieldConfig.value)];
    if (fieldConfig.value.length === 0) {
      if (none !== undefined && none !== null) {
        fieldConfig.value.push(none[0].id.toString());
      }
    }
    response.data = fieldConfig.value;
    return this.processAnswer(fieldConfig, response);
  }


  /**
  * Obtains the policies of the insured and check if have a policy by calling WS TSV
  * @param {*} operators array with the identifiers of the questions to process
  * @returns WS response object
  */
  async getProductType(operators, fieldConfig) {
    let response = { statusResponse: true, data: null, error: null }
    let body = this.createBody(operators);
    let isK = false;
    let isA = false;
    if (body !== null) {
      response = await this.webServiceTSV.PersonProductInfo(body);
      if (response.statusResponse && response.data.data !== null) {
        let products = response.data.data;
        products.productInfo.forEach(product => {
          if (product.productType === 'K') {
            isK = true
          }
          if (product.productType === 'A') {
            isA = true
          }
        });
      }
    }
    response.data = (isA && isK) ? 'Ambos' : isA ? 'A' : isK ? 'K' : 'Ninguno';
    return this.processAnswer(fieldConfig, response);
  }
  /**
  * Obtains the Surcharge Percentage of the insured 
  * @param {*} operators array with the identifiers of the questions to process
  * @returns WS response object
  */
  async getSurchargePercentage(operators, fieldConfig, currentField) {
    let response = { statusResponse: true, data: null, error: null }
    let body = { Feet: 0, Inch: 0, Weight: 0 };
    if (fieldConfig.type === matrix) {
      let answersOperators = await this.getAnswersMatrix(operators, currentField);
      let totalInsurer = answersOperators[0].answers[0].answer_list.length;
      let subQuestions = await this.prepareMatrizQuestion(fieldConfig, totalInsurer);
      let status = true;
      let ratedTable = [];
      for (let index = 0; index < totalInsurer; index++) {
        let feet = answersOperators.filter(a => a.element === 'Feet')[0].answers[0].answer_list;
        let inch = answersOperators.filter(a => a.element === 'Inch')[0].answers[0].answer_list
        let weight = answersOperators.filter(a => a.element === 'Weight')[0].answers[0].answer_list;
        body.Feet = feet[index].value_answer;
        body.Inch = inch[index].value_answer;
        body.Weight = weight[index].value_answer;
        response = await this.webServiceTSV.SurchargePercentage(body);
        if (response.statusResponse && response.data !== null) {
          let surcharge = Number(response.data.data) - 1;
          let rated = surcharge / 0.25;
          let ratedInsurer = { "table": rated, "id_insured": index + 1 };
          ratedTable.push(ratedInsurer);
          response.data = surcharge;
          const newchild = { ...subQuestions[index].children[0] };
          newchild.value = surcharge;
          subQuestions[index].children[0] = newchild;
        } else if (response.statusResponse === 404) {
          const newchildNotFound = { ...subQuestions[index].children[0] };
          newchildNotFound.value = 3;
          let ratedNotFound = 3 / 0.25;
          let ratedNotFount = { "table": ratedNotFound, "id_insured": index + 1 };
          ratedTable.push(ratedNotFount);
          subQuestions[index].children[0] = newchildNotFound;
        } else {
          status = false;
        }
      }
      if (status) {
        this.matrixCardServices.SaveMatrixQuestionInLocalStorage(fieldConfig, subQuestions);
        this.jsonExchangeTSV.setRatedTable(ratedTable);
      } else {
        ratedTable = [];
        this.jsonExchangeTSV.setRatedTable(ratedTable);
      }
      response.statusResponse = status;
      response.data = fieldConfig.options.step;
      return response;
    } else {
      body = this.createBody(operators, fieldConfig);
      if (body !== null) {
        response = await this.webServiceTSV.SurchargePercentage(body);
        if (response.statusResponse && response.data.data !== null) {
          let surcharge = Number(response.data.data) - 1;
          response.data = surcharge;
          return this.processAnswer(fieldConfig, response);
        }
      }
    }
  }
  /**
   * save the answers in the localstorage
   * @param {*} fieldConfig object with the properties of the question 
   * @param {*} response object with the response status of the process
   * @returns the pass to the next question according to the given answer value
   */
  processAnswer(fieldConfig, response) {
    if (response.statusResponse && response.data !== null) {
      fieldConfig.value = response.data;
      let answerValue = fieldConfig.type === multichocice ? this.processHidedQuestionMulti(fieldConfig) : this.processHidedQuestion(fieldConfig);
      let stepField = answerValue[0].step;
      response.data = stepField;
      response.error = false;
      return response;
    }
    return response;
  }
  /**
  * Process hidden questions multichoice type and assign an answer value
  * @param {*} fieldConfig Contains the attributes of a question
  * @returns The value for the answer
  */
  processHidedQuestionMulti(fieldConfig) {
    let answerValue = [];
    fieldConfig.value.forEach(value => {
      answerValue.push(fieldConfig.options.filter(option => option.id === parseInt(value))[0]);
    });
    let answerListJSON = [];
    let value_answer_list = [];
    let insuredPersons = this.jsonExchangeTSV.getCurrentInsuredPersons();
    answerValue.forEach(answerValue => {

      value_answer_list.push({
        'id_answer': `${answerValue.id}`, 'value_answer': `${answerValue.text}`,
        'idInsuredPerson': insuredPersons[0] ? insuredPersons[0].idInsuredPerson : null
      });
    });
    answerListJSON.push(value_answer_list);
    let productQuestion = {
      "id_question": `${fieldConfig.id}`,
      "question_type": `${fieldConfig.type}`,
      "question_text": `${fieldConfig.body}`,
      "id_tsv_field": `${fieldConfig.idTSVField}`,
      "question_number": fieldConfig.questionNumber,
      "answer_list": answerListJSON
    };
    if (!this.jsonExchangeTSV.existQuestionOfProductQuestions(productQuestion)) {
      this.jsonExchangeTSV.addQuestionToProductQuestion(productQuestion);
    } else {
      let lastQuestion = this.jsonExchangeTSV.getQuestionOfProductQuestionsById(productQuestion);
      this.jsonExchangeTSV.removeQuestionOfProductQuestions(lastQuestion);
      this.jsonExchangeTSV.addQuestionToProductQuestion(productQuestion);
    }
    return answerValue;
  }
  /**
    * Process hidden questions and assign an answer value
    * @param {*} fieldConfig Contains the attributes of a question
    * @returns The value for the answer
    */
  processHidedQuestion(fieldConfig) {
    let answerValue = fieldConfig.options.filter(option => option.text === fieldConfig.value);
    let productQuestion = {
      "id_question": `${fieldConfig.id}`,
      "question_type": `${fieldConfig.type}`,
      "question_text": `${fieldConfig.body}`,
      "id_answer": `${answerValue[0].id}`,
      "value_answer": `${fieldConfig.value}`,
      "id_tsv_field": `${fieldConfig.idTSVField}`,
      "question_number": fieldConfig.questionNumber

    }
    if (!this.jsonExchangeTSV.existQuestionOfProductQuestions(productQuestion)) {
      this.jsonExchangeTSV.addQuestionToProductQuestion(productQuestion);
    } else {
      let lastQuestion = this.jsonExchangeTSV.getQuestionOfProductQuestionsById(productQuestion);
      this.jsonExchangeTSV.removeQuestionOfProductQuestions(lastQuestion);
      this.jsonExchangeTSV.addQuestionToProductQuestion(productQuestion);
    }
    return answerValue;
  }
  /**
   * get the answers of the base questions to apply them in the hidden questions and save the data in the local storageget the answers of the base questions 
   * to apply them in the hidden questions and save the data in the local storage
   * @param {*} fieldConfig product selection question list
   * @param {*} totalInsurer total insured
   * @param {*} baseQuestions base questions of the main matrix
   * @param {*} fieldsProduct list of select product question
   * @returns object with the base questions and their answers
   */
  async prepareMatrizQuestionBase(fieldConfig, totalInsurer, baseQuestions, fieldsProduct) {
    let question = fieldConfig.questions[0].children[0];
    let baseQuesionAnswers = this.getBaseAnswer(baseQuestions, fieldsProduct, totalInsurer);
    for (let index = 0; index < totalInsurer; index++) {
      baseQuesionAnswers[index].children.push(question);
    }
    return baseQuesionAnswers;
  }
  /**
 * create the answer for each person in the matrix
 * @param {*} fieldConfig object with the properties of the question 
 * @param {*} totalInsurer number of people who are applying for insurance, are taken from the main matrix
 * @returns array of responses for each person in the matrix
 */
  async prepareMatrizQuestion(fieldConfig, totalInsurer) {
    let question = fieldConfig.questions[0].children[0];
    let subQuestions = [];
    for (let index = 0; index < totalInsurer; index++) {
      let childrens = { children: [] };
      childrens.children.push(question);
      subQuestions.push(childrens);
    }
    return subQuestions;
  }

  /**
   * get the answers of the base questions to apply them in the hidden questions and save the data in the local storageget the answers of the base questions 
   * to apply them in the hidden questions and save the data in the local storage
   * @param {*} baseQuestions base questions of the main matrix
   * @param {*} fieldsProduct product selection question list
   * @param {*} totalInsurer total insured
   * @returns object with the base questions and their answers
   */
  getBaseAnswer(baseQuestions, fieldsProduct, totalInsurer) {
    if (baseQuestions !== undefined && baseQuestions.length > 0) {
      let mainMatrix = fieldsProduct.filter(q => q.mainMatriz === true)[0];
      let mainMatrizbaseQuestions = mainMatrix.questions[0].children.filter(child => child.base === true);
      let answers = [];
      baseQuestions.forEach(questionBase => {
        let id = questionBase.id_question;
        for (let index = 0; index < totalInsurer; index++) {
          answers.push({ idInsurer: index, id_question: id, value: questionBase.answer_list[index].value_answer })
        }
      });
      let subQuestionsBase = [];
      for (let index = 0; index < totalInsurer; index++) {
        let childrens = { children: [] };
        mainMatrizbaseQuestions.forEach(element => {
          let newchild = { ...element };
          let answer = answers.filter(q => q.idInsurer === index && newchild.id == q.id_question)[0];
          newchild.value = answer.value;
          childrens.children.push(newchild);
        });
        subQuestionsBase.push(childrens);
      }
      return subQuestionsBase;
    }
  }
  /**
   * Take the question of the amount to request with the age of 
   * each one of the insured only to know in which range of options it 
   * @param {*} operators array with the identifiers of the questions to process
   * @param {*} fieldConfig object with the properties of the question 
   * @param {*} fieldsProduct list with product selection type questions
   * @returns an object with the status of the process, and go to the next question
   */
  async calculateAgeAmount(operators, fieldConfig, fieldsProduct) {
    let response = { statusResponse: true, data: null, error: null }
    try {
      let approvedPerson = await this.matrixCardServices.getApprovedPersonJSONMatrix();
      let baseQuestions = approvedPerson[0].filter(question => question.question_base === true);
      let totalInsurer = approvedPerson[0][0].answer_list.length;
      let answer_list = approvedPerson[0][0].answer_list;
      operators[0].id_question = operators[0].idQuestion;
      let amount = await this.jsonExchangeTSV.getQuestionOfRiskQuestionsById(operators[0]);
      let subQuestions = await this.prepareMatrizQuestionBase(fieldConfig, totalInsurer, baseQuestions, fieldsProduct);
      let id = fieldConfig.questions[0].children[0].id;
      for (let index = 0; index < totalInsurer; index++) {
        let dateOfBirth = answer_list[index].dateOfBirth;
        let idProductAge = answer_list[index].id_product_age;
        let idInsured = answer_list[index].idInsured;
        let age = await this.matrixCardServices.getAgeProduct(dateOfBirth, idProductAge, idInsured);
        let sub = subQuestions[index].children.filter(sub => sub.id === id)[0];
        if (sub !== undefined) {
          sub.value = amount.value_answer;
          sub.age = age;
          sub.risk = true;
        }
      }
      this.matrixCardServices.SaveMatrixQuestionInLocalStorage(fieldConfig, subQuestions, true);
      response.statusResponse = true;
      response.data = fieldConfig.options.step;
      return response;
    } catch {
      response.statusResponse = false;
      response.data = null;
    }
    return response;
  }
  /**
   * takes the response of the product selection percentage surcharge and assigns it to the risk selection, 
   * only with the data of the selected insured
   * @param {*} operators array with the identifiers of the questions to process
   * @param {*} fieldConfig object with the properties of the question 
   * @returns pass the question
   */
  async loadData(operators, fieldConfig) {
    let response = { statusResponse: true, data: null, error: null }
    try {
      let approvedPerson = await this.matrixCardServices.getApprovedPersonJSONMatrix();
      operators[0].id_question = operators[0].idQuestion;
      let surchar = await this.jsonExchangeTSV.getQuestionOfProductQuestionsById(operators[0]);
      let question_list = surchar.question_list;
      let answer_list = approvedPerson[0][0].answer_list;
      question_list.forEach(question => {
        let answer_list_surchar = question.answer_list;
        let new_answer_surchar = [];
        answer_list.forEach(answer => {
          let id_insured = answer.id_insured;
          let insurer = answer_list_surchar.filter(a => a.id_insured === id_insured);
          if (insurer !== undefined && insurer.length > 0) {
            new_answer_surchar.push(insurer[0]);
          }
        });
        question.answer_list = new_answer_surchar;
      });
      let children = fieldConfig.questions[0].children;
      children.forEach(child => {
        let body = child.body;
        question_list.forEach(questionL => {
          let question_text = questionL.question_text;
          if (question_text == body) {
            questionL.id_question = child.id;
            let answersL = questionL.answer_list;
            answersL.forEach(a => {
              a.id_answer = child.options[0].id;
            });
          }
        });
      });
      surchar.question_list = question_list;
      surchar.base_question_risk = false;
      surchar.id_question = fieldConfig.id;
      this.jsonExchangeTSV.removeQuestionOfRiskQuestions(surchar);
      this.jsonExchangeTSV.addRiskQuestionToRiskQuestions(surchar);
      response.statusResponse = true;
      response.data = fieldConfig.options.step;
      return response;
    } catch {
      response.statusResponse = false;
      response.data = null;
    }
    return response;
  }
}