import React from "react";
import { LoadingPage } from "../../pages/LoadingPage";
import { ProductServices } from "../../Services/ProductServices";
import { SelectedProductListServices } from "../../Services/SelectedProductListServices";
import { ProductCard } from "../ProductCard/ProductCard";
import './SelectedProductsList.css';

const { REACT_APP_FREEDOM_TERM_10_YEARS, REACT_APP_FREEDOM_TERM_15_YEARS, REACT_APP_ID_LIFE,
    REACT_APP_FREEDOM_TERM_20_YEARS, REACT_APP_FREEDOM_TERM_30_YEARS, REACT_APP_FREEDOM_TERM } = process.env;

export class SelectedProductsList extends React.Component {
    constructor(props) {
        super(props);
        this.productServices = new ProductServices();
        this.selectedProductListServices = new SelectedProductListServices();
        this.state = {
            products: [],
            loading: false,
            filteredProduct: [],
            productTermList: []
        };
    }
    componentDidMount() {
        let helpSticky = document.getElementById("div-help-sticky");
        let helpChat = document.getElementById("div-help-chat");
        helpChat.style.display = "none";
        helpSticky.style.display = "block";
    }
    componentDidUpdate(prevProps) {
        if (prevProps.nextProp !== this.props.nextProp) {
            if (this.props.selectProduct) {
                let request = this.selectedProductListServices.GetSelectedProductRequestObject();
                this.setState({ loading: true });
                this.productServices.GetSelectedProducts(request).then(result => {
                    if (result.statusResponse) {
                        let productsResponse = result.data;
                        this.existsProduct(productsResponse);
                        if (REACT_APP_ID_LIFE == request.idCategory) {
                            this.setState({ products: this.state.filteredProduct });
                        } else {
                            this.setState({ products: productsResponse });
                        }
                        setTimeout(() => this.setState({ loading: false }), 2000);
                        if (productsResponse.length == 0) {
                            let noProductsMessage = 'Lo sentimos, en esta categoría no es posible ofrecerte alguno de nuestros productos. Te invitamos a conocer nuestras opciones en otras categorías.';
                            return this.props.handleErrorInComponent(true, noProductsMessage);
                        }
                    } else {
                        this.setState({ loading: false });
                        this.props.handleErrorInComponent(true);
                    }
                });
                let helpChat = document.getElementById("div-help-chat");
                helpChat.style.display = "none";
                let helpSticky = document.getElementById("div-help-sticky");
                helpSticky.style.display = "block";
            }
        }
    }

    /**
      * Function that adds term products to the list and updates it 
      * @param {Array} products List of products to select
      */
    existsProduct = (products) => {
        let listTerms = [];
        let subProduct = [];

        products.map((product) => {
            if (REACT_APP_FREEDOM_TERM_10_YEARS == product.idProduct || REACT_APP_FREEDOM_TERM_15_YEARS == product.idProduct ||
                REACT_APP_FREEDOM_TERM_20_YEARS == product.idProduct || REACT_APP_FREEDOM_TERM_30_YEARS == product.idProduct ||
                REACT_APP_FREEDOM_TERM == product.idProduct) {
                let productTerm = { idTSVProduct: product.idTSVProduct, name: product.name, idProduct: product.idProduct };
                listTerms.push(productTerm);
            }
            if (REACT_APP_FREEDOM_TERM_30_YEARS == product.idProduct) {
                subProduct = [];
                let productTerm = { idTSVProduct: product.idTSVProduct, name: product.name, idProduct: product.idProduct };
                subProduct.push(productTerm)
            } else if (REACT_APP_FREEDOM_TERM_20_YEARS == product.idProduct) {
                subProduct = [];
                let productTerm = { idTSVProduct: product.idTSVProduct, name: product.name, idProduct: product.idProduct };
                subProduct.push(productTerm)
            } else if (REACT_APP_FREEDOM_TERM_15_YEARS == product.idProduct) {
                subProduct = [];
                let productTerm = { idTSVProduct: product.idTSVProduct, name: product.name, idProduct: product.idProduct };
                subProduct.push(productTerm)
            } else if (REACT_APP_FREEDOM_TERM_10_YEARS == product.idProduct) {
                subProduct = [];
                let productTerm = { idTSVProduct: product.idTSVProduct, name: product.name, idProduct: product.idProduct };
                subProduct.push(productTerm)
            }
            if (REACT_APP_FREEDOM_TERM == product.idProduct) {
                let productTerm = { idTSVProduct: product.idTSVProduct, name: product.name, idProduct: product.idProduct };
                subProduct.push(productTerm)
            }
        });
        let filteredProduct = [];
        filteredProduct = products.filter(function (obj) {
            return !listTerms.some(function (obj2) {
                return obj.idTSVProduct === obj2.idTSVProduct;
            });
        });
        let termProducts = [];
        termProducts = products.filter(function (obj) {
            return subProduct.some(function (obj2) {
                return obj.idTSVProduct === obj2.idTSVProduct;
            });
        });
        termProducts.map((product) => {
            filteredProduct.push(product)
        });

        this.setState({ productTermList: listTerms, filteredProduct: filteredProduct });
    }

    handleRiskQuestions = (idProduct, idTSVProduct, idTSVCategory) => {
        let listTerms = this.state.productTermList;
        this.props.handleGetFieldsRisk(idProduct, idTSVProduct, idTSVCategory, listTerms);
    }
    render() {
        const { parentRef, goRiders } = this.props;
        const { products, loading } = this.state;
        if (loading) {
            return (
                <div className='page'>
                    <LoadingPage />
                </div>
            );
        }
        else if (products.length > 0) {
            return (
                <>
                    <div >
                        <p className="title-wel-product">
                            Estas son las recomendaciones que tenemos para ti.
                        </p>
                        <p className="title-wel-sub-product">
                            Escoge la que más se ajuste a tus necesidades:
                        </p>
                        <div className=''>
                            <div className="cards-products">
                                {products.map((product) => {
                                    if (!product.deleted)
                                        return (
                                            <ProductCard
                                                key={product.idProduct}
                                                title={product.name}
                                                description={product.description}
                                                average={product.average}
                                                goRiders={goRiders}
                                                idProduct={product.idProduct}
                                                idTSVProduct={product.idTSVProduct}
                                                idTSVCategory={product.idTSVCategory}
                                                handleRiskQuestions={this.handleRiskQuestions}
                                                buttonText={this.props.buttonText}
                                                handleProductNameInfo={this.props.handleProductNameInfo}
                                                ref={parentRef}
                                                product={product}
                                            />)
                                    return null;
                                })
                                }
                            </div>

                        </div>
                    </div>

                </>
            )
        } else return (<></>)


    }
}
export default SelectedProductsList;