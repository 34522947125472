import './NumericalRangeCard.css';
import React from 'react';
import { NumericalRangeCardServices } from '../../Services/NumericalRangeCardServices';

export class NumericalRangeCard extends React.Component {

    constructor(props) {
        super(props);
        this.numericalRangeCardServices = new NumericalRangeCardServices();
        this.state = {
            errorMessageRangeOne: null,
            validRangeOne: true,
            valueRangeOne: '',
            minVal: 0,
            maxVal: 0,
            optionValues: []
        };

    }

    /**
     * Verifies that the nextProp property has changed, when its value is true it takes the identifier of the current component and calls nextStep to verify that the question was answered correctly.
     */
    componentDidUpdate(prevProps) {
        if (prevProps.nextProp !== this.props.nextProp) {
            if (this.props.nextProp && this.props.indexNext === this.props.index) {
                this.nextStep();
            } else if (this.props.nextProp && this.props.fieldConfig.idMainQuestion !== '') {
                if (this.props.indexNext.includes(this.props.index)) {
                    this.nextStep();
                }
            }
        }
    }
    /**
     * Checks if the answer of the question is stored in the local storage if so the component is rendered with that answer 
     */
    componentDidMount() {
        const { fieldConfig, idCurrentCategory } = this.props;
        this.setProperties(fieldConfig);
        fieldConfig.idMainQuestion === '' ? this.setValueAnswerd(fieldConfig, idCurrentCategory) :
            this.setState({ validRangeOne: true, valueRangeOne: fieldConfig.value });
    }
    /**
     * Add the minimum and maximum value for validations
     * @param {*} fieldConfig object with component attributes
     */
    setProperties(fieldConfig) {
        const responseSetProperties = this.numericalRangeCardServices.SetPropertiesBeforeRender(fieldConfig);
        if (responseSetProperties.optionValues) {
            this.setState({
                minVal: responseSetProperties.minValue,
                maxVal: responseSetProperties.maxVal,
                optionValues: responseSetProperties.optionValues
            });
        }
    }

    /**
     * Take the answer of the question saved in the localstorage according to the component ID
     * @param {*} fieldConfig object with component attributes
     * @param {*} idCurrentCategory idCurrentCategory id of the current category
     */

    setValueAnswerd(fieldConfig, idCurrentCategory) {
        const response =
            this.numericalRangeCardServices.GetPreviousAnswerFromLocalStorage(idCurrentCategory, fieldConfig);
        if (response) {
            this.setState({
                validRangeOne: response.validRangeOne, errorMessageRangeOne: response.errorMessageRangeOne,
                valueRangeOne: response.valueRangeOne
            });
            this.props.funtionHandleSetProps(true, response.step, false, response.fieldConfig);
        }
    }
    /**
     * Validates if the input is correct to go to the next question and save it in the local storage.
     */
    nextStep = () => {
        const { fieldConfig } = this.props;
        const { validRangeOne, optionValues, minVal, maxVal } = this.state;
        let response = null;
        if (!fieldConfig.required && fieldConfig.value === '') {
            response = this.numericalRangeCardServices.NextStep(true, fieldConfig, optionValues, minVal, maxVal);
        } else {
            response = this.numericalRangeCardServices.NextStep(validRangeOne, fieldConfig, optionValues, minVal, maxVal);
        }
        if (response.step !== undefined) {
            this.props.funtionHandleSetProps(response.validRangeOne, response.step, true, response.fieldConfig);
        } else {
            this.props.funtionHandleSetProps(response.validRangeOne, response.step, true, response.field);
            this.setState({ validRangeOne: response.validRangeOne, errorMessageRangeOne: response.errorMessageRangeOne, valueRangeOne: response.valueRangeOne });
        }
    }

    /**
     * Handles a change on a input a valid if this change is correct.
     */
    handleInputChange = (event, field) => {
        const { validRangeOne, minVal, maxVal } = this.state;
        const response = this.numericalRangeCardServices.HandleInputChange(event, field, validRangeOne, minVal, maxVal);
        this.setState({
            validRangeOne: response.validRangeOne, errorMessageRangeOne: response.errorMessageRangeOne,
            valueRangeOne: response.valueRangeOne
        });
        this.props.funtionHandleSetProps(validRangeOne, false, 0);
    }

    render() {
        const { required, placeholder, id, idMainQuestion, disabled } = this.props.fieldConfig;
        let validInput = this.state.validRangeOne;
        let isInputMatix = idMainQuestion !== '';

        return (
            <>
                <div className={validInput && !isInputMatix ? ('border-div-input') : validInput && isInputMatix ? ('') :
                    !validInput && !isInputMatix ? ('is-invalid-border') : ''}>
                    <div className={!isInputMatix ? ('text-left inpt-select') : ('text-left control-matrix')}>
                        <input
                            type="text"
                            value={this.state.valueRangeOne}
                            onChange={(event) => this.handleInputChange(event, this.props.fieldConfig)}
                            required={required}
                            placeholder={placeholder}
                            disabled={disabled}
                            autoComplete="off"
                            id={id}
                            name={`r1-${id}`}
                            className={ `form-control custom-input ${ (validInput && isInputMatix) ? (' height-input-matrix') :
                            (validInput && !isInputMatix)? (' height-input'):
                            (!validInput && !isInputMatix) ? (' is-invalid-input height-input'): 
                            (!validInput && isInputMatix) ?' height-input-matrix  is-invalid-input' : ''}`} />
                        {!this.state.validRangeOne ?
                            <i className={isInputMatix ? 'numerical-i-matrix' : ''}>
                                <svg width={isInputMatix ? '25' : '37'} height={isInputMatix ? '25' : '38'} viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <ellipse cx="18.5" cy="19.3965" rx="18.5" ry="18.4428" fill="#F2274C" />
                                    <path d="M17 27.8814C17 27.4259 17.1675 27.0299 17.5026 26.6932C17.8377 26.3566 18.2319 26.1883 18.6852 26.1883C19.1385 26.1883 19.5327 26.3566 19.8678 26.6932C20.2029 27.0299 20.3704 27.4259 20.3704 27.8814C20.3704 28.3368 20.2029 28.7229 19.8678 29.0398C19.5327 29.3368 19.1385 29.4853 18.6852 29.4853C18.2319 29.4853 17.8377 29.3368 17.5026 29.0398C17.1675 28.7229 17 28.3368 17 27.8814ZM18.3896 24.1388C18.3107 23.2081 18.222 22.2279 18.1235 21.1982C18.0446 20.1487 17.9559 19.119 17.8574 18.1091C17.7785 17.0794 17.6997 16.0992 17.6209 15.1685C17.5617 14.2378 17.5026 13.4061 17.4435 12.6734C17.3843 11.921 17.3351 11.3071 17.2957 10.8319C17.2562 10.3368 17.2365 10.0398 17.2365 9.94077C17.2365 9.40611 17.3843 9.02987 17.68 8.81205C17.9756 8.59423 18.3107 8.48532 18.6852 8.48532C19.0597 8.48532 19.3948 8.59423 19.6904 8.81205C19.9861 9.02987 20.1339 9.40611 20.1339 9.94077C20.1339 10.0992 20.0945 10.6635 20.0156 11.6338C19.9368 12.6041 19.8382 13.7824 19.72 15.1685C19.6214 16.5546 19.5032 18.0497 19.3652 19.6536C19.2272 21.2378 19.0991 22.7328 18.9809 24.1388H18.3896Z" fill="white" />
                                </svg>
                            </i> : 
                        null}
                    </div>
                </div>
                {!this.state.validRangeOne &&
                    <div className="text-left">
                        <span className='error text-left'>
                            {this.state.errorMessageRangeOne}
                        </span>
                    </div>
                }
            </>
        );

    }
}