import './LoadingPage.css';
import React from 'react';

export class LoadingPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){
  }

  render(){
    return (
      <div id="preloader">
        <div id="loader"></div>
      </div>
    );
  }

}