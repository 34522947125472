
export class JSONExchangeTSV {

    /**
     * This function initializes the json structure in the local storage.
     */
    initializeJSON() {
        let jsonBase = {
            insured_persons: [],
            serie: {},
            product_questions: [],
            selected_product: {},
            risk: { risk_questions: [], insured_risk: [] },
            product_payment: { payment: {}, details: {}, applicationData: {} },
            rated_table: [],

        };
        localStorage.setItem("JSONTSV", JSON.stringify(jsonBase));

    }

    /**
     * Determinates if the json in local storage exists.
     * @return {Boolean} True if json is iniatilized , false if not.
     */
    isJSONinitialized() {
        return localStorage.getItem("JSONTSV") ? true : false;
    }

    /**
     * Gets the actual json of the local storage.
     * @return {String} The json structure with data.
    */
    getJSONTSV() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        return jsonTsv;
    }

    /**
     * Sets the insured persons of the json in the local storage.
     *  @param  {Object} insuredPersons The insured persons array.
     */
    setInsuredPersons(insuredPersons) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        jsonTsv.insured_persons = insuredPersons;
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));

    }
    /**
     * Sets the insured rated table of the json in the local storage.
     *  @param  {Object} ratedTable The insured ratedTable array.
     */
    setRatedTable(ratedTable) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        jsonTsv.rated_table = ratedTable;
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));

    }
    /**
     * Gets the insured rate table of the json in the local storage.
     *  @param  {Object} rateTable The insured rateTable array.
     */
    getRateTable() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let rateTable = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);

            if (jsonTsv.rated_table) {
                rateTable = jsonTsv.rated_table;
            }
            return rateTable;

        }
    }

    /**
     * Gets the current insured persons array of the json of the local storage.
     *  @return  {Array}  The insured persons object.
     */
    getCurrentInsuredPersons() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let insuredPersons = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.insured_persons) {
            insuredPersons = jsonTsv.insured_persons;
        }
        return insuredPersons;
    }

    /**
     * Removes the current insured persons array of the json in the local storage.
     */
    removeCurrentInsuredPersons() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.insured_persons) {
            jsonTsv.insured_persons = {};
        }
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));
    }

    /**
     * Modify a insured person of the array of insured persons in the local storage.
     * @param  {Object} insuredPerson The insured person object.
     */
    modifyPersonfInsuredPersons(insuredPerson) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let insuredPersons = [];
        if (insuredPerson !== undefined) {
            if (jsonTsv) {
                jsonTsv = JSON.parse(jsonTsv);
                if (jsonTsv.insured_persons) {
                    insuredPersons = jsonTsv.insured_persons;

                    for (let index = 0; index < insuredPersons.length; index++) {
                        if (insuredPersons[index].id_insured === insuredPerson.id_insured || insuredPersons[index].id_insured === insuredPerson.idInsured) {
                            if (index > -1) {
                                insuredPersons.splice(index, 1, insuredPerson);
                            }

                        }
                    }
                    jsonTsv.insured_persons = insuredPersons;
                }
            }

            localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));

        }

    }

    /**
     * Removes a insured person of the array of insured persons in the local storage.
     * @param  {Object} insuredPerson The insured person object.
     */
    removePersonfInsuredPersons(insuredPerson) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let insuredPersons = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
            if (jsonTsv.insured_persons) {
                insuredPersons = jsonTsv.insured_persons;

                for (let index = 0; index < insuredPersons.length; index++) {
                    if (btoa(JSON.stringify(insuredPersons[index])) === (btoa(JSON.stringify(insuredPerson)))) {
                        if (index > -1) {
                            insuredPersons.splice(index, 1);
                        }

                    }
                }
                jsonTsv.insured_persons = insuredPersons;
            }
        }

        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));
    }

    /**
     * Adds a insured person of the array of insured persons in the local storage.
     * @param  {Object} insuredPerson The insured person object.
     */
    addPersonToInsuredPersons(insuredPerson) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let insuredPersons = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.insured_persons) {
            insuredPersons = jsonTsv.insured_persons;
        }
        insuredPersons.push(insuredPerson);
        jsonTsv.insured_persons = insuredPersons;
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));
    }

    /**
     * Sets the serie of the json in the local storage.
     *  @param  {Object} serie The serie object.
     */
    setSerie(serie) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        jsonTsv.serie = serie;
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));

    }

    /**
     * Gets the current serie of the json of the local storage.
     *  @return  {Object} serie The serie object.
     */
    getCurrentSerie() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let serie = {};
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.serie) {
            serie = jsonTsv.serie;
        }
        return serie;
    }

    /**
     * Removes the current serie of the json in the local storage.
     */
    removeCurrentSerie() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.serie) {
            jsonTsv.serie = {};
        }
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));
    }

    /**
     * Set the product questions of the json in the local storage.
     *  @param  {Array} productQuestions The product question array.
     */
    setProductQuestions(productQuestions) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        jsonTsv.product_questions = productQuestions;
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));

    }

    /**
     * Get the current serie of the json in the local storage.
     *  @return  {Array} productsQuestions The product questions array.
     */
    getCurrentProductQuestions() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let productsQuestions = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.product_questions) {
            productsQuestions = jsonTsv.product_questions;
        }
        return productsQuestions;
    }

    /**
     * Removes the current product questions of the json in the local storage.
     */
    removeCurrentProductQuestions() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.product_questions) {
            jsonTsv.product_questions = [];
        }
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));
    }

    /**
     * Determinates if a question exist in the array of product questions.
     * @param  {Object} question The question object.
     * @return {Boolean}         True if exists, if not then returns false.
     */
    existQuestionOfProductQuestions(question) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let productQuestions = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
            if (jsonTsv.product_questions) {
                productQuestions = jsonTsv.product_questions;
                for (let index = 0; index < productQuestions.length; index++) {
                    if (productQuestions[index].id_question === question.id_question) {
                        return true;
                    }
                }
            }
        }

        return false;
    }
    /**
    * Gets a main matriz question of product questions.
    * @return {Object} The question object if existe, if not then returns false.
    */
    getMainMatrix() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let productQuestions = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
            if (jsonTsv.product_questions) {
                productQuestions = jsonTsv.product_questions;
                for (let index = 0; index < productQuestions.length; index++) {
                    if (productQuestions[index].main_matrix === true) {
                        return productQuestions[index];
                    }
                }
            }
        }

        return false;
    }

    /**
     * Gets a question of product questions by id.
     * @param  {Object} question The question object with id.
     * @return {Object}         The question object if existe, if not then returns false.
     */
    getQuestionOfProductQuestionsById(question) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let productQuestions = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
            if (jsonTsv.product_questions) {
                productQuestions = jsonTsv.product_questions;
                for (let index = 0; index < productQuestions.length; index++) {
                    if (productQuestions[index].id_question === question.id_question) {
                        return productQuestions[index];
                    }
                }
            }
        }

        return false;
    }


    /**
     * Gets a sub question of the main question matrix of the product questions by id.
     * @param  {Object} question The question object with id.
     * @return {Object}          The question object if existe, if not then returns null.
     */
    getSubQuestionOfProductQuestionsById(question) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let productQuestions = [];
        let foundQuestion;
        let subQuestions;
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
            if (jsonTsv.product_questions) {
                productQuestions = jsonTsv.product_questions;
                for (let index = 0; index < productQuestions.length; index++) {
                    if (productQuestions[index].question_list) {
                        subQuestions = productQuestions[index].question_list;
                        subQuestions.forEach(subQuestion => {
                            if (subQuestion.id_question === question.id_question) {
                                foundQuestion = subQuestion;
                            }
                        });
                    }
                }
            }
        }
        return foundQuestion;
    }

    /**
     * Removes a question of the array of product questions in the local storage.
     * @param  {Object} question The question object.
     */
    removeQuestionOfProductQuestions(question) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let productQuestions = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
            if (jsonTsv.product_questions) {
                productQuestions = jsonTsv.product_questions;

                for (let index = 0; index < productQuestions.length; index++) {
                    if (btoa(JSON.stringify(productQuestions[index])) === (btoa(JSON.stringify(question)))) {
                        if (index > -1) {
                            productQuestions.splice(index, 1);
                        }

                    }
                }
                jsonTsv.product_questions = productQuestions;
            }
        }

        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));
    }

    /**
     * Adds a question in the array of product questions in the local storage.
     * @param  {Object} question The question object.
     */
    addQuestionToProductQuestion(question) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let productsQuestion = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.product_questions) {
            productsQuestion = jsonTsv.product_questions;
        }
        productsQuestion.push(question);
        jsonTsv.product_questions = productsQuestion;
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));
    }

    /**
     * Sets the selected product in the local storage.
     * @param  {Object} selectedProduct The product object.
     */
    setSelectedProduct(selectedProduct) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        jsonTsv.selected_product = selectedProduct;
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));

    }

    /**
     * Gets the selected product of the local storage.
     * @return {Object} The selected product of the json.
     */
    getCurrentSelectedProduct() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let selectedProduct = {};
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.selected_product) {
            selectedProduct = jsonTsv.selected_product;
        }
        return selectedProduct;
    }

    /**
     * Removes the current product of the json in the local storage.
     */
    removeCurrentSelectedProduct() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.selected_product) {
            jsonTsv.selected_product = {};
        }
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));
    }

    /**
     * Sets the risk general structure in the local storage.
     * @param  {Object} risk The risk object.
     */
    setRisks(risks) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        jsonTsv.risk = risks;
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));
    }
    /**
     * Sets the risk question in the local storage.
     * @param  {Object} risk The risk object.
     */
    setRiskQuestion(risk) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        jsonTsv.risk.risk_questions = risk;
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));

    }

    /**
    * Determinates if a risk question exist in the array of risk questions.
    * @param  {Object} question The question object.
    * @return {Boolean}         True if exists, if not then returns false.
    */
    existQuestionOfRiskQuestions(question) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let riskQuesitons = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
            if (jsonTsv.risk.risk_questions) {
                riskQuesitons = jsonTsv.risk.risk_questions;
                for (let index = 0; index < riskQuesitons.length; index++) {
                    if (riskQuesitons[index].id_question === question.id_question) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    /**
     * Gets a risk question of product questions by id.
     * @param  {Object} question The question object with id.
     * @return {Object}         The question object if existe, if not then returns false.
     */
    getQuestionOfRiskQuestionsById(question) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let riskQuestions = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
            if (jsonTsv.risk.risk_questions) {
                riskQuestions = jsonTsv.risk.risk_questions;
                for (let index = 0; index < riskQuestions.length; index++) {
                    if (riskQuestions[index].id_question === question.id_question) {
                        return riskQuestions[index];
                    }
                }
            }
        }

        return false;
    }
    /**
    * Adds a risk question in the array of product questions in the local storage.
    * @param  {Object} riskQuestion The question object.
    */
    addRiskQuestionToRiskQuestions(riskQuestion) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let riskQuestions = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.risk.risk_questions) {
            riskQuestions = jsonTsv.risk.risk_questions;
        }
        riskQuestions.push(riskQuestion);
        jsonTsv.risk.risk_questions = riskQuestions;
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));
    }

    /**
      * Removes a risk question of the array of risk questions in the local storage.
      * @param  {Object} question The question object.
      */
    removeQuestionOfRiskQuestions(question) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let riskQuestions = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
            if (jsonTsv.risk.risk_questions) {
                riskQuestions = jsonTsv.risk.risk_questions;

                for (let index = 0; index < riskQuestions.length; index++) {
                    if (btoa(JSON.stringify(riskQuestions[index])) === (btoa(JSON.stringify(question)))) {
                        if (index > -1) {
                            riskQuestions.splice(index, 1);
                        }

                    }
                }
                jsonTsv.risk.risk_questions = riskQuestions;
            }
        }

        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));
    }

    /**
     * Adds a insured risk to he insured risks array in the local storage.
     * @param  {Object} insuredRisk The insured risk object.
     */
    addInsuredRiskToRisk(insuredRisk) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let insuredRisks = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.risk) {
            if (jsonTsv.risk.insured_risk) {
                insuredRisks = jsonTsv.risk.insured_risk;
            }
        }

        insuredRisks.push(insuredRisk);
        jsonTsv.risk.insured_risk = insuredRisks;
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));
    }

    /**
     * Removes a insured risk of the array of insured risks in the local storage.
     * @param  {Object} question The question object.
     */
    removeInsuredRiskOfRisk(insuredRisk) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let insuredRisks = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
            if (jsonTsv.risk) {
                if (jsonTsv.risk.insured_risk) {
                    insuredRisks = jsonTsv.risk.insured_risk;
                }

                for (let index = 0; index < insuredRisks.length; index++) {
                    if (btoa(JSON.stringify(insuredRisks[index])) === (btoa(JSON.stringify(insuredRisk)))) {
                        if (index > -1) {
                            insuredRisks.splice(index, 1);
                        }

                    }
                }
                jsonTsv.risk.insured_risk = insuredRisks;
            }
        }

        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));
    }

    /**
     * Sets the insured risks in the local storage.
     * @param  {Object} risk The risk object.
     */
    setInsuredRisks(insuredRisks) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        jsonTsv.risk.insured_risk = insuredRisks;
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));

    }

    /**
     * Gets the insured risks array of the local storage.
     * @return {Object} The current insured risk of the json.
     */
    getCurrentRisk() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let risk = {};
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.risk) {
            risk = jsonTsv.risk;
        }
        return risk;
    }
    /**
     * Removes the insured risks array in the local storage.
     */
    removeCurrentRisk() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.risk) {
            jsonTsv.risk = { risk_questions: {}, insured_risk: [] };
        }
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));
    }

    /**
     * Sets the product payment in the local storage.
     * @param  {Object} productPayment The product payment object.
     */
    setProductPayment(productPayment) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        jsonTsv.product_payment = productPayment;
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));

    }

    /**
     * Gets the product payment of the local storage.
     * @return {Object} The selected product of the json.
     */
    getProductPayment() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let productPayment = {};
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.product_payment) {
            productPayment = jsonTsv.product_payment;
        }
        return productPayment;
    }

    /**
     * Removes the current product payment of the json in the local storage.
     */
    removeProductPayment() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.product_payment) {
            jsonTsv.product_payment = { payment: {}, details: {}, applicationData: {} };
        }
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));
    }

    /**
     * Sets the product payment in the local storage.
     * @param  {Object} productPayment The product payment object.
     */
    setPaymentOfProductPayment(productPayment) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        jsonTsv.product_payment.payment = productPayment;
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));

    }

    /**
     * Gets the payment object in  the product payment of the local storage.
     * @return {Object} The payment of the product payment of the json.
     */
    getPaymentOfProductPayment() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let productPayment = {};
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.product_payment.payment) {
            productPayment = jsonTsv.product_payment.payment;
        }
        return productPayment;
    }

    /**
     * Removes the payment object in  the product payment of the local storage.
     * @return {Object} The payment of the product payment of the json.
     */
    removePaymentOfProductPayment() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.product_payment.payment) {
            jsonTsv.product_payment.payment = {};
        }
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));
    }

    /**
     * Sets the details array of the product payment in the local storage.
     * @param  {Array} details The rider payment array.
     */
    setDetailsOfProductPayment(details) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        jsonTsv.product_payment.details = details;
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));

    }

    /**
     * Gets the details array of the product payment in the local storage.
     * @returns  {Array} details The rider payment array.
     */
    getCurrentDetailsOfProductPayment() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let details = {};
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.product_payment.details) {
            details = jsonTsv.product_payment.details;
        }
        return details;
    }

    /**
     * Removes the details array of the product payment in the local storage.
     */
    removeCurrentDetailsOfProductPayment() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.product_payment.details) {
            jsonTsv.product_payment.details = {};
        }
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));
    }


    /**
     * Sets the application data array of the product payment in the local storage.
     * @param  {Array} applicationData The endorsement array.
     */
    setApplicationDataOfProductPayment(applicationData) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        jsonTsv.product_payment.applicationData = applicationData;
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));

    }

    /**
     * Gets the applicationData object of the product payment in the local storage.
     * @returns  {Array} applicationData The applicationData object.
     */
    getCurrentApplicationDataOfPayment() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let applicationData = {};
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.product_payment.applicationData) {
            applicationData = jsonTsv.product_payment.applicationData;
        }
        return applicationData;
    }

    /**
     * Removes the application data object of the product payment in the local storage.
     */
    removeCurrentApplicationDataOfPayment() {
        let jsonTsv = localStorage.getItem("JSONTSV");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.product_payment.applicationData) {
            jsonTsv.product_payment.applicationData = {};
        }
        localStorage.setItem("JSONTSV", JSON.stringify(jsonTsv));
    }

    /**
    * Gets a question of product questions by idTSV.
    * @param  {Object} id_tsv_field The idTSV.
    * @return {Object}         The question object if existe, if not then returns false.
    */
    getQuestionOfProductQuestionsByIdTSV(id_tsv_field) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let productQuestions = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
            if (jsonTsv.product_questions) {
                productQuestions = jsonTsv.product_questions;
                for (let index = 0; index < productQuestions.length; index++) {
                    if (productQuestions[index].id_tsv_field === id_tsv_field) {
                        return productQuestions[index];
                    }
                }
            }
        }

        return false;
    }

    /**
      * Gets a question of product risks by idTSV.
      * @param  {Object} id_tsv_field The idTSV.
      * @return {Object} The question object if existe, if not then returns false.
      */
    getQuestionOfRiskQuestionsByIdTSV(id_tsv_field, question) {
        let jsonTsv = localStorage.getItem("JSONTSV");
        let riskQuestions = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
            if (jsonTsv.risk.risk_questions) {
                riskQuestions = jsonTsv.risk.risk_questions;
                for (let index = 0; index < riskQuestions.length; index++) {
                    if (riskQuestions[index].question_text.includes(id_tsv_field)) {
                        let riskAnswer = riskQuestions[index].question_list != null ? riskQuestions[index].question_list : [];
                        for (let risk = 0; risk < riskAnswer.length; risk++) {
                            if (riskAnswer[risk].question_text.includes(question)) {
                                let answerList = riskAnswer[risk].answer_list;
                                for (let answer = 0; answer < answerList.length; answer++) {
                                    if (answerList[answer].id_insured == 1) {
                                        return answerList[answer];
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return false;
    }
}