import React from "react";
import { LoadingPage } from "../../pages/LoadingPage";
import './SelectedRiderSaving.css';
import { SelectedRiderSavingService } from "../../Services/SelectedRiderSavingService";
import { RatesServiceTSV } from "../../Services/RatesServiceTSV";
import { SelectedRiderListService } from "../../Services/SelectedRiderListService";
import { ButtonTSV } from '../ButtonTSV';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
const { REACT_APP_SECTION_HIDE_SAVINGS, REACT_APP_SHOW_SAVINGS_SECTION } = process.env;

export class SelectedSavingProjection extends React.Component {
    constructor(props) {
        super(props);
        this.selectedRiderSavingService = new SelectedRiderSavingService();
        this.ratesServiceTSV = new RatesServiceTSV();
        this.selectedRiderListService = new SelectedRiderListService();
        this.state = {
            loading: false,
            riders: { details: {}, applicationData: {} }, details: [],
            value: this.props.value,
            valueEntry: this.props.valueEntry
        }
    }

    /**
      * This method is used to load the rider.
      */
    loadRidersCategory = async () => {
        let payment = this.selectedRiderListService.GetPayment();
        let request = {
            idTSVProduct: this.props.idTSVProduct,
            methodKey: payment.methodKey,
            modeIntervals: payment.modeIntervals,
            value: this.state.value != "" ? this.state.value : null,
            amount: this.state.valueEntry != "" ? this.state.valueEntry : null,
            idTSVCategory: this.props.idTSVCategory
        };

        let rider = this.selectedRiderSavingService.GetRiderRequest(request);

        const resultLogin = await this.ratesServiceTSV.Login();
        if (resultLogin.statusResponse) {
            const resultQuote = await this.ratesServiceTSV.QuoteRiders(rider, resultLogin.data.token);
            if (resultQuote.statusResponse) {
                let data = resultQuote.data;
                let detail = data.details.illustrationEmail != undefined ? data.details.illustrationEmail.policyDetails.sections : [];
                let details = data.details != undefined ? data.details : null;
                let application = data.applicationData != undefined ? data.applicationData : null;
                this.selectedRiderListService.SaveDetailsAndApplicationData(details, application);

                this.setState({
                    riders: { details: details, applicationData: application },
                    details: detail, loading: false
                });
            } else {
                if (resultQuote.error) {
                    this.props.handleErrorInComponent(true);
                }
                this.setState({ loading: false })
            }
        }
    }
    exportProjections() {


    }
    /**
     * This method upload the detail the rider
     */
    componentDidMount() {
        this.setState({ loading: true });
        this.loadRidersCategory();
    }

    render() {
        const { loading, details } = this.state;
        const { disclaimer } = this.props;
        let header = details[1] != undefined ? details[1].entries[0].items : [];
        if (loading) {
            return (
                <div className='page'> <LoadingPage /> </div>
            );
        }
        return (
            <div>
                <div className='riders-list'>
                    <div >
                        <p className="title-proyections">
                            Proyecciones
                        </p>
                        <div className="header-table-projections">
                            {details.map((detail, index) => (

                                detail.title != REACT_APP_SECTION_HIDE_SAVINGS ?
                                    <> {index === 0 ?
                                        <div className="sub-header">

                                            <span className="text-header" >Información General</span >
                                        </div> : null
                                    }   {index != 2 ?
                                        <div className="options-header">
                                            {index != 2 ?
                                                <div className="option-header-title">
                                                    <span className="modal-sub-text"> {detail.title}</span>
                                                </div>
                                                : null
                                            }
                                            <>
                                                {detail.title === REACT_APP_SHOW_SAVINGS_SECTION ?
                                                    null
                                                    : <div className='detail-header'>
                                                        {detail.entries.map((entry, index) => (
                                                            <tr key={index} >
                                                                {entry.items.map((item, indexItem) => (
                                                                    <td className='detail-p' key={indexItem} data-label={item}>{item}</td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </div>
                                                }
                                            </>
                                            {index === 0 ?
                                                <div>

                                                    <svg className="header-separator-max" height="79" viewBox="0 0 2 79" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 0V79" stroke="#052675" />
                                                    </svg>
                                                    <svg  className="header-separator-min"  width="100%" height="2" viewBox="0 0 242 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M242 1L5.24521e-06 1" stroke="#052675" />
                                                    </svg>

                                                </div>
                                                : null
                                            }
                                        </div>
                                        : null
                                        }
                                    </> :
                                    null
                            ))}
                        </div>
                        <span className="text-header-table" >Tabla de proyección de depósitos y valores</span >

                        {details.map((detail) => (
                            detail.title != REACT_APP_SECTION_HIDE_SAVINGS ?
                                <>
                                    <div className="proyections-container">
                                        <>
                                            {detail.title === REACT_APP_SHOW_SAVINGS_SECTION ?

                                                <table id="table-to-xls" className="table table-responsive table-rider">
                                                    {detail.entries.map((entry, indexEntry) => (

                                                        indexEntry === 0 ?
                                                            <thead className="table-riders">
                                                                {entry.items.map((item, indexItem) => (
                                                                    <th key={indexItem} >
                                                                        <p className="colum-text-rider"> {item} </p>

                                                                    </th>
                                                                ))}
                                                            </thead> :
                                                            <tr className={`${'table-riders-body '} ${indexEntry % 2 == 0 ? 'color-tr' : 'color-tr-white'}`} key={indexEntry} >
                                                                {entry.items.map((item, indexItemEntry) => (
                                                                    <td className={`${indexItemEntry > 7 ? '' : 'colum-table-rider'}`} key={indexItemEntry}  >{item}</td>
                                                                ))}
                                                            </tr>
                                                    ))}
                                                </table>
                                                : null
                                            }
                                        </>
                                    </div>
                                </> :
                                null
                        ))}
                        <div className="button-next-rider mt-3 mb-3">
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button btn-white text-btn-white"
                                table="table-to-xls"
                                filename="proyecciones"
                                sheet="proyecciones"
                                buttonText="DESCARGAR TABLA" />
                            <span className="btn-image-xls" >
                                <svg width={"24"} height={"24"}
                                    className='image-svg'
                                    xmlns="http://www.w3.org/2000/svg"
                                    fillRule="evenodd" clipRule="evenodd">
                                    <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
                                </svg>
                            </span>
                        </div>

                        <div>
                            <p className='disclaimer-text'>{disclaimer}</p>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default SelectedSavingProjection;