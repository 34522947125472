import React from 'react';
import { SliderCard } from '../../components/SliderCard';
import { SelectedRider, SelectedRiderMultiple } from '../SelectedRider';

export class FieldRider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
    }
    render() {
        const { type, rider, id, premium, premiumAmount, isBasicPlan } = this.props;

        let element = null;

        switch (type) {

            case ('sliderMultiple'):
                let sliderTypeMultiple = {
                    faceAmount: rider.faceAmount, faceAmountText: rider.faceAmountText,
                    faceAmountSliderIsVisible: rider.faceAmountSliderIsVisible, faceAmountSliderStep: rider.faceAmountSliderStep,
                    isOptional: rider.isOptional, maxFaceAmountText: rider.maxFaceAmountText, maxFaceAmountSlider: rider.maxFaceAmount_Slider,
                    minFaceAmountText: rider.minFaceAmountText, minFaceAmountSlider: rider.minFaceAmount_Slider,
                    validationMessage: rider.validationMessage,
                    validationMessageIsVisible: rider.validationMessageIsVisible, isValid: rider.isValid
                };
                element = (
                    <>
                        {this.props.show !== undefined && this.props.show ? null :
                         <SelectedRider options={rider.availableSpecs} premium={premium}
                            key={rider.categoryName} loadRiderCategory={this.props.loadRiderCategory} categoryName={rider.categoryName}
                            type={this.props.type} selectedInsuredCategory={rider.selectedInsuredCategory.planCodePrefix} />
                        }
                        <SliderCard
                            key={`${rider.categoryName}_slider`}
                            categoryName={rider.categoryName}
                            isActive={rider.isActive}
                            youngestChildRequirement={rider.youngestChildRequirement}
                            sliderType={sliderTypeMultiple}
                            id={id}
                            premium={premium}
                            loadRiderCategory={this.props.loadRiderCategory}
                            selectedInsuredCategory={rider.selectedInsuredCategory.planCodePrefix}
                            type={this.props.type}
                            handleValidRange={this.props.handleValidRange} />

                    </>
                );
                break;
            case ('slider'):
                let sliderType = {
                    faceAmount: rider.faceAmount, faceAmountText: rider.faceAmountText,
                    faceAmountSliderIsVisible: rider.faceAmountSliderIsVisible, faceAmountSliderStep: rider.faceAmountSliderStep,
                    isOptional: rider.isOptional, maxFaceAmountText: rider.maxFaceAmountText, maxFaceAmountSlider: rider.maxFaceAmount_Slider,
                    minFaceAmountText: rider.minFaceAmountText, minFaceAmountSlider: rider.minFaceAmount_Slider,
                    validationMessage: rider.validationMessage,
                    validationMessageIsVisible: rider.validationMessageIsVisible, isValid: rider.isValid
                };

                element = (
                    <SliderCard
                        key={rider.categoryName}
                        amount={this.props.amount}
                        idTSVCategory={this.props.idTSVCategory}
                        categoryName={rider.categoryName}
                        isActive={rider.isActive}
                        youngestChildRequirement={rider.youngestChildRequirement}
                        sliderType={sliderType}
                        id={id} premium={premium}
                        loadRiderCategory={this.props.loadRiderCategory}
                        selectedInsuredCategory={rider.selectedInsuredCategory.planCodePrefix}
                        type={this.props.type}
                        handleValidRange={this.props.handleValidRange}
                    />
                );
                break;
            case ('optionsSelect'):
                element = (
                    <SelectedRider options={rider.faceAmountSpecification.faceAmountOptions} premium={premium}
                        key={rider.categoryName} loadRiderCategory={this.props.loadRiderCategory} categoryName={rider.categoryName}
                        type={'faceAmountOptions'} selectedInsuredCategory={rider.selectedInsuredCategory.planCodePrefix}
                        premiumAmount={premiumAmount} isBasicPlan={isBasicPlan}
                        handleValidRange={this.props.handleValidRange} />
                );
                break;
            case ('categoriesSelect'):
                element = (
                    <SelectedRider options={rider.insuredCategories} premium={premium}
                        key={rider.categoryName} loadRiderCategory={this.props.loadRiderCategory} categoryName={rider.categoryName}
                        type={'insuredCategories'} selectedInsuredCategory={rider.selectedInsuredCategory.planCodePrefix} />
                );
                break;
            case ('selectMultiple'):
                element = (
                    <>
                        < SelectedRiderMultiple
                            optionsInsured={rider.insuredCategories}
                            key={rider.categoryName}
                            loadRiderCategory={this.props.loadRiderCategory}
                            categoryName={rider.categoryName}
                            type={'selectMultiple'}
                            selectedInsuredCategory={rider.selectedInsuredCategory.planCodePrefix}
                            options={rider.faceAmountSpecification.faceAmountOptions}
                            premiumAmount={premiumAmount} isBasicPlan={isBasicPlan}
                            handleValidRange={this.props.handleValidRange}
                            premium={premium} />
                    </>
                );
                break;
            case ('specsSelect'):
                element = (
                    <>
                        <SelectedRider options={rider.availableSpecs} premium={premium}
                            key={rider.categoryName} loadRiderCategory={this.props.loadRiderCategory} categoryName={rider.categoryName}
                            type={'availableSpecs'} selectedInsuredCategory={rider.selectedInsuredCategory.planCodePrefix} />
                    </>
                );
                break;
            default:
                element = null;
                break;
        }
        return (
            <div>
                {element}
            </div>
        )
    }

}