import { TokenServices } from "./TokenServices";


export class LocalStorageTokenServices {

    constructor() {
        this.tokenService = new TokenServices();
    }
    /**
     * Get the experidation date information in the local storage.
     */
    CreateDateExpirationToken = async () => {
        const responseToken = await this.tokenService.GetPublicToken();
        if (responseToken.statusResponse) {
            this.tokenService.EncryptToken(responseToken.data);
            //localStorage.setItem('dateExpirationToken', new Date());
        }

        return responseToken;
    }
    /**
     * Refresh the experidation date information in the local storage.
     */
    RefreshDateExpirationToken = async () => {
        //let dateCookie = new Date(localStorage.getItem('dateExpirationToken'));
        if (!this.tokenService.ValidateToken()) {
            await this.CreateDateExpirationToken();
        }
    }

}


