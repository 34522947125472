import { JSONExchangeTSV } from "./JSONExchangeTSV";
import { ParameterService } from "./ParameterService";

export class SelectedProductListServices {

    constructor() {
        this.jsonExchangeTSV = new JSONExchangeTSV();
        this.parameterService = new ParameterService();
    }

    /**
     * Gets the questions and answers from local storage and set th request object needed by the api.
     * @return {Object}         The object request needed in the api.
     */
    GetSelectedProductRequestObject = () => {
        let request = {
            idCategory: null,
            questions: null,
            insuredPersons: []
        };
        let questions = [];
        let jsonQuestions = this.jsonExchangeTSV.getCurrentProductQuestions();
        jsonQuestions.forEach(jsonQuestion => {
            let questionTsv = {
                idQuestion: null,
                idAnswer: null,
                valueAnswer: null,
                mainMatrix: null,
                questionList: null,
                answerList: null
            }
            questionTsv.idQuestion = Number(jsonQuestion.id_question);
            questionTsv.idAnswer = jsonQuestion.id_answer ? Number(jsonQuestion.id_answer) : null;
            questionTsv.valueAnswer = jsonQuestion.value_answer;
            if (jsonQuestion.main_matrix === null) {
                questionTsv.mainMatrix = null;
            } else {
                questionTsv.mainMatrix = jsonQuestion.main_matrix;
            }
            questionTsv.questionList = jsonQuestion.question_list ?
                this.GetMatrixSubQuestions(jsonQuestion.question_list) : null;

            questionTsv.answerList = jsonQuestion.answer_list ?
                this.GetMultipleChoiseAnswer(jsonQuestion.answer_list) : null;

            questions.push(questionTsv);
        });
        request.questions = questions;
        request.idCategory = Number(this.jsonExchangeTSV.getCurrentSerie().id_series);
        let insuredPersonsArray = [];
        insuredPersonsArray = this.jsonExchangeTSV.getCurrentInsuredPersons();
        let rateTable = this.jsonExchangeTSV.getRateTable();
        if (rateTable.length > 0) {
            for (const rate of rateTable) {
                if (rate.id_insured) {
                    let insuredPerson =
                        insuredPersonsArray.filter(insured => insured.id_insured == rate.id_insured);
                    if (insuredPerson[0]) {
                        let indexToUpdate = insuredPersonsArray.findIndex
                            (insured => insured.id_insured === insuredPerson[0].id_insured);
                        insuredPerson[0] = {
                            ...insuredPerson[0], 'rated_table':
                                rate.table ? rate.table : null
                        };
                        insuredPersonsArray[indexToUpdate] = { ...insuredPerson[0] };
                        this.jsonExchangeTSV.modifyPersonfInsuredPersons(insuredPerson[0]);
                    }
                }
            }
        }
        for (const insuredPerson of insuredPersonsArray) {
            request.insuredPersons.push({
                'idInsured': insuredPerson.id_insured,
                'primaryInsured': insuredPerson.primary_insured,
                'dateOfBirth': insuredPerson.date_Of_birth ? insuredPerson.date_Of_birth : null,
                'relationship': insuredPerson.relationship ? insuredPerson.relationship : null,
                'age': insuredPerson.age ? insuredPerson.age : null,
                'gender': insuredPerson.gender ? insuredPerson.gender : null,
                'name': insuredPerson.name ? insuredPerson.name : null,
                'lastName': insuredPerson.last_name ? insuredPerson.last_name : null,
                'ratedTable': insuredPerson.rated_table ? insuredPerson.rated_table : null

            })
        };
        return request;
    }

    /**
  * Process the insured persons approved form the selection of product process from the api in the format of tsv local storage.
  * @return {Array}         The array needed in the local storage.
  */
    ProcessInsuredPersonsApproved = (insuredPersonsApproved) => {
        let insuredPersonsTSV = [];
        for (const insuredPerson of insuredPersonsApproved) {
            insuredPersonsTSV.push({
                'id_insured': insuredPerson.idInsured,
                'primary_insured': insuredPerson.primaryInsured,
                'date_Of_birth': insuredPerson.dateOfBirth ? insuredPerson.dateOfBirth : null,
                'relationship': insuredPerson.relationship ? insuredPerson.relationship : null,
                'age': insuredPerson.age ? insuredPerson.age : null,
                'gender': insuredPerson.gender ? insuredPerson.gender : null,
                'name': insuredPerson.name ? insuredPerson.name : null,
                'last_name': insuredPerson.lastName ? insuredPerson.lastName : null,
                'rated_table': insuredPerson.ratedTable ? insuredPerson.ratedTable : null

            })
        };
        return insuredPersonsTSV;
    }

    /**
    * Change the insured persons format to the format needed by the TSV API .
    * @return {Array}         The array transformed
    */
    ToInsuredPersonsTSVFormat = (insuredPersonsApproved) => {
        let insuredPersonsTSV = [];
        for (const insuredPerson of insuredPersonsApproved) {
            insuredPersonsTSV.push({
                'idInsured': insuredPerson.id_insured,
                'primaryInsured': insuredPerson.primary_insured,
                'dateOfBirth': insuredPerson.date_Of_birth ? insuredPerson.date_Of_birth : null,
                'relationship': insuredPerson.relationship ? insuredPerson.relationship : null,
                'age': insuredPerson.age ? insuredPerson.age : null,
                'gender': insuredPerson.gender ? insuredPerson.gender : null,
                'name': insuredPerson.name ? insuredPerson.name : null,
                'lastName': insuredPerson.last_name ? insuredPerson.last_name : null,
                'ratedTable': insuredPerson.rated_table ? insuredPerson.rated_table : null

            })
        };
        return insuredPersonsTSV;
    }

    /**
      * Gets the answers of a multiple choise question in the format of the api
      * @return {Object}         The array of questions and asnwers  needed in the api.
      */
    GetMultipleChoiseAnswer = (answersList) => {
        let answersListTSV = [];
        for (const answerValue of answersList[0]) {
            let answer = {
                idAnswer: answerValue.id_answer ? Number(answerValue.id_answer) : null,
                valueAnswer: answerValue.value_answer,
            }
            answersListTSV.push(answer);
        }
        return answersListTSV;

    }

    /**
     * Gets the questions and answers of a matrix in the format of the api
     * @return {Object}         The array of questions and asnwers  needed in the api.
     */
    GetMatrixSubQuestions = (subQuestionList) => {
        let questionList = [];
        for (const subQuestion of subQuestionList) {
            let question = {
                idQuestion: null, questionText: null, questionType: null,
                baseQuestion: null, answerList: null
            };
            question.idQuestion = subQuestion.id_question ? Number(subQuestion.id_question)
                : null;
            question.questionType = subQuestion.question_type
                ? Number(subQuestion.question_type) : null;

            question.questionText = subQuestion.question_text;

            question.baseQuestion = subQuestion.question_base;

            for (const answer of subQuestion.answer_list) {
                let answerTSV = { idAnswer: null, valueAnswer: null, idInsuredPerson: null };
                if (Array.isArray(answer)) {
                    if (answer.length > 0) {
                        if (!question.answerList) {
                            question.answerList = [];
                        }
                        if (!answerTSV.answerValueList) {
                            answerTSV.answerValueList = [];
                        }
                        for (const answerValue of answer) {
                            answerTSV.answerValueList.push({
                                idAnswer: answerValue.id_answer ? Number(answerValue.id_answer) : null,
                                valueAnswer: answerValue.value_answer,
                                idInsuredPerson: answerValue.id_insured
                            })
                        }
                        question.answerList.push(answerTSV);
                    }

                } else {
                    if (!question.answerList) {
                        question.answerList = [];
                    }
                    answerTSV.idAnswer = answer.id_answer ? Number(answer.id_answer) : null;
                    answerTSV.valueAnswer = answer.value_answer;
                    answerTSV.idInsuredPerson = answer.id_insured;
                    question.answerList.push(answerTSV);
                }

            }
            questionList.push(question);
        }

        return questionList;
    }
    /**
     * Function you get if you show the score of the products
     * @param {*} idScore score parameter id 
     * @returns true or false of show score 
     */
     IsVisibleScore = async (idScore) => {
        const idParameter = { "idParameterValue": Number(idScore) };
        let show = false; 
        await this.parameterService.GetAllParameterById(idParameter).then(async result => {
            if (result.statusResponse) {
                if (result.data[0] != undefined) {
                    let value = result.data[0].value;
                    show = value.toUpperCase() === 'SI' ? true : false;
                }
            }
        });
        return show;
    }
}