import React from "react";
import { LoadingPage } from "../../pages/LoadingPage";
import { Col, Row, Card } from "react-bootstrap";
import { SelectedRiderListService } from "../../Services/SelectedRiderListService";
import { RatesServiceTSV } from "../../Services/RatesServiceTSV";
import { RiderCard } from "../RiderCard";
import { PaymentDetails } from "./PaymentDetails";
import './SelectedRiderList.css';
import { SelectedRisksServices } from "../../Services/SelectedRisksServices";
import { RiskServices } from "../../Services/RiskServices";
import { JSONExchangeTSV } from "../../Services/JSONExchangeTSV";
import { InsuranceAplicationServices } from "../../Services/InsuranceApplicationServices";
import { TokenServices } from "../../Services/TokenServices";
import { SelectedProductListServices } from "../../Services/SelectedProductListServices";
import { WebServicesTSV } from "../../Services/WebServicesTSV";

const { REACT_APP_PAYMENT_TYPE, REACT_APP_PAYMENT_MODE, REACT_APP_IDTSVCATEGORY_LIFE,
    REACT_APP_IDTSVCATEGORY_HEALTH, REACT_APP_ID_LIFE, REACT_APP_FREEDOM_TERM_10_YEARS,REACT_APP_ID_TSV_AMOUNT,
    REACT_APP_FREEDOM_TERM_15_YEARS, REACT_APP_FREEDOM_TERM_20_YEARS, REACT_APP_FREEDOM_TERM_30_YEARS, REACT_APP_FREEDOM_TERM,
    REACT_APP_LIST_ID_PRODUCT, REACT_APP_SSN_QUESTION, REACT_APP_BASIC_PLAN, REACT_APP_URL_TSV_REQUEST } = process.env;

export class SelectedRiderList extends React.Component {
    constructor(props) {
        super(props);
        this.jsonExchangeTSV = new JSONExchangeTSV();
        this.selectedRiderListService = new SelectedRiderListService();
        this.ratesServiceTSV = new RatesServiceTSV();
        this.selectedRisksServices = new SelectedRisksServices();
        this.riskServices = new RiskServices();
        this.insuranceApplicationServices = new InsuranceAplicationServices();
        this.selectedProductlistServices = new SelectedProductListServices();
        this.tokenService = new TokenServices();
        this.webServicesTSV = new WebServicesTSV();
        const familyInfo = this.selectedRiderListService.GetFamilyMember(this.props.idTSVCategory, this.props.idTSVProduct);
        let Primary = familyInfo.Primary;
        this.state = {
            send: false,
            urlRedirect: `${REACT_APP_URL_TSV_REQUEST}`,
            riders: { minimumPremium: "", requiredRiderCategoriesInfo: [], details: {}, applicationData: {} },
            loading: true,
            refresh: false,
            temRisk: Primary.RiskKey,
            opcionesTitle: "Opciones",
            noRiskError: false,
            emptyList: null,
            idTSVCategory: this.props.idTSVCategory,
            productTermList: [],
            idTSVProduct: this.props.idTSVProduct,
            isTerms: false, isValidRange: true,
            riderDetails: [], premium: 0,
            premiumAmount: { idProduct: '', maxPremium: 0, premium: 0 }
        };
        this.riderListFunction = this.riderListFunction.bind(this);
    };

    /**
      * Function to return to the previous screen.
      */
    riderListFunction() {
        if (this.props.handleClickBackRider) {
            this.props.handleClickBackRider();
        }
    }
    getNumber(value) {
        let newValue = value.replace(",", "");
        return newValue;
    }
    /**
      * This method is used to load the rider.
      */
     loadRidersCategory = async (idTSVProduct) => {
        try {
            let amount = 0;
            const familyInfo = this.selectedRiderListService.GetFamilyMember(this.props.idTSVCategory, this.state.idTSVProduct);
            let payment = this.selectedRiderListService.GetPayment();
            let Primary = familyInfo.Primary;
            let listRider = this.selectedRiderListService.GetRiderSelectLocalStorage();
           /* if (listRider.length == 0) {
                let basicPlan = { 
                    "categoryName": REACT_APP_BASIC_PLAN, 
                    "isActive": true }
                listRider.push(basicPlan);
            }
           ;*/
           let basicPlan ={};
            if(this.props.idTSVCategory == REACT_APP_IDTSVCATEGORY_LIFE){
                amount = this.jsonExchangeTSV.getQuestionOfProductQuestionsByIdTSV(REACT_APP_ID_TSV_AMOUNT);
                if(listRider.length == 0){
                    basicPlan = { 
                        "categoryName": REACT_APP_BASIC_PLAN, 
                        "isActive": true,
                        "faceAmount": this.getNumber(amount.value_answer),
                        "faceAmountText": amount.value_answer };
                }
            }else{
                basicPlan = { 
                    "categoryName": REACT_APP_BASIC_PLAN, 
                    "isActive": true };
            }
            if (listRider.length == 0) {
                listRider.push(basicPlan);
            }else{
                if(this.props.idTSVCategory == REACT_APP_IDTSVCATEGORY_LIFE){
                   /* listRider[0]=basicPlan;
                    this.selectedRiderListService
                    .modifyRiderBasicPlan(basicPlan);*/
                    if (Number(this.getNumber(amount.value_answer)) >= 200001) {
                        let temRisk =  Number(listRider[0].faceAmountText) < 200001 ?  'N' : Primary.RiskKey;
                        if(temRisk !== this.state.temRisk){
                         let listRiderTem =  [];
                         listRiderTem.push(listRider[0]);
                        await  this.selectedRiderListService.SetRiders(listRiderTem);
                         listRider = listRiderTem;
                        } 
                        
                        Primary.RiskKey = temRisk;
                        this.setState({temRisk, listRider: listRider});
                     } 
                 
                }
            }
            let rider = {
                "ProductInfo": {
                    "ProductKey": idTSVProduct != undefined ? idTSVProduct : this.state.idTSVProduct,
                    "PaymentMethodKey": payment.methodKey === undefined ? "E" : payment.methodKey,
                    "PaymentModePayments": payment.modeIntervals === undefined ? 12 : payment.modeIntervals,
                    "DeathBenefitCode": null,
                    "FetchDetails": true,
                    "riderCategories": listRider != null ? listRider : []
                },
                "FamilyInfo": {
                    "SeriesAccessCode": this.props.idTSVCategory,
                    "EffectiveDateOverride": null,
                    Primary
                }
            };

            if (this.props.idTSVCategory == REACT_APP_IDTSVCATEGORY_HEALTH) {
                let spouse = familyInfo.spouse;
                let child = familyInfo.child;
                if (spouse !== undefined) {
                    rider.FamilyInfo.spouse = spouse;
                }
                if (child !== undefined) {
                    rider.FamilyInfo.child = child;
                }
            }

            if (this.props.idTSVCategory == REACT_APP_IDTSVCATEGORY_LIFE) {
                let additionalInsured = familyInfo.additionalInsured;
                if (additionalInsured !== undefined) {
                    rider.FamilyInfo.additionalInsured = additionalInsured;
                }
            };

            const resultLogin = await this.ratesServiceTSV.Login();
            if (resultLogin.statusResponse) {
                const resultQuote = await this.ratesServiceTSV.QuoteRiders(rider, resultLogin.data.token);
                if (resultQuote.statusResponse) {
                    let data = resultQuote.data;
                    let applicationData = data.applicationData;
                    let riderDetails = applicationData.riders != undefined ? applicationData.riders : [];

                    let details = data.details;
                    let illustration = details != undefined ? details.illustrationEmail : details;
                    let policy = illustration != undefined ? illustration.policyDetails : illustration;
                    let premiumTotal = policy == undefined ? 0 :
                        policy.totalPremium != undefined ? policy.totalPremium : 0;

                    this.setState({
                        loading: false, riderDetails: riderDetails, premium: premiumTotal,
                        riders: {
                            minimumPremium: data.minimumPremium,
                            requiredRiderCategoriesInfo: data.requiredRiderCategoriesInfo,
                            details: data.details !== undefined ? data.details : null,
                            applicationData: data.applicationData != undefined ? data.applicationData : null
                        }
                    });
                    this.selectedRiderListService
                        .SaveDetailsAndApplicationData(this.state.riders.details, this.state.riders.applicationData);
                } else {
                    if (resultQuote.error) {
                        this.props.handleErrorInComponent(true);
                    }
                    this.setState({ loading: false });
                }
            } else {
                this.setState({ loading: false });
            }
        } catch (err) {
            this.setState({ loading: false, noRiskError: true });
            console.error(err);
        }
    }


    /**
      * This method upload the information of the riders 
      */
    componentDidMount() {
        if (Number(this.jsonExchangeTSV.getCurrentSerie().id_series) === Number(REACT_APP_ID_LIFE)) {
            let idProduct = Number(this.jsonExchangeTSV.getCurrentSelectedProduct().id_product);

            if (Number(REACT_APP_FREEDOM_TERM_10_YEARS) == idProduct || Number(REACT_APP_FREEDOM_TERM_15_YEARS) == idProduct ||
                Number(REACT_APP_FREEDOM_TERM_20_YEARS) == idProduct || Number(REACT_APP_FREEDOM_TERM_30_YEARS) == idProduct ||
                Number(REACT_APP_FREEDOM_TERM) == idProduct) {

                this.setState({ productTermList: this.props.productTermList, isTerms: true });
            } else {
                this.setState({ isTerms: false });
            }

            let request = this.selectedRisksServices.getSelectedRisksRequestObject();
            this.setState({ loading: true });
            this.riskServices.GetSelectedRisks(request).then(result => {
                if (result.statusResponse) {
                    let risksResponse = result.data;
                    setTimeout(() => this.setState({ loading: false }), 2000);
                    if (risksResponse.productError) {
                        this.setState({ noRiskError: true })
                    } else {
                        this.riskServices.SaveCalculatedRisksInLocalStorage(risksResponse.selectedRisks);
                    }
                } else {
                    this.setState({ loading: false });
                    this.props.handleErrorInComponent(true);
                }
            });
       
        } else {
            this.setState({ loading: true });
            this.getPolicyTotals();
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.refresh !== this.state.refresh &&
            prevState.refreshType !== this.state.refreshType) {
            this.setState({ loading: prevState.loading });
            this.loadRidersCategory();
        }
    }

    /**
      * Function that obtains the detail of the amount of the policy of the selected product
      */
    getPolicyTotals = async () => {
        let { idTSVProduct } = this.state;
        let idProductJSON = Number(this.jsonExchangeTSV.getCurrentSelectedProduct().id_product);
        const list = JSON.parse(REACT_APP_LIST_ID_PRODUCT);

        let product = list.filter(product => Number(product.id_product) === idProductJSON)[0];
        let questionSSN = this.jsonExchangeTSV.getCurrentProductQuestions();
        questionSSN = questionSSN.filter(quesiton => quesiton.question_text.includes(REACT_APP_SSN_QUESTION));
        let ssn = questionSSN[0] ? questionSSN[0].value_answer ? questionSSN[0].value_answer : null : null;
        let policy = {};

        if (product != undefined && ssn != null) {
            let insuredPerson = this.selectedRiderListService.GetPrimaryInsured();
            policy = await this.webServicesTSV.PolicyTotals(idTSVProduct, insuredPerson);
            if (policy.statusResponse) {
                let premiumData = policy.data.data;
                this.setState({
                    premiumAmount: {
                        idProduct: idTSVProduct,
                        maxPremium: premiumData.benefitMaximunFaceAmount,
                        premium: premiumData.totalAmount
                    }, loading: false
                });
            } else {
                this.setState({ loading: false });
            }
        } else {
            this.setState({ loading: false });
        }
        return policy
    }

    /**
      * Method that updates the list of riders
      */
    refreshData = () => {
        let refresh = !this.state.refresh;
        this.setState({ refresh: refresh });
    }

    /**
      * Method that updates the list of riders
      */
    refreshTypeData = () => {
        let refreshType = !this.state.refreshType;
        let refresh = !this.state.refresh;
        this.setState({ refreshType: refreshType, refresh: refresh });
    }

    /**
      * This method upload the rider method information
      */
    handleLoadRiderChange = () => {

        this.setState({ loading: true });
        this.loadRidersCategory();
    }

    /**
      * Function that updates the amount of insurance you have entered.
      */
    handleValidRange = (isValidRange) => {
        this.setState({ isValidRange: isValidRange });
    }

    /**
      * Process the call to pass to the next component
      */
    handleClickNext = async () => {
        this.setState({ loading: true });
        let listRider = this.selectedRiderListService.GetRiderSelectLocalStorage(this.props.idTSVProduct);
        let isValid = true;
        let isValidRider = this.state.riders.minimumPremium == 'Inválida' ? false : true;

        if (this.state.isValidRange && isValidRider) {
            if (listRider != null && listRider.length > 0) {
                this.setState({ emptyList: null });
                isValid = true;
                let applicationSent = await this.selectedRiderListService.SendApplicationSale();
                if (applicationSent) {
                    let currentCategory = this.jsonExchangeTSV.getCurrentSerie();
                    if (applicationSent.success) {
                        let JSONprocessFileCreate = await this.selectedRiderListService.CreateJSONProcessFile();
                        if (!JSONprocessFileCreate) {
                            this.props.handleErrorInComponent(true);
                        } else {
                            const token = this.tokenService.DecryptToken();
                            let insuranceApplication = {
                                'idTSVApplication': applicationSent.data,
                                'name': 'N/A',
                                'firstSurname': 'N/A',
                                'email': 'N/A',
                                'phone': 'N/A',
                                'comments': 'N/A',
                                'idCategory': currentCategory.id_series ? Number(currentCategory.id_series) : null,
                                'idJSONProcess': JSONprocessFileCreate.idJSONProcessFile ? JSONprocessFileCreate.idJSONProcessFile : null
                            };
                            let applicationSaved = await this.insuranceApplicationServices.AddInsuranceApp
                                (insuranceApplication, token);

                            if (!applicationSaved || !applicationSaved.statusResponse) {
                                this.props.handleErrorInComponent(true);

                            } else {
                                let selectedProduct = this.jsonExchangeTSV.getCurrentSelectedProduct();
                                this.jsonExchangeTSV.setInsuredPersons(this.selectedProductlistServices.
                                    ProcessInsuredPersonsApproved(
                                        selectedProduct.insured_persons_approved));
                                window.open(`${REACT_APP_URL_TSV_REQUEST}${insuranceApplication.idTSVApplication}`, '_blank');
                                this.setState({ loading: false, send: true, urlRedirect: `${REACT_APP_URL_TSV_REQUEST}${insuranceApplication.idTSVApplication}` });
                            }
                        }

                    } else {
                        this.props.handleErrorInComponent(true);

                    }
                } else {
                    this.props.handleErrorInComponent(true);
                }
            } else {
                isValid = false;
                this.setState({ emptyList: "*Debe seleccionar al menos un suplemento" });
            }

            this.state.riders.requiredRiderCategoriesInfo.map((rider) => {
                if (!rider.hideInDirectSale) {
                    if (rider.validationMessageIsVisible) {
                        isValid = false;
                    }
                }
            });
            if (isValid) {
                this.props.handleClickNext();
            }
        }
    }

    /**
     * Function that updates the selected product and updates it in the localstorage
     * @param {*} event select field event 
     */
    handleProduct = (event) => {
        let value = event.target.value;
        let product = this.state.productTermList.filter(product => product.idTSVProduct == value)[0];
        this.setState({ idTSVProduct: value });
        this.props.handleProductNameInfo(product.name);
        this.selectedRiderListService.UpdateSelectedProduct(product);
        this.setState({ loading: true });
        this.loadRidersCategory(value);
    }
    render() {
        const { riders, loading, noRiskError, idTSVCategory, isTerms, send, urlRedirect } = this.state;
        if (loading) {
            return (
                <div className='load'>
                    <LoadingPage />
                </div>
            );
        } else if (!loading && noRiskError) {
            return (
                <p className="not-risks-tittle">
                    Lo sentimos, en esta categor&iacute;a no es posible ofrecerle alguno de
                    nuestros productos. Lo invitamos a valorar nuestras opciones en
                    otras categor&iacute;as.
                </p>
            )
        } else {
            return (<>
                <div className="container card-body rider">

                    <p className="title-wel-sub-rider">
                        Selecciona las cubiertas que deseas agregar a tu seguro:
                    </p>
                    <Card className='shadow-sm letters div-riders'>
                        <Card.Body>
                            <Row>
                                <Col md='6'>
                                    <PaymentDetails
                                        text={'Método de Pago'}
                                        idPayment={REACT_APP_PAYMENT_TYPE}
                                        refreshData={this.refreshData}
                                        handleErrorInComponent={this.props.handleErrorInComponent}
                                        handledRider={this.handleLoadRiderChange}
                                    />
                                </Col>
                                <Col md='6'>
                                    <PaymentDetails
                                        text={'Frecuencia de Pago'}
                                        idPayment={REACT_APP_PAYMENT_MODE}
                                        refreshTypeData={this.refreshTypeData}
                                        handleErrorInComponent={this.props.handleErrorInComponent}
                                        handledRider={this.handleLoadRiderChange}
                                    />
                                </Col>
                                {isTerms ?
                                    <Col md='6'>
                                        <Card.Text className='text-left title-card'>
                                            {'Producto'}
                                        </Card.Text>
                                        <div className='select-payment'>
                                            <select className="form-control input custom-input"
                                                onChange={(event) => this.handleProduct(event)}
                                                value={this.state.idTSVProduct} >
                                                {this.state.productTermList.map((product, key) =>
                                                    <option key={key} value={product.idTSVProduct}> {product.name} </option>
                                                )}
                                            </select>
                                        </div>
                                    </Col>
                                    : null
                                }
                            </Row>
                        </Card.Body>
                    </Card>
                    <br />
                    <div className="div-riders">
                        <div >
                            <div className="rider-titles">
                                <div className="title-rider">
                                    <p> {'Cubiertas'} </p>
                                </div>
                                <div className="title-rider-min">
                                    <p>{this.state.opcionesTitle}</p>
                                </div>

                                <div className="title-rider-premium ">
                                    <p>{'Prima'}</p>
                                </div>
                            </div>
                            <div className="container">
                                {riders.requiredRiderCategoriesInfo.map((rider, index) => {
                                    if (!rider.hideInDirectSale) {
                                        let maxSlider = rider.maxFaceAmount_Slider != null ? Number(rider.maxFaceAmount_Slider) : 0;
                                        let isSlider = maxSlider > 1 ? true : false;
                                        let type = rider.faceAmountSpecification.faceAmountOptions != null &&
                                            (rider.insuredCategories != null && rider.insuredCategories.length > 1) ?
                                            'selectMultiple' :
                                            (rider.hasMultipleSpecs === true && rider.faceAmountSliderIsVisible === true && isSlider) ?
                                                'sliderMultiple' :
                                                rider.faceAmountSpecification.faceAmountOptions != null ? 'optionsSelect' :
                                                    rider.insuredCategories.length > 1 ? 'categoriesSelect' :
                                                        rider.hasMultipleSpecs === true ? 'specsSelect' :
                                                            rider.hasSolveTypes == true ? 'solverType' : 'slider';
                                                            //rider.categoryName === REACT_APP_BASIC_PLAN && rider.faceAmount < this.getNumber(amount) ? rider.faceAmountText = amount : rider.faceAmountText = rider.faceAmountText;
                                                            //rider.categoryName === REACT_APP_BASIC_PLAN && rider.faceAmount < this.getNumber(amount) ? rider.faceAmount = this.getNumber(amount) : rider.faceAmount = rider.faceAmount;
                
                                                            rider.categoryName === REACT_APP_BASIC_PLAN ? rider.minFaceAmountText = "Min: $15,000" : rider.minFaceAmountText = rider.minFaceAmountText;
                                                            rider.categoryName === REACT_APP_BASIC_PLAN ? rider.minFaceAmount_Slider = "15000" : rider.minFaceAmount_Slider = rider.minFaceAmount_Slider;
                
                                        let ref = React.createRef();
                                        return (
                                            <RiderCard type={type}
                                                key={index}
                                                id={index}
                                                rider={rider}
                                                idTSVCategory={idTSVCategory}
                                                categoryName={rider.categoryName}
                                                ref={ref}
                                                changeOptionsTitle={this.changeOptionsTitle}
                                                loadRiderCategory={this.handleLoadRiderChange}
                                                handleValidRange={this.handleValidRange}
                                                riderDetails={this.state.riderDetails}
                                                premiumAmount={this.state.premiumAmount} />
                                        )
                                    }
                                }
                                )}
                            </div>
                            <div className="text-left">
                                {this.state.emptyList != null &&
                                    <span className='error text-left'> {this.state.emptyList} </span>
                                }
                            </div>
                        </div>
                        <div className="rider-text">
                            <div className='container-amount'>
                                <p className="amount"> Monto de la prima mensual: </p>
                            </div>
                            <div className='container-rider-amount'>
                                <p className="rider-amount"> {riders.minimumPremium} </p>
                            </div>
                            <div className='container-rider-amount-premium'>
                                <p className="amount-text">
                                    {riders.minimumPremium == 'Inválida' ? '0' : this.state.premium} </p>
                            </div>
                        </div>
                    </div>
                    <div className="bottons--container">

                        <div className="button-back-rider" onClick={() => this.riderListFunction()}>
                            <svg className="svg-back" width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.9727 22.4905L1.47221 11.9476L10.9727 1.40466" stroke="#052675" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className="button-next" disabled={send} >
                       

                            <button onClick={this.handleClickNext} className="btn btn-landing stepbtn  btn-primary22 btn-buy">
                                <span className='home'><span>{'Completar Solicitud'}</span></span>
                                <div className="icon iconCard">     <svg width={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"} height={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"}
                                    className='image-svg-landing'
                                    xmlns="http://www.w3.org/2000/svg"
                                    fillRule="evenodd" clipRule="evenodd">
                                    <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
                                </svg></div>
                            </button>

                        </div>
                    </div>
                    {send ?
                        <div className="redirect">
                            <a href={urlRedirect} target="_blank" rel="TSV">Si el botón Completar Solicitud no le redirige presione AQUI</a>
                        </div>
                        : null}
                </div>
            </>
            )
        }
    }
}
export default SelectedRiderList;