import './Home.css';
import React from "react";
import { Container } from 'react-bootstrap';
import { Header } from '../../components/Header';
import { Assistant } from '../../components/Assistant';
import { Footer } from '../../components/Footer';
import { LoadingPage } from '../LoadingPage';
import { Welcome } from '../Welcome';
import { StepperForm } from '../../components/StepperForm';
import { TokenServices } from '../../Services/TokenServices';
import { CategoryServices } from '../../Services/CategoryServices';
import { JSONExchangeTSV } from '../../Services/JSONExchangeTSV';
import { LandingPage } from '../LandingPage';
import { Terms } from '../Terms/Terms';
import { Privacy } from '../Privacy';
import { SelectedRiderListS4 } from '../../components/SelectedRiderS4';
import {LocalStorageTokenServices} from "../../Services/LocalStorageTokenServices";

const life = process.env.REACT_APP_ID_LIFE;
const saving = process.env.REACT_APP_SAVING_CATEGORY;
const health = process.env.REACT_APP_HEALTH_CATEGORY;

export class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      idCategory: 0,
      action: 'welcome',
      fields: []
    };
    this.tokenService = new TokenServices();
    this.categoryServices = new CategoryServices();
    this.jsonExchangeTSV = new JSONExchangeTSV();
    this.localStorageTokenServices = new LocalStorageTokenServices();
  }

  categoryFunction = async (idCategory) => {
    await this.localStorageTokenServices.RefreshDateExpirationToken();
    await this.changeCategory(idCategory);
    this.setState({ action: "pageStepper" });
    this.setState({ idCategory }, () =>
      this.conditionalRender()
    );

  }
  async changeCategory(idCategory) {
    const token = this.tokenService.DecryptToken();
    const response = await this.categoryServices.GetCategories(token);
    if (response.statusResponse) {
      let categorys = response.data;
      let category = categorys.filter(cat => cat.idCategory === idCategory);
      let serie = {
        'id_series': `${category[0].idCategory}`,
        'name': `${category[0].name}`,
      };
      if (this.jsonExchangeTSV.isJSONinitialized()) {
        if (this.jsonExchangeTSV.getCurrentSerie().id_series != serie.id_series) {
          await this.jsonExchangeTSV.initializeJSON();
          this.jsonExchangeTSV.addPersonToInsuredPersons({ id_insured: 1, primary_insured: true });
          this.jsonExchangeTSV.setSerie(serie);
        }
      } else {
        this.jsonExchangeTSV.initializeJSON();
        this.jsonExchangeTSV.addPersonToInsuredPersons({ id_insured: 1, primary_insured: true });
        this.jsonExchangeTSV.setSerie(serie);
      }

    }
  }
  conditionalRender = () => {
    let { action } = this.state;
    let page = null;
   if (window.location.pathname === '/compra') {
      action = 'landing'
    } else if (window.location.pathname === '/compra/terminos') {
      action = 'terms'
    } else if (window.location.pathname === '/compra/privacidad') {
      action = 'privacy'
    }
    else if (window.location.pathname === '/compra/riders') {
      action = 'riders'
    }
    let landing = <div style={{ paddingBottom: "10px" }}>
      <LandingPage categoryFunction={this.categoryFunction} /> </div>;
    let terms = <div style={{ paddingBottom: "10px" }}>
      <Terms /> </div>;
    let privacy = <div style={{ paddingBottom: "10px" }}>
      <Privacy /> </div>;
    let riders = <div style={{ paddingBottom: "10px" }}>
      <SelectedRiderListS4 idTSVProduct="Freedom Term 10 Años - Ind"/> </div>;
    switch (action) {
      case 'landing':
        page = landing;
        break;
      case 'terms':
        page = terms;
        break; 
        case 'privacy':
        page = privacy;
        break;
        case 'riders':
          page = riders;
          break;
      case 'pageStepper':
        page =
          <div className='page-form'>
            <Container style={{ paddingBottom: "10px" }}>
              <StepperForm idCategory={this.state.idCategory} />
            </Container>
          </div>;
        break;
      default:
        page = landing;
    }
    return <>{page}</>;
  }

  async componentDidMount() {
    setTimeout(() => this.setState({ loading: false, }), 2000);
    await this.goPage();

  }
  async goPage() {
    if (window.location.pathname === '/compra') {
      this.setState({ action: "welcome", idCategory: 0 });
      this.setState({ idCategory: 0 }, () =>
        this.conditionalRender()
      );
    } else if (window.location.pathname === '/compra/ahorro') {
      await this.categoryFunction(Number(saving));
    } else if (window.location.pathname === '/compra/vida') {
      await this.categoryFunction(Number(life));
    }
    else if (window.location.pathname === '/compra/salud') {
      await this.categoryFunction(Number(health));
    }
    else if (window.location.pathname === '/compra/riders') {
      await this.categoryFunction(Number(health));
    }
  }
  render() {
    if (this.state.loading) {
      return (
        <div className='page'>
          <LoadingPage />
        </div>
      );
    } else {
      return (
        <div>
          <Header />
          {this.conditionalRender()}
          <Footer />
        </div>
      );
    }
  }
}