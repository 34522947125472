import React from 'react';
import './SelectedRider';
import { Col, Row } from "react-bootstrap";
import { SelectedRiderListService } from '../../Services/SelectedRiderListService';
import './SelectedRider.css';
import { JSONRider } from '../../Services/JSONRider';
import { JSONExchangeTSV } from "../../Services/JSONExchangeTSV";

const { REACT_APP_LIST_ID_PRODUCT } = process.env;

export class SelectedRider extends React.Component {

    constructor(props) {
        super(props);
        this.selectedRiderListService = new SelectedRiderListService();
        this.jsonRider = new JSONRider();
        this.jsonExchangeTSV = new JSONExchangeTSV();
        this.state = {
            value: 0,
            selectedInsuredCategory: this.props.selectedInsuredCategory,
            options: this.props.options,
            messageRange: '', isRange: true,
            isBasicPlan: this.props.isBasicPlan != undefined ? this.props.isBasicPlan : false
        };
    }

    /**
      * Method that loads selected options and updates which section to render
      */
    componentDidMount() {
        let { categoryName, type } = this.props;
        let riderCategory = this.jsonRider.getCurrentRiders();
        let { faceAmountText, selectedInsuredCategory } = '';

        const sameValue = riderCategory.filter(rider => rider.categoryName === categoryName);

        if (sameValue[0]) {
            let value = this.getValueRider();
            selectedInsuredCategory = sameValue[0].selectedInsuredCategoryPlanCodePrefix === this.props.selectedInsuredCategory ?
                sameValue[0].selectedInsuredCategoryPlanCodePrefix : this.props.selectedInsuredCategory;

            if (type === 'sliderMultiple' || type === 'availableSpecs') {
                faceAmountText = (value !== sameValue[0].selectedSpecDescription && sameValue[0].selectedSpecDescription != null) ?
                    sameValue[0].selectedSpecDescription : value;
            } else {
                if (type === 'insuredCategories') {
                    faceAmountText = sameValue[0].selectedInsuredCategoryPlanCodePrefix != value ?
                        sameValue[0].selectedInsuredCategoryPlanCodePrefix : value;
                } else {
                    faceAmountText = value !== sameValue[0].faceAmountText ? sameValue[0].faceAmountText : value;
                }
            }
            this.setState({ value: faceAmountText, selectedInsuredCategory: selectedInsuredCategory });
        } else {
            this.loadDataRider();
        }
    }

    /**
      * gets the respective value of the field of type selection.
      */
    getValueRider() {
        let options = this.getOptions();
        let isRange = this.state.isRange;
        let messageRange = '';

        if (this.props.options[0].display != undefined) {
            if (options.length == 0) {
                options.push({ 'display': '', 'value': '0' });
                isRange = false;
                messageRange = 'El monto permitido para este producto ha sobrepasado el límite';
                this.props.handleValidRange(false);
            }
        }

        this.setState({ options: options, isRange: isRange, messageRange: messageRange });
        return options[0].display != undefined ? options[0].value :
            options[0].name != undefined ? options[0].planCodePrefix :
                options[0].description != undefined ? options[0].description : null;
    }

    /**
      * Function that gets options from the supplement.
      */
    getOptions() {
        let { premiumAmount } = this.props;
        let maxPremium = premiumAmount != undefined ? premiumAmount.maxPremium : 0;
        let premium = premiumAmount != undefined ? premiumAmount.premium : 0;
        let minPremium = 0;
        let newOptions = this.props.options;

        if (Number(premium) > 0) {
            let premiumAmount = Number(maxPremium - premium);
            let idProductJSON = Number(this.jsonExchangeTSV.getCurrentSelectedProduct().id_product);
            const list = JSON.parse(REACT_APP_LIST_ID_PRODUCT);

            let product = list.filter(product => Number(product.id_product) === idProductJSON)[0];

            if (this.props.options[0].display != undefined) {
                if (product != null && this.state.isBasicPlan) {
                    newOptions = [];
                    this.state.options.map((option, index) => {
                        if (index == 0) {
                            minPremium = Number(option.value);
                        }
                        if (Number(option.value) <= premiumAmount && minPremium <= Number(option.value)) {
                            newOptions.push(option);
                        }
                    });
                }
            }
        }
        return newOptions;
    }

    /**
      * Function that updates the value of the selected rider and adds it to the list of selected categories.
      */
    loadDataRider = () => {
        let value = this.getValueRider();
        this.initialization(value);
    }

    /**
      * Function that updates the value of the selected rider and adds it to the list of selected categories.
      * @param {Int} value quantity selected in selection field
      */
    initialization = (value, change) => {
        let { categoryName, type } = this.props;
        let { selectedInsuredCategory } = this.state;
        this.setState({ value: value });
        let isSelected = type === 'sliderMultiple' ? 'select' : '';
        let request = { categoryName: categoryName, type: type, value: value, selectedInsuredCategory: selectedInsuredCategory, isSelected: isSelected };
        let rider = this.selectedRiderListService.SaveRiderCategories(request);
        if (change) {
            this.props.loadRiderCategory();
        }
    }

    /**
      * Function that updates the value of the selected rider and adds it to the list of selected categories.
      * @param {Event} event change value of selection field
      */
    handleInputChange = (event) => {
        let value = event.target.value
        this.initialization(value, true);
    }

    render() {
        return (
            <div className='text-left mb-4'>
                <Row>
                    <Col md='10'>
                        <select className="form-control input custom-input"
                            onChange={(event) => this.handleInputChange(event)}
                            value={this.state.value == null ? '': this.state.value}>

                            {this.state.options.map((option) => {
                                if (option.display != undefined) { // selected faceAmountOptions 
                                    return (
                                        <option key={option.value} value={option.value}>
                                            {option.display} </option>
                                    );
                                }
                                if (option.name != undefined) { // selected categories 
                                    return (
                                        <option key={option.planCodePrefix} value={option.planCodePrefix}>
                                            {option.name} </option>
                                    );
                                }
                                if (option.description != undefined) { // selected availableSpecs 
                                    return (
                                        <option key={option.description} value={option.description}>
                                            {option.description} </option>
                                    );
                                }
                            })
                            }
                        </select>
                        <div className="text-left">
                            {!this.state.isRange &&
                                <span className='error text-left'> {this.state.messageRange} </span>
                            }
                        </div>
                    </Col>
                    <Col md='2'>
                        <div className="premium">
                            <p className="title-premiun amount-text">SubTotal: {this.props.premium}</p>
                            <p className="value-premiun amount-text">{this.props.premium} </p>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}