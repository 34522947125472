import './MultipleChoiceCard.css';
import React from 'react';
import Select from 'react-select';
import { MultipleChoiseCardServices } from '../../Services/MultipleChoiseCardServices';
import { ModalInfo } from '../ModalInfo';

export class MultipleChoiceCard extends React.Component {

  constructor(props) {
    super(props);
    this.multipleChoiseCardServices = new MultipleChoiseCardServices();
    this.state = {
      selectedOption: this.props.selectedOption || "",
      options: this.props.options,
      action: '',
      title: '',
      description: '',
      selectedCheckboxes: [],
      errorMessage: null,
      valid: false,
      validInput: true,
      value: null,
      optionsSelect: [],
      answers: [],
      modalOptions: { show: false, title: '', description: '' },
    };
  }

  /**
   * Verifies that the nextProp property has changed, when its value is true it takes the identifier of the current component and calls nextStep to verify that the question was answered correctly.
   */
  componentDidUpdate(prevProps) {
    if (prevProps.nextProp !== this.props.nextProp) {
      if (this.props.nextProp && this.props.indexNext === this.props.index) {
        this.nextStep();
      } else if (this.props.nextProp && this.props.fieldConfig.idMainQuestion !== '') {
        if (this.props.indexNext.includes(this.props.index)) {
          this.nextStep();
        }
      }
    }
  }
  /**
   *select the select options saved in the matrix
   */
  setOptionsForSelect = () => {
    const { options, fieldConfig } = this.props;
    let optionsToIterate = [...options];
    let optionsSelect = [];
    optionsToIterate.forEach(option => {
      let optionSelect = { value: `${option.id}`, label: `${option.text}` };
      optionsSelect.push(optionSelect);
    });
    this.setState({ optionsSelect: optionsSelect, value: fieldConfig.value });
    this.props.funtionHandleSetProps(true, 0, false, fieldConfig);

  }

  /**
   * Checks if the answer of the question is stored in the local storage if so the component is rendered with that answer 
   */
  componentDidMount() {
    const { fieldConfig } = this.props;
    fieldConfig.idMainQuestion === '' ? this.setAnswers() : this.setMatrizAnswers();
  }

  /** 
   * call the method setOptionsForSelect to set the options that were saved in the localstorage
   */
  setMatrizAnswers() {
    this.setOptionsForSelect();

  }
  setAnswers() {
    const { fieldConfig, idCurrentCategory } = this.props;
    const { options, selectedCheckboxes } = this.state;
    const response = this.multipleChoiseCardServices.GetPreviousAnswerFromLocalStorage(idCurrentCategory, fieldConfig,
      options, selectedCheckboxes);
    if (response) {
      this.setState({ selectedCheckboxes: response.selectedCheckboxes });
      this.setState({ errorMessage: response.selectedCheckboxes, valid: response.valid, value: response.value });
      this.props.funtionHandleSetProps(true, response.step, false, response.fieldConfig);
    }
  }

  /**
   * Validates if the input is correct to go to the next question and save it in the local storage.
   */
  nextStep = () => {
    const { valid } = this.state;
    const { fieldConfig } = this.props;
    let response = null;
    if (fieldConfig.value.length === 0 && !fieldConfig.required) {
      response = this.multipleChoiseCardServices.NextStep(true, fieldConfig);
    } else {
      response = this.multipleChoiseCardServices.NextStep(valid, fieldConfig);
    }
    if (!response.isValid) {
      
      this.state.errorMessage = '*Debe seleccionar al menos una de las opciones';
      this.props.funtionHandleSetProps(response.isValid, 0, true, fieldConfig);
      this.setState({validInput: response.isValid});

    } else {
      this.props.funtionHandleSetProps(response.valid, response.step, response.next, response.fieldConfig);
    }
  }

  /**
   * Handles a change on a input a valid if this change is correct.
   */
  handleChangeInput = (event) => {
    let { fieldConfig } = this.props;
    const { selectedCheckboxes } = this.state;
    let response = null;
    //select
    if (event[0] != undefined) {
      response = this.multipleChoiseCardServices.HandleInputChange(event, fieldConfig, selectedCheckboxes);
      this.setState({
        errorMessage: response.errorMessage, valid: response.isValid, validInput: response.isValid, value: response.value,
        selectedCheckboxes: response.selectedCheckboxes
      });
      this.props.funtionHandleSetProps(response.isValid, false);
    } else if (event.target) {
      //checkbox
      response = this.multipleChoiseCardServices.HandleInputChange(event.target.value, fieldConfig, selectedCheckboxes);
      this.setState({
        errorMessage: response.errorMessage, valid: response.isValid, value: response.value,
        selectedCheckboxes: response.selectedCheckboxes
      });
      this.props.funtionHandleSetProps(response.isValid, false);
    } else {
      this.setState({
        errorMessage: '*Debe seleccionar al menos una de las opciones', valid: false, value: [], validInput: false,
        selectedCheckboxes: []
      });
      fieldConfig.valid = false;
      fieldConfig.value = '';
      this.props.funtionHandleSetProps(false, false);
    }
  }

  /**
   * Renders a modal with de information of each answer option.
   */
  showModal(title, description) {
    let modalOptions = { show: true, title: title, description: description };
    this.setState({ modalOptions: modalOptions });
  }

  /**
   * Close the modal with de information of each answer option.
   */
  closeModal = () => {
    let modalOptions = { show: false };
    this.setState({
      modalOptions: modalOptions
    });
  };

  render() {
    const { selectedCheckboxes, valid, validInput, errorMessage, optionsSelect, value, modalOptions } = this.state;
    const { options, fieldConfig } = this.props;
    return (
      fieldConfig.idMainQuestion === '' ?
        <>
          <div className="col-md-12">
            {options.map(option => (
              <div key={option.id} className="multiple-choise p-3 bg-white rounded checkbox-container">
                <div className="">
                <input className="ml-3" type="checkbox" onChange={(event) => this.handleChangeInput(event)}
                  value={option.id} checked={selectedCheckboxes.includes(option.id.toString())} />
                 </div>
                <label className="col-md-10 options" key={option.text}>{option.text} </label>
                <div className="col-ml-9 ml-auto info" tabIndex="0" aria-label="Más información" role="button"
                  onClick={() => this.showModal(option.text, option.description)}
                  style={{ display: option.description === '' || option.description === null ? 'none' : 'block' }}>
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12.5" cy="12.5" r="12.5" fill="#FDC740" onClick={() => this.showModal(option.text, option.description)}/>
                    <path d="M11.4863 18.2508C11.4863 17.9421 11.5995 17.6737 11.8259 17.4455C12.0523 17.2174 12.3187 17.1033 12.625 17.1033C12.9313 17.1033 13.1976 17.2174 13.424 17.4455C13.6504 17.6737 13.7636 17.9421 13.7636 18.2508C13.7636 18.5595 13.6504 18.8212 13.424 19.036C13.1976 19.2373 12.9313 19.3379 12.625 19.3379C12.3187 19.3379 12.0523 19.2373 11.8259 19.036C11.5995 18.8212 11.4863 18.5595 11.4863 18.2508ZM12.4252 15.7142C12.3719 15.0834 12.312 14.4191 12.2454 13.7212C12.1922 13.0098 12.1322 12.3119 12.0656 11.6275C12.0124 10.9296 11.9591 10.2652 11.9058 9.63441C11.8659 9.00361 11.8259 8.43992 11.786 7.94334C11.746 7.43333 11.7127 7.01727 11.6861 6.69516C11.6595 6.35963 11.6461 6.15832 11.6461 6.09121C11.6461 5.72884 11.746 5.47383 11.9458 5.3262C12.1455 5.17857 12.3719 5.10475 12.625 5.10475C12.878 5.10475 13.1044 5.17857 13.3042 5.3262C13.5039 5.47383 13.6038 5.72884 13.6038 6.09121C13.6038 6.19858 13.5772 6.58108 13.5239 7.23872C13.4707 7.89636 13.4041 8.69492 13.3242 9.63441C13.2576 10.5739 13.1777 11.5872 13.0844 12.6743C12.9912 13.748 12.9047 14.7613 12.8247 15.7142H12.4252Z" fill="#052675" />
                  </svg>
                 </div>
              </div>
            ))}
            <div className="text-left">
              {!valid && <span className='error'>{errorMessage}</span>}
            </div>
          </div>
          {modalOptions.show ?
            <ModalInfo modalOptions={modalOptions} closeModal={this.closeModal} /> :
            null}
        </>
        :
        <div className="text-left multi">
          <Select 
           components={{ CrossIcon:() => null,IndicatorSeparator:() => null}}
           className={ `basic-multi-select height-input-matrix ${ (validInput) ? '' : ' custom_select__control__invalid'}`}
            onChange={(event) => this.handleChangeInput(event)}
            closeMenuOnSelect={false}
            isMulti
            options={optionsSelect}
            classNamePrefix="custom_select"
            id='multi-matrix'
            placeholder='Selecciona'
            value={value}
          />
          <div className="text-left">
            {!valid && <span className='error'>{errorMessage}</span>}
          </div>
        </div>

    );
  }
}
export default MultipleChoiceCard;