import {  TokenServices } from "./TokenServices";
const urlBase = process.env.REACT_APP_URL_API_TSV;

export class ProductServices {

    constructor(){
        this.tokenService = new TokenServices();
    }
    /**
     * Determinates the selected products for a client.
     * @param  {Object} request The request of questions and answers.
     * @return {Promise}         A promise from the api.
     */
    GetSelectedProducts = async (request) => {
        const result = { statusResponse: false, data: null, error: null }
        await fetch(`${urlBase}Product/CalculatedSelectedProducts`, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer  ${this.tokenService.DecryptToken()}`
            }
        }).then(async response => {
            if (response.ok) { result.statusResponse = true; }
            await response.json().then(resultapi => result.data = resultapi.selectedProducts);
        }).catch(error => console.log(error));
        return result;
    }
}