import './Assistant.css';
import React from 'react';
export class Assistant extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  btnClick() {
    window.open('https://prod-tsv-use-chatbotiframe-webapp.azurewebsites.net/?chatId=jl5ERWI-iG4.wMCJwDXP7dKZ_soBRZnW_mFnPr_Oo7Ban5_EOnX0xaA', '_blank',
      "width=320, height=480, left=500, top=130");
  }

  render() {
    return (
      <div >
        <div className='btn-assistant text-center' onClick={this.btnClick}>
          <svg className='chat-assistant' viewBox="0 0 39 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5 3.8147e-06C12.5 3.8147e-06 7.49801 1.97994 4.57325 4.79603C1.64849 7.61213 0.00537872 11.4316 0.00537872 15.4141C0.00537872 19.3967 1.64849 23.2161 4.57325 26.0322C7.49801 28.8483 11.4648 30.4304 15.6011 30.4304V37C25.3484 33.246 38.9946 27.6149 38.9946 15.4141C38.9946 11.4316 37.3515 7.61213 34.4268 4.79603C31.502 1.97994 27 3.8147e-06 19.5 3.8147e-06Z" fill="#052675" />
          </svg>

          <svg className="line-assistant" viewBox="0 0 193 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 46.0564C20.6237 13.9712 96.1557 -16.7409 147.101 14.4178C177.263 32.8653 189.727 67.3738 192.176 100" stroke="white" />
          </svg>

          <div className='btn-assistant-border'>
            <div className='btn-assistant-logo'>
              <svg className="img-logo" viewBox="0 0 40 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.3146 3.8147e-06H30.464C27.1101 4.63918 25.5264 7.42268 26.9238 12.433C28.8802 19.299 25.34 22.5464 23.1973 25.1443H32.0478C34.0974 22.5464 37.7308 19.299 35.7743 12.433C34.3769 7.42268 35.9607 4.63918 39.3146 3.8147e-06Z" fill="white" />
                <path d="M16.1173 3.8147e-06H7.26676C3.91287 4.63918 2.32909 7.42268 3.72654 12.433C5.68298 19.299 2.14276 22.5464 0 25.1443H8.85054C10.9933 22.5464 14.5335 19.299 12.5771 12.433C11.0865 7.42268 12.7634 4.63918 16.1173 3.8147e-06Z" fill="white" />
                <path d="M27.67 3.8147e-06H18.8195C15.4656 4.63918 13.8818 7.42268 15.2793 12.433C17.2357 19.299 13.6955 22.5464 11.5527 25.1443H20.4033C22.546 22.5464 26.0862 19.299 24.1298 12.433C22.7324 7.42268 24.4093 4.63918 27.67 3.8147e-06Z" fill="white" />
              </svg>
            </div>
          </div>

          <br />
          <div className='btn-assistant-text'>
            <svg className="help-position" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="9.5" cy="9.5" r="9.5" fill="white" />
              <path d="M8.71094 11.5078C8.71094 10.7787 8.79948 10.1979 8.97656 9.76566C9.15365 9.33336 9.47656 8.90888 9.94531 8.49222C10.4193 8.07034 10.7344 7.7292 10.8906 7.46878C11.0469 7.20316 11.125 6.92451 11.125 6.63284C11.125 5.75263 10.7188 5.31253 9.90625 5.31253C9.52083 5.31253 9.21094 5.43232 8.97656 5.67191C8.7474 5.90628 8.6276 6.2318 8.61719 6.64847H6.35156C6.36198 5.65368 6.68229 4.87503 7.3125 4.31253C7.94792 3.75003 8.8125 3.46878 9.90625 3.46878C11.0104 3.46878 11.8672 3.73701 12.4766 4.27347C13.0859 4.80472 13.3906 5.55732 13.3906 6.53128C13.3906 6.97399 13.2917 7.39326 13.0938 7.78909C12.8958 8.17972 12.5495 8.61461 12.0547 9.09378L11.4219 9.69534C11.026 10.0756 10.7995 10.5209 10.7422 11.0313L10.7109 11.5078H8.71094ZM8.48438 13.9063C8.48438 13.5573 8.60156 13.2709 8.83594 13.0469C9.07552 12.8177 9.38021 12.7032 9.75 12.7032C10.1198 12.7032 10.4219 12.8177 10.6562 13.0469C10.8958 13.2709 11.0156 13.5573 11.0156 13.9063C11.0156 14.25 10.8984 14.5339 10.6641 14.7578C10.4349 14.9818 10.1302 15.0938 9.75 15.0938C9.36979 15.0938 9.0625 14.9818 8.82812 14.7578C8.59896 14.5339 8.48438 14.25 8.48438 13.9063Z" fill="#052675" />
            </svg>
            <label className="help-text">¿Hablamos?</label>
            <label className="help">Ayuda</label>

          </div>
        </div>
      </div>

    );
  }

}