import React, { Component } from 'react';
import './HeaderStepper.css';

export class HeaderStepper extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        
    }
    render() {
        const controls = this.props.fields;
        return (<>
            {
                controls.map((fields, index) => {
                    return (
                        <div key={index}>
                            <div key={index} className="step" data-target={"#question-" + index}>
                                <button className="step-trigger">
                                    <span className="bs-stepper-circle">{index}</span>
                                    <span className="bs-stepper-label"></span>
                                </button>
                            </div>
                            <div className="line"></div>
                        </div>
                    );
                })
            }
        </>)
    }
}