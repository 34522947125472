import { JSONExchangeTSV } from "./JSONExchangeTSV";

export class SelectedRisksServices {

    constructor() {
        this.jsonExchangeTSV = new JSONExchangeTSV();
    }

  
    /**
     * Gets the risk questions and answers from local storage and set th request object needed by the api.
     * @return {Object}         The object request needed in the api.
     */
     getSelectedRisksRequestObjectTest = () => {
        let request =   {
            "idSelectedProduct": 30,
            "riskQuestions": [
                {
                    "idQuestion": 375,
                    "IdAnswerOptionRisk": 52,
                    "valueAnswer": "50,000",
                    "baseQuestionRisk": false,
                    "subQuestionList": null
                },
                {
                    "idQuestion": 376,
                    "IdAnswerOptionRisk": null,
                    "baseQuestionRisk": true,
                    "subQuestionList": [
                        {
                            "idQuestion": 181,
                            "questionText": "Nombre",
                            "questionType": 2,
                            "baseQuestion": true,
                            "answerList": [
                                {
                                    "idAnswer": 517,
                                    "valueAnswer": "a",
                                    "idInsuredPerson": 1
                                }
                            ]
                        },
                        {
                            "idQuestion": 182,
                            "questionText": "Apellido Paterno",
                            "questionType": 2,
                            "baseQuestion": true,
                            "answerList": [
                                {
                                    "idAnswer": 518,
                                    "valueAnswer": "a",
                                    "idInsuredPerson": 1
                                }
                            ]
                        },
                        {
                            "idQuestion": 384,
                            "questionText": "Monto deseado",
                            "questionType": 10,
                            "baseQuestion": false,
                            "answerList": [
                                {
                                    "idAnswer": 57,
                                    "valueAnswer": "70,000",
                                    "idInsuredPerson": 1
                                }
                            ]
                        }
                    ]
                },
                {
                    "idQuestion": 377,
                    "IdAnswerOptionRisk": null,
                    "baseQuestionRisk": false,
                    "subQuestionList": [
                        {
                            "idQuestion": 181,
                            "questionText": "Nombre",
                            "questionType": 2,
                            "baseQuestion": true,
                            "answerList": [
                                {
                                    "idAnswer": 517,
                                    "valueAnswer": "a",
                                    "idInsuredPerson": 1
                                }
                            ]
                        },
                        {
                            "idQuestion": 182,
                            "questionText": "Apellido Paterno",
                            "questionType": 2,
                            "baseQuestion": true,
                            "answerList": [
                                {
                                    "idAnswer": 517,
                                    "valueAnswer": "a",
                                    "idInsuredPerson": 1
                                }
                            ]
                        },
                        {
                            "idQuestion": 385,
                            "questionText": "¿Ha usado?",
                            "questionType": 3,
                            "baseQuestion": false,
                            "answerList": [
                                {
                                    "idAnswer": 68,
                                    "valueAnswer": "No",
                                    "idInsuredPerson": 1
                                }
                            ]
                        }
                    ]
                },
                {
                    "idQuestion": 378,
                    "IdAnswerOptionRisk": null,
                    "baseQuestionRisk": false,
                    "subQuestionList": [
                        {
                            "idQuestion": 181,
                            "questionText": "Nombre",
                            "questionType": 2,
                            "baseQuestion": true,
                            "answerList": [
                                {
                                    "idAnswer": 517,
                                    "valueAnswer": "a",
                                    "idInsuredPerson": 1
                                }
                            ]
                        },
                        {
                            "idQuestion": 182,
                            "questionText": "Apellido Paterno",
                            "questionType": 2,
                            "baseQuestion": true,
                            "answerList": [
                                {
                                    "idAnswer": 517,
                                    "valueAnswer": "a",
                                    "idInsuredPerson": 1
                                }
                            ]
                        },
                        {
                            "idQuestion": 386,
                            "questionText": "¿Está tomando?",
                            "questionType": 3,
                            "baseQuestion": false,
                            "answerList": [
                                {
                                    "idAnswer": 71,
                                    "valueAnswer": "No",
                                    "idInsuredPerson": 1
                                }
                            ]
                        }
                    ]
                },
                {
                    "idQuestion": 379,
                    "IdAnswerOptionRisk": null,
                    "baseQuestionRisk": false,
                    "subQuestionList": [
                        {
                            "idQuestion": 181,
                            "questionText": "Nombre",
                            "questionType": 2,
                            "baseQuestion": true,
                            "answerList": [
                                {
                                    "idAnswer": 517,
                                    "valueAnswer": "a",
                                    "idInsuredPerson": 1
                                }
                            ]
                        },
                        {
                            "idQuestion": 182,
                            "questionText": "Apellido Paterno",
                            "questionType": 2,
                            "baseQuestion": true,
                            "answerList": [
                                {
                                    "idAnswer": 517,
                                    "valueAnswer": "a",
                                    "idInsuredPerson": 1
                                }
                            ]
                        },
                        {
                            "idQuestion": 387,
                            "questionText": "¿Practica?",
                            "questionType": 3,
                            "baseQuestion": false,
                            "answerList": [
                                {
                                    "idAnswer": 73,
                                    "valueAnswer": "No",
                                    "idInsuredPerson": 1
                                }
                            ]
                        }
                    ]
                },
                {
                    "idQuestion": 388,
                    "IdAnswerOptionRisk": null,
                    "baseQuestionRisk": false,
                    "subQuestionList": [
                        {
                            "idQuestion": 389,
                            "questionText": "¿Cuál es su valor de recargo?",
                            "questionType": 10,
                            "baseQuestion": false,
                            "answerList": [
                                {
                                    "idAnswer": 78,
                                    "valueAnswer": "0.5",
                                    "idInsuredPerson": 1
                                }
                            ]
                        }
                    ]
                },
                {
                    "idQuestion": 390,
                    "IdAnswerOptionRisk": null,
                    "baseQuestionRisk": false,
                    "subQuestionList": [
                        {
                            "idQuestion": 181,
                            "questionText": "Nombre",
                            "questionType": 2,
                            "baseQuestion": true,
                            "answerList": [
                                {
                                    "idAnswer": 517,
                                    "valueAnswer": "a",
                                    "idInsuredPerson": 1
                                }
                            ]
                        },
                        {
                            "idQuestion": 182,
                            "questionText": "Apellido Paterno",
                            "questionType": 2,
                            "baseQuestion": true,
                            "answerList": [
                                {
                                    "idAnswer": 517,
                                    "valueAnswer": "a",
                                    "idInsuredPerson": 1
                                }
                            ]
                        },
                        {
                            "idQuestion": 391,
                            "questionText": "¿Está dispuesto?",
                            "questionType": 3,
                            "baseQuestion": false,
                            "answerList": [
                                {
                                    "idAnswer": 81,
                                    "valueAnswer": "No",
                                    "idInsuredPerson": 1
                                }
                            ]
                        }
                    ]
                }
            ],
            "insuredPersons": [
                {
                    "idInsured": 1,
                    "primaryInsured": true,
                    "dateOfBirth": "1992-09-10T00:00:00Z",
                    "relationship": "Yo",
                    "age": 30,
                    "name": "a"
                }
            ]
        }
        return request;
    }
    /**
     * Gets the risk questions and answers from local storage and set th request object needed by the api.
     * @return {Object}         The object request needed in the api.
     */
    getSelectedRisksRequestObject = () => {
        let request = {
            idSelectedProduct: null,
            riskQuestions: null,
            insuredPersons: []
        };
        
        let riskQuestions = [];
        let jsonRiskQuestions = this.jsonExchangeTSV.getCurrentRisk().risk_questions;

        jsonRiskQuestions.forEach(riskQuestion => {
            let riskQuestionTSV = {
                idQuestion: null,
                IdAnswerOptionRisk: null,
                valueAnswer: null,
                baseQuestionRisk: null,
                subQuestionList: null,
            }
            riskQuestionTSV.idQuestion = Number(riskQuestion.id_question);
            riskQuestionTSV.IdAnswerOptionRisk = riskQuestion.id_answer ? Number(riskQuestion.id_answer) : null;
            riskQuestionTSV.valueAnswer = riskQuestion.value_answer;
            if (riskQuestion.base_question_risk === null) {
                riskQuestionTSV.baseQuestionRisk = null;
            } else {
                riskQuestionTSV.baseQuestionRisk = riskQuestion.base_question_risk;
            }
            riskQuestionTSV.subQuestionList = riskQuestion.question_list ?
                this.GetMatrixSubQuestions(riskQuestion.question_list) : null;

            riskQuestions.push(riskQuestionTSV);

        });
        request.riskQuestions = riskQuestions;
        request.idSelectedProduct = Number(this.jsonExchangeTSV.getCurrentSelectedProduct().id_product);
        let insuredPersonsArray = this.jsonExchangeTSV.getCurrentInsuredPersons();
        for (const insuredPerson of insuredPersonsArray) {
            request.insuredPersons.push({
                'idInsured': insuredPerson.id_insured ? insuredPerson.id_insured : insuredPerson.idInsured
                    ? insuredPerson.idInsured : null,
                'primaryInsured': insuredPerson.primary_insured != undefined ? insuredPerson.primary_insured :
                    insuredPerson.primaryInsured != undefined ? insuredPerson.primaryInsured : null,
                'dateOfBirth': insuredPerson.date_Of_birth ? insuredPerson.date_Of_birth :
                    insuredPerson.dateOfBirth ? insuredPerson.dateOfBirth : null,
                'relationship': insuredPerson.relationship ? insuredPerson.relationship : null,
                'age': insuredPerson.age ? insuredPerson.age : null,
                'name': insuredPerson.name ? insuredPerson.name : null
            })
        };
        return request;
    }
    /**
     * Gets the questions and answers of a risk matrix in the format of the api
     * @return {Object}         The array of questions and asnwers  needed in the api.
     */
    GetMatrixSubQuestions = (subQuestionList) => {
        let questionList = [];
        for (const subQuestion of subQuestionList) {
            let question = {
                idQuestion: null, questionText: null, questionType: null,
                baseQuestion: null, answerList: null
            };
            question.idQuestion = subQuestion.id_question ? Number(subQuestion.id_question)
                : null;
            question.questionType = subQuestion.question_type
                ? Number(subQuestion.question_type) : null;

            question.questionText = subQuestion.question_text;

            question.baseQuestion = subQuestion.question_base;


            for (const answer of subQuestion.answer_list) {
                let answerTSV = { idAnswer: null, valueAnswer: null, idInsuredPerson: null };
                if (Array.isArray(answer)) {
                    if (!question.answerList) {
                        question.answerList = [];
                    }
                    if (!answerTSV.answerValueList) {
                        answerTSV.answerValueList = [];
                    }
                    for (const answerValue of answer) {
                        answerTSV.answerValueList.push({
                            idAnswer: answerValue.id_answer ? Number(answerValue.id_answer) : null,
                            valueAnswer: answerValue.value_answer,
                            idInsuredPerson: answerValue.id_insured
                        })
                    }
                    question.answerList.push(answerTSV);
                } else {
                    if (!question.answerList) {
                        question.answerList = [];
                    }
                    answerTSV.idAnswer = answer.id_answer ? Number(answer.id_answer) : null;
                    answerTSV.valueAnswer = answer.value_answer;
                    answerTSV.idInsuredPerson = answer.id_insured;
                    question.answerList.push(answerTSV);
                }

            }
            questionList.push(question);
        }

        return questionList;
    }
}