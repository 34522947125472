
export class JSONRider {

    /**
     * This function initializes the json structure in the local storage.
     */
    initializeJSON() {
        let jsonBase = {
            selected_product: {}, 
            riders: [], 
            saving_amount: {}
        };
        localStorage.setItem("JSONRider", JSON.stringify(jsonBase));

    }

    /**
     * Determinates if the json in local storage exists.
     * @return {Boolean} True if json is iniatilized , false if not.
     */
    isJSONinitialized() {
        return localStorage.getItem("JSONRider") ? true : false;
    }

    /**
     * Gets the actual json of the local storage.
     * @return {String} The json structure with data.
    */
    getJSONRider() {
        let jsonRider = localStorage.getItem("JSONRider");
        return jsonRider;
    }


    /**
    * Sets the selected product in the local storage.
    * @param  {Object} selectedProduct The product object.
    */
    setSelectedProduct(selectedProduct) {
        let jsonRider = localStorage.getItem("JSONRider");
        if (jsonRider) {
            jsonRider = JSON.parse(jsonRider);
        }
        jsonRider.selected_product = selectedProduct;
        localStorage.setItem("JSONRider", JSON.stringify(jsonRider));

    }

    /**
     * Gets the selected product of the local storage.
     * @return {Object} The selected product of the json.
     */
    getCurrentSelectedProduct() {
        let jsonRider = localStorage.getItem("JSONRider");
        let selectedProduct = {};
        if (jsonRider) {
            jsonRider = JSON.parse(jsonRider);
        }
        if (jsonRider.selected_product) {
            selectedProduct = jsonRider.selected_product;
        }
        return selectedProduct;
    }

    /**
     * Removes the current product of the json in the local storage.
     */
    removeCurrentSelectedProduct() {
        let jsonRider = localStorage.getItem("JSONRider");
        if (jsonRider) {
            jsonRider = JSON.parse(jsonRider);
        }
        if (jsonRider.selected_product) {
            jsonRider.selected_product = {};
        }
        localStorage.setItem("JSONRider", JSON.stringify(jsonRider));
    }

    /**
     * Sets the savingAmount of the json in the local storage.
     *  @param  {Object} savingAmount The savingAmount array.
     */
    setSavingAmount(savingAmount) {
        let jsonRider = localStorage.getItem("JSONRider");
        if (jsonRider) {
            jsonRider = JSON.parse(jsonRider);
        }
        jsonRider.saving_amount = savingAmount;
        localStorage.setItem("JSONRider", JSON.stringify(jsonRider));

    }

    /**
     * Gets the current saving amount of the json of the local storage.
     *  @return  {Array}  The saving amount object.
     */
    getCurrentSavingAmount() {
        let jsonRider = localStorage.getItem("JSONRider");
        let savingAmount = {};
        if (jsonRider) {
            jsonRider = JSON.parse(jsonRider);
        }
        if (jsonRider.saving_amount) {
            savingAmount = jsonRider.saving_amount;
        }
        return savingAmount;
    }

    /**
     * Removes the current saving amount of the json in the local storage.
     */
    removeCurrentSavingAmount() {
        let jsonRider = localStorage.getItem("JSONRider");
        if (jsonRider) {
            jsonRider = JSON.parse(jsonRider);
        }
        if (jsonRider.saving_amount) {
            jsonRider.saving_amount = {};
        }
        localStorage.setItem("JSONRider", JSON.stringify(jsonRider));
    }


    /**
      * Sets the riders of the json in the local storage.
      *  @param  {Object} riders The riders array.
      */
    async setRiders(riders) {
        let jsonTsv = localStorage.getItem("JSONRider");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        jsonTsv.riders = riders;
        localStorage.setItem("JSONRider", JSON.stringify(jsonTsv));
    }

    /**
     * Gets the current riders array of the json of the local storage.
     *  @return  {Array}  The riders object.
     */
    getCurrentRiders() {
        let jsonTsv = localStorage.getItem("JSONRider");
        let riders = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        
        if (jsonTsv != null && jsonTsv.riders) {
            riders = jsonTsv.riders;
        }
        return riders;
    }

    /**
     * Removes the current riders array of the json in the local storage.
     */
    removeCurrentRiders() {
        let jsonTsv = localStorage.getItem("JSONRider");
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv.riders) {
            jsonTsv.riders = {};
        }
        localStorage.setItem("JSONRider", JSON.stringify(jsonTsv));
    }
   /**
     * Modify a rider basic plan  in the local storage.
     * @param  {Object} rider The rider object.
     */
   modifyRiderBasicPlan(rider) {
    let jsonTsv = localStorage.getItem("JSONRider");
    let riders = [];
    if (jsonTsv) {
        jsonTsv = JSON.parse(jsonTsv);
        if (jsonTsv.riders) {
            riders = jsonTsv.riders;
            riders[0].faceAmountText = Number(rider.faceAmount);
            jsonTsv.riders = riders;
        }
    }
    localStorage.setItem("JSONRider", JSON.stringify(jsonTsv));
}
    /**
     * Modify a rider of the array of riders in the local storage.
     * @param  {Object} rider The rider object.
     */
    modifyRiderOfRiders(rider) {
        let jsonTsv = localStorage.getItem("JSONRider");
        let riders = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
            if (jsonTsv.riders) {
                riders = jsonTsv.riders;

                for (let index = 0; index < riders.length; index++) {
                    if (riders[index].categoryName === rider.categoryName) {
                        if (index > -1) {
                            riders.splice(index, 1, rider);
                        }

                    }
                }
                jsonTsv.riders = riders;
            }
        }

        localStorage.setItem("JSONRider", JSON.stringify(jsonTsv));
    }

    /**
     * Removes a rider of the array of riders in the local storage.
     * @param  {Object} rider The rider object.
     */
    removeRiderOfRiders(rider) {
        let jsonTsv = localStorage.getItem("JSONRider");
        let riders = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
            if (jsonTsv.riders) {
                riders = jsonTsv.riders;

                for (let index = 0; index < riders.length; index++) {
                    if (btoa(JSON.stringify(riders[index])) === (btoa(JSON.stringify(rider)))) {
                        if (index > -1) {
                            riders.splice(index, 1);
                        }
                    }
                }
                jsonTsv.riders = riders;
            }
        }
        localStorage.setItem("JSONRider", JSON.stringify(jsonTsv));
    }

    /**
     * Adds a rider of the array of riders in the local storage.
     * @param  {Object} rider The rider object.
     */
    addRiderToRiders(rider) {
        let jsonTsv = localStorage.getItem("JSONRider");
        let riders = [];
        if (jsonTsv) {
            jsonTsv = JSON.parse(jsonTsv);
        }
        if (jsonTsv != null &&jsonTsv.riders) {
            riders = jsonTsv.riders;
        }else {
            jsonTsv = {
                "selected_product": {
                  "id_tsv_product": "",
                  "isNew": true
                },
                "riders": [
                  
                ],
                "saving_amount": {
                  "initial_deposit": "",
                  "additional_deposit": ""
                }
              } 
        }
        riders.push(rider);
        jsonTsv.riders = riders;
        localStorage.setItem("JSONRider", JSON.stringify(jsonTsv));
    }
}