import React from "react";
import './ModalContact.css';
import { TokenServices } from '../../Services/TokenServices';
import { WebServicesTSV } from '../../Services/WebServicesTSV';
import { InsuranceAplicationServices } from '../../Services/InsuranceApplicationServices';
import { CategoryServices } from '../../Services/CategoryServices';
import { ButtonTSV } from '../ButtonTSV';
import { LocalStorageTokenServices } from '../../Services/LocalStorageTokenServices';


export class ModalContact extends React.Component {
    constructor(props) {
        super(props);
        this.tokenService = new TokenServices();
        this.localStorageTokenServices = new LocalStorageTokenServices();
        this.webServicesTSV = new WebServicesTSV();
        this.InsuranceAplicationServices = new InsuranceAplicationServices();
        this.categoryServices = new CategoryServices();
        this.state = {
            products: [],
            loading: false,
            name: "", surname: "", email: "", phone: "", product: 0, comments: "",
            nameValidate: true, nameMessage: "",
            surnameValidate: true, surnameMessage: "",
            emailValidate: true, emailMessage: "",
            phoneValidate: true, phoneMessage: "",
            productValidate: true, productMessage: "",
            commentsValidate: true, commentsMessage: "",
            listCategory: [],
            showMessage: false,
            error: false,
            refresh: false

        };
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.reset && this.state.refresh) {
            this.setState({
                showMessage: false,
                error: false,
                refresh: false
            })
        }
        if (prevState.showMessage !== this.state.showMessage && !this.state.refresh) {
            this.setState({
                refresh: true
            })
        }
    }

    componentDidMount() {
        const loadCategories = async () => {
            try {
                await this.localStorageTokenServices.RefreshDateExpirationToken();
                const token = this.tokenService.DecryptToken();
                const response = await this.categoryServices.GetCategories(token);
                if (response.statusResponse)
                    this.setState({
                        listCategory: response.data
                    })
            } catch (err) {
                console.error(err);
            }
        };
        loadCategories().catch(null);
    }

    /**
     * this method handle the new product value selected by user 
     * @param {number} value, value that will save 
     */
    handleSaleSelectChange(value) {
        if (parseInt(value, 10) > 0) {
            this.setState({
                product: value,
                productValidate: true, productMessage: ''
            })
        }
    }

    /**
     * this method checks if new value for the input is ok 
     * @param {*} fieldName, fieldname of the input that did change  
     * @param {*} value, new value of the input
     */
    validateUserField(fieldName, value) {
        let nameVal = this.state.nameValidate;
        let nameMessage = '';
        let surnameVal = this.state.surnameValidate;
        let surnameMessage = '';
        let emailVal = this.state.emailValidate;
        let emailMessage = '';
        let phoneVal = this.state.phoneValidate;
        let phoneMessage = '';
        let commentsVal = this.state.commentsValidate;
        let commentsMessage = '';
        switch (fieldName) {
            case "name":
                if (value.length > 50) {
                    nameMessage = 'La cantidad digitada de caracteres no debe ser mayor que 50.';
                    nameVal = false;
                    break;
                }
                if (value.trim() == "") {
                    nameMessage = 'El nombre es requerido.';
                    nameVal = false;
                    break;
                }
                nameVal = true;
                break;
            case "surname":
                if (value.length > 30) {
                    surnameMessage = 'La cantidad digitada de caracteres no debe ser mayor que 30.';
                    surnameVal = false;
                    break;
                }
                if (value.trim() == "") {
                    surnameMessage = 'El primer apellido es requerido.';
                    surnameVal = false;
                    break;
                }
                surnameVal = true;
                break;
            case "email":
                if (value.length > 255) {
                    emailMessage = 'La cantidad digitada de caracteres no debe ser mayor que 255.';
                    emailVal = false;
                    break;
                }
                if (value.trim() === "") {
                    emailMessage = 'El correo electrónico es requerido.';
                    emailVal = false;
                    break;
                }
                if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                    emailMessage = 'El correo electrónico no es valido.';
                    emailVal = false;
                    break;
                }
                emailVal = true;
                break;
            case "phone":
                if (value.length > 10) {
                    phoneMessage = 'La cantidad digitada de caracteres no debe ser mayor que 10.';
                    phoneVal = false;
                    break;
                }
                if (value.trim() == "") {
                    phoneMessage = 'El número de teléfono es requerido.';
                    phoneVal = false;
                    break;
                }
                if (!value.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g)) {
                    phoneMessage = 'El número teléfonico no es valido.';
                    phoneVal = false;
                    break;
                }
                phoneVal = true;
                break;
            case "comments":
                if (value.length > 500) {
                    commentsMessage = 'La cantidad digitada de caracteres no debe ser mayor que 500.';
                    commentsVal = false;
                    break;
                }
                if (value.trim() == "") {
                    commentsMessage = 'El comentario es requerido.';
                    commentsVal = false;
                    break;
                }
                commentsVal = true;
                break;
            default:
                break;
        }
        this.setState({
            nameValidate: nameVal, nameMessage: nameMessage,
            surnameValidate: surnameVal, surnameMessage: surnameMessage,
            emailValidate: emailVal, emailMessage: emailMessage,
            phoneValidate: phoneVal, phoneMessage: phoneMessage,
            commentsValidate: commentsVal, commentsMessage: commentsMessage,
            [fieldName]: value
        });
    }

    /**
     * this method clear agent form data
     */
    clearData() {
        this.setState({
            name: "", surname: "", email: "", phone: "", product: 0, comments: "",
            nameValidate: true, nameMessage: "",
            phoneValidate: true, phoneMessage: "",
            surnameValidate: true, surnameMessage: "",
            emailValidate: true, emailMessage: "",
            productValidate: true, productMessage: "",
            commentsValidate: true, commentsMessage: ""
        });
    }

    /**
     * this method handle submit click of the contact form and calls TSV service
     */
    handleContactSaleConfirm = () => {
        if (this.contactValidation()) {
            const token = this.tokenService.DecryptToken();
            const contactInfo =
            {
                firstName: this.state.name,
                lastName: this.state.surname,
                email: this.state.email,
                phoneNumber: this.state.phone,
                product: this.state.product.toString(),
                message: this.state.comments
            };
            const addContactInfo = async () => {
                try {
                    const responseTSV = await this.webServicesTSV.SalesForce(contactInfo);
                    if (responseTSV.statusResponse) {
                        const info = {
                            name: contactInfo.firstName,
                            firstSurname: contactInfo.lastName,
                            email: contactInfo.email,
                            phone: contactInfo.phoneNumber,
                            idCategory: contactInfo.product * 1,
                            comments: contactInfo.message,
                            IdJSONProcess: null
                        }
                        const responseSave = await this.InsuranceAplicationServices.AddInsuranceApp(info, token);
                        if (responseSave.statusResponse) {
                            this.setState({
                                showMessage: true,
                                error: false
                            })
                            this.clearData();
                        } else {
                            this.setState({
                                showMessage: true,
                                error: true
                            })
                        }
                    } else {
                        this.setState({
                            showMessage: true,
                            error: true
                        })
                    }
                } catch (err) {
                    this.setState({
                        showMessage: true,
                        error: true
                    })
                    console.error(err);
                }
            };
            addContactInfo().catch(null);
        }
        this.clearData();
    }

    /**
     * this method checks the value of each form input
     * @returns true if all form inputs are correctly completed otherwise false
     */
    contactValidation = () => {
        if (!this.state.name.trim() == "" && this.state.name.length <= 50 && this.state.product > 0
            && !this.state.surname.trim() == "" && this.state.surname.length <= 30 && this.state.email.length <= 255
            && !this.state.email.trim() == "" && !this.state.phone.trim() == "" && !this.state.comments.trim() == "") {
            return true;
        }
        if (this.state.product <= 0) {
            this.setState({
                productValidate: false, productMessage: 'El producto es requerido.'
            });
        }
        if (this.state.name.length > 50) {
            this.setState({
                nameValidate: false, nameMessage: 'La cantidad digitada de caracteres no debe ser mayor que 50.'
            });
        }
        if (this.state.name.trim() == "") {
            this.setState({
                nameValidate: false, nameMessage: 'El nombre es requerido.'
            });
        }
        if (this.state.surname.length > 30) {
            this.setState({
                surnameValidate: false, surnameMessage: 'La cantidad digitada de caracteres no debe ser mayor que 30.'
            });
        }
        if (this.state.surname.trim() == "") {
            this.setState({
                surnameValidate: false, surnameMessage: 'El primer apellido es requerido.'
            });
        }
        if (this.state.email.length > 255) {
            this.setState({
                emailValidate: false, emailMessage: 'La cantidad digitada de caracteres no debe ser mayor que 255.'
            });
        }
        if (this.state.email.trim() == "") {
            this.setState({
                emailValidate: false, emailMessage: 'El correo electrónico es requerido.'
            });
        }

        if (this.state.phone.length > 10) {
            this.setState({
                phoneValidate: false, phoneMessage: 'La cantidad digitada de caracteres no debe ser mayor que 10.'
            });
        }
        if (this.state.phone.trim() == "") {
            this.setState({
                phoneValidate: false, phoneMessage: 'El teléfono es requerido.'
            });
        }


        if (this.state.comments.length > 500) {
            this.setState({
                commentsValidate: false, commentsMessage: 'La cantidad digitada de caracteres no debe ser mayor que 500.'
            });
        }
        if (this.state.comments.trim() == "") {
            this.setState({
                commentsValidate: false, commentsMessage: 'El comentario u observación es requerido.'
            });
        }

        return false;
    }

    /**
     * this method contains the html code of message that will be show after send the user information to web service
     * @returns html code of result message 
     */
    infoSendedMessage = () => {
        return (
            <div className='main-box-message'>
                <div className="div-thumbs" hidden={this.state.error}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                        fill="currentColor"
                        className="mr-2" viewBox="0 0 49.02 46.87">
                        <title>icon-thanks</title>
                        <path class="cls-1" d="M47.35,36.21l-21.6,5.72a.55.55,0,0,1-.18,0l-5.42,0a.67.67,0,0,1-.67-.67.66.66,0,0,1,.68-.67l5.33,0L46.79,35a3.15,3.15,0,0,0,.84-2.53,2.9,2.9,0,0,0-1.43-1.74.7.7,0,0,1-.35-.45.71.71,0,0,1,.11-.55,3.85,3.85,0,0,0,1-3.22,3.23,3.23,0,0,0-1.7-1.83.68.68,0,0,1-.39-.47.67.67,0,0,1,.14-.59,3.61,3.61,0,0,0,1.11-3,3.39,3.39,0,0,0-1.69-2,.71.71,0,0,1-.36-.45.66.66,0,0,1,.12-.56c.84-1.11,1.13-2,.88-2.72a2.9,2.9,0,0,0-1.88-1.42L31.8,16.56a2.62,2.62,0,0,1-2.32-.18,2.27,2.27,0,0,1-.94-1.53c-.18-.76-1.06-5.95-1.16-6.55-.88-4.91-3.56-5.62-3.67-5.65a3.3,3.3,0,0,0-2.23.05.79.79,0,0,0-.32.54C26.33,16.15,16.08,24.38,16,24.46a.68.68,0,0,1-1-.11.67.67,0,0,1,.12-1,17,17,0,0,0,4.7-19.81.65.65,0,0,1-.05-.31,2.19,2.19,0,0,1,.92-1.69,4.4,4.4,0,0,1,3.33-.23c.1,0,3.6.86,4.65,6.7.4,2.32,1.06,6.13,1.15,6.5a.36.36,0,0,1,0,.1h0a1,1,0,0,0,.36.61,1.61,1.61,0,0,0,1.2,0L43,12.1a1.15,1.15,0,0,1,.32,0c.1,0,2.34.53,3,2.3a3.8,3.8,0,0,1-.65,3.4,4.37,4.37,0,0,1,1.76,2.53,4.44,4.44,0,0,1-.92,3.5,4.16,4.16,0,0,1,1.73,2.29,4.66,4.66,0,0,1-.79,3.79A4,4,0,0,1,49,32.14,4.56,4.56,0,0,1,47.7,36,.7.7,0,0,1,47.35,36.21Z" transform="translate(-1 -1.13)" />
                        <path class="cls-1" d="M15.73,43.81a2.76,2.76,0,1,1,2-3.38A2.78,2.78,0,0,1,15.73,43.81Zm-1.08-4a1.42,1.42,0,1,0,1.74,1A1.42,1.42,0,0,0,14.65,39.77Z" transform="translate(-1 -1.13)" />
                        <path class="cls-1" d="M1.72,25l8.79-2.06a3.7,3.7,0,0,1,4.61,2.43l4.19,15.8a3.69,3.69,0,0,1-2.81,4.39l-.91.22,2,.81a5,5,0,0,0,3.1-5.8L16.53,25a5.1,5.1,0,0,0-6.37-3.36l-8.36,2Z" transform="translate(-1 -1.13)" /><path class="cls-1" d="M41.08,13.64l1.25-.42A22.53,22.53,0,0,0,26.05,3.15L26.5,4.5A21.25,21.25,0,0,1,41.08,13.64Z" transform="translate(-1 -1.13)" />
                        <path class="cls-1" d="M41.16,37.28A21.23,21.23,0,1,1,20.5,4.5l-.41-1.24A22.5,22.5,0,1,0,43,36.66Z" transform="translate(-1 -1.13)" />
                    </svg>
                </div>
                <div className="modal-contact__titles div-title-message" >
                    <h3 >{this.state.error ? "Lo sentimos" : "¡Gracias!"}</h3>
                </div>

                <div className="message-content">
                    <p >
                        {this.state.error ? "En este momento no podemos continuar con la solicitud. Por favor intente más tarde." :
                            "Gracias por ponerte en contacto con Triple-S Vida, muy pronto uno de nuestros representantes estará dando respuesta a tu solicitud."}
                    </p>

                </div>
            </div>
        )
    }

    render() {
        return (
            <div id='root-modal-contact' >
                <div className="div-modal-contact">
                    <div className="modal-dialog modal-contact" tabIndex="100" role="dialog">
                        <div onClick={e => {
                            // do not close modal if anything inside modal content is clicked
                            e.stopPropagation();
                        }}>
                            <div className="modal-content modal-content__border">
                                {!this.state.showMessage ? <>
                                    <div style={{ width: '90%' }} className="modal-contact__titles" >
                                        <div className="modal-contact__title">Estoy indeciso, prefiero ser conectado directamente</div>
                                        <div className='modal-contact__subtitle'>
                                            Completa tú información para comunicarnos contigo y orientarte
                                        </div>
                                    </div>
                                    <div className="modal-body modal-body-contact " style={{ width: '90%' }} className="modal-contact__titles" >
                                        <form>
                                            <div className="form-group form-group-contact">
                                                <label htmlFor="name" className="col-form-label"></label>
                                                <input
                                                    className={`form-control modal-contact__input ${(!this.state.nameValidate) ? (' is-invalid-input') : ('')}`}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    name="name"
                                                    id="name"
                                                    value={this.state.name}
                                                    maxLength="50"
                                                    autoComplete='off'
                                                    placeholder='Nombre'
                                                    onChange={(event) => this.validateUserField(event.target.name, event.target.value)}
                                                />
                                                <div className="text-left">
                                                    {!this.state.nameValidate &&
                                                        <span className='error'>
                                                            {this.state.nameMessage}
                                                        </span>
                                                    }

                                                </div>
                                            </div>
                                            <div className="form-group form-group-contact">
                                                <label htmlFor="surname" className="col-form-label"></label>
                                                <input
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    name="surname"
                                                    id="surname"
                                                    placeholder='Apellido'
                                                    value={this.state.surname}
                                                    maxLength="30"
                                                    autoComplete='off'
                                                    onChange={(event) => this.validateUserField(event.target.name, event.target.value)}
                                                    className={`form-control modal-contact__input ${(!this.state.surnameValidate) ? (' is-invalid-input') : ('')}`}
                                                />
                                                <div className="text-left">
                                                    {!this.state.surnameValidate &&
                                                        <span className='error'>
                                                            {this.state.surnameMessage}
                                                        </span>

                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group form-group-contact">
                                                <label htmlFor="phone" className="col-form-label"></label>
                                                <input
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    name="phone"
                                                    id="phone"
                                                    placeholder='Teléfono'
                                                    autoComplete='off'
                                                    value={this.state.phone}
                                                    onChange={(event) => this.validateUserField(event.target.name, event.target.value)}
                                                    maxLength="10"
                                                    className={`form-control modal-contact__input ${(!this.state.phoneValidate) ? (' is-invalid-input') : ('')}`} />
                                                <div className="text-left">
                                                    {!this.state.phoneValidate &&
                                                        <span className='error'>
                                                            {this.state.phoneMessage}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group form-group-contact">
                                                <label htmlFor="email" className="col-form-label"></label>
                                                <input
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    name="email"
                                                    id="email"
                                                    placeholder='Email'
                                                    autoComplete='off'
                                                    value={this.state.email}
                                                    onChange={(event) => this.validateUserField(event.target.name, event.target.value)}
                                                    maxLength="255"
                                                    className={`form-control modal-contact__input ${(!this.state.emailValidate) ? (' is-invalid-input') : ('')}`} />
                                                <div className="text-left">
                                                    {!this.state.emailValidate &&
                                                        <span className='error'>
                                                            {this.state.emailMessage}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group form-group-contact">
                                                <label htmlFor="products" className="col-form-label"></label>
                                                <select
                                                    className={`form-control modal-contact__input custom-select-matrix  ${(!this.state.productValidate) ? (' is-invalid-input') : ('')}`}
                                                    value={this.state.product}
                                                    name='products'
                                                    autoComplete='off'
                                                    id='products'
                                                    placeholder="¿Interesado en?"
                                                    onChange={(event) => this.handleSaleSelectChange(event.target.value)}>
                                                    <option value={0} defaultValue={true}>¿Interesado en?</option>
                                                    {
                                                        this.state.listCategory &&
                                                        this.state.listCategory.map((h, i) =>
                                                            (<option key={i} value={h.idCategory}>{h.name}</option>))
                                                    }
                                                </select>
                                                <div className="text-left">
                                                    {!this.state.productValidate &&
                                                        <span className='error'>
                                                            {this.state.productMessage}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group form-group-contact">
                                                <label htmlFor="comments" className="col-form-label"></label>
                                                <textarea
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    name="comments"
                                                    id="comments"
                                                    autoComplete='off'
                                                    value={this.state.comments}
                                                    maxLength="500"
                                                    rows='2' wrap="soft"
                                                    placeholder="Comentarios"
                                                    onChange={(event) => this.validateUserField(event.target.name, event.target.value)}
                                                    className={`form-control modal-contact__input ${(!this.state.commentsValidate) ? (' is-invalid-input') : ('')}`}
                                                />
                                                <div className="text-left">
                                                    {!this.state.commentsValidate &&
                                                        <span className='error'>
                                                            {this.state.commentsMessage}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                        <div className="modal-contact__action">
                                            <svg className="svg-separator" width="153" height="2" viewBox="0 0 153 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M152 1.5C152.276 1.5 152.5 1.27614 152.5 1C152.5 0.723858 152.276 0.5 152 0.5V1.5ZM1 0.5C0.723858 0.5 0.5 0.723858 0.5 1C0.5 1.27614 0.723858 1.5 1 1.5V0.5ZM1 1.5H2.98684V0.5H1V1.5ZM6.96053 1.5H10.9342V0.5H6.96053V1.5ZM14.9079 1.5H18.8816V0.5H14.9079V1.5ZM22.8553 1.5H26.829V0.5H22.8553V1.5ZM30.8026 1.5H34.7763V0.5H30.8026V1.5ZM38.75 1.5H42.7237V0.5H38.75V1.5ZM46.6974 1.5H50.6711V0.5H46.6974V1.5ZM54.6447 1.5H58.6184V0.5H54.6447V1.5ZM62.5921 1.5H66.5658V0.5H62.5921V1.5ZM70.5395 1.5H74.5132V0.5H70.5395V1.5ZM78.4869 1.5H82.4605V0.5H78.4869V1.5ZM86.4342 1.5H90.4079V0.5H86.4342V1.5ZM94.3816 1.5H98.3553V0.5H94.3816V1.5ZM102.329 1.5H106.303V0.5H102.329V1.5ZM110.276 1.5H114.25V0.5H110.276V1.5ZM118.224 1.5H122.197V0.5H118.224V1.5ZM126.171 1.5H130.145V0.5H126.171V1.5ZM134.118 1.5H138.092V0.5H134.118V1.5ZM142.066 1.5H146.039V0.5H142.066V1.5ZM150.013 1.5H152V0.5H150.013V1.5Z" fill="#052675" />
                                            </svg>
                                            <div className="btn-contact">
                                                <ButtonTSV text={'Enviar'} colorbBtn='btn-text-contact'
                                                    function={this.handleContactSaleConfirm}
                                                />
                                                <div className='btn-image-action text-center'>
                                                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.23633 12.9042L6.61954 6.9316L1.23633 0.958984" stroke="#052675" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="position-sticky-contact">
                                                <div className="help-sticky"    >
                                                    <label className="help-sticky__text">Aún no me decido, contáctenme</label>
                                                    <div className="help-sticky__circle">
                                                        <svg className="help-sticky__circle_vector" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.3266 9.37737C10.5354 8.70066 9.73244 8.29075 8.95096 8.96644L8.48431 9.37482C8.14289 9.67126 7.50809 11.0564 5.05375 8.233C2.59991 5.41321 4.06015 4.97416 4.40208 4.68028L4.87128 4.27139C5.64868 3.59417 5.3553 2.74164 4.79461 1.86406L4.45626 1.33251C3.89302 0.456976 3.27968 -0.118022 2.50024 0.558176L2.07909 0.926175C1.7346 1.17713 0.771669 1.99286 0.538092 3.54255C0.256982 5.40196 1.14376 7.53124 3.17542 9.86753C5.20452 12.2048 7.19121 13.3788 9.07311 13.3584C10.6371 13.3415 11.5816 12.5023 11.8771 12.1972L12.2997 11.8287C13.0771 11.153 12.5941 10.465 11.8024 9.78677L11.3266 9.37737Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </> : this.infoSendedMessage()}
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        )
    }

}
export default ModalContact;