const REACT_APP_URL_API_TSV_QUOTE = process.env.REACT_APP_URL_API_TSV_QUOTE;
const { REACT_APP_CLIENTID_TSV, REACT_APP_CLIENTSECRET_TSV } = process.env

export class RatesServiceTSV {
    constructor() {
    }

    /**
      *  Method in charge of consulting the amount of the premium and supplements to the api tsv
      *  @param  {Object} riders Information on products, supplements and family group
      *  @return {Promise} promise The promise from the api.
      */
    QuoteRiders = async (riders, token) => {
        let myHeaders = new Headers();
        myHeaders.append("Accept", "*/*");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("clientId", REACT_APP_CLIENTID_TSV);
        myHeaders.append("clientSecret", REACT_APP_CLIENTSECRET_TSV);
        let body = JSON.stringify(riders);

        const result = { statusResponse: false, data: null, error: null }

        await fetch(`${REACT_APP_URL_API_TSV_QUOTE}QuoteRates`, {
            method: 'POST',
            body: body,
            headers: myHeaders
        }).then(async response => {
            if (response.status == 500) {
                result.error = true;
            }
            if (response.ok) {
                result.statusResponse = true;
                await response.json().then(resultapi => result.data = resultapi);
            }
        }).catch(error => {
            result.error = error;
        })
        return result;
    }

    /**
      *  Method in charge of obtaining the access token to the tsv api
      *  @return {Promise} promise The promise from the api.
      */
    Login = async () => {
        const result = { statusResponse: false, data: null, error: null }
        await fetch(`${REACT_APP_URL_API_TSV_QUOTE}Login`, {
            method: 'POST',
            Headers: {
                'Accept': "*/*",
                'Content-Type': 'application/json',
                'clientId': REACT_APP_CLIENTID_TSV,
                'clientSecret': REACT_APP_CLIENTSECRET_TSV
            }
        }).then(async response => {
            if (response.ok) { result.statusResponse = true; }
            await response.json().then(resultapi => result.data = resultapi);
        }).catch(error => console.log(error));
        return result;
    }
}