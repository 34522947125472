import './ButtonTSV.css';
import React from 'react';

export class ButtonTSV extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.btnClick = this.btnClick.bind(this);

  }

  componentDidMount() {
  }

  btnClick() {
    if (this.props.function) {
      this.props.function();
    }
  }

  render() {
    return (
      <div onClick={this.btnClick} className="container-fluid h-100">
        <div className="row w-100 align-items-center">
          <div className="col text-center col-left">
            <div>
              <div>
                <button className={`btn btn-sm  ${this.props.colorbBtn} ${this.props.productButton? 'btn-primary': 'btn-primary2'}  `}>
                  {  this.props.colorbBtn === 'btn-white' ?
                  <div className="text-center text-button text-btn-white"> {this.props.text}</div>
                  :
                 <span><span className="text-capitalize span-t"> {this.props.text}</span> </span> 
                  }
                
                </button >
                <span className={this.props.colorbBtn === 'btn-text' ? "btn-image" :
                  this.props.colorbBtn === 'btn-white' ? 'btn-image-white' :
                    this.props.colorbBtn === 'btn-text-product' ? "btn-image-product" : "btn-image-contact"}>
                  {this.props.customClass === 'plus' ?
                    <svg width="28" height="28"
                      className='image-svg-insurance bi bi-plus'
                      fillRule="evenodd" clipRule="evenodd" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    : this.props.customClass === 'white' ?
                      <svg width="21" height="24" viewBox="0 0 21 24" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="10.5" cy="13.3398" r="10.5" transform="rotate(-90 10.5 13.3398)" fill="#052675" />
                        <path d="M11.6398 12.5029H16.0655V13.9854H11.6398V18.5615H10.1466V13.9854H5.7423V12.5029H10.1466V7.90527H11.6398V12.5029Z" fill="white" />
                      </svg>
                      :
                      <svg width={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"} height={this.props.colorbBtn !== 'btn-text-contact' ? "24" : "15"}
                        className='image-svg'
                        xmlns="http://www.w3.org/2000/svg"
                        fillRule="evenodd" clipRule="evenodd">
                        <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
                      </svg>
                  }
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}