import React from 'react';
import { YesNoQuestionCard } from '../../components/YesNoQuestionCard';
import { MultipleChoiceCard } from '../MultipleChoiceCard';
import { LongAnswerCard } from '../LongAnswerCard';
import { DateCard } from '../DateCard';
import { NumericalRangeCard } from '../NumericalRangeCard';
import { UniqueSelectionCard } from '../UniqueSelectionCard';
import { HiddenQuestion } from '../CardType';

const uniqueSelection = process.env.REACT_APP_UNIQUE_SELECTION;
const longAnswer = process.env.REACT_APP_LONG_ANSWER;
const yesNo = process.env.REACT_APP_YES_NO;
const range = process.env.REACT_APP_RANGE;
const multichoice = process.env.REACT_APP_MULTICHOICE;
const date = process.env.REACT_APP_DATE;
const date_calculation = process.env.REACT_APP_DATE_CALCULATION;

export class Field extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: ['field'],
      now: new Date(),
      errorMessage: null
    };
  }
  componentDidMount() {
    const { fieldConfig } = this.props;
    const { classes } = this.state;
    if (fieldConfig.touched && !fieldConfig.valid) {
      classes.push('invalid');
    }
  }
  render() {
    const { funtionHandleSetProps, handleErrorInComponent, nextProp, indexNext, parentRef, index, idCurrentCategory, fieldConfig } = this.props;
    const { classes } = this.state;
    let element = null;
    switch (fieldConfig.type) {
      case (yesNo):
        element = (
          <YesNoQuestionCard title=''
            index={index}
            className={classes.join(' ')}
            body={fieldConfig.body}
            options={fieldConfig.options}
            id={fieldConfig.id}
            nextProp={nextProp}
            indexNext={indexNext}
            fieldConfig={fieldConfig}
            funtionHandleSetProps={funtionHandleSetProps}
            ref={parentRef}
            idCurrentCategory={idCurrentCategory}
          />
        );
        break;
      case (multichoice):
        element = (
          <MultipleChoiceCard
            index={index}
            className={classes.join(' ')}
            required={fieldConfig.required}
            fieldConfig={fieldConfig}
            title=''
            nextProp={nextProp}
            indexNext={indexNext}
            body={fieldConfig.body}
            name={fieldConfig.id}
            id={fieldConfig.id}
            options={fieldConfig.options}
            funtionHandleSetProps={funtionHandleSetProps}
            key={JSON.stringify(fieldConfig.id)}
            ref={parentRef}
            idCurrentCategory={idCurrentCategory}
          />
        );
        break;
      case (longAnswer):
        element = (
          <LongAnswerCard
            index={index}
            className={classes.join(' ')}
            required={fieldConfig.required}
            title=''
            disabledInput = {this.props.disabledInput}
            nextProp={nextProp}
            indexNext={indexNext}
            fieldConfig={fieldConfig}
            body={fieldConfig.body}
            name={fieldConfig.id}
            id={fieldConfig.id}
            funtionHandleSetProps={funtionHandleSetProps}
            key={JSON.stringify(fieldConfig.id)}
            ref={parentRef}
            idCurrentCategory={idCurrentCategory}
          />
        );
        break;
      case (date):
        element = (
          <DateCard
            index={index}
            className='date-field'
            required={fieldConfig.required}
            title=''
            nextProp={nextProp}
            indexNext={indexNext}
            body={fieldConfig.body}
            name={fieldConfig.id}
            id={fieldConfig.id}
            funtionHandleSetProps={funtionHandleSetProps}
            key={JSON.stringify(fieldConfig.id)}
            ref={parentRef}
            fieldConfig={fieldConfig}
            idCurrentCategory={idCurrentCategory}
          />
        );
        break;
      case (date_calculation):
        element = (
          <DateCard
            index={index}
            className='date-field'
            required={fieldConfig.required}
            title=''
            nextProp={nextProp}
            indexNext={indexNext}
            body={fieldConfig.body}
            name={fieldConfig.id}
            id={fieldConfig.id}
            funtionHandleSetProps={funtionHandleSetProps}
            handleErrorInComponent={handleErrorInComponent}
            key={JSON.stringify(fieldConfig.id)}
            ref={parentRef}
            fieldConfig={fieldConfig}
            idCurrentCategory={idCurrentCategory}
          />
        );
        break;
      case (uniqueSelection):
        element = (
          <UniqueSelectionCard
            index={index}
            className={classes.join(' ')}
            required={fieldConfig.required}
            title=''
            body={fieldConfig.body}
            name={fieldConfig.id}
            id={fieldConfig.id}
            options={fieldConfig.options}
            key={JSON.stringify(fieldConfig.id)}
            fieldConfig={fieldConfig}
            ref={parentRef}
            nextProp={nextProp}
            indexNext={indexNext}
            funtionHandleSetProps={funtionHandleSetProps}
            idCurrentCategory={idCurrentCategory}
          />
        );
        break;
      case (range):
        element = (
          <div>
            <NumericalRangeCard
              index={index}
              className={classes.join(' ')}
              fieldConfig={fieldConfig}
              id={fieldConfig.id}
              funtionHandleSetProps={funtionHandleSetProps}
              key={JSON.stringify(fieldConfig.id)}
              ref={parentRef}
              nextProp={nextProp}
              indexNext={indexNext}
              idCurrentCategory={idCurrentCategory}
            />
          </div>
        );
        break;
      default:
        element = null;
        break;
    }
    return (
      <div>
        {element}
      </div>
    )
  }

}