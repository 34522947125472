import { JSONExchangeTSV } from "./JSONExchangeTSV";
import { TokenServices } from "./TokenServices";
const urlBase = process.env.REACT_APP_URL_API_TSV;

export class RiskServices {

    constructor() {
        this.tokenService = new TokenServices();
        this.jsonExchangeTSV = new JSONExchangeTSV();
    }
    /**
     * Determinates the selected risk for a client.
     * @param  {Object} request The request of questions and answers.
     * @return {Promise}         A promise from the api.
     */
    GetSelectedRisks = async (request) => {
        const result = { statusResponse: false, data: null, error: null }
        await fetch(`${urlBase}Risk/CalculatedSelectedrisks`, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer  ${this.tokenService.DecryptToken()}`
            }
        }).then(async response => {
            if (response.ok) { result.statusResponse = true; }
            await response.json().then(resultapi => result.data = resultapi);
        }).catch(error => console.log(error));
        return result;
    }

    /**
     * Process and save the selected risk from the api in the local storage.
     * @param  {Array} selectedRisks The selected risks from the api.
     */
    SaveCalculatedRisksInLocalStorage = (selectedRisks) => {
      
        let selectedRisksTSV = [];
        for (const selectedRisk of selectedRisks) {
            selectedRisksTSV.push(
                {
                    'id_risk': selectedRisk.risk.idRisk ? selectedRisk.risk.idRisk : null,
                    'risk_name': selectedRisk.risk.name ? selectedRisk.risk.name : null,
                    'id_tsv_risk': selectedRisk.risk.idTSVRisk ? selectedRisk.risk.idTSVRisk : null,
                    'id_insured': selectedRisk.insuredPerson.idInsured ?
                        selectedRisk.insuredPerson.idInsured : null
                }
            )
        };
        this.jsonExchangeTSV.setInsuredRisks(selectedRisksTSV);

    }
}