import { TokenServices } from "./TokenServices";

const urlBase = process.env.REACT_APP_URL_API_TSV;
const matrix = process.env.REACT_APP_MATRIX;
const mainMatrix = process.env.REACT_APP_MAIN_MATRIX;
const classSelectProduct = process.env.REACT_APP_SELECT_PRODUCT;
const classSelectRisk  = process.env.REACT_APP_SELECT_RISK;

export class QuestionServices {
  constructor() {
    this.tokenServices = new TokenServices();
    this.token = this.tokenServices.DecryptToken();
  }
  /**
   *  Gets the question of a category from the api.
   *  @param  {number} idCategory The numerical id of the category.
   *  @return  {Promise} promise The promise from the api.
   */
  GetQuestionByIdCategory = async (idCategory) => {
    const result = { statusResponse: false, data: null, error: null }
    await fetch(`${urlBase}Question/GetJsonField`, {
      method: 'POST',
      body: JSON.stringify(idCategory),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${this.token}`
      }
    }).then(async response => {
      if (response.ok) { result.statusResponse = true; }
      await response.json().then(resultapi => result.data = resultapi.list);
    })
      .catch(error =>

        console.log(error)

      );
    return result;
  }

  /**
 *  Gets the question of a category and a product from the api.
 * @param  {number} idCategory The numerical id of the category.
 * @param  {number} idProduct The numerical id of the product.
 *  @return  {Promise} promise The promise from the api.
 */
  GetQuestionByIdCategoryAndIdProduct = async (idCategory, idProduct) => {
    const result = { statusResponse: false, data: null, error: null }
    await fetch(`${urlBase}Question/GetJsonRiskField`, {
      method: 'POST',
      body: JSON.stringify({ 'idCategory': idCategory, 'idProduct': idProduct }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${this.token}`
      }
    }).then(async response => {
      if (response.ok) { result.statusResponse = true; }
      await response.json().then(resultapi => result.data = resultapi.list);
    })
      .catch(error =>

        console.log(error)

      );
    return result;
  }
  /**
   *  Gets the sub quesitons of a main question from the api.
   *  @param  {number} idMainQuestion The numerical id of the main question.
   *  @return  {Promise} promise The promise from the api.
   */
  GetAllSubQuestionsByIdMainQuestion = async (idMainQuestion) => {
    const result = { statusResponse: false, data: null, error: null }
    await fetch(`${urlBase}Question/GetAllSubQuestionsByIdMainQuestion`, {
      method: 'POST',
      body: JSON.stringify(idMainQuestion),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }
    }).then(async response => {
      if (response.ok) { result.statusResponse = true; }
      await response.json().then(resultapi => result.data = resultapi.list);
    })
      .catch(error => console.log(error));
    return result;
  }

  /**
   *  Gets the  quesitons for the selection of product of the category from the api.
   *  @param  {number} idCategory The numerical id of the category.
   *  @return  {Promise} promise The promise from the api.
   */
  GetDataFields = async (idCategory) => {
    let response = await this.GetQuestionByIdCategory(idCategory);
    let idMainQuestion = '';
    const result = { statusResponse: response.statusResponse, fields: null }
    if (response.statusResponse) {
      result.fields = response.data;
      try {
        let main_questions = result.fields;
        let main_questions_temp = result.fields;
        main_questions_temp.forEach(main_question => {
          main_question.questions = [];
          if (main_question.type === matrix || main_question.type === mainMatrix) {
            idMainQuestion = main_question.id;
            main_questions_temp.forEach((question) => {
              if (idMainQuestion === question.idMainQuestion) {
                main_question.questions.push(question);
                main_question.options = question.options[0];
              }
            });
            let questionsChildren = [{ children: main_question.questions }];
            main_question.questions = Object.assign(questionsChildren);
            main_question.mainMatriz = main_question.type === mainMatrix;
          }
        });

        let questions = main_questions;
        main_questions_temp.forEach(main_question => {
          if (main_question.idMainQuestion !== '') {
            questions = questions.filter(question => question.idMainQuestion !== main_question.idMainQuestion);
          }
        });

        questions.push({
          "type": 'Seleccion Producto', "key": questions.length, "back": '',
          process: false, "questionNumber": questions.length + 1, "step": questions.length + 2,
          idQuestionClass: classSelectProduct
        })
        result.fields = questions;
      } catch (error) {
        result.statusResponse = false;
      }
      return result;
    }
    return result;
  }

  /**
   *  Gets the  quesitons for the selection of risk of the category and the product from the api.
   *  @param  {number} idProduct The numerical id of the product.
   *  @param  {number} idCategory The numerical id of the category.

   *  @return  {Promise} promise The promise from the api.
   */
   GetRiskDataFields = async (idProduct,idCategory) => {
    let response = await this.GetQuestionByIdCategoryAndIdProduct(idCategory, idProduct);
    let idMainQuestion = '';
    const result = { statusResponse: response.statusResponse, fields: null }
    if (response.statusResponse) {
      result.fields = response.data;
      try {
        let main_questions = result.fields;
        let main_questions_temp = result.fields;
        main_questions_temp.forEach(main_question => {
          main_question.questions = [];
          if (main_question.type === matrix || main_question.type === mainMatrix) {
            idMainQuestion = main_question.id;
            main_questions_temp.forEach((question) => {
              if (idMainQuestion === question.idMainQuestion) {
                main_question.questions.push(question);
                main_question.options = question.options[0];
              }
            });
            let questionsChildren = [{ children: main_question.questions }];
                questionsChildren.isRisk = true;
            main_question.questions = Object.assign(questionsChildren);
            main_question.mainMatriz = main_question.type === mainMatrix;
          }
        });

        let questions = main_questions;
        main_questions_temp.forEach(main_question => {
          if (main_question.idMainQuestion !== '') {
            questions = questions.filter(question => question.idMainQuestion !== main_question.idMainQuestion);

          }
        });

        questions.push({
          "type": 'Seleccion Riesgo', "key": questions.length, "back": '',
          process: false, "questionNumber": questions.length + 1, "step": questions.length + 2,
          idQuestionClass: classSelectRisk
        })
        result.fields = questions;
      } catch (error) {
        result.statusResponse = false;
      }
      return result;
    }
    return result;
  }

}


