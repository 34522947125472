import { JSONExchangeTSV } from "./JSONExchangeTSV";
const selectProductQuestion = process.env.REACT_APP_SELECT_PRODUCT;

export class NumericalRangeCardServices {

    constructor() {
        this.jsonExchangeTSV = new JSONExchangeTSV();

    }
    /**
     * Set properties and values needed before the component is rendered.
     * @param  {Object} fieldConfig The question of the current component.
     * @return {Object} The properies object needed by the component.
    */
    SetPropertiesBeforeRender = (fieldConfig) => {
        let rangeOpValues = [];
        let response = {
            minValue: null,
            maxVal: null,
            optionValues: null
        }
        if (fieldConfig.options !== null && fieldConfig.options.length > 0) {
            fieldConfig.options.forEach(element => {
                rangeOpValues.push(element.option * 1); //convert to number
                rangeOpValues.push(element.option2 * 1); // convert to number 
            });
            rangeOpValues.sort(function (a, b) {
                return a - b;
            });
            response.minValue = rangeOpValues[0];
            response.maxVal = rangeOpValues[rangeOpValues.length - 1];
            response.optionValues = fieldConfig.options

            return response;
        }
    }
    /**
     * Gets the previous question with his answer from local storage, answered by the user.
     * @param  {Number} idCurrentCategory The numerical id of the current category.
     * @param  {Object} fieldConfig The question of the current component.
     * @return {Object} The question with his answer object if exist, if not then returns false.
    */
    GetPreviousAnswerFromLocalStorage = (idCurrentCategory, fieldConfig) => {
        let response = {
            validRangeOne: null,
            errorMessageRangeOne: null,
            valueRangeOne: null,
            fieldConfig: null,
            step: null
        }
        if(fieldConfig.idQuestionClass == '3'){
            let valueAnswerProduct = this.jsonExchangeTSV.getQuestionOfProductQuestionsByIdTSV(fieldConfig.idTSVField);
            if(valueAnswerProduct !== null && valueAnswerProduct !== undefined){
                fieldConfig.value = valueAnswerProduct.value_answer;
                let step = fieldConfig.options[0].step;
                fieldConfig.valid = true;
                fieldConfig.disabled = true;
                response.validRangeOne = true;
                response.errorMessageRangeOne = '';
                response.valueRangeOne = fieldConfig.value;
                response.step = step;
                response.fieldConfig = fieldConfig;
                return response;
            }else{
                return response;
            }
        }else
        if (this.IsCurrentCategory(idCurrentCategory)) {
            if (this.GetLastAnswerSaved(fieldConfig) != null) {
                let localStorageAnswer = this.GetLastAnswerSaved(fieldConfig);
                fieldConfig.value = localStorageAnswer.value_answer;
                let chosenAnswerOption = fieldConfig.options.filter(option => option.id === Number(localStorageAnswer.id_answer));
                let step = chosenAnswerOption[0] ? chosenAnswerOption[0].step : 0;
                fieldConfig.valid = true;

                response.validRangeOne = true;
                response.errorMessageRangeOne = '';
                response.valueRangeOne = fieldConfig.value;
                response.step = step;
                response.fieldConfig = fieldConfig;

                return response;
            }
            return false;
        }
        return false;
    }

    /**
     * Handles a change on a input a valid if this change is correct.
     * @param  {Object} event The event when the input change.
     * @param {Object}  field The question object used in the component.
     * @param {Boolean} validRangeOne The boolean value if the first number input is valid.
     * @param {Number}  minVal The minimum value for the input.
     * @param {Number}  maxVal The maximum value for the input.
     * @return {Object} The response object to set props and state in the component.
     */
    HandleInputChange = (event, field, validRangeOne, minVal, maxVal) => {
        field.value = event.target.value;
        const response = this.CheckValidity(field, validRangeOne, minVal, maxVal);
        return response;
    }

    /**
     * Validates if the value of the input is correct.
     * @param {Object}  field The question object used in the component.
     * @param {Boolean} validRangeOne The boolean value if the first number input is valid.
     * @param {Number}  minVal The minimum value for the input.
     * @param {Number}  maxVal The maximum value for the input.
     * @return {Object} The response object to set props and state in the component.
     */
    CheckValidity = (field, validRangeOne, minVal, maxVal) => {
        const pattern = /^[0-9\b]+$/;
        let errorMessageRangeOne = "";
        let response = {
            valueRangeOne: null,
            errorMessageRangeOne: null,
            validRangeOne: null,
            fieldConfig: null

        };
        let value = field.value !== null ? field.value.replaceAll(',', '') : ''; // value without format to do validation
        if (value.trim() == '' && field.required) {
            errorMessageRangeOne = '*Campo es requerido.';
            validRangeOne = false;
        } else if (!field.required && value.trim() == '') {
            validRangeOne = true;

        }
        else if (!value.trim() == '' && !pattern.test(value)) {
            errorMessageRangeOne = '*Este campo solo permite valores númericos enteros.';
            validRangeOne = false;
        }
        else {
            if (!value.trim() == '' && Number(value) >= 0) {
                if (Number(value) < minVal || Number(value) > maxVal) {
                    let min = Number(minVal).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                    let max = Number(maxVal).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                    errorMessageRangeOne = `*El valor debe estar entre ${min.substring(0, min.indexOf('.'))} y  
                            ${max.substring(0, max.indexOf('.'))}`;
                    validRangeOne = false;

                    //format value
                    value = (value * 1).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                    value = value.toString().substring(0, value.indexOf('.'))

                } else {
                    if (Number(value) >= minVal && Number(value) <= maxVal) {
                        errorMessageRangeOne = '';
                        validRangeOne = true;
                        //format value
                        value = Number(value).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                        value = value.toString().substring(0, value.indexOf('.'))
                    }
                }
            } else {
                errorMessageRangeOne = `*El valor debe ser mayor o igual a 0.`;
                validRangeOne = false;
            }
        }
        field.valid = validRangeOne;
        field.value = value

        response.errorMessageRangeOne = errorMessageRangeOne;
        response.validRangeOne = validRangeOne;
        response.valueRangeOne = value;
        response.fieldConfig = field;
        return response;
    }
    /**
     * Validates if the input is correct to go to the next question and save it in the local storage.
     * @param {Boolean} validRangeOne The boolean value if the first number input is valid.
     * @param  {Object} fieldConfig The question of the current component.
     *  @param {Array}  optionValues The possible options values for the answer of the user.
     * @param {Number}  minVal The minimum value for the input.
     * @param {Number}  maxVal The maximum value for the input.
     * @return {Object} The response object to set props and state in the component.
     */
    NextStep = (validRangeOne, fieldConfig, optionValues, minVal, maxVal) => {
        let step = 0;
        let response = {
            validRangeOne: null,
            step: null,
            fieldConfig: null
        }
        if (!fieldConfig.required && fieldConfig.value.trim() === '') {
            response.validRangeOne = true;
            let step = fieldConfig.questionNumber + 1;
            if (step === fieldConfig.totalFields) {
                step = 0;
                response.step = step;
            } else {
                response.step = step;
            }
            response.fieldConfig = fieldConfig;

            return response;
        }
        const checkValidityResponse = this.CheckValidity(fieldConfig, validRangeOne, minVal, maxVal);
        if (checkValidityResponse.validRangeOne) {
            fieldConfig.valid = true;
            let idAnswerOption = this.GetIdOptionRangeValue(optionValues, fieldConfig.value);
            let chosenAnswerOption = fieldConfig.options.filter(option => option.id === Number(idAnswerOption));
            step = chosenAnswerOption[0] ? chosenAnswerOption[0].step : 0;
            this.SaveAnswer(fieldConfig, idAnswerOption);
            response.validRangeOne = validRangeOne;
            response.step = step;
            response.fieldConfig = fieldConfig;

            return response;
        } else {
            fieldConfig.valid = false;
            response = this.CheckValidity(fieldConfig, checkValidityResponse.validRangeOne, minVal, maxVal);
            return response;
        }
    }
    SaveAnswer(fieldConfig, idAnswerOption) {
        let question = {
            "id_question": `${fieldConfig.id}`,
            "question_type": `${fieldConfig.type}`,
            "question_text": `${fieldConfig.body}`,
            "id_answer": `${idAnswerOption}`,
            "value_answer": `${fieldConfig.value}`,
            "id_tsv_field": `${fieldConfig.idTSVField}`,
            "question_number": fieldConfig.questionNumber
        }
        if (Number(fieldConfig.idQuestionClass) == selectProductQuestion) {
            if (!this.jsonExchangeTSV.existQuestionOfProductQuestions(question)) {
                this.jsonExchangeTSV.addQuestionToProductQuestion(question);
            } else {
                let lastQuestion = this.jsonExchangeTSV.getQuestionOfProductQuestionsById(question);
                this.jsonExchangeTSV.removeQuestionOfProductQuestions(lastQuestion);
                this.jsonExchangeTSV.addQuestionToProductQuestion(question);
            }
        } else {

            question.base_question_risk = false;
            if (!this.jsonExchangeTSV.existQuestionOfRiskQuestions(question)) {
                this.jsonExchangeTSV.addRiskQuestionToRiskQuestions(question);
            } else {
                let lastQuestion = this.jsonExchangeTSV.getQuestionOfRiskQuestionsById(question);
                this.jsonExchangeTSV.removeQuestionOfRiskQuestions(lastQuestion);
                this.jsonExchangeTSV.addRiskQuestionToRiskQuestions(question);
            }
        }
    }
    GetCorrectAnswerOption = (value, options, age, risk) => {
        if (risk !== undefined && risk) {
            const numericalValue = value !== null ? value.toString().replaceAll(',', '') : '';
            const numericalAge = age !== null ? Number(age) : null;
            for (const option of options) {
                if (numericalValue >= Number(option.option) && numericalValue <= Number(option.option2)) {
                    if (numericalAge >= option.ageMin && numericalAge <= option.ageMax) {
                        return option;
                    }
                }
            }
            return null;
        } else {
            const numericalValue = value !== null ? value.toString().replaceAll(',', '') : '';
            for (const option of options) {
                if (numericalValue >= Number(option.option) && numericalValue <= Number(option.option2)) {
                    return option;
                }
            }
            return null;
        }
    }

    /**
     * Gets the id of the option value according to the answer of the object.
     * @param {Array}   optionValues The array of possible answer options
     * @param {Object}  value The possible options values for the answer of the user.
     * @return {number} The id of the answer option.
     */
     GetIdOptionRangeValue = (optionValues, value) => {
        let numericalValue = parseInt(value.replaceAll(',', ''));
        if (optionValues != null && optionValues.length > 0) {
            for (const element of optionValues) {
                if (numericalValue >= parseInt(element.option) && numericalValue <= parseInt(element.option2)) {
                    return element.id;
                }
            }
        }
    }

    /**
     * Validates if the category chosen by the user is the same of the local storage.
     * @param {Number}   isCurrentCategory A numerical id of the category
     * @return {Boolean} True if it is the current category.
     */
    IsCurrentCategory = (idCurrentCategory) => {
        let currentCategory = this.jsonExchangeTSV.getCurrentSerie();
        if (parseInt(currentCategory.id_series) === idCurrentCategory) {
            return true;
        }
        return false;
    }

    /**
     * Gets the last answer saved for a question in the local storage.
     * @param {Object}   fieldConfig The question of the current component.
     * @return {Boolean} True if it is the current category.
     */
    GetLastAnswerSaved = (fieldConfig) => {
        let question = { id_question: fieldConfig.id };
        let lastAnswer = {};
        if (Number(fieldConfig.idQuestionClass) == selectProductQuestion) {
            if (fieldConfig.idMainQuestion === '') {
                lastAnswer = this.jsonExchangeTSV.getQuestionOfProductQuestionsById(question);
            } else {
                lastAnswer = this.jsonExchangeTSV.getSubQuestionOfProductQuestionsById(question);
            }

        } else {
            lastAnswer = this.jsonExchangeTSV.getQuestionOfRiskQuestionsById(question);
        }
        if (!lastAnswer) {
            return null;
        }
        if (lastAnswer.value_answer) {
            return lastAnswer;
        } else if (lastAnswer.answer_list) {
            return lastAnswer.answer_list[0] ? lastAnswer.answer_list[0] : null;
        }
        return null;
    }
}