import './Footer.css';
import React from 'react';
import { ModalContact } from '../ModalContact';
import { Link } from 'react-router-dom';


export class Footer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      display: 'none',
      reset: false
    }
  }
  onLeave(event) {
    if (event.path[0].className !== 'help-sticky__text') {
      this.setState({ display: 'none', reset: true });
      let body = document.querySelector("body");
      body.style.overflow = 'visible';
    }
  }
  handleHover = (e) => {
    this.setState({ display: 'block', reset: false });
    let body = document.querySelector("body");
    body.style.overflow = 'hidden';
    body.addEventListener('click', (event) => this.onLeave(event));
  }
  btnClick() {
    window.open('https://www.chatbase.co/chatbot-iframe/ltrrRWVGLiyxvJIf2Z9Ox', '_blank',
      "width=320, height=480, left=500, top=130");
  }
  componentDidMount() {
  }
  render() {
    const { display } = this.state;
    return (
      <>
        <div style={{ display: display }}>
          <ModalContact reset={this.state.reset} />
        </div>
        <div className="row row-f footer">
          <div className="rectangle">
          </div>
          <footer>
            <div className="row footer__info">
              <div className="c1">
                <Link to={location => ({ ...location, pathname: "/compra/privacidad" })}>
                  <div className="terms-site">Aviso de Privacidad de Sitios Web</div>
                </Link>

              </div>
              <div className="c2">
                <Link to={location => ({ ...location, pathname: "/compra/terminos" })}>
                  <div className="terms-use">Términos de Uso</div>
                </Link>
              </div>
              <div className="c3">
                <div id="div-help-sticky" style={{ display: "none" }} className="help-sticky" onClick={e => this.handleHover(e)}    >
                  <a className="help-sticky__text" >Aún no me decido, contáctenme</a>
                  <div className="help-sticky__circle__footer">
                    <svg className="help-sticky__circle_vector" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.3266 9.37737C10.5354 8.70066 9.73244 8.29075 8.95096 8.96644L8.48431 9.37482C8.14289 9.67126 7.50809 11.0564 5.05375 8.233C2.59991 5.41321 4.06015 4.97416 4.40208 4.68028L4.87128 4.27139C5.64868 3.59417 5.3553 2.74164 4.79461 1.86406L4.45626 1.33251C3.89302 0.456976 3.27968 -0.118022 2.50024 0.558176L2.07909 0.926175C1.7346 1.17713 0.771669 1.99286 0.538092 3.54255C0.256982 5.40196 1.14376 7.53124 3.17542 9.86753C5.20452 12.2048 7.19121 13.3788 9.07311 13.3584C10.6371 13.3415 11.5816 12.5023 11.8771 12.1972L12.2997 11.8287C13.0771 11.153 12.5941 10.465 11.8024 9.78677L11.3266 9.37737Z" fill="white" />
                    </svg>
                  </div>
                </div>
                <div id="div-help-chat" style={{ display: "block" }} onClick={this.btnClick}>
                  <div className='btn-assistant-text-footer'>
                    <svg className="help-position" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="9.5" cy="9.5" r="9.5" fill="white" />
                      <path d="M8.71094 11.5078C8.71094 10.7787 8.79948 10.1979 8.97656 9.76566C9.15365 9.33336 9.47656 8.90888 9.94531 8.49222C10.4193 8.07034 10.7344 7.7292 10.8906 7.46878C11.0469 7.20316 11.125 6.92451 11.125 6.63284C11.125 5.75263 10.7188 5.31253 9.90625 5.31253C9.52083 5.31253 9.21094 5.43232 8.97656 5.67191C8.7474 5.90628 8.6276 6.2318 8.61719 6.64847H6.35156C6.36198 5.65368 6.68229 4.87503 7.3125 4.31253C7.94792 3.75003 8.8125 3.46878 9.90625 3.46878C11.0104 3.46878 11.8672 3.73701 12.4766 4.27347C13.0859 4.80472 13.3906 5.55732 13.3906 6.53128C13.3906 6.97399 13.2917 7.39326 13.0938 7.78909C12.8958 8.17972 12.5495 8.61461 12.0547 9.09378L11.4219 9.69534C11.026 10.0756 10.7995 10.5209 10.7422 11.0313L10.7109 11.5078H8.71094ZM8.48438 13.9063C8.48438 13.5573 8.60156 13.2709 8.83594 13.0469C9.07552 12.8177 9.38021 12.7032 9.75 12.7032C10.1198 12.7032 10.4219 12.8177 10.6562 13.0469C10.8958 13.2709 11.0156 13.5573 11.0156 13.9063C11.0156 14.25 10.8984 14.5339 10.6641 14.7578C10.4349 14.9818 10.1302 15.0938 9.75 15.0938C9.36979 15.0938 9.0625 14.9818 8.82812 14.7578C8.59896 14.5339 8.48438 14.25 8.48438 13.9063Z" fill="#052675" />
                    </svg>
                    <label className="help-text-footer">¿Hablamos?</label>
                  </div>
                </div>
              </div>
              <div className="c4">
                <span className="terms-s">2021 Triple-S Vida es una subsidiaria de Triple-S Management.</span>
              </div>
            </div>
          </footer>
        </div>
      </>
    );
  }

}