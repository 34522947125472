import React from "react";
import { LoadingPage } from "../../pages/LoadingPage";
import './SelectedRiderS4Style.css';
import { SelectedRiderSavingService } from "../../Services/SelectedRiderSavingService";
import { RatesServiceTSV } from "../../Services/RatesServiceTSV";
import { SelectedRiderListService } from "../../Services/SelectedRiderListService";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
const { REACT_APP_SECTION_HIDE_SAVINGS, REACT_APP_SHOW_SAVINGS_SECTION } = process.env;

export class SelectedSavingProjectionS4 extends React.Component {
    constructor(props) {
        super(props);
        this.selectedRiderSavingService = new SelectedRiderSavingService();
        this.ratesServiceTSV = new RatesServiceTSV();
        this.selectedRiderListService = new SelectedRiderListService();
        this.state = {
            loading: false,
            riders: { details: {}, applicationData: {} }, details: [],
            value: this.props.value,
            values: null,
            valueEntry: this.props.valueEntry
        }
    }

    /**
      * This method is used to load the rider.
      */
    loadRidersCategory = async () => {
        let { idTSVProduct, listRider, temRisk } = this.props;
        let values = null;
        let request = {
            idTSVProduct: idTSVProduct,
            methodKey: "E",
            modeIntervals: "12",
            value: this.props.value,
            idTSVCategory: idTSVProduct == 'Freedom UL - Ind' ? "S4" : 'S2',
            EnteredPremiumText: this.props.targetPremium,

        };
        if(listRider.length == 0 ){
           listRider = this.selectedRiderListService.GetRiderSelectLocalStorage();
        }
        let rider = this.selectedRiderSavingService.GetRiderRequestS4(request, listRider, temRisk);

     
        const resultLogin = await this.ratesServiceTSV.Login();
        if (resultLogin.statusResponse) {
            const resultQuote = await this.ratesServiceTSV.QuoteRiders(rider, resultLogin.data.token);
            if (resultQuote.statusResponse) {
                let data = resultQuote.data;
                let detail = data.details.illustrationEmail != undefined ? data.details.illustrationEmail.policyDetails.sections : [];
                let details = data.details != undefined ? data.details : null;
                let application = data.applicationData != undefined ? data.applicationData : null;
                this.selectedRiderListService.SaveDetailsAndApplicationData(details, application);

                if (detail !== null && detail.length > 0 && !this.props.show) {
                    values = detail[2].entries.filter(obj => {
                        return obj.items[1] == this.props.retirementAge;
                    });
                    this.props.setValues(values);
                }
                this.setState({
                    riders: { details: details, applicationData: application },
                    values: values,
                    details: detail, loading: false
                });
            } else {
                if (resultQuote.error) {
                    this.props.handleErrorInComponent(true);
                }
                this.setState({ loading: false })
            }
        }
    }
    /**
     * This method upload the detail the rider
     */
    componentDidMount() {
        this.setState({ loading: true });
        this.loadRidersCategory();
    }

    render() {
        const { loading, details } = this.state;
        const { disclaimer } = this.props;
        if (loading) {
            return (
                <div className='page'> <LoadingPage /> </div>
            );
        } else {
            return (
                <div>
                    <div className='riders-list'>
                        {details && details.length > 0 ? <div >
                            <p className="title-proyections">
                                Proyección de Beneficio por Muerte y Valores en Efectivo
                            </p>

                            <span className="text-header-table" >Tabla de proyección de depósitos y valores</span >

                            {details.map((detail) => (
                                detail.title != REACT_APP_SECTION_HIDE_SAVINGS ?
                                    <>
                                        <div className="proyections-container">
                                            <>
                                                {detail.title === REACT_APP_SHOW_SAVINGS_SECTION ?

                                                    <table id="table-to-xls" className="table table-responsive table-rider">
                                                        {detail.entries.map((entry, indexEntry) => (

                                                            indexEntry === 0 ?
                                                                <thead className="table-riders">
                                                                    {entry.items.map((item, indexItem) => (
                                                                        item !== 'Año' ?
                                                                            <th key={indexItem} >
                                                                                <p className="colum-text-rider"> {item} </p>
                                                                            </th>
                                                                            : null
                                                                    ))}
                                                                </thead> :
                                                                <tr className={`${'table-riders-body '}`} key={indexEntry} >
                                                                    
                                                                    {  entry.items.map((item, indexItemEntry) => (
                                                                    
                                                                    indexItemEntry >0 ?
                                                                        
                                                                             <td className={`${indexItemEntry > 7 ? '' : indexItemEntry > 1 && indexItemEntry < 6 && this.props.idTSVProduct !== 'Freedom Whole Life - Ind' ?  'colum-table-rider': 'colum-table-rider2'}  ${indexItemEntry >2 && indexItemEntry < 6  && this.props.idTSVProduct !== 'Freedom Whole Life - Ind' ? 'color-tr' : 'color-tr-white'}`} key={indexItemEntry}  >{item}</td>
                                                                          
                                                                             : null
                                                                    ))}
                                                                </tr>
                                                        ))}
                                                    </table>
                                                    : null
                                                }
                                            </>
                                        </div>
                                    </> :
                                    null
                            ))}
                            <div className="button-next-rider mt-3 mb-3">
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button btn-white text-btn-white"
                                    table="table-to-xls"
                                    filename="proyecciones"
                                    sheet="proyecciones"
                                    buttonText="DESCARGAR TABLA" />
                                <span className="btn-image-xls" >
                                    <svg width={"24"} height={"24"}
                                        className='image-svg'
                                        xmlns="http://www.w3.org/2000/svg"
                                        fillRule="evenodd" clipRule="evenodd">
                                        <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
                                    </svg>
                                </span>
                            </div>

                            <div>
                                <p className='disclaimer-text'>{disclaimer}</p>
                            </div>
                        </div> : <p className='disclaimer-text'>No se obtuvieron resultados</p>}

                    </div>
                </div >
            )
        }

    }
}
export default SelectedSavingProjectionS4;