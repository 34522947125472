const urlBase = process.env.REACT_APP_URL_API_TSV;

export class CategoryServices {
  
  /**
   *  Gets the categories from the api.
   *  @param  {token} string The token to authenticate in the api.
   *  @return  {Promise} promise The promise from the api.
   */
  GetCategories = async (token) => {
    const result = { statusResponse: false, data: null, error: null }
    await fetch(`${urlBase}Category/GetAll`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then(async response => {
      if (response.ok) {  result.statusResponse = true; }
      await response.json().then(resultapi => result.data = resultapi.list);
    })
      .catch(error => console.log(error));
    return result;
  }

  /**
   *  Checks if the category has risk questions in the api.
   *  @param  {token} string The token to authenticate in the api.
   *  @param  {idCategory} Object The id category needed for the api.
   *  @return  {Promise} promise The promise from the api.
   */
  ExistsRiskInCategory = async (token, idCategory) => {
    const result = { statusResponse: false, data: null, error: null }
    await fetch(`${urlBase}Category/ExistsRiskInCategory`, {
      method: 'POST',
      body: JSON.stringify(idCategory),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then(async response => {
      if (response.ok) { result.statusResponse = true; }
      await response.json().then(resultapi => result.data = resultapi.existsRiskInCategory);
    })
      .catch(error => console.log(error));
    return result;
  }

}

