import CryptoJS from "crypto-js";
const urlBase = process.env.REACT_APP_URL_API_TSV;
const referer = process.env.REACT_APP_REFERER
export class TokenServices {


  /** 
  * 
  * This method validates the expiration of the user's token 
  * 
  */
   ValidateToken() {
    let dateExpitationLocal = new Date().getTime() / 1000;
    const token = this.DecryptToken();
    let data = '';
    if (token !== "") {
      data = JSON.parse(atob(token.split('.')[1]));
      let dateExpitationToken = new Date(data.exp).getTime();
      if (dateExpitationLocal >= dateExpitationToken) {
        document.cookie = 'tsv=; expires=Thu, 01-Jan-1970 00:00:01 GMT;';
       return false;
      }else {
          return true;
      }
    } else {
        return false;
    }
  }

    /**
     *  Encrypts the user's token and saves it in a cookie named tsv.
     *  @param  {string} token The token string.
    */
    EncryptToken = (token) => {
        const tokenEncrypt = CryptoJS.AES.encrypt(JSON.stringify(token), 'gasfgasjfgasñodf').toString();
        document.cookie = `tsv=${tokenEncrypt}`;
    }

    /**
    *  Decrypt the user's token saved in a cookie with name tsv.
    *  @return  {string} token The decrypted token string.
    */
    DecryptToken = () => {
        let cookie = document.cookie.split(";").filter((x) => (x.split("=")[0]).trim() === 'tsv') || '';
        let token = "";
        if (cookie.length > 0) {
            token = document.cookie.split(";").filter((x) => (x && x.split("=") && (x.split("=")[0]).trim() === 'tsv'))[0].split("=")[1].trim() || '';
        }
        const bytes = CryptoJS.AES.decrypt(token, 'gasfgasjfgasñodf');
        const originalText = bytes.toString(CryptoJS.enc.Utf8);
        const tokendeCrypt = originalText.replace(/['"]+/g, '');
        return tokendeCrypt;
    }
    /**
     *  Gets the public token from the api.
     *  @return  {Promise} promise The promise from the api.
     */
    GetPublicToken = async () => {
        const result = { statusResponse: false, data: null, error: null }
        await fetch(`${urlBase}Security/GetPublicToken`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Referer': `${referer}`
            }
        }).then(async response => {
            if (response.ok) { result.statusResponse = true; }
            await response.json().then(resultapi => result.data = resultapi);
        })
            .catch(error => console.log(error));
        return result;
    }
}
