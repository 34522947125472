import { TokenServices } from "./TokenServices";

const urlBase = process.env.REACT_APP_URL_API_TSV;

export class InsuranceAplicationServices {
    constructor() {
        this.tokenService = new TokenServices();
    }

    /*This method is used to add a new insurance aplication , receives the user information  and 
   sends it to the api */
    AddInsuranceApp = async (contactInfo, token) => {
        const result = { statusResponse: false, data: null, error: null }
        await fetch(`${urlBase}InsuranceAplication/Add`, {
            method: 'POST',
            body: JSON.stringify(contactInfo),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(async response => {
            if (response.ok) { result.statusResponse = true; }
            await response.json().then(resultapi => result.data = resultapi);
        })
            .catch(error => console.log(error));
        return result;
    }
}