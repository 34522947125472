import { TokenServices } from "./TokenServices";

const urlBase = process.env.REACT_APP_URL_API_TSV;

export class ProcedureServices {

  constructor() {
    this.tokenService = new TokenServices();
  }

  /**
   * Gets the operators for the question from the api.
   *  @param  {Object} question The question object.
   *  @return  {Promise} promise The promise from the api.
   */
  GetOperators = async (question) => {
    const result = { statusResponse: false, data: null, error: null }
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer  ${this.tokenService.DecryptToken()}`);

    let body = JSON.stringify(question);

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: body
    };
    await fetch(`${urlBase}QuestionProcedure/GetProcedureByIdQuestion`, requestOptions)
      .then(async response => {
        if (response.ok) { result.statusResponse = true; }
        await response.json().then(resultapi => result.data = resultapi);
      })
      .catch(error => console.log('error', error));
    return result;
  }
}

