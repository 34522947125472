import { JSONExchangeTSV } from "./JSONExchangeTSV";

export class YesNoQuestionCardServices {

    constructor() {
        this.jsonExchangeTSV = new JSONExchangeTSV();

    }

    /**
     * Gets the previous question with his answer from local storage, answered by the user.
     * @param  {Number} idCurrentCategory The numerical id of the current category.
     * @param  {Object} fieldConfig The question of the current component.
     * @return {Object} The question with his answer object if exist, if not then returns false.
    */
    GetPreviousAnswerFromLocalStorage = (idCurrentCategory, fieldConfig) => {
        let response = {
            formValid: null,
            step: null,
            next: null,
            value: null,
            isValid: null,
            field: null,
            errorMessage: null
        };
        let valueChecked = null;
        if (this.IsCurrentCategory(idCurrentCategory)) {
            if (this.GetLastAnswerSaved(fieldConfig) != null) {
                valueChecked = this.GetLastAnswerSaved(fieldConfig) === 'Si' ? true : false;
                fieldConfig.value = this.GetLastAnswerSaved(fieldConfig);
                fieldConfig.valid = true;
                let step = fieldConfig.options[0].step;
                response.errorMessage = '';
                response.valid = true;
                response.value = fieldConfig.value;
                response.valueChecked = valueChecked;
                response.step = step;
                response.field = fieldConfig;

                return response;
            }
            return false;
        }
        return false;
    }

    /**
     * Handles a change on a input a valid if this change is correct.
     * @param  {Object} event The event when the input change.
     * @param {field}   field The question object used in the component.
     * @return {Object} The response object to set props and state in the component.
    */
    HandleInputChange = (event, field) => {
        field.value = event.target.value;
        const response = this.CheckValidity(field);
        return response;
    }

    /**
     * Validates if the value of the input is correct.
     * @param {field}   field The question object used in the component.
     * @return {Object} The response object to set props and state in the component.
    */
    CheckValidity = (field) => {
        let rules = field;
        const value = field.value;
        let isValid = true;
        let valueChecked = null;
        let errorMessage = "";
        if (rules.required && (value !== '-1' || value.trim() !== '')) {
            isValid = true;
        } else if (!rules.required) {
            isValid = true;

        }
        if (isValid) {
            valueChecked = value === 'Si' ? true : false;
        }
        field.valid = isValid;

        let response = {
            errorMessage: errorMessage,
            isValid: isValid,
            value: value,
            valueChecked: valueChecked
        }
        return response;
    }

    /**
     * Validates if the input is correct to go to the next question and save it in the local storage.
     * @param {Boolean}   valid A boolean value to handle if the question status is correct to go forward.
     * @param {fieldConfig}   fieldConfig The question object used in the component.
     * @return {Object} The response object to set props and state in the component.
    */
    NextStep = (valid, fieldConfig) => {
        let response = {
            valid: null,
            formValid: null,
            step: null,
            next: null,
            field: null,
            isValid: null
        }
        let step = 0;
        if (!valid) {
            response.isValid = false;
            return response;
        } else {
            if (fieldConfig.value === '' || fieldConfig.value === '-1') {
                response.valid = valid;
                let step = fieldConfig.questionNumber + 1;
                if (step === fieldConfig.totalFields) {
                    step = 0;
                    response.step = step;
                } else {
                    response.step = step;
                }
                response.next = true;
                response.field = fieldConfig
                response.isValid = true;

                return response;
            }
            let answerValue = [{ "id": 0 }];
            if (fieldConfig.options) {
                if (fieldConfig.options.length > 0) {
                    answerValue = fieldConfig.options.filter(option => option.text === fieldConfig.value);
                }
            }
            step = answerValue[0].step;
            let productQuestion = {
                "id_question": `${fieldConfig.id}`,
                "question_type": `${fieldConfig.type}`,
                "question_text": `${fieldConfig.body}`,
                "id_answer": `${answerValue[0].id}`,
                "value_answer": `${fieldConfig.value}`,
                "id_tsv_field": `${fieldConfig.idTSVField}`,
                "question_number": fieldConfig.questionNumber
            }
            if (!this.jsonExchangeTSV.existQuestionOfProductQuestions(productQuestion)) {
                this.jsonExchangeTSV.addQuestionToProductQuestion(productQuestion);
            } else {
                let lastQuestion = this.jsonExchangeTSV.getQuestionOfProductQuestionsById(productQuestion);
                this.jsonExchangeTSV.removeQuestionOfProductQuestions(lastQuestion);
                this.jsonExchangeTSV.addQuestionToProductQuestion(productQuestion);
            }
        }
        response.valid = valid;
        response.step = step;
        response.next = true;
        response.field = fieldConfig
        response.isValid = true;

        return response;

    }

    /**
     * Validates if the category chosen by the user is the same of the local storage.
     * @param {Number}   isCurrentCategory A numerical id of the category
     * @return {Boolean} True if it is the current category.
    */
    IsCurrentCategory = (idCurrentCategory) => {
        let currentCategory = this.jsonExchangeTSV.getCurrentSerie();
        if (parseInt(currentCategory.id_series) === idCurrentCategory) {
            return true;
        }
        return false;
    }

    /**
     * Gets the last answer saved for a question in the local storage.
     * @param {Object}   fieldConfig The question of the current component.
     * @return {Boolean} True if it is the current category.
    */
    GetLastAnswerSaved = (fieldConfig) => {
        let question = { id_question: fieldConfig.id };
        let lastAnswer = {};
        if (fieldConfig.idMainQuestion === '') {
            lastAnswer = this.jsonExchangeTSV.getQuestionOfProductQuestionsById(question);
        } else {
            lastAnswer = this.jsonExchangeTSV.getSubQuestionOfProductQuestionsById(question);
        }
        if (!lastAnswer) {
            return null;
        }
        if (lastAnswer.value_answer) {
            if (lastAnswer.value_answer === 'Si') {
                return 'Si';
            } else if (lastAnswer.value_answer === 'No') {
                return 'No';
            }
        } else if (lastAnswer.answer_list) {
            let idAnswer = null;
            lastAnswer.answer_list.forEach(answerOption => {
                idAnswer = answerOption.value_answer;
            });
            return idAnswer;
        }
        return null;
    }
}