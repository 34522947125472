import React from 'react';
import { Link } from 'react-router-dom';

export class Terms extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="page-content">
                <div className="container">
                    <p>    <Link to={location => ({ ...location, pathname: "/compra" })} className="go-home">&laquo; Regresar al Inicio</Link></p>

                    <header className="page-header">
                        <h1 className="page-title">Términos de Uso</h1>
                    </header>

                    <main className="page-main">
                        <p><em>Última modificación: 18 de junio de 2021</em></p>
                        <h4>Aceptación de los Términos de Uso</h4>
                        <p>Estos términos de uso son suscritos por y entre usted y Triple-S Management Corporation, Triple-S
                            Salud, Inc., Triple-S Advantage, Inc., Triple-S Propiedad, Inc. y Triple-S Vida, Inc. (en conjunto,
                            <strong>«Triple-S», «Compañía», «nosotros» o «nuestro/a[s]»</strong>). Los siguientes términos y
                            condiciones, junto con todo documento que expresamente incorporan mediante referencia (en conjunto,
                            «Términos de Uso»), rigen su acceso a los sitios web de Triple-S y su uso [<a
                                href="http://management.grupotriples.com" target="_blank"
                                rel="noopener noreferrer">management.grupotriples.com</a>; <a
                                    href="http://salud.grupotriples.com" el="" rel="noopener noreferrer">salud.grupotriples.com</a>;
                            <a href="http://advantage.grupotriples.com" target="_blank"
                                rel="noopener noreferrer">advantage.grupotriples.com</a>; <a href="http://sssvital.com"
                                    target="_blank" rel="noopener noreferrer">sssvital.com</a>; <a
                                        href="http://propiedad.grupotriples.com" target="_blank"
                                        rel="noopener noreferrer">propiedad.grupotriples.com</a>; <a
                                            href="http://vida.grupotriples.com">vida.grupotriples.com</a>], incluido todo contenido,
                            funcionalidad y servicios ofrecidos en ellos o a través de ellos (los <strong>«Sitios
                                Web»</strong>), ya sea como invitado o como un usuario registrado.
                        </p>
                        <p>Lea los Términos de Uso con detenimiento antes de comenzar a usar cualquiera de los Sitios Web.
                            <strong>Al usar los Sitios Web o al hacer clic en aceptar los Términos de Uso cuando se le da la
                                opción, usted acepta y está de acuerdo con regirse y cumplir con estos Términos de Uso y nuestro
                                <Link to={location => ({ ...location, pathname: "/compra/privacidad" })}>Aviso de Privacidad de Sitios Web</Link> y el <a
                                    href="./" target="_blank"
                                    rel="noopener noreferrer">Aviso de Prácticas de Privacidad</a> (según se define a
                                continuación), incorporados en el presente documento como referencia.</strong> Si no desea
                            aceptar estos Términos de Uso o el Aviso de Privacidad de Sitios Web, no debe acceder o usar
                            nuestros Sitios Web.
                        </p>
                        <p>Al usar nuestros Sitios Web, usted declara y garantiza que es mayor de edad y puede ser parte de un
                            contrato vinculante con la Compañía y cumplir con el requisito de elegibilidad anterior. Si no
                            cumple con este requisito, no debe acceder o usar nuestros Sitios Web.</p>
                        <div className="spacer " ></div>
                        <h4>Cambios a los Términos de Uso</h4>
                        <p>Es posible que revisemos y actualicemos estos Términos de Uso ocasionalmente y a nuestra discreción.
                            Todos los cambios entrarán en vigencia inmediatamente después de ser publicados, y aplicarán a todos
                            los accesos y usos de los Sitios Web a partir de ese momento. Sin embargo, todo cambio a las
                            disposiciones de resolución de conflictos establecidas en <a className="ignore-external-alert"
                                href="#legislacion">la legislación aplicable y jurisdicción</a> no aplicará a ninguna disputa
                            sobre la que se haya notificado a las partes en o antes de la fecha en que se publicó el cambio en
                            los Sitios Web.</p>
                        <p>El uso continuo de los Sitios Web después de la publicación de los Términos de Uso revisados
                            significa que usted acepta y se adhiere a estos cambios. Se espera que visite esta página cada vez
                            que accede a este Sitio Web para que esté al tanto de cualquier cambio, ya que estos son
                            vinculantes.</p>
                        <div className="spacer " ></div>
                        <h4>Cómo acceder al Sitio Web y seguridad de la cuenta</h4>
                        <p>Nos reservamos el derecho de retirar o cambiar nuestros Sitios Web y cualquier servicio o material
                            que proporcionamos en los Sitios Web, a nuestra discreción y sin previo aviso. No seremos
                            responsables si, por cualquier razón, todos o parte de nuestros Sitios Web no están disponibles en
                            algún momento o en algún período. Es posible que ocasionalmente restrinjamos el acceso de nuestros
                            usuarios, incluidos nuestros usuarios registrados, a algunas partes de nuestro Sitio Web, o la
                            totalidad de nuestros Sitios Web.</p>
                        <p>Usted es responsable de:</p>
                        <ul>
                            <li className="first">Hacer todos los trámites necesarios para acceder a nuestros Sitios Web;</li>
                            <li className="last">Asegurarse de que todas las personas que accedan a los Sitios Web a través de su
                                conexión de Internet estén al tanto de estos Términos de Uso y los cumplan.</li>
                        </ul>
                        <p>Para acceder a los Sitios Web o a algunos recursos que ofrecen, es posible que se le solicite cierta
                            información de registro u otra información. Es una condición del uso de los Sitios Web que toda la
                            información que usted proporcione en los Sitios Web sea correcta, actual y completa. Usted acepta
                            que toda información que proporciona para registrarse en nuestros Sitios Web o demás, incluidos,
                            entre otros, a través del uso de cualquier función interactiva en los Sitios Web, está regida por
                            nuestro <a className="ignore-external-alert" href="/aviso-de-privacidad-de-sitios-web/">Aviso de
                                Privacidad de Sitios Web</a>, y usted se adhiere a todas las medidas que tomamos con respecto a
                            su información de conformidad con tal aviso.</p>
                        <p>En algunos casos, la información que recopilamos sobre usted está sujeta a los requisitos de la Ley
                            de Transferencia y Responsabilidad de Seguro Médico (HIPAA, por sus siglas en inglés) de 1996 y/o la
                            Ley Gramm-Leach-Bliley (GLBA, por sus siglas en inglés), según enmendadas. Si usted es miembro de
                            Triple-S y se registra para utilizar los servicios en línea del portal de miembros, esa información
                            está sujeta a la HIPAA y/o a la GLBA. En esas circunstancias, aplicarán el <a
                                className="ignore-external-alert" href="https://salud.grupotriples.com/politica-de-privacidad/"
                                target="_blank" rel="noopener noreferrer">Aviso de Prácticas de Privacidad de HIPAA de Triple-S
                                Salud, Inc.</a> y <a href="https://advantage.grupotriples.com/aviso-de-practicas-de-privacidad/"
                                    target="_blank" rel="noopener noreferrer">Triple-S Advantage, Inc.</a> y el <a
                                        href="https://vida.grupotriples.com/wp-content/uploads/Privacy-Notice-Spa-TSV-2021.pdf"
                                        target="_blank" rel="noopener noreferrer">Aviso de Prácticas de Privacidad de la GLBA de
                                Triple-S Vida, Inc.</a> y <a
                                    href="https://propiedad.grupotriples.com/politica-de-confidencialidad/" target="_blank"
                                    rel="noopener noreferrer">Triple-S Propiedad, Inc</a>. (en conjunto, «Aviso de Prácticas de
                            Privacidad»). Al hacer clic para aceptar los términos de uso de nuestro <a
                                className="ignore-external-alert" href="/aviso-de-privacidad-de-sitios-web/">Aviso de Privacidad de
                                Sitios Web</a> y el <a href="https://vida.grupotriples.com/wp-content/uploads/Privacy-Notice-Spa-TSV-2021.pdf"
                                    target="_blank">Aviso de Prácticas de Privacidad</a> cuando esta opción esté disponible, usted acepta y se adhiere a sus términos.</p>
                        <p>Si elige o se le proporciona un nombre de usuario, contraseña o cualquier otra información como parte de nuestros procedimientos de seguridad, usted debe tratar tal información como confidencial, y no debe divulgarla a ninguna otra persona o entidad. También reconoce que su cuenta es personal para usted y acepta no brindar a ninguna otra persona acceso a este Sitio Web o a secciones de este usando su nombre de usuario, contraseña u otra información de seguridad. Usted acepta notificarnos de inmediato sobre todo acceso o uso no autorizado de su nombre de usuario o contraseña o toda otra falla de seguridad. También acepta que se asegurará de salir de su cuenta cuando finalice cada sesión. Deberá tener especial cuidado cuando acceda a su cuenta desde una computadora pública o compartida para que otros no puedan ver o registrar su contraseña u otra información personal.</p>
                        <p>Tenemos el derecho de desactivar cualquier nombre de usuario, contraseña u otro identificador, ya sea que usted lo haya escogido o que nosotros se lo hayamos provisto, en cualquier momento bajo nuestra total discreción por cualquier o ninguna razón, incluido si, en nuestra propia opinión, usted ha violado alguna disposición de estos Términos de Uso.</p>
                        <div className=" spacer " >
                        </div>
                        <h4>Derechos de propiedad intelectual</h4>
                        <p>Nuestros Sitios Web y todo su contenido, características y funciones (incluidos, entre otros, toda la
                            información, software, textos, exhibiciones, imágenes, video y audio, y el diseño, la selección y la
                            disposición) son propiedad de la Compañía, sus licenciantes u otros proveedores de tal material, y están
                            protegidos por los Estados Unidos y las leyes internacionales de derechos de autor, marcas comerciales,
                            patentes, secretos comerciales u otras leyes de propiedad intelectual o de derechos de propiedad.</p>
                        <p>Estos Términos de Uso le permiten utilizar los Sitios Web solo para uso personal y no comercial. No debe
                            reproducir, distribuir, modificar, crear trabajos que derivan de ellos, exhibir públicamente, interpretar
                            públicamente, volver a publicar, descargar, almacenar o transmitir ninguno de los materiales que se
                            encuentran en nuestro Sitio Web, excepto lo siguiente:</p>
                        <ul>
                            <li className="first">Su computadora puede almacenar copias de tales materiales en la RAM de manera temporal
                                como resultado de haber accedido y visualizado dichos materiales.</li>
                            <li>Usted puede almacenar archivos que son guardados automáticamente por su navegador web para propósitos de
                                ampliación de visualización.</li>
                            <li>Usted puede imprimir o descargar una copia de una cantidad razonable de páginas del Sitio Web para su
                                uso personal y no comercial, y no para la distribución, publicación o reproducción futuras.</li>
                            <li>Si le proporcionamos aplicaciones de escritorio, móviles o demás para su descarga, puede descargar una
                                única copia a su computadora o dispositivo móvil solo para su uso personal y no comercial, siempre y
                                cuando acepte regirse por nuestro contrato de licencia de usuario final en relación con tales
                                aplicaciones.ç</li>
                            <li className="last">Si proporcionamos funciones de redes sociales con cierto contenido, usted puede realizar
                                acciones que tales funciones permitan.</li>
                        </ul>
                        <p>Usted no debe:</p>
                        <ul>
                            <li className="first">Modificar copias de cualquier material de este sitio;</li>
                            <li>Usar cualquier ilustración, fotografía, secuencias de audio o video, o gráficas por separado del texto
                                que los acompaña;</li>
                            <li className="last">Eliminar o alterar cualquier aviso sobre derechos de autor, marcas comerciales u otros
                                derechos de propiedad de las copias de los materiales de este sitio.</li>
                        </ul>
                        <p>Usted no debe acceder o usar para fines comerciales ninguna parte de los Sitios Web u otros servicios o
                            materiales disponibles a través de los Sitios Web.</p>
                        <p>Si imprime, copia, modifica, descarga, utiliza o proporciona acceso a otra persona a cualquier parte de
                            nuestros Sitios Web en incumplimiento con los Términos de Uso, su derecho a usar los Sitios Web se cancelará
                            de inmediato y deberá, a nuestra elección, devolver o destruir toda copia de los materiales que usted haya
                            hecho. Ningún derecho, título o interés en o a los Sitios Web ni ningún contenido en los Sitios Web se
                            transfiere a usted, y la Compañía se reserva todos los derechos otorgados de manera implícita. Todo uso de
                            nuestro Sitio Web que no está permitido explícitamente por estos Términos de Uso es una violación de estos
                            Términos de Uso y pueden infringir las leyes sobre derechos de autor, marcas comerciales y demás.</p>
                        <div className="spacer "></div>
                        <h4>Marcas comerciales</h4>
                        <p>El nombre de la Compañía y todos los nombres relacionados, logotipos, nombres de productos y servicios,
                            diseños y eslóganes son marcas comerciales de la Compañía o sus afiliadas o licenciantes. No debe usar tales
                            marcas sin el permiso previo por escrito de la Compañía. Todos los demás nombres, logotipos, nombres de
                            productos y servicios, diseños y eslóganes en nuestros Sitios Web son marcas comerciales de sus respectivos
                            dueños.</p>
                        <div className="spacer " ></div>
                        <h4>Usos prohibidos</h4>
                        <ul>
                            <li className="first">Usted puede usar nuestros Sitios Web solo por motivos legales y en conformidad con estos
                                Términos de Uso. Acepta no usar el Sitio Web:</li>
                            <li>De ninguna manera que infrinja cualquier ley o reglamentación federal, estatal, local o internacional
                                correspondiente (incluida, entre otras, toda ley relacionada con la exportación de datos o software
                                desde y hacia los Estados Unidos u otros países);</li>
                            <li>Para propósitos de explotación, daño o intentos de explotar o perjudicar a menores mediante su
                                exposición a contenido inapropiado, solicitarles información personal identificable, y demás;</li>
                            <li>Enviar, recibir a sabiendas, subir, descargar, usar o reusar todo material que no cumpla con las <a
                                className="ignore-external-alert" href="#standar">Normas de Contenido</a> establecidas en estos Términos
                                de Uso;</li>
                            <li>Transmitir o procurar el envío de cualquier material de publicidad o promoción sin nuestro
                                consentimiento previo por escrito, incluido cualquier «correo basura», «cadena de mensajes», «correo no
                                deseado» o cualquier otra solicitud similar;</li>
                            <li>Hacerse pasar o intentar hacerse pasar por la Compañía, un empleado de la Compañía, otro usuario u otra
                                persona o entidad (incluido, entre otros, usar direcciones de correo electrónico o nombres de usuario
                                asociados a lo anterior).</li>
                            <li className="last">Participar en cualquier otro comportamiento que restrinja o suspenda el uso o disfrute de
                                nuestros Sitios Web a cualquier persona, o que pueda, según determinado por nosotros, perjudicar a la
                                Compañía o a los usuarios de nuestros Sitios Web, o los responsabilice.</li>
                        </ul>
                        <p>Además, usted acepta no:</p>
                        <ul>
                            <li className="first">Usar nuestros Sitios Web de cualquier forma que pueda desactivar, sobrecargar, perjudicar
                                o afectar el sitio o interferir con el uso de nuestros Sitios Web por cualquier otra parte, incluida su
                                capacidad de entablar actividades en tiempo real mediante nuestros Sitios Web;</li>
                            <li>Usar cualquier robot, araña u otro dispositivo automático, proceso o medios para acceder a nuestros
                                Sitios Web para cualquier propósito, incluido el monitoreo o la copia de cualquier material en nuestros
                                Sitios web;</li>
                            <li>Usar algún proceso manual para monitorear o copiar cualquier material del Sitio Web, o para cualquier
                                otro propósito que no esté expresamente autorizado en estos Términos de Uso, sin nuestro consentimiento
                                previo por escrito;</li>
                            <li>Usar cualquier dispositivo, software o rutina que interfiera con el funcionamiento apropiado de nuestros
                                Sitios Web;</li>
                            <li>Introducir cualquier virus, troyano, gusano, bomba lógica u otro material que sea malicioso o
                                tecnológicamente dañino;</li>
                            <li>Intentar obtener acceso no autorizado a, interferir con, perjudicar o interrumpir alguna parte de
                                nuestros Sitios Web, a el/los servidor(es) en donde están almacenados nuestros Sitios Web, o a cualquier
                                servidor, computadora o base de datos conectados a nuestros Sitios Web;</li>
                            <li>Atacar a nuestros Sitios Web mediante un ataque por denegación de servicios (DOS) o un ataque por
                                negación de servicio distribuido (DDoS);</li>
                            <li className="last">O intentar interferir con el funcionamiento apropiado de nuestros Sitios Web.</li>
                        </ul>
                        <div className="spacer "></div>
                        <h4>Contribuciones de usuarios</h4>
                        <p>Nuestros Sitios Web pueden contener funciones interactivas (en conjunto, «Servicios Interactivos») que les
                            permiten a nuestros usuarios publicar, entregar, divulgar o transmitir a otros usuarios u otras personas (en
                            adelante, «publicar») contenido o materiales (en conjunto, «Contribuciones de Usuarios») en nuestros Sitios
                            Web o a través de ellos, y/o en los perfiles de redes sociales de la Compañía.</p>
                        <p>Todas las Contribuciones de Usuarios deben cumplir con las Normas de Contenido establecidas en estos Términos
                            de Uso.</p>
                        <p>Toda Contribución de Usuarios que usted publique a través de nuestros Sitios Web y/o los perfiles de redes
                            sociales de nuestra Compañía, se considerarán no confidenciales y sin derecho de propiedad. Al proporcionar
                            cualquier Contribución de Usuarios, usted otorga a la Compañía y a nuestras afiliadas y nuestros proveedores
                            de servicios, y a cada uno de nuestros y sus respectivos licenciatarios, causahabientes y cesionarios, el
                            derecho de usar, reproducir, modificar, interpretar, exhibir, distribuir y divulgar a terceros tal material
                            por cualquier motivo.</p>
                        <p>Usted declara y garantiza que:</p>
                        <ul>
                            <li className="first">Tiene o controla todos los derechos de las Contribuciones de Usuarios y tiene el derecho
                                de otorgarnos la licencia mencionada anteriormente a nosotros y a nuestras afiliadas y nuestros
                                proveedores de servicios, y a cada uno de sus y nuestros respectivos licenciatarios, causahabientes y
                                cesionarios.</li>
                            <li className="last">Todas sus Contribuciones de Usuarios cumplen y cumplirán con estos Términos de Uso.</li>
                        </ul>
                        <p>Usted entiende y acepta que es responsable de cualquier Contribución de Usuarios que publique o contribuya, y
                            usted, y no la Compañía, es totalmente responsable de tal contenido, incluidos su legalidad, confiabilidad,
                            exactitud y pertinencia.</p>
                        <p>No somos responsables ante ningún tercero por el contenido o la exactitud de cualquier Contribución de
                            Usuarios publicada por usted o por otro usuario en nuestros Sitios Web. Para obtener más información sobre
                            las Contribuciones de Usuarios, consulte nuestro <a className="ignore-external-alert"
                                href="/aviso-de-privacidad-de-sitios-web/">Aviso de Privacidad de Sitios Web</a>.</p>
                        <div className="spacer "></div>
                        <h4>Monitoreo e implementación; terminación</h4>
                        <p>Tenemos el derecho de:</p>
                        <ul>
                            <li className="first">Eliminar o negarnos a publicar cualquier Contribución de Usuarios por cualquier o ningún
                                motivo a nuestra discreción;</li>
                            <li>Tomar cualquier medida con respecto a alguna Contribución de Usuarios que creemos necesaria o apropiada
                                a nuestra discreción, incluido si creemos que tal Contribución de Usuarios viola los Términos de</li>
                            <li>Uso, incluidas las Normas de Contenido, infringe cualquier derecho de propiedad intelectual u otro
                                derecho de cualquier persona o entidad, amenaza la seguridad personal de los usuarios de nuestros Sitios
                            </li>
                            <li>Web o del público, o podría responsabilizar a la Compañía;</li>
                            <li>Divulgar su identidad u otra información sobre usted a cualquier tercero que afirme que el material que
                                usted publicó viola sus derechos, incluidos sus derechos de propiedad intelectual o su derecho a la
                                privacidad;</li>
                            <li>Tomar acciones legales apropiadas, incluidas, entre otras, referido a las autoridades de orden público,
                                por cualquier uso ilegal o no autorizado de nuestros Sitios Web;</li>
                            <li className="last">Terminar o suspender su acceso a todos o parte de nuestros Sitios Web por cualquier o
                                ningún motivo, incluido, entre otros, por alguna violación a estos Términos de Uso.</li>
                        </ul>
                        <p>Sin limitar lo anterior, tenemos el derecho de cooperar completamente con cualquier autoridad de orden
                            público u orden judicial que nos solicite o nos ordene divulgar la identidad u otra información de cualquier
                            persona que publica algún material en nuestros Sitios Web o a través de ellos. USTED RENUNCIA Y LIBERA DE
                            TODA RESPONSABILIDAD A LA COMPAÑÍA Y SUS AFILIADAS, LICENCIATARIOS Y PROVEEDORES DE SERVICIOS, DE TODO
                            RECLAMO CAUSADO POR ALGUNA MEDIDA TOMADA POR LA COMPAÑÍA O POR CUALQUIERA DE LAS PARTES ANTERIORES DURANTE O
                            COMO CONSECUENCIA DE INVESTIGACIONES POR LA COMPAÑÍA/DICHAS PARTES O LAS AUTORIDADES DEL ORDEN PÚBLICO.</p>
                        <p>Sin embargo, no podemos y no nos comprometeremos a revisar todo el material antes de su publicación en
                            nuestros Sitios Web, y no podemos garantizar la eliminación oportuna de material inaceptable después de su
                            publicación. Por lo tanto, no asumimos ninguna responsabilidad por cualquier acción o inacción relacionada
                            con las transmisiones, comunicaciones o contenido proporcionado por cualquier usuario o tercero. No somos
                            responsables ante nadie por el desempeño o la falta de desempeño de las actividades descritas en esta
                            sección.</p>
                        <div id="standar">
                            <div className="spacer " ></div>
                            <h4>Normas de Contenido</h4>
                        </div>
                        <p>Estas Normas de Contenido aplican a todas las Contribuciones de Usuarios y al uso de los Servicios
                            Interactivos. Las Contribuciones de Usuarios deben cumplir completamente con todas las leyes y
                            reglamentaciones federales, estatales, locales e internacionales correspondientes. Sin limitar lo anterior,
                            las Contribuciones de Usuarios no deben:</p>
                        <ul>
                            <li className="first">Contener ningún material que sea difamatorio, obsceno, indecente, abusivo, ofensivo,
                                hostigador, violento, detestable, provocativo o inaceptable.</li>
                            <li>Promover material que sea sexualmente explícito o pornográfico, violencia o discriminación por raza,
                                sexo, religión, nacionalidad, discapacidad, orientación sexual o edad.</li>
                            <li>Infringir alguna patente, marca comercial, secreto comercial u otra propiedad intelectual u otros
                                derechos de cualquier otra persona.</li>
                            <li>Violar los derechos legales (incluidos los derechos de publicidad y privacidad) de otros o contener
                                algún material que podría dar lugar a cualquier responsabilidad civil o penal conforme a las leyes o
                                reglamentaciones aplicables o que pueda estar en conflicto con estos Términos de Uso y nuestro <a
                                    className="ignore-external-alert" href="/aviso-de-privacidad-de-sitios-web/">Aviso de Privacidad de
                                    Sitios Web</a> o <a href="https://vida.grupotriples.com/wp-content/uploads/Privacy-Notice-Spa-TSV-2021.pdf" target="_blank"
                                        rel="noopener noreferrer">Aviso de Prácticas de Privacidad</a>.</li>
                            <li>Ser capaz de engañar a cualquier persona.</li>
                            <li>Promover cualquier actividad ilegal, o respaldar, promover o ayudar cualquier acto que sea ilegal.</li>
                            <li>Provocar molestia, inconveniencia o ansiedad innecesaria o ser capaz de molestar, avergonzar, alarmar o
                                enojar a cualquier persona.</li>
                            <li>Hacerse pasar por alguna persona, o tergiversar su identidad o afiliación con alguna persona u
                                organización.</li>
                            <li>Involucrar actividades comerciales o ventas, como concursos, loterías y otras promociones de ventas,
                                trueques o publicidad.</li>
                            <li className="last">Dar la impresión de que emanan de nosotros o que las respaldamos o cualquier otra persona o
                                entidad, si no fuera el caso.</li>
                        </ul>
                        <div className="spacer " ></div>
                        <h4>Violación de derechos de autor</h4>
                        <p>Si usted cree que alguna Contribución de Usuarios viola su derecho de autor comuníquese con el Oficial de
                            Cumplimiento de Triple-S enviando un correo electrónico a <a target="_blank">Elizabeth.Rodriguez@sssvida.com</a>. Es la política
                            de la Compañía cancelar las cuentas de usuario de infractores reincidentes.</p>
                        <div className="spacer "></div>
                        <h4>Confianza en la información publicada</h4>
                        <p>La información presentada en nuestros Sitios Web y a través de ellos está disponible solamente para
                            propósitos informativos generales. No garantizamos la exactitud, completitud o utilidad de esta información.
                            Cualquier confianza que usted tenga en tal información es estrictamente bajo su propio riesgo. Renunciamos a
                            toda responsabilidad que pueda surgir por cualquier confianza puesta en tales materiales por usted o
                            cualquier otro visitante de nuestros Sitios Web, o por cualquier persona que pueda estar informado de tales
                            contenidos. Por momentos, es posible que nuestros Sitios Web incluyan contenidos proporcionados por
                            terceros. Todas las declaraciones y/u opiniones expresadas en estos materiales, y todos los artículos y
                            respuestas a preguntas y otro contenido, que no sea el contenido proporcionado por la Compañía, son solo las
                            opiniones y responsabilidad de la persona o entidad que proporciona esos materiales. Estos materiales no
                            reflejan necesariamente la opinión de la Compañía. No somos responsables ante usted ni cualquier tercero por
                            el contenido o la exactitud de todo material proporcionado por cualquier tercero.</p>
                        <p>Productos y coberturas del seguro. Toda descripción de productos y cobertura del seguro proporcionada en
                            nuestros Sitios Web son descripciones generales de las coberturas disponibles y no son una declaración de
                            contrato. Para obtener la cobertura, debe enviar una solicitud, instrucción o pedido según requerido por
                            Triple-S. Todas las solicitudes están sujetas a la aceptación de Triple-S y a su aprobación. Además, todas
                            las políticas del seguro y/o planes de beneficios grupales están sujetos a sus propios términos y
                            condiciones, exclusiones y limitaciones. Para obtener información completa sobre el seguro, la
                            disponibilidad y los costos, contacte a un agente certificado o a un representante de ventas de Triple-S.
                        </p>
                        <div className="spacer "></div>
                        <h4>Cambios en los Sitios Web</h4>
                        <p>Es posible que ocasionalmente actualicemos el contenido de nuestros Sitios Web, pero el contenido no está
                            necesariamente completo o actualizado. Cualquiera de los materiales de nuestros Sitios Web puede estar
                            desactualizado en algún momento y, excepto que las leyes y reglamentaciones aplicables lo requieran, no
                            tenemos obligación de actualizar tales materiales.</p>
                        <div className="spacer " ></div>
                        <h4>Información sobre usted y sus visitas a nuestros Sitios Web</h4>
                        <p>Toda la información que recopilamos en nuestros Sitios Web está sujeta a nuestros <Link to={location => ({ ...location, pathname: "/compra/privacidad" })}>Aviso de Privacidad de Sitios Web</Link> y <a
                                href="./" target="_blank"
                                rel="noopener noreferrer">Aviso de Prácticas de Privacidad</a>. Al usar nuestros Sitios Web o al hacer
                            clic para aceptar o acordar con los Términos de Uso y el Aviso de Privacidad de Sitios Web y el Aviso de
                            Prácticas de Privacidad, cuando esta opción esté disponible, usted consiente con todas las medidas que
                            tomamos con respecto a su información en cumplimiento con tales avisos.</p>
                        <div className="spacer " ></div>
                        <h4>Transacciones en línea y otros términos y condiciones</h4>
                        <p>Todas las compras y otras transacciones para la venta de bienes y/o servicios están regidas por nuestros
                            Términos y Condiciones sobre las Ventas de Bienes y Servicios, que se incorporan en estos Términos de Uso.
                        </p>
                        <p>Los términos y las condiciones adicionales también pueden aplicar a secciones, servicios o características
                            específicas del Sitio Web. Tales términos y condiciones adicionales se incorporan en estos Términos de Uso.
                        </p>
                        <div className="spacer " ></div>
                        <h4>Vinculación a nuestros Sitios Web y funciones de redes sociales</h4>
                        <p>Usted puede vincularse a las páginas de inicio de nuestros Sitios Web, siempre que lo haga en una forma que
                            sea justa y legal y no dañe nuestra reputación o se aproveche de ella, pero no debe establecer un enlace de
                            una manera que sugiera cualquier forma de asociación, aprobación o patrocinio de nuestra parte sin nuestro
                            consentimiento expreso por escrito.</p>
                        <p>Nuestros Sitios Web pueden proporcionar ciertas funciones de redes sociales que le permiten:</p>
                        <ul>
                            <li className="first">Vincular sitios web de usted o de terceros a ciertos contenidos en nuestros Sitios Web;
                            </li>
                            <li>Enviar correos electrónicos u otros comunicados con cierto contenido, o enlaces a ciertos contenidos, en
                                nuestros Sitios Web;</li>
                            <li className="last">Provocar que secciones limitadas del contenido de nuestros Sitios Web se muestren o
                                aparenten mostrarse en su sitio web o en el de ciertos terceros.</li>
                        </ul>
                        <p>Usted podrá usar estas funciones solo como se provean por nosotros, solo en relación con el contenido con el
                            que se muestran, y de acuerdo con los términos y condiciones adicionales que proveamos sobre dichas
                            funciones. Sujeto a lo anterior, usted no debe:</p>
                        <ul>
                            <li className="first">Establecer un enlace de todo sitio web que no sea de su propiedad;</li>
                            <li>Causar que nuestros Sitios Web o secciones sean exhibidos o parezcan serlo por cualquier otro sitio, por
                                ejemplo, enmarcado («framing»), vinculación profunda, o vinculación en línea («hotlinking»);</li>
                            <li className="last">Realizar alguna acción con respecto a los materiales en nuestros Sitios Web que sea
                                inconsistente con cualquiera de las disposiciones de estos Términos de Uso.</li>
                        </ul>
                        <p>El/Los sitio(s) web desde donde se está vinculando o en donde usted pone cierto contenido a disposición
                            debe(n) cumplir con todos los aspectos de las Normas de Contenido establecidas en estos Términos de Uso.</p>
                        <p>Usted accede a cooperar con nosotros en cesar de manera inmediata el uso de cualquier enmarcado o vinculación
                            sin autorización. Nos reservamos el derecho de retirar los permisos de vinculación sin previo aviso.</p>
                        <p>Podremos desactivar todas o cualquier función de redes sociales y cualquier enlace en cualquier momento a
                            nuestra discreción y sin notificación previa.</p>
                        <div className="spacer " ></div>
                        <h4>Enlaces de nuestros Sitios Web</h4>
                        <p>Si nuestros Sitios Web contienen enlaces a otros sitios y recursos proporcionados por terceros, estos enlaces
                            solo se otorgan para su conveniencia. Esto puede incluir enlaces dentro de anuncios, incluidos los banners
                            publicitarios y enlaces patrocinados. No tenemos control sobre los contenidos de esos sitios o recursos, y
                            no aceptamos ninguna responsabilidad por ellos o por cualquier daño o pérdida que pueda ocasionar usarlos.
                            Si decide acceder a cualquier sitio web de terceros que en ocasiones están vinculados a nuestros Sitios Web,
                            lo hace completamente bajo su propio riesgo y está sujeto a los términos y condiciones de uso de tales
                            sitios web.</p>
                        <div className="spacer "></div>
                        <h4>Restricciones geográficas</h4>
                        <p>Nuestros Sitios Web y su propiedad están basados en el Estado Libre Asociado de Puerto Rico, Estados Unidos,
                            y están destinados a los residentes de Puerto Rico. Como tal, no afirmamos que nuestros Sitios Web o
                            cualquiera de sus contenidos son accesibles o apropiados fuera de los Estados Unidos. El acceso a nuestros
                            Sitios Web por ciertas personas puede ser ilegal en ciertos países. Si accede a nuestros Sitios Web desde
                            fuera de los Estados Unidos, lo hace por su propia iniciativa y es responsable de cumplir con las leyes
                            locales.</p>
                        <div className="spacer " ></div>
                        <h4>Descargo sobre garantías</h4>
                        <p>Usted comprende que no podemos garantizar ni garantizamos que los archivos disponibles para su descarga desde
                            Internet o de nuestros Sitios Web estén libres de virus u otros códigos destructivos. Usted es responsable
                            de implementar los procedimientos y verificaciones suficientes para satisfacer sus requisitos particulares
                            en materia de protección de antivirus y la exactitud de la salida y entrada de datos, y de mantener un medio
                            externo a nuestro sitio para toda reconstrucción de cualquier dato perdido. HASTA EL ALCANCE PERMITIDO POR
                            LA LEY EN TODA SU EXTENSIÓN, NO SEREMOS RESPONSABLES DE NINGUNA PÉRDIDA O DAÑOS Y PERJUICIOS CAUSADOS POR UN
                            ATAQUE DE DENEGACIÓN DE SERVICIO DISTRIBUIDO, VIRUS U OTRO MATERIAL TECNOLÓGICAMENTE DAÑINO QUE PUEDA
                            INFECTAR SU COMPUTADORA, LOS PROGRAMAS DE INFORMÁTICA, LOS DATOS U OTRO MATERIAL DE PROPIEDAD DEBIDO AL USO
                            DE NUESTROS SITIOS WEB O A CUALQUIER SERVICIO O PRODUCTO OBTENIDO EN NUESTROS SITIOS WEB, O DEBIDO A LA
                            DESCARGA DE CUALQUIER MATERIAL PUBLICADO EN ÉL O EN CUALQUIER SITIO WEB VINCULADO A ESTE.</p>
                        <p>EL USO DE NUESTROS SITIOS WEB, SU CONTENIDO Y CUALQUIER SERVICIO O PRODUCTO OBTENIDO A TRAVÉS DE NUESTROS
                            SITIOS WEB ES BAJO SU PROPIO RIESGO. NUESTROS SITIOS WEB, SU CONTENIDO Y TODO SERVICIO O PRODUCTO OBTENIDO A
                            TRAVÉS DE NUESTROS SITIOS WEB SE PROPORCIONAN «TAL COMO SON» Y «SEGÚN SE ENCUENTREN DISPONIBLES» SIN NINGUNA
                            GARANTÍA DE CUALQUIER TIPO, YA SEA EXPLÍCITA O IMPLÍCITA. NI LA COMPAÑÍA NI CUALQUIER OTRA PERSONA ASOCIADA
                            A LA COMPAÑÍA GARANTIZA O DECLARA EN FUNCIÓN DE LA COMPLETITUD, SEGURIDAD, FIABILIDAD, CALIDAD, EXACTITUD O
                            DISPONIBILIDAD DE LOS SITIOS WEB. SIN LIMITAR LO ANTERIOR, NI LA COMPAÑÍA NI OTRA PERSONA ASOCIADA A LA
                            COMPAÑÍA DECLARA NI GARANTIZA QUE NUESTROS SITIOS WEB, SU CONTENIDO O CUALQUIER SERVICIO O PRODUCTO OBTENIDO
                            A TRAVÉS DE LOS SITIOS WEB SEAN EXACTOS, CONFIABLES, ININTERRUMPIDOS O NO TENGAN ERRORES, QUE LOS DEFECTOS
                            SERÁN CORREGIDOS, QUE NUESTROS SITIOS O LOS SERVIDORES QUE LOS DISPONEN NO TENGAN VIRUS U OTROS COMPONENTES
                            MALICIOSOS, O QUE NUESTROS SITIOS WEB O CUALQUIER SERVICIO O PRODUCTO OBTENIDO A TRAVÉS DE NUESTROS SITIOS
                            WEB SATISFARÁN SUS NECESIDADES O EXPECTATIVAS.</p>
                        <p>HASTA EL ALCANCE PERMITIDO POR LA LEY EN TODA SU EXTENSIÓN, LA COMPAÑÍA NIEGA TODA GARANTÍA DE CUALQUIER
                            TIPO, YA SEA EXPLÍCITA O IMPLÍCITA, ESTATUTARIA O DEMÁS, INCLUIDA, ENTRE OTRAS, CUALQUIER GARANTÍA DE
                            COMERCIALIZACIÓN, NO INCUMPLIMIENTO E IDONEIDAD PARA UN PROPÓSITO EN PARTICULAR.</p>
                        <p>LO ANTERIOR NO AFECTA NINGUNA GARANTÍA QUE NO PUEDA EXCLUIRSE O LIMITARSE SEGÚN LA LEGISLACIÓN APLICABLE.</p>
                        <div className="spacer " ></div>
                        <h4>Límite de responsabilidad</h4>
                        <p>HASTA EL ALCANCE PERMITIDO POR LA LEY EN TODA SU EXTENSIÓN, EN NINGUNA CIRCUNSTANCIA LA COMPAÑÍA, SUS
                            AFILIADAS O SUS LICENCIANTES, PROVEEDORES DE SERVICIOS, EMPLEADOS, AGENTES, OFICIALES O DIRECTORES SERÁN
                            RESPONSABLES POR DAÑOS Y PERJUICIOS DE CUALQUIER TIPO, BAJO NINGUNA TEORÍA LEGAL, QUE SURJAN DE O ESTÉN
                            RELACIONADOS A SU USO O SU INCAPACIDAD DE USAR LOS SITIOS WEB, CUALQUIER SITIO WEB VINCULADO A ESTOS,
                            CUALQUIER CONTENIDO EN LOS SITIOS WEB O EN TALES OTROS SITIOS WEB, INCLUIDOS DAÑOS Y PERJUICIOS DIRECTOS,
                            INDIRECTOS, ESPECIALES, INCIDENTALES, CONSIGUIENTES O PUNITIVOS, INCLUIDOS, ENTRE OTROS, LESIÓN PERSONAL,
                            DOLOR Y SUFRIMIENTO, AFLICCIÓN EMOCIONAL, PÉRDIDA DE INGRESOS, LUCRO CESANTE, PÉRDIDA DE OPORTUNIDADES DE
                            NEGOCIO, PÉRDIDA DE USO, PÉRDIDA DE FONDO DE COMERCIO, PÉRDIDA DE DATOS, ACCIDENTES RELACIONADOS CON LA
                            SEGURIDAD DE DATOS, Y SI SON CAUSADOS POR DAÑOS (INCLUIDA LA NEGLIGENCIA), INCUMPLIMIENTO CONTRACTUAL O
                            DEMÁS, INCLUSO SI ES PREVISIBLE.</p>
                        <p>SI ALGUNA SECCIÓN DEL LÍMITE DE LA RESPONSABILIDAD ANTERIOR SE DECLARA INVÁLIDA O INEXIGIBLE POR CUALQUIER
                            RAZÓN, ENTONCES LA RESPONSABILIDAD COLECTIVA DE LA COMPAÑÍA Y SUS SUBSIDIARIAS Y AFILIADAS, Y SUS
                            LICENCIANTES, PROVEEDORES DE SERVICIOS, EMPLEADOS, AGENTES, OFICIALES Y DIRECTORES, ANTE CUALQUIER PARTE
                            (INDEPENDIENTEMENTE DE LA ACCIÓN LEGAL, YA SEA EN EL CONTRATO, POR DAÑOS Y PERJUICIOS O DE OTRO MODO) QUE
                            EXCEDA LOS $100.00 O EL MONTO QUE USTED HA PAGADO A LA COMPAÑÍA POR LOS PRODUCTOS CORRESPONDIENTES O
                            SERVICIOS EN LOS ÚLTIMOS DOCE (12) MESES EN DONDE LA RESPONSABILIDAD SURGIÓ.</p>
                        <p>LO ANTERIOR NO AFECTA NINGUNA RESPONSABILIDAD QUE NO PUEDA EXCLUIRSE O LIMITARSE SEGÚN LA LEGISLACIÓN
                            APLICABLE.</p>
                        <div className="spacer " ></div>
                        <h4>Indemnización</h4>
                        <p>Usted acepta defender, indemnizar y eximir de responsabilidad a la Compañía, sus afiliadas, licenciantes y
                            proveedores de servicios, y sus respectivos oficiales, directores, empleados, contratistas, agentes,
                            licenciantes, proveedores, causahabientes y cesionarios ante cualquier reclamo, responsabilidad, daños y
                            perjuicios, sentencias, indemnizaciones, pérdidas, costos, gastos u honorarios (incluidos los honorarios
                            razonables del abogado) que ocurran por su violación a estos términos de uso o por su uso de nuestros Sitios
                            Web, o se relacionen con esto, incluidos, entre otros, sus Contribuciones de Usuarios, cualquier uso del
                            contenido, los servicios y los productos de los Sitios Web que no sean los autorizados expresamente en estos
                            Términos de Uso, o el uso de cualquier información obtenida de nuestros Sitios Web.</p>
                        <div id="legislacion"></div>
                        <div className="spacer " ></div>
                        <h4>Legislación aplicable y jurisdicción</h4>
                        <p>Todo asunto relacionado con nuestros Sitios Web y estos Términos de Uso, y toda disputa o todo reclamo que
                            surja o se relacione con ellos (en cada caso, incluidos las disputas o los reclamos no estipulados en el
                            contrato), se rigen e interpretan en conformidad con las leyes internas del Estado Libre Asociado de Puerto
                            Rico sin dar efecto a ninguna disposición o norma de elección o conflicto de leyes (ya sea en el Estado
                            Libre Asociado de Puerto Rico o cualquier otra jurisdicción).</p>
                        <p>Cualquier demanda, acción o proceso judicial que ocurra por estos Términos de Uso o nuestros Sitios Web, o se
                            relacionen con ellos, se iniciará exclusivamente en los tribunales federales o en los tribunales del Estado
                            Libre Asociado de Puerto Rico, aunque nos reservamos el derecho de demandarlo o tomar acciones legales en
                            contra de usted por violación a estos Términos de Uso en su domicilio o en otro país pertinente. Usted
                            renuncia a cualquiera y a todas las objeciones al ejercicio de la jurisdicción en usted por tales tribunales
                            y competencia de tales tribunales.</p>
                        <div className="spacer " ></div>
                        <h4>Arbitraje</h4>
                        <p>A discreción absoluta de la Compañía, es posible que se le solicite que presente cualquier disputa que ocurra
                            por estos Términos de Uso o por el uso de sus Sitios Web, incluidas las disputas que ocurran por su
                            interpretación, violación, invalidez, incumplimiento o terminación, al arbitraje final y vinculante según
                            las Reglas de Arbitraje de la Asociación Americana de Arbitraje que aplican en las leyes del Estado Libre
                            Asociado.</p>
                        <div className="spacer " ></div>
                        <h4>Límite de tiempo para presentar reclamos</h4>
                        <p>CUALQUIER CAUSA DE ACCIÓN O RECLAMO QUE USTED PUEDA TENER O QUE SURJA DE ESTOS TÉRMINOS DE USO O LOS SITIOS
                            WEB, O SE RELACIONEN CON ELLOS, DEBE INICIARSE DENTRO DE UN (1) AÑO DESPUÉS DE SURGIDA LA CAUSA DE ACCIÓN;
                            DE LO CONTRARIO, TAL CAUSA DE ACCIÓN O RECLAMO QUEDARÁ PROHIBIDO DE FORMA PERMANENTE.</p>
                        <div className="spacer " ></div>
                        <h4>Exención y divisibilidad</h4>
                        <p>Ninguna exención de la Compañía de algún término o condición establecido en estos Términos de Uso se
                            considerará una exención en curso o adicional de tal término o condición o una exención de cualquier otro
                            término o condición, y si la Compañía no afirma un derecho o disposición bajo estos Términos de Uso, no
                            constituirá una exención de tal derecho o disposición.</p>
                        <p>Si una corte u otro tribunal de jurisdicción competente declara inválida, ilegal o inexigible alguna
                            disposición de estos Términos de Uso por cualquier razón, dicha disposición se deberá eliminar o limitar al
                            mínimo su extensión para que las disposiciones restantes de los Términos de Uso sigan en plena vigencia.</p>
                        <div className="spacer " ></div>
                        <h4>Integridad del acuerdo</h4>
                        <p>Los Términos de Uso, el <Link to={location => ({ ...location, pathname: "/compra/privacidad" })}>Aviso de Privacidad de Sitios Web</Link> y
                            otros documentos incorporados en el presente constituyen el único acuerdo íntegro entre usted y Triple-S en
                            relación con sus Sitios Web y sustituye todos los entendidos, acuerdos, declaraciones y garantías, tanto
                            escritos como verbales, anteriores y contemporáneos con respecto a los Sitios Web.</p>
                        <div className="spacer " ></div>
                        <h4>Comentarios e inquietude</h4>
                        <p>Nuestros Sitios Web son operados por nuestra agencia digital y equipos internos. Todos los demás
                            comentarios,
                            observaciones, solicitudes de soporte técnico y otras comunicaciones relacionadas con los Sitios Web
                            deben
                            dirigirse a nuestro Departamento de Servicio al Cliente.</p>
                        <p>&nbsp;</p>
                    </main>

                    <p><Link to={location => ({ ...location, pathname: "/compra" })}> Regresar al Inicio</Link> </p>
                </div>
            </div>

        );
    }

}