import { JSONExchangeTSV } from './JSONExchangeTSV';
import { SelectedRiderListService } from './SelectedRiderListService';

const { REACT_APP_FREEDOM_TERM_10_YEARS, REACT_APP_FREEDOM_TERM_15_YEARS, REACT_APP_BASIC_PLAN,
    REACT_APP_FREEDOM_TERM_20_YEARS, REACT_APP_FREEDOM_TERM_30_YEARS, REACT_APP_FREEDOM_TERM,
    REACT_APP_ID_TSV_AMOUNT, REACT_APP_MAX_TEST } = process.env;

export class SliderCardService {

    constructor() {
        this.jsonExchangeTSV = new JSONExchangeTSV();
        this.selectedRiderListService = new SelectedRiderListService();
    }

    /**
      * Replace text to a numeric value.
      * @param {String}  value The value used in the component.
      * @return {Number} The numeric value.
      */
    getAmountText(value) {
        let response = value.replace("Max: $", "").replace("Min: $", "");
        let newValue = response.replace(",", "");
        return newValue
    }

    /**
      * Validates if the value of the input is correct.
      * @param {Number}  valueCurrent The value used in the component.
      * @param {Number}  minValue The minimum value for the input.
      * @param {Number}  maxValue The maximum value for the input.
      * @return {Object} The response object to set props and state in the component.
      */
    HandleInputChange = (valueCurrent, minValue, maxValue) => {
        const pattern = /^[0-9\b]+$/;
        let errorMessageRange = "";
        let validRange = true;

        let response = {
            valueRange: null,
            errorMessageRange: null,
            validRange: null
        };

        let value = valueCurrent !== null ? valueCurrent.replaceAll(',', '') : '';
        maxValue = maxValue !== null ? maxValue.replaceAll(',', '') : '';
        if (value.trim() == '') {
            errorMessageRange = '*Campo es requerido.';
            validRange = false;
        }
        else if (!value.trim() == '' && !pattern.test(value)) {
            errorMessageRange = '*Este campo solo permite valores númericos enteros.';
            validRange = false;
        }
        else {
            if (!value.trim() == '' && Number(value) >= 0) {
                if (Number(value) < minValue || Number(value) > maxValue) {
                    let min = Number(minValue).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                    let max = Number(maxValue).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                    errorMessageRange = `*El monto del seguro debe estar entre $${min.substring(0, min.indexOf('.'))} y  
                        $${max.substring(0, max.indexOf('.'))}`;
                    validRange = false;
                    value = (value * 1).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                    value = value.toString().substring(0, value.indexOf('.'))

                } else {
                    if (Number(value) >= minValue && Number(value) <= maxValue) {
                        errorMessageRange = '';
                        validRange = true;
                        value = Number(value).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                        value = value.toString().substring(0, value.indexOf('.'))
                    }
                }
            } else {
                errorMessageRange = `*El valor debe ser mayor o igual que 0.`;
                validRange = false;
            }
        }
        response.errorMessageRange = errorMessageRange;
        response.validRange = validRange;
        response.valueRange = value;
        return response;
    }

    /**
      * Function that obtains the amount of insurance that was entered in the selection of products.
      */
    getInsuranceAmount(value, categoryName,
        type, selectedInsuredCategory, valueMin, changeField, isLaboratoryTest) {
        let valueTemp = value;
        let idProduct = Number(this.jsonExchangeTSV.getCurrentSelectedProduct().id_product);
        let isSelected = type === 'sliderMultiple' ? 'sliderSelected' : '';
    
        if (categoryName == REACT_APP_BASIC_PLAN) {
            if (REACT_APP_FREEDOM_TERM_10_YEARS == idProduct || REACT_APP_FREEDOM_TERM_15_YEARS == idProduct ||
                REACT_APP_FREEDOM_TERM_20_YEARS == idProduct || REACT_APP_FREEDOM_TERM_30_YEARS == idProduct ||
                REACT_APP_FREEDOM_TERM == idProduct) {
                 
                if (!isLaboratoryTest) {
                    let amount = this.jsonExchangeTSV.getQuestionOfProductQuestionsByIdTSV(REACT_APP_ID_TSV_AMOUNT);
                    if (amount) {
                        let amountNumber = amount.value_answer.replaceAll(",", "");
                        valueTemp = Number(amountNumber) >= Number(valueMin) ?
                            amountNumber : valueMin;

                        let request = {
                            categoryName: categoryName, type: type, value: valueTemp, selectedInsuredCategory: selectedInsuredCategory,
                            isSelected: isSelected, changeField: changeField
                        };
                        this.selectedRiderListService.SaveRiderCategories(request);
                    }
                } else {
                    valueTemp = REACT_APP_MAX_TEST;
                }
            }
        }
        return valueTemp;
    }
}