import './LongAnswerCard.css';
import React from 'react';
import { LongAnswerCardServices } from '../../Services/LongAnswerCardServices';
import { ModalInfo } from '../ModalInfo';

export class LongAnswerCard extends React.Component {
  constructor(props) {
    super(props);
    this.longAnswerCardServices = new LongAnswerCardServices();
    this.state = {
      errorMessage: null,
      valid: false,
      value: '',
      stepBack: 0,
      validInput: true,
      modalOptions: { show: false, title: '', description: '' },
    };
  }

  /**
   * Verifies that the nextProp property has changed, when its value is true it takes the identifier of the current component and calls nextStep to verify that the question was answered correctly.
   */
  componentDidUpdate(prevProps) {
    if (prevProps.nextProp !== this.props.nextProp) {
      if (this.props.nextProp && this.props.indexNext === this.props.index) {
        this.nextStep();
      } else if (this.props.nextProp && this.props.fieldConfig.idMainQuestion !== '') {
        if (this.props.indexNext.includes(this.props.index)) {
          this.nextStep();
        }
      }
    }
  }

  /**
   * Checks if the answer of the question is stored in the local storage if so the component is rendered with that answer 
   */
  componentDidMount() {
    const { fieldConfig, idCurrentCategory } = this.props;
    const response = this.longAnswerCardServices.GetPreviousAnswerFromLocalStorage(idCurrentCategory, fieldConfig);
    if (response) {
      this.setState({ errorMessage: response.errorMessage, valid: response.isValid, value: response.value });
      this.props.funtionHandleSetProps(true, response.step, false, response.field);
    }
  }

  /**
   * Validates if the input is correct to go to the next question and save it in the local storage.
   */
  nextStep = () => {
    const { fieldConfig } = this.props;
    let response = this.longAnswerCardServices.CheckValidity(fieldConfig);
    if (!response.isValid) {
      const response = this.longAnswerCardServices.CheckValidity(fieldConfig);
      this.setState({
        errorMessage: response.errorMessage, valid: response.isValid,
        value: response.value, validInput: response.isValid
      });
      this.props.funtionHandleSetProps(response.isValid, response.step, true, response.field);

    } else {
      response = this.longAnswerCardServices.NextStep(response.isValid, fieldConfig);
      this.props.funtionHandleSetProps(response.formValid, response.step, response.next, response.field);
    }
  }

  /**
   * Handles a change on a input a valid if this change is correct.
   */
  handleInputChange = (event, field) => {
    const response = this.longAnswerCardServices.HandleInputChange(event, field);
    this.setState({
      errorMessage: response.errorMessage, valid: response.isValid,
      value: response.value, validInput: response.isValid
    });
    this.props.funtionHandleSetProps(response.isValid, false);
  }

  /**
   * Renders a modal with de information of each answer option.
   */
  showModal(title, description) {
    let modalOptions = { show: true, title: title, description: description };
    this.setState({ modalOptions: modalOptions });
  }

  /**
   * Close the modal with de information of each answer option.
   */
  closeModal = () => {
    let modalOptions = { show: false };
    this.setState({
      modalOptions: modalOptions
    });
  };

  render() {
    const { valid, validInput, errorMessage, modalOptions } = this.state;
    const { fieldConfig } = this.props;
    let isInputMatix = fieldConfig.idMainQuestion !== '';
    let description = fieldConfig.options[0].description ? fieldConfig.options[0].description : false;
    return (
      <>
        <div className={validInput && !isInputMatix ? ('border-div-input') : validInput && isInputMatix ? ('') :
          !validInput && !isInputMatix ? ('is-invalid-border') : ''}>
          <div className={fieldConfig.idMainQuestion === '' ? ('text-left input-txt') : ('text-left control-matrix')}>
            <textarea
              className={`form-control custom-input long-modal ${(validInput && isInputMatix) ? ('height-input-matrix') :
                (validInput && !isInputMatix) ? ('height-input') :
                  (!validInput && !isInputMatix) ? ('is-invalid-input height-input') :
                    (!validInput && isInputMatix) ? 'height-input-matrix  is-invalid-input' : ''}`}
              type='text'
              name={this.props.id}
              placeholder={this.props.label}
              required={this.props.required}
              minLength={this.props.minLength}
              disabled={this.props.disabledInput}
              maxLength={this.props.maxLength}
              onChange={(event) => this.handleInputChange(event, this.props.fieldConfig)}
              value={fieldConfig.value}
              rows={this.props.fieldConfig.idMainQuestion !== '' ? '1' : '2'} wrap="soft">
            </textarea>
            {!validInput ?
              <i className={isInputMatix ? 'long-i-matrix' : 'textarea-i'}>
                <svg width={isInputMatix ? '25' : '37'} height={isInputMatix ? '25' : '38'} viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <ellipse cx="18.5" cy="19.3965" rx="18.5" ry="18.4428" fill="#F2274C" />
                  <path d="M17 27.8814C17 27.4259 17.1675 27.0299 17.5026 26.6932C17.8377 26.3566 18.2319 26.1883 18.6852 26.1883C19.1385 26.1883 19.5327 26.3566 19.8678 26.6932C20.2029 27.0299 20.3704 27.4259 20.3704 27.8814C20.3704 28.3368 20.2029 28.7229 19.8678 29.0398C19.5327 29.3368 19.1385 29.4853 18.6852 29.4853C18.2319 29.4853 17.8377 29.3368 17.5026 29.0398C17.1675 28.7229 17 28.3368 17 27.8814ZM18.3896 24.1388C18.3107 23.2081 18.222 22.2279 18.1235 21.1982C18.0446 20.1487 17.9559 19.119 17.8574 18.1091C17.7785 17.0794 17.6997 16.0992 17.6209 15.1685C17.5617 14.2378 17.5026 13.4061 17.4435 12.6734C17.3843 11.921 17.3351 11.3071 17.2957 10.8319C17.2562 10.3368 17.2365 10.0398 17.2365 9.94077C17.2365 9.40611 17.3843 9.02987 17.68 8.81205C17.9756 8.59423 18.3107 8.48532 18.6852 8.48532C19.0597 8.48532 19.3948 8.59423 19.6904 8.81205C19.9861 9.02987 20.1339 9.40611 20.1339 9.94077C20.1339 10.0992 20.0945 10.6635 20.0156 11.6338C19.9368 12.6041 19.8382 13.7824 19.72 15.1685C19.6214 16.5546 19.5032 18.0497 19.3652 19.6536C19.2272 21.2378 19.0991 22.7328 18.9809 24.1388H18.3896Z" fill="white" />
                </svg>
              </i> :
              null}
            {description ?
              <a><i className={isInputMatix ? 'modal-matrix' : 'modal-icon'} tabIndex="0" aria-label="Más información" role="button"
                onClick={() => this.showModal('', description)}
                style={{ display: description === '' || description === null || description === undefined ? 'none' : 'block' }}>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12.5" cy="12.5" r="12.5" fill="#FDC740" onClick={() => this.showModal('', description)} />
                  <path d="M11.4863 18.2508C11.4863 17.9421 11.5995 17.6737 11.8259 17.4455C12.0523 17.2174 12.3187 17.1033 12.625 17.1033C12.9313 17.1033 13.1976 17.2174 13.424 17.4455C13.6504 17.6737 13.7636 17.9421 13.7636 18.2508C13.7636 18.5595 13.6504 18.8212 13.424 19.036C13.1976 19.2373 12.9313 19.3379 12.625 19.3379C12.3187 19.3379 12.0523 19.2373 11.8259 19.036C11.5995 18.8212 11.4863 18.5595 11.4863 18.2508ZM12.4252 15.7142C12.3719 15.0834 12.312 14.4191 12.2454 13.7212C12.1922 13.0098 12.1322 12.3119 12.0656 11.6275C12.0124 10.9296 11.9591 10.2652 11.9058 9.63441C11.8659 9.00361 11.8259 8.43992 11.786 7.94334C11.746 7.43333 11.7127 7.01727 11.6861 6.69516C11.6595 6.35963 11.6461 6.15832 11.6461 6.09121C11.6461 5.72884 11.746 5.47383 11.9458 5.3262C12.1455 5.17857 12.3719 5.10475 12.625 5.10475C12.878 5.10475 13.1044 5.17857 13.3042 5.3262C13.5039 5.47383 13.6038 5.72884 13.6038 6.09121C13.6038 6.19858 13.5772 6.58108 13.5239 7.23872C13.4707 7.89636 13.4041 8.69492 13.3242 9.63441C13.2576 10.5739 13.1777 11.5872 13.0844 12.6743C12.9912 13.748 12.9047 14.7613 12.8247 15.7142H12.4252Z" fill="#052675" />
                </svg>
              </i></a>
              :
              null
            }

          </div>
        </div>
        <div className="text-left">
          {!validInput && <span className='error'>{errorMessage}</span>}
        </div>
        {modalOptions.show ?
          <ModalInfo modalOptions={modalOptions} closeModal={this.closeModal} /> :
          null}
      </>

    );
  }
}
export default LongAnswerCard;
