import React from "react";
import './ProductCard.css';
import { ButtonTSV } from "../ButtonTSV";


export class ModalProductDescription extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <>
                <div className="div-modal-product-description" >
                    
                    <div className="modal-size">
                        <div className="modal-dialog modal-dialog-product modal-lg modal-border-product-description">
                     <div className="modal-content modal-content-product border-dp">
                                <div className="header-modal-product header-dp">
                                    <h5 className="title-dp">  {this.props.title}</h5>
                                    <button  className="close trash-align" data-dismiss="modal"
                                        onClick={this.props.closeModalDescription} aria-label="Close">
                                        <span className='close-modal' aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="text-dp">
                                        {this.props.description}
                                    </p>
                                    <div className="center-dp">
                                    <div className='card-footer text-center btn-spacing-product w-dp'>
                                        <ButtonTSV text={'quiero esta opción'} colorbBtn='btn-text'
                                            colorbBtnImage='btn-image'
                                            function={this.props.handleProductCard}
                                        />
                                    </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}
export default ModalProductDescription;
