import './App.css';
import React from 'react';
import '../bootstrap/dist/css/bootstrap.min.css'
import '../bootstrap/bs-stepper/dist/css/bs-stepper.min.css';
import { Home } from "./pages/Home";


export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){
  }

  render(){
    return (
      <Home></Home>
    );
  }

}