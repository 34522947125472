import { JSONExchangeTSV } from './JSONExchangeTSV';
import { JSONProcessFileServices } from './JSONProcessFileServices';
import { JSONRider } from './JSONRider';
import { SelectedRiderListService } from './SelectedRiderListService';
import { WebServicesTSV } from './WebServicesTSV';

export class SelectedRiderSavingService {

    constructor() {
        this.jsonRider = new JSONRider();
        this.webServicesTSV = new WebServicesTSV();
        this.JSONProcessFileServices = new JSONProcessFileServices();
        this.selectedRiderListService = new SelectedRiderListService();
        this.jsonExchangeTSV = new JSONExchangeTSV();
    }
    /**
      * Method that obtains the data stored in the local storage of the saving rider
      * @return {Object} rider data 
      */
    GetCurrentSavingAmount() {
        let response = [];
        let currentProduct = this.jsonRider.getCurrentSelectedProduct();
       
        if (!currentProduct.isNew) {
            response = this.jsonRider.getCurrentSavingAmount();
            return response;
        }
        return false;
    }
        /**
      * Method that obtains the data stored in the local storage of the saving rider
      * @return {Object} rider data 
      */
         GetCurrentSavingAmounts4() {
            let response = [];
            let currentProduct = this.jsonRider.getCurrentSelectedProduct();
            
            if (currentProduct) {
                response = this.jsonRider.getCurrentSavingAmount();
                return response;
            }
            return false;
        }

    /**
      * Sets the savingAmount of the json in the local storage.
      *  @param  {Object} savingAmount The savingAmount array.
      */
    SetSavingAmount(valueInitial, valueEntry, currentValue, guaranteedValue ) {
        let savingAmount = { 'initial_deposit': valueInitial, 'additional_deposit': valueEntry, 'age': valueEntry, 
        'current_value': currentValue, 'guaranteed_value': guaranteedValue };
        this.jsonRider.setSavingAmount(savingAmount);
    }

    /**
     * Function that obtains the structure in json format to send to the TSV api
     * @param  {Object} request savings rider application information
     * @returns {Object} request in json format 
     */
    GetRiderRequest(request) {
        let primary = this.selectedRiderListService.GetFamilyMember(request.idTSVCategory).Primary;
        let rider = {
            "ProductInfo": {
                "ProductKey": request.idTSVProduct,
                "PaymentMethodKey": request.methodKey,
                "PaymentModePayments": request.modeIntervals,
                "DeathBenefitCode": null,
                "FetchDetails": true,
                "EnteredInitialLumpSumText": request.value,
                "Deposit": {
                    "TransactionPayments": [
                        {
                            "StartYear": null,
                            "EndYear": null,
                            "Amount": request.amount
                        }
                    ]
                }
            },
            "FamilyInfo": {
                "SeriesAccessCode": request.idTSVCategory,
                "EffectiveDateOverride": null,
                primary
            }
        };
        return rider;
    }
      /**
     * Function that obtains the structure in json format to send to the TSV api
     * @param  {Object} request savings rider application information
     * @returns {Object} request in json format 
     */
       GetRiderRequestS4(request, listRider, temRisk) {
        let primary = this.selectedRiderListService.GetFamilyMember(request.idTSVCategory).Primary;
        primary.RiskKey = temRisk !== undefined? temRisk : primary.RiskKey;
         let rider = {
            "ProductInfo": {
                "ProductKey": request.idTSVProduct,
                "PaymentMethodKey": request.methodKey,
                "PaymentModePayments": request.modeIntervals,
                "DeathBenefitCode": "A",
                "FetchDetails": true,
                "EnteredInitialLumpSumText": request.value,
                "EnteredPremiumText":request.EnteredPremiumText,
                "riderCategories": listRider
            },
            "FamilyInfo": {
                "SeriesAccessCode": request.idTSVCategory,
                "EffectiveDateOverride": null,
              primary
            }
        };
        return rider;
    }
      /**
     * Function that obtains the structure in json format to send to the TSV api
     * @param  {Object} request savings rider application information
     * @returns {Object} request in json format 
     */
       GetRiderRequest(request) {
        let primary = this.selectedRiderListService.GetFamilyMember(request.idTSVCategory).Primary;
        let rider = {
            "ProductInfo": {
                "ProductKey": request.idTSVProduct,
                "PaymentMethodKey": request.methodKey,
                "PaymentModePayments": request.modeIntervals,
                "DeathBenefitCode": "A",
                "FetchDetails": true,
                "EnteredInitialLumpSumText": request.value,
                "Deposit": {
                    "TransactionPayments": [
                        {
                            "StartYear": null,
                            "EndYear": null,
                            "Amount": request.amount
                        }
                    ]
                }
            },
            "FamilyInfo": {
                "SeriesAccessCode": request.idTSVCategory,
                "EffectiveDateOverride": null,
                primary
            }
        };
        return rider;
    }
 /**
     * Function that performs the validations of the numeric type field
     * @param {String} valueReal value of the amount deposited in the savings deposit
     * @param {Number} minValue minimum value for the savings deposit amount
     * @returns {Object} The response object to set state in the component.
     */
  HandleInputChangeS4 = (valueReal, minValue, id) => {
    const pattern = /^[0-9\b]+$/;
    let request = {
        valid: true,
        message: null,
        value: valueReal!= undefined ? valueReal.replaceAll(',', ''): ''
    };

    if (request.value.trim() == '') {
        if(id == 'retirementAge'){
        request.message = '*Campo es requerido.';
        request.valid = false;
        }  
    }
    else if (!request.value.trim() == '' && !pattern.test(request.value)) {
        request.message = '*Este campo solo permite valores númericos enteros.';
        request.valid = false;
    } else {
        if (!request.value.trim() == '' && pattern.test(request.value) && minValue != null) {
            if (Number(request.value) < minValue) {
                if(id == 'retirementAge'){
                    request.message = `*La edad debe ser mayor o igual que ${minValue}.`;
                    request.valid = false; 
                }else{
                    request.message = `*El monto debe ser mayor o igual que $${minValue}.`;
                    request.valid = false; 

                }
            } else {
                if (Number(request.value) >= 0) {
                    request.valid = true;
                    request.value = (request.value * 1).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                    request.value = request.value.toString().substring(0, request.value.indexOf('.'));
                }
            }
        } else {
            if (!request.value.trim() == '' && pattern.test(request.value)) {
                if (Number(request.value) >= 0) {
                    request.valid = true;
                    request.value = (request.value * 1).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                    request.value = request.value.toString().substring(0, request.value.indexOf('.'));
                }
            }
        }
    }

    return request;
}
    /**
     * Function that performs the validations of the numeric type field
     * @param {String} valueReal value of the amount deposited in the savings deposit
     * @param {Number} minValue minimum value for the savings deposit amount
     * @returns {Object} The response object to set state in the component.
     */
    HandleInputChange = (valueReal, minValue, id) => {
        const pattern = /^[0-9\b]+$/;
        let request = {
            valid: true,
            message: null,
            value: valueReal.replaceAll(',', '')
        };

        if (request.value.trim() == '') {
            request.message = '*Campo es requerido.';
            request.valid = false;
        }
        else if (!request.value.trim() == '' && !pattern.test(request.value)) {
            request.message = '*Este campo solo permite valores númericos enteros.';
            request.valid = false;
        } else {
            if (!request.value.trim() == '' && pattern.test(request.value) && minValue != null) {
                if (Number(request.value) < minValue) {
                    if(id == 'retirementAge'){
                        request.message = `*La edad debe ser mayor o igual que ${minValue}.`;
                        request.valid = false; 
                    }else{
                        request.message = `*El monto debe ser mayor o igual que $${minValue}.`;
                        request.valid = false; 

                    }
                } else {
                    if (Number(request.value) >= 0) {
                        request.valid = true;
                        request.value = (request.value * 1).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                        request.value = request.value.toString().substring(0, request.value.indexOf('.'));
                    }
                }
            } else {
                if (!request.value.trim() == '' && pattern.test(request.value)) {
                    if (Number(request.value) >= 0) {
                        request.valid = true;
                        request.value = (request.value * 1).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                        request.value = request.value.toString().substring(0, request.value.indexOf('.'));
                    }
                }
            }
        }

        return request;
    }

    /**
      * Sends a new application of insurance to TSV server.
      * @return {Promise} The response of TSV server.
      */
    SendApplicationSale = async () => {
        let JSONLocalStorage = this.jsonExchangeTSV.getJSONTSV();
        let request = { data: JSONLocalStorage };
        let responseService =
            await this.webServicesTSV.ApplicationSales(request);
        if (responseService.statusResponse) {
            return responseService.data;
        } else {
            return false;
        }
    }

    /**
     * Creates a new JSV process file in the API.
     * @return {Promise} The response of the API.
     */
    CreateJSONProcessFile = async () => {
        let JSONLocalStorage = this.jsonExchangeTSV.getJSONTSV();
        let request = { file: JSONLocalStorage };
        let responseService =
            await this.JSONProcessFileServices.AddJSONProcessFile(request);
        if (responseService.statusResponse) {
            return responseService.data;
        } else {
            return false;
        }
    }
    /**
      * Get the rider detail from the localstorage 
      * @returns {Array} Rider detail.
      */
    GetCurrentDetails = () => {
        let details = this.jsonExchangeTSV.getCurrentDetailsOfProductPayment();
        let sections = details.illustrationEmail != undefined ?
            details.illustrationEmail.policyDetails.sections : [];

        return sections;
    }
}